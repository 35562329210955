import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Actions } from 'src/Routes/Cabs/Navigation';
import { prepareTqPageData } from '../utils/cabsLocalStore';
import { PaymentMode } from './PaymentOptions';
import { trackReviewAbEvent, trackReviewEvent, trackReviewLoad } from '../cabsAnalytics';
import TripType from '../types/TripType';
import logAction from '../../../Helpers/actionsLogger';
import {
  CABS_HOLD_FAILURE_MSG,
  CabsMultiVendorTags,
  myBizTravellerTypes,
  CabABKeys,
  TRAVELLER_DETAILS,
} from '../cabsConstants';
import {
  handleReviewDateChange,
  handleReviewCityChange,
  searchDeepLink,
  setCabPackage,
} from '../Listing/cabsListingActions';
import { tuneReviewTracker } from '../cabsTuneTracker';
import {
  showMoreOptions,
  modifyPartnerBannerData,
  getPokusFlagsForBE,
  getTripDataFromState,
  isIsomorphicFunnelUser,
} from '../utils/cabsCommonUtils';
import {
  getCabDetailResponse,
  getFetchCouponResponse,
  getHoldResponse,
  getValidateCouponResponse,
  getDeleteCouponResponse,
  getMyBizTripTagData,
} from '../api/cabsApis';
import {
  _isDepartureTimePassed,
  fetchPaxDetailsHelper,
  getUpdatedCab,
  openPaymentPage,
  Status,
  getGender,
} from '../utils/cabReviewActionsUtils';
import * as CabsCityPickerAction from '../Landing/Components/CityPickerAction';
import { validateSearchRequest } from '../utils/cabsSearchUtil';
import { showShortToast } from '../../../Common/Components/Toast';
import CabsABConfigHelper from '../utils/CabsABConfigHelper';
import {
  getFetchCouponRequest,
  getHoldRequest,
  getValidateCouponRequest,
} from '../api/cabsRequestBuilder';
import CabsPdtReviewHelper from '../PdtAnalytics/PdtHelper/CabsPdtReviewHelper';
import CabsPdtListingHelper from '../PdtAnalytics/PdtHelper/CabsPdtListingHelper';
import cabsListDecorator from '../utils/cabsListDecorator';
import { ViewStates } from '../Listing/cabsListingReducer';
import CabOmnitureEvent from '../cabsOmnitureEvents';
import { skywalkerSectorConnector } from '../Skywalker/connector';
import { pushGTMData, getParamsForGTM } from '../utils/GTM/util';

import {
  formatGSTDetails,
  formatTripTagResponse,
  prepareGSTDataForHold,
  prepareTripTagDataForHold,
} from './Components/TripTagWithGST/util';
import { ProfileType } from '../../../Native/UserSession/UserSessionModule';
import { firebaseReviewTracker } from '../Analytics/Firebase/util';
import { CabsScreen } from '../config/cabsScreen';
import _compact from 'lodash/compact';
import { GSTIN_USER_CONSENT, editParamsToUrl } from './utils';
import { Platform } from 'react-native';
export const ACTION_SET_ROOT_TAG = 'ACTION_SET_ROOT_TAG';
export const ACTION_SET_CAB_TYPE = 'ACTION_SET_CAB_TYPE';
export const ACTION_SET_CAB_FOR_REVIEW = 'ACTION_SET_CAB_FOR_REVIEW';
export const ACTION_UPDATE_PAX_DETAIL = 'ACTION_UPDATE_PAX_DETAIL';
export const ACTION_HOLD_FAILURE = 'ACTION_HOLD_FAILURE';
export const ACTION_HOLD_SUCCESS = 'ACTION_HOLD_SUCCESS';
export const ACTION_HOLD_IN_PROGRESS = 'ACTION_HOLD_IN_PROGRESS';
export const ACTION_OTP_CHANGE = 'ACTION_OTP_CHANGE';
export const ACTION_PAYMENT_OPTION_CHANGE = 'ACTION_PAYMENT_OPTION_CHANGE';
export const ACTION_UPDATE_COUPONS = 'ACTION_UPDATE_COUPONS';
export const ACTION_UPDATE_COUPON_VALIDATION_STATUS = 'ACTION_UPDATE_COUPON_VALIDATION_STATUS';
export const ACTION_UPDATE_AVAILABLE_CABS = 'ACTION_UPDATE_AVAILABLE_CABS';
export const ACTION_DISMISS_REVIEW = 'ACTION_DISMISS_REVIEW';
export const ACTION_RESET_REVIEW = 'ACTION_RESET_REVIEW';
export const ACTION_UPDATE_VIEW_STATE = 'ACTION_UPDATE_VIEW_STATE';
export const ACTION_SET_HYPERLOCATION_ACTIVITY_INDICATOR =
  'ACTION_SET_HYPERLOCATION_ACTIVITY_INDICATOR';
export const ACTION_SET_MY_VALUES_PARAMS = 'ACTION_SET_MY_VALUES_PARAMS';
export const ACTION_SET_E_PASS_CHECKLIST = 'ACTION_SET_E_PASS_CHECKLIST';
export const ACTION_SET_MMT_BLACK_PERSUASION = 'ACTION_SET_MMT_BLACK_PERSUASION';
export const ACTION_ADD_INTERVENTION_CAB_VISITED = 'ACTION_ADD_INTERVENTION_CAB_VISITED';
export const ACTION_UPDATE_TRAVEL_REQUEST = 'ACTION_UPDATE_TRAVEL_REQUEST';
export const ACTION_ADD_TYPED_COUPON = 'ACTION_ADD_TYPED_COUPON';
export const ACTION_SET_BPG_INFO_REVIEW = 'ACTION_SET_BPG_INFO_REVIEW';
export const ACTION_SET_CANCELLATION_INFO = 'ACTION_SET_CANCELLATION_INFO';
export const ACTION_SET_REVIEW_DATA = 'ACTION_SET_REVIEW_DATA';
export const ACTION_SET_ALTER_COUPON_MODAL_OPEN = 'ACTION_SET_ALTER_COUPON_MODAL_OPEN';
export const ACTION_UPDATE_TRIP_TAG_VALUE = 'ACTION_UPDATE_TRIP_TAG_VALUE';
export const ACTION_UPDATE_GST_DETAILS = 'ACTION_UPDATE_GST_DETAILS';
export const ACTION_UPDATE_DEFAULT_GST_DETAILS = 'ACTION_UPDATE_DEFAULT_GST_DETAILS';
export const ACTION_SET_LOCUS_INFO = 'ACTION_SET_LOCUS_INFO';
export const ACTION_SET_MYBIZ_REQUEST_REASONS = 'ACTION_SET_MYBIZ_REQUEST_REASONS';
export const ACTION_SET_INITIATE_APPROVAL_DATA = 'ACTION_SET_INITIATE_APPROVAL_DATA';
export const ACTION_SET_REVIEW_ERROR = 'ACTION_SET_REVIEW_ERROR';
export const ACTION_RESET_REVIEW_ERROR = 'ACTION_RESET_REVIEW_ERROR';
export const ACTION_SET_BOOKING_FOR_TAB = 'ACTION_SET_BOOKING_FOR_TAB'
export const ACTION_SET_USER_PAX_DETAILS = 'ACTION_SET_USER_PAX_DETAILS'
export const ACTION_UPDATE_SELECTED_ADDONS = 'ACTION_UPDATE_SELECTED_ADDONS';
export const KeyboardEvents = {
  KEYBOARD_EVENT_FLIGHT_NUMBER: 'KEYBOARD_EVENT_FLIGHT_NUMBER',
  KEYBOARD_EVENT_PASSENGER_DETAIL: 'KEYBOARD_EVENT_PASSENGER_DETAIL',
};

export const updateTripTagData = (tripTagData) => ({
  type: ACTION_UPDATE_TRIP_TAG_VALUE,
  data: tripTagData,
});

export const updateTripTagValue = (id, value) => (dispatch, getState) => {
  const { tripTagData, defaultGSTDetails } = getState().cabsReview;
  try {
    // Create new trip tag object and update
    const updatedAttributeIndex = tripTagData.tripTagAttributeList.findIndex(
      (item) => item.attributeId === id,
    );
    const updatedTripTagValue = {
      ...tripTagData.tripTagAttributeList[updatedAttributeIndex],
      attributeValue: value,
      isAttributeErrorState: false,
    };
    const mutatedAttrList = [...tripTagData.tripTagAttributeList];
    mutatedAttrList.splice(updatedAttributeIndex, 1);
    mutatedAttrList.splice(updatedAttributeIndex, 0, updatedTripTagValue);
    const updatedTripTagData = {
      ...tripTagData,
      tripTagAttributeList: mutatedAttrList,
    };
    dispatch(updateTripTagData(updatedTripTagData));

    // Update GST mapped to the trip tag value
    if (
      updatedTripTagValue?.possibleValuesAndGST?.length > 0 &&
      updatedTripTagValue?.gstBasedTripTag
    ) {
      const optionIndex = updatedTripTagValue.possibleValuesAndGST.findIndex(
        (item) => item.value === value,
      );
      if (optionIndex < 0) return;
      if (updatedTripTagValue.possibleValuesAndGST[optionIndex]?.gstDetails) {
        dispatch(
          updateGSTData(
            formatGSTDetails(updatedTripTagValue.possibleValuesAndGST[optionIndex].gstDetails),
          ),
        );
      } else {
        dispatch(updateGSTData(defaultGSTDetails));
      }
    }
  } catch (e) {
    //  Ignore exception
  }
};

export const setCabForReviewAction = (cab, isAlternateCab = false) => ({
  type: ACTION_SET_CAB_FOR_REVIEW,
  data: { cab, isAlternateCab },
});

export const updateSelectedAddons = (addon) => ({
  type: ACTION_UPDATE_SELECTED_ADDONS,
  data: { addon },
});

export const setAvailableCabForReviewAction = (cabList) => ({
  type: ACTION_UPDATE_AVAILABLE_CABS,
  data: cabList,
});

export const setCabType = (type) => ({
  type: ACTION_SET_CAB_TYPE,
  data: type,
});

export const setViewState = (state) => ({
  type: ACTION_UPDATE_VIEW_STATE,
  data: state,
});

export const setPaxDetail = (pax, paxType = TRAVELLER_DETAILS.MYSELF) => ({
  type: ACTION_UPDATE_PAX_DETAIL,
  data: { pax, paxType },
});

export const setUserPaxDetails = (paxType) => (dispatch, getState) => {
  try {
    const { userDetails } = getState().cabs;
    const { firstName, lastName, email, mobile, gender = null } = userDetails;
    let mobileNumber,
      countryCode = '';
    if (mobile) {
      mobileNumber = mobile.mobileNumber;
      countryCode = mobile.countryCode;
    }
    const userGender = getGender(gender);
    const hasCompleteFields = !(_isEmpty(email) || _isEmpty(mobileNumber));
    const fullName = _compact([firstName, lastName]).join(' ');
    const pax = {
      name: fullName,
      email,
      mobile: mobileNumber,
      countryCode: countryCode || '91',
      gender: userGender,
      hasCompleteFields,
    };
    if (!_isEmpty(pax)) {
      dispatch(setPaxDetail(pax, paxType));
    }
  } catch (error) {
    if (error) {
      const pax = {
        name: '',
        email: '',
        mobile: '',
        countryCode: '91',
        gender: '',
      };
      dispatch(setPaxDetail(pax));
    }
  }

}
export const setRootTag = (tag) => ({
  type: ACTION_SET_ROOT_TAG,
  data: tag,
});

export const setOtp = (otp) => ({
  type: ACTION_OTP_CHANGE,
  data: otp,
});

export const holdInProgress = () => ({
  type: ACTION_HOLD_IN_PROGRESS,
});

export const holdSuccess = () => ({
  type: ACTION_HOLD_SUCCESS,
});

export const setInitiateApprovalSuccessData = (data) => ({
  type: ACTION_SET_INITIATE_APPROVAL_DATA,
  data: data,
});

export const holdFailure = (message, target_screen = null) => (dispatch) => {
  trackReviewEvent('Mob_Cab_Review_Hold_Failed');
  dispatch({
    type: ACTION_HOLD_FAILURE,
  });
  dispatch(setReviewError(message, target_screen));
};

export const setReviewError = (message, target_screen = null) => (dispatch) => {
  dispatch({
    type: ACTION_SET_REVIEW_ERROR,
    data: {
      message,
      target_screen,
    },
  });
};

export const resetReviewError = () => ({
  type: ACTION_RESET_REVIEW_ERROR,
});

export const setIsAlterCouponModalOpen = (data) => (dispatch) => {
  dispatch({
    type: ACTION_SET_ALTER_COUPON_MODAL_OPEN,
    data: data,
  });
};

export const updateCoupons = (coupons) => ({
  type: ACTION_UPDATE_COUPONS,
  data: coupons,
});

export const addTypedCoupon = (coupon) => ({
  type: ACTION_ADD_TYPED_COUPON,
  data: coupon,
});

export const updateCouponStatus = (status, response) => ({
  type: ACTION_UPDATE_COUPON_VALIDATION_STATUS,
  data: {
    status,
    response,
  },
});

export const setHyperlocationActivityIndicator = (value) => ({
  type: ACTION_SET_HYPERLOCATION_ACTIVITY_INDICATOR,
  data: value,
});
export const paymentOptionChange = (option) => ({
  type: ACTION_PAYMENT_OPTION_CHANGE,
  data: option,
});

export const setMySafetyParams = (values) => ({
  type: ACTION_SET_MY_VALUES_PARAMS,
  data: values,
});

export const setMyBizRequestReasons = (data) => ({
  type: ACTION_SET_MYBIZ_REQUEST_REASONS,
  data: data,
});

export const updateTravelRequest = (data) => ({
  type: ACTION_UPDATE_TRAVEL_REQUEST,
  data,
});

export const setMMTBlackPersuasion = (values) => ({
  type: ACTION_SET_MMT_BLACK_PERSUASION,
  data: values,
});

export const setEPassChecklist = (values) => ({
  type: ACTION_SET_E_PASS_CHECKLIST,
  data: values,
});

export const resetReviewAction = () => ({
  type: ACTION_RESET_REVIEW,
});

export const dismissReviewAction = () => ({
  type: ACTION_DISMISS_REVIEW,
});

export const addInterventionCabVisited = (cabDetails) => ({
  type: ACTION_ADD_INTERVENTION_CAB_VISITED,
  data: cabDetails,
});

export const otpChange = (otp) => (dispatch) => {
  dispatch(setOtp(otp));
};

export const setDeepLinkData = (deepLinkData) => async (dispatch) => {
  dispatch(setCabType(deepLinkData.cabType));
  dispatch(searchDeepLink(deepLinkData));
  dispatch(
    setCabPackage({
      category_id: deepLinkData?.category_id,
      package: deepLinkData?.cabPackage ? deepLinkData?.cabPackage : 'NONE',
      vendor_name: deepLinkData?.vendor_name,
      safety_variant: deepLinkData?.safety_variant,
      vehicle_model_type: deepLinkData?.vehicle_model_type,
      fuel_type: deepLinkData?.fuel_type,
    }),
  );
};

export const setBPGInfo = (info) => (dispatch) => {
  dispatch({
    type: ACTION_SET_BPG_INFO_REVIEW,
    data: info,
  });
};

export const setFreeCancellationText = (text) => (dispatch) => {
  dispatch({
    type: ACTION_SET_CANCELLATION_INFO,
    data: text,
  });
};

export const setLocusInfo = (locusInfo) => (dispatch) => {
  dispatch({
    type: ACTION_SET_LOCUS_INFO,
    data: locusInfo,
  });
};

export const onCabsLoadedAction = () => (dispatch, getState) => {
  const {
    cabsReview: { selectedCabType, fuelType, isMySafetyKitEnabled },
  } = getState();
  dispatch(fetchCabDetails(fuelType, selectedCabType, isMySafetyKitEnabled || false));
};

const _handleGTMData = (data) => {
  try {
    const request = data.request || {};
    const response = data.response || {};
    // TODO : change getting isFromAirport value
    const isFromAirport =
      (request?.trip_type === TripType.AT.value && request?.source_city?.indexOf('_AIR') > -1) ||
      false;
    let gtmTagCode = TripType[request?.trip_type]?.gtmTagCode || '';
    if (request?.trip_type === TripType.AT.value) {
      if (isFromAirport) {
        gtmTagCode = 'AT-A2C';
      } else {
        gtmTagCode = 'AT-C2A';
      }
    }
    const gtmData = getParamsForGTM({
      sourceLocation: request?.source_location || {},
      destinationLocation: request?.destination_location || {},
      departureDate: request?.departure_date || '',
      pickupTime: request?.pickup_time || '',
      returnDate: request?.return_date || '',
      gtmTagCode,
      bookingAmount: response?.cab_list[0]?.cab_fare_detail?.full_payable_amount,
      cabType: response?.cab_list[0]?.type,
      sourceCityName: request?.source_city_name || '',
      destinationCityName: request?.destination_city_name || '',
      pageType: 'review',
    });

    setTimeout(() => pushGTMData(gtmData), 1000);
  } catch (e) {
    // Ignore exception
  }
};
export const fetchTripTagData = (sourceState) => async (dispatch, getState) => {
  const {
    cabs: {
      userDetails: { profileType = ProfileType.PERSONAL, mmtAuth = null, email },
    },
    cabsMyBizPrimaryPax: { is_guest_user = false, primary_pax_details = null },
  } = getState();
  if (profileType !== ProfileType.BUSINESS) return;
  let primaryPaxEmail = email;
  if (!is_guest_user) {
    primaryPaxEmail = primary_pax_details?.businessEmailId;
  }

  const tripTagResponse = await getMyBizTripTagData(mmtAuth, sourceState, primaryPaxEmail);
  const tripTagData = formatTripTagResponse(tripTagResponse);
  dispatch(updateTripTagData(tripTagData));
  if (tripTagResponse?.status === 'success' && tripTagResponse?.defaultGST?.gstDetails) {
    const gstData = {
      header: tripTagResponse?.gstSectionDetails?.header || '',
      description: tripTagResponse?.gstSectionDetails?.description || '',
      ...formatGSTDetails(tripTagResponse?.defaultGST?.gstDetails),
    };
    dispatch(updateGSTData(gstData));
    dispatch(updateDefaultGSTData(gstData));
  }
};

const updateGSTData = (gstData) => ({
  type: ACTION_UPDATE_GST_DETAILS,
  data: gstData,
});

export const updateDefaultGSTData = (gstData) => ({
  type: ACTION_UPDATE_DEFAULT_GST_DETAILS,
  data: gstData,
});

export const fetchCabDetails = (
  fuelType,
  type,
  isMySafetyKitEnabled = false,
  connectorSearch = false,
) => async (dispatch, getState) => {
  dispatch(setViewState(ViewStates.VIEW_LOADING));
  const {
    cabsListing: {
      searchServerRequest: request,
      selectedCabPackage,
      fuelTypePokusKey,
      tripType = TripType.OW.value,
      marketingHotelId,
      nonRapCabData = null,
    },
    cabsReview: { cab }
  } = getState();
  if (type) {
    const reviewRequest = {
      ...request,
      type: 'reviewPageDetails',
      is_e_pass_enabled: true,
      is_default_search: false,
      is_custom_payment_allowed: true,
      show_kit_enabled_cabs: true,
      is_mysafety_kit_available: isMySafetyKitEnabled,
      cab_type: type,
      fuel_type: fuelType,
      connector_search: connectorSearch,
      is_other_cabs_enabled: false, // showMoreOptions()
      fuel_type_pokus_config: fuelTypePokusKey,
      pokus_flags: getPokusFlagsForBE(),
      ...selectedCabPackage,
      misc_attributes: {
        marketing_hotel_id: marketingHotelId,
      },
    };
    if(reviewRequest?.trip_type === TripType.RT.value){
      reviewRequest.drop_time = null
    }
    if (Boolean(nonRapCabData)) {
      reviewRequest.is_upsell_request = true;
    } else {
      reviewRequest.is_upsell_request = false;
    }
    const response = await getCabDetailResponse(reviewRequest);
    const detailResponse = await response.json();
    try {
      _handleGTMData(detailResponse);
      dispatch(
        setFreeCancellationText(detailResponse.response.cancellation_message_new?.content || ''),
      );
      dispatch(setBPGInfo(detailResponse.response.best_price_guaranteed_data));
      const mySelectedCab = detailResponse.response?.cab_list[0];
      dispatch(
        setCabPackage({
          category_id: mySelectedCab?.category_id,
          package: mySelectedCab?.cabPackage,
          vendor_name: mySelectedCab?.vendor_name,
          safety_variant: mySelectedCab?.safety_variant,
          vehicle_model_type: mySelectedCab?.vehicle_model_type,
          fuel_type: mySelectedCab?.fuel_type || null,
        }),
      );
      if (tripType !== TripType.HR.value && !reviewRequest.stopovers?.length) {
        skywalkerSectorConnector(detailResponse.request, CabsScreen.REVIEW);
      }
      let cabList = detailResponse.response.cab_list;
      cabList = cabsListDecorator(cabList);
      const recommendedCabs = cabList.filter(
        (cab) => cab.multi_vendor_tag === CabsMultiVendorTags.RECOMMENDED,
      );

      let activeCab = cabList[0];
      if (!_isEmpty(recommendedCabs)) {
        activeCab = recommendedCabs[0];
      }

      if (activeCab) {
        dispatch(setCabForReviewAction(activeCab));
        const reviewData = {};
        reviewData.tripGuarantee = detailResponse?.response?.trip_guarantee || null;
        reviewData.userConsentData = detailResponse?.response?.consent || null;
        reviewData.locusInfo = detailResponse.request?.locus_info || null;
        reviewData.cancellationPolicies =
          detailResponse?.response?.cancellation_details?.cancellation_policy || null;
        reviewData.myBizApprovalFlowData = detailResponse?.response?.mybiz?.approval || null;
        reviewData.fuelTypePokusKey = detailResponse?.request?.fuel_type_pokus_config || null;
        reviewData.responsePokusValues = detailResponse?.request?.pokus_flags || null;
        reviewData.rapBanner = detailResponse?.response?.rap_banner || null;
        reviewData.trip_distance_and_duration = detailResponse?.trip_distance_and_duration;
        reviewData.rydeUpsellData = detailResponse?.response?.ryde_upsell_data || null;
        reviewData.isCabCardExpanded = detailResponse?.response?.is_cab_card_expanded || false;
        reviewData.payButtonCTAData = detailResponse?.response?.review_page_pay_cta || null;
        reviewData.brand_info = detailResponse?.response?.brand_info || null;
        reviewData.whatsapp_chat_details = detailResponse?.response?.whatsapp_chat_details || null;
        reviewData.show_trip_assistant = detailResponse?.response?.show_trip_assistant || false;
        dispatch({
          type: ACTION_SET_REVIEW_DATA,
          data: reviewData,
        });
        dispatch(fetchTripTagData(detailResponse.source_state));
        // @TODO saurav: combine other dispatch functions in setReviewData
        dispatch(setMMTBlackPersuasion(detailResponse?.response?.mmt_black_persuasion || null));
        dispatch(setAvailableCabForReviewAction(cabList));
        dispatch(onReviewCabChange());
        if (detailResponse?.response?.my_safety_values && activeCab.my_safety) {
          dispatch(setMySafetyParams(detailResponse.response.my_safety_values));
        } else {
          dispatch(setMySafetyParams(null));
        }
        dispatch(setEPassChecklist(detailResponse?.response?.e_pass_checklist || null));
        const newCabAddons = activeCab?.travel_addons || [];
        const missingAddons = cab?.travel_addons?.filter(
          (selectedAddon) => !newCabAddons?.find((addon) => addon?.id === selectedAddon?.id),
        );
        if (missingAddons?.length) {
          dispatch(
            setReviewError(
              `Sorry! Addon services (${missingAddons
                .map((addon) => addon?.name)
                .join(
                  ', ',
                )}) are not available for selected date time or pickup location. Please select a different cab`,
            ),
          );
          trackReviewEvent(`Mob_Cab_Review_Addon_Not_Available_On_Change`);
        }
      } else {
        dispatch(dismissReviewAction());
      }
    } catch (e) {
      if (detailResponse?.errors?.error_list?.[0]?.message) {
        dispatch(
          setReviewError(
            detailResponse?.errors?.error_list?.[0]?.message,
            detailResponse?.request?.target_screen || null,
          ),
        );
      } else {
        dispatch(dismissReviewAction());
      }
    }
  }
};

export const onReviewCabChange = () => (dispatch, getState) => {
  dispatch(fetchCoupons());
  const { commonPokusFlags } = getState().cabs;
  const {
    cabsReview: {
      cab,
      alternateCabCount,
      myBizApprovalFlowData,
      responsePokusValues,
      rydeUpsellData,
    },
    cabsListing: {
      searchServerRequest,
      mmtApproxDistance,
      activeDurationFilter,
      selectedHRCabPackage,
      nonRapCabData,
    },
    cabsLanding: { travelType },
  } = getState();

  const tripType = searchServerRequest.trip_type;
  const isMultiVendorPresent = alternateCabCount > 0;
  const cabsFlightInputEnabled = Boolean(commonPokusFlags?.Cab_Ask_Flight_Number);
  let is_airport_prime = false;
  try {
    is_airport_prime = cab.cab_fare_detail.is_airport_prime || false;
  } catch (e) {
    is_airport_prime = false;
  }
  if (is_airport_prime) {
    trackReviewEvent('Mob_Cab_Listing_Airport_Prime');
  }
  const durationFilter = tripType === TripType.HR.value ? activeDurationFilter : null;
  const isMyBizApprovalRequired = Boolean(myBizApprovalFlowData?.is_approval_required);
  trackReviewLoad(
    cab,
    mmtApproxDistance,
    durationFilter,
    isMyBizApprovalRequired,
    responsePokusValues,
    selectedHRCabPackage,
    tripType,
    rydeUpsellData,
    nonRapCabData,
  );
  trackReviewEvent(`Mob_Cab_Review_${cab.type}`);
  if (travelType !== 'Outstation') {
    trackReviewAbEvent(`Flight_Number_Field_${cabsFlightInputEnabled ? 'Present' : 'Absent'}`);
  }
  tuneReviewTracker(searchServerRequest, cab);
  firebaseReviewTracker(searchServerRequest, cab);
};

export const fetchPaxDetail = () => async (dispatch, getState) => {
  const pax = await fetchPaxDetailsHelper(getState);
  const { userDetails } = getState().cabs
  const review = getState().cabsReview
  if ((!review.pax?.bookingForTabSelected && !_isEmpty(pax)) || _isEmpty(userDetails)) {
    dispatch(setPaxDetail(pax));
    return
  }
  if (!_isEmpty(pax)) {
    dispatch(setPaxDetail({ ...pax, bookingFor: review.pax?.bookingForTabSelected }), review.pax?.bookingForTabSelected === TRAVELLER_DETAILS.MYSELF ? TRAVELLER_DETAILS.MYSELF : TRAVELLER_DETAILS.SOMEONEELSE);
  }


  return pax;
};

export const fetchCoupons = () => async (dispatch, getState) => {
  const {
    cabsReview: { cab },
    cabsListing: {
      searchServerRequest: request,
      transactionKey,
      cabs,
      distance,
      supplier_id: supplierId,
      sourceAndCmp,
      selectedHRCabPackage,
    },
    cabs: { userDetails, deviceDetails = {} },
    cabsLanding: { sourceData, destinationData, departDate, returnDate },
  } = getState();

  const {
    source_city: sourceCityCode,
    destination_city: destinationCityCode,
    trip_type: tripType,
    locus_info: locusInfo,
  } = request;

  let coupons = [];
  let fetchCouponRequest = await getFetchCouponRequest(
    cab,
    request,
    transactionKey,
    userDetails,
    deviceDetails,
  );
  fetchCouponRequest = {
    ...fetchCouponRequest,
    supplier_id: supplierId,
    package_key: selectedHRCabPackage,
  };
  if (Boolean(sourceAndCmp?.source?.toLowerCase().includes('seo'))) {
    fetchCouponRequest = { ...fetchCouponRequest, flow_type: 'SEO' };
  }
  try {
    const response = await getFetchCouponResponse(fetchCouponRequest);
    const couponResponse = await response.json();
    coupons = couponResponse.response;
  } catch (e) {
    console.error('fetchCoupon failed', e);
  }
  dispatch(updateCoupons(coupons));
  const pdtData = {
    userDetails,
    deviceDetails,
    cab,
    cabs,
    coupons,
    searchData: {
      sourceData,
      destinationData,
      departDate,
      returnDate,
      sourceCityCode,
      destinationCityCode,
      tripType,
      locusInfo,
    },
  };
  CabsPdtListingHelper.trackPageExit(pdtData);
  CabsPdtReviewHelper.trackPageLoad(pdtData);
};

export const validateReviewCoupon = (code, isDeleteCoupon = false) => async (
  dispatch,
  getState,
) => {
  code = code?.toUpperCase();
  const {
    cabsReview: { cab, coupons = [], typedCoupons = [] },
    cabsListing: {
      searchServerRequest: request,
      transactionKey,
      activeDurationFilter,
      selectedCabPackage,
      searchRequest,
      sourceAndCmp,
      selectedHRCabPackage,
    },
    cabs: { userDetails, deviceDetails = {} },
  } = getState();

  // ToDo Check if coupon code is already applied
  // if (cab.coupon_code === code) {
  //   return;
  // }

  dispatch(updateCouponStatus(Status.IN_PROGRESS, { code, message: '' }));

  let package_key = selectedHRCabPackage;
  let validCouponRequest = await getValidateCouponRequest(
    cab,
    request,
    transactionKey,
    activeDurationFilter,
    userDetails,
    code,
    package_key,
    deviceDetails,
  );
  validCouponRequest = {
    ...validCouponRequest,
    ...selectedCabPackage,
    is_intercity_2: searchRequest.is_intercity_2,
    pokus_flags: getPokusFlagsForBE(),
  };
  if (Boolean(sourceAndCmp?.source?.toLowerCase().includes('seo'))) {
    validCouponRequest = { ...validCouponRequest, flow_type: 'SEO' };
  }
  let response = null;
  try {
    response = isDeleteCoupon
      ? await getDeleteCouponResponse(validCouponRequest)
      : await getValidateCouponResponse(validCouponRequest);
  } catch (e) {
    //
  }
  const { validateResponseStatus, message, updatedCab, trackEventMessage } = await getUpdatedCab(
    response,
    cab,
    isDeleteCoupon,
  );
  if (validateResponseStatus === 'SUCCESS') {
    if (isDeleteCoupon === false) {
      trackReviewEvent(trackEventMessage);
      if (
        !(
          (coupons && coupons.some((coupon) => coupon.coupon_code === code)) ||
          (typedCoupons && typedCoupons.some((coupon) => coupon.coupon_code === code))
        )
      ) {
        dispatch(addTypedCoupon(code));
      }
    }
    dispatch(updateCouponStatus(Status.SUCCESS, { code, message }));
    dispatch(updateCab(updatedCab));
    return;
  }
  if (isDeleteCoupon === false) {
    trackReviewEvent(trackEventMessage);
  }
  dispatch(updateCouponStatus(Status.FAILED, { code, message }));
  dispatch(updateCab(updatedCab));
};

export const updateCab = (cab) => (dispatch, getState) => {
  const { isAlternateCab } = getState().cabsReview;
  dispatch(setCabForReviewAction(cab, isAlternateCab));
};

export const onAlternateCabChange = (cab) => (dispatch) => {
  dispatch(setCabForReviewAction(cab, true));
  dispatch(onReviewCabChange());
};

export const performHold = (gstDetails = {}) => async (dispatch, getState) => {
  let is_full_payment = true;
  const {
    cabsReview: {
      cab,
      pax,
      otp,
      coupons,
      allowPartPayment,
      ePassChecklist,
      allowZeroPayment,
      selectedPaymentOption,
      isAlternateCab,
      travelRequest,
      tripTagData,
      gstData,
      locusInfo,
      myBizApprovalRequestReasons,
      fuelTypePokusKey,
    },
    cabsListing: {
      cabs,
      flightNumber,
      searchServerRequest: request,
      activeDurationFilter,
      transactionKey,
      headerProps: tripHeaderData,
      selectedCabPackage,
      searchRequest,
      selectedHRCabPackage,
      marketingHotelId,
      sourceAndCmp,
    },
    cabsLanding: { sourceData, destinationData, departDate, returnDate, additionalCityData = null, landingSourceAndCmp },
    cabs: { userDetails, deviceDetails = {} },
    cabsMyBizPrimaryPax: { is_guest_user = false, primary_pax_details = null },
    bnplReducer: { bnplEligibleAmount = 0 },
    gstinReducer: { userConsent }

  } = getState();

  const isMulticityEnabled = CabsABConfigHelper.multiCityEnabled();
  let isMulticityRequest = false;
  const tripType = request.trip_type;

  if (isMulticityEnabled && !_isEmpty(additionalCityData) && tripType === TripType.RT.value) {
    isMulticityRequest = true;
  }
  let advance_type = 'FULL_ADVANCE';
  let waitForCTD = null;
  if (allowPartPayment || allowZeroPayment) {
    switch (selectedPaymentOption) {
      case PaymentMode.full:
        trackReviewEvent('Mob_Cab_Review_Complete_Payment_Click');
        break;
      case PaymentMode.part:
        is_full_payment = false;
        advance_type = 'CUSTOM_ADVANCE';
        trackReviewEvent('Mob_Cab_Review_Partial_Payment_Click');
        break;
      case PaymentMode.zero:
        is_full_payment = false;
        advance_type = 'CTD';
        waitForCTD = new Promise((resolve) => {
          setTimeout(resolve, 3000, '');
        });
        trackReviewEvent('Mob_Cab_Review_Zero_Payment_Click');
        break;
      default:
        break;
    }
  } else {
    trackReviewEvent('Mob_Cab_Review_Default_Payment_Click');
  }
  const paxDetails = pax[pax.bookingForTabSelected]
  let userEmail = paxDetails.email;
  let sessionToken = null;
  let userProfileType = null;
  let emailVerified = null;
  let loginType = null;
  if (!_isEmpty(userDetails)) {
    sessionToken = userDetails.mmtAuth;
    userProfileType = userDetails.profileType;
    emailVerified = userDetails.emailVerified;
    loginType = userDetails.loginType;
  }

  let package_key = '';
  let package_duration = '';
  let package_distance = '';
  const {
    source_city,
    destination_city,
    intermediate_cities,
    intermediate_locations,
    departure_date,
    return_date,
    pickup_time,
    drop_time,
    is_instant_search: isInstantCab = false,
  } = request;

  const minDepartHrs = _get(cab.journey_detail, 'min_tat_hrs_v2', 0);
  const departureResponse = _isDepartureTimePassed(request, minDepartHrs);
  if (departureResponse.timePassed && !isInstantCab) {
    trackReviewEvent('Mob_Cabs_Review_Hold_Fail_Time_Reset');
    dispatch(handleReviewDateChange(departureResponse.newDepartDate));
    dispatch(setReviewError('Showing cab for nearest available pickup time. Please retry payment'));
    return;
  }

  let tripKey = `${source_city}_${destination_city}_${departure_date}`;

  let destination = destination_city;
  if (tripType === TripType.HR.value) {
    destination = null;
    tripKey = `${source_city}_${departure_date}`;
    if (activeDurationFilter !== null) {
      package_key = activeDurationFilter.package_id;
      package_duration = activeDurationFilter.time;
      package_distance = activeDurationFilter.distance;
    }
  }

  if (return_date) {
    tripKey = `${tripKey}_${return_date}`;
  }

  logAction('hold booking', tripKey);
  let myBizData = {
    trip_tag_details: prepareTripTagDataForHold(tripTagData),
    mybiz_gstin_details: prepareGSTDataForHold(gstData),
  };
  if (Boolean(myBizApprovalRequestReasons)) {
    myBizData = { ...myBizData, ...myBizApprovalRequestReasons };
  }
  let mybiz_traveller_email = null;
  let mybiz_traveller_type = null;
  if (userDetails?.profileType === ProfileType.BUSINESS) {
    try {
      if (is_guest_user) {
        mybiz_traveller_type = myBizTravellerTypes.GUEST;
      } else if (userDetails?.email === primary_pax_details?.businessEmailId) {
        mybiz_traveller_type = myBizTravellerTypes.SELF;
        mybiz_traveller_email = primary_pax_details?.businessEmailId;
      } else {
        mybiz_traveller_type = myBizTravellerTypes.COLLEAGUE;
        mybiz_traveller_email = primary_pax_details?.businessEmailId;
      }
    } catch (e) {
      //
    }
  }

  myBizData = { ...myBizData, mybiz_traveller_email, mybiz_traveller_type };

  const holdRequestData = {
    flightNumber,
    is_full_payment,
    isAlternateCab,
    departure_date,
    pickup_time,
    return_date,
    drop_time,
    source_city,
    intermediate_cities,
    intermediate_locations,
    destination,
    tripKey,
    otp,
    pax: paxDetails,
    tripType,
    transactionKey,
    request,
    cab,
    advance_type,
    userEmail,
    sessionToken,
    package_key,
    package_duration,
    package_distance,
    profile_type: userProfileType,
    deviceDetails,
    emailVerified,
    loginType,
    isInstantCab: request.is_instant_search,
    isMulticityRequest,
    travelRequest,
    myBizData,
    selectedHRCabPackage,
  };
  const pdtData = {
    userDetails,
    deviceDetails,
    cab,
    cabs,
    coupons,
    searchData: {
      sourceData,
      destinationData,
      departDate,
      returnDate,
      sourceCityCode: source_city,
      destinationCityCode: destination_city,
      tripType,
      locusInfo,
    },
    pax: paxDetails,
  };
  CabsPdtReviewHelper.trackPageExit(pdtData);
  CabsPdtReviewHelper.trackPerformHold(pdtData);
  let holdRequest = await getHoldRequest(holdRequestData);
            const url=new URLSearchParams(window.location.href)

  holdRequest = {
    ...holdRequest,
    pokus_flags: getPokusFlagsForBE(),
    misc_attributes: {
      funnel_source: sourceAndCmp?.source || landingSourceAndCmp?.source || null,
      isomorphic_flow: isIsomorphicFunnelUser().toString()
    },
    pay_later_amount: Boolean(bnplEligibleAmount) ? bnplEligibleAmount : 0, // Pass eligible pay now amount for the user
  };
  if(url.has('token')){
    holdRequest = {
      ...holdRequest,
     external_attributes: {
        mgToken: url.get('token') ,
      },
    };
  }
  if (Boolean(marketingHotelId)) {
    holdRequest = {
      ...holdRequest,
      misc_attributes: {
        ...holdRequest?.misc_attributes,
        marketing_hotel_id: marketingHotelId,
      },
    };
  }
  const initiateApprovalType = 'myBizInitiateApprovalRequest';
  if (Boolean(myBizApprovalRequestReasons)) {
    holdRequest = {
      ...holdRequest,
      type: initiateApprovalType,
    };
  }
  if (Boolean(sourceAndCmp?.source?.toLowerCase().includes('seo'))) {
    holdRequest = { ...holdRequest, flow_type: 'SEO' };
  }
  if (!_isEmpty(gstDetails)) {
    holdRequest = { ...holdRequest, gst_details: gstDetails };
  }
  try {
    dispatch(holdInProgress());
    const response = await getHoldResponse(waitForCTD, Boolean(myBizApprovalRequestReasons), {
      ...holdRequest,
      ...selectedCabPackage,
      is_intercity_2: searchRequest.is_intercity_2,
      fuel_type_pokus_config: fuelTypePokusKey,
    });
    const holdResponse = response.pop();
    if (holdResponse.status === 'FAILURE' || holdResponse.errors !== null) {
      logAction('hold booking failed', tripKey);
      const message =
        holdResponse.errors === null || _isEmpty(holdResponse.errors.error_list)
          ? CABS_HOLD_FAILURE_MSG
          : holdResponse.errors.error_list[0].message;
      dispatch(holdFailure(message, holdResponse?.request?.target_screen || null));
      if (holdRequest.type === initiateApprovalType) {
        trackReviewEvent(
          `Mob_Cab_MyBiz_Approval_Request_Error_${holdResponse.errors?.error_list[0]?.code}`,
        );
      } else {
        if (userDetails?.profileType === ProfileType.BUSINESS) {
          trackReviewEvent(
            `Mob_Cab_MyBiz_Skip_Request_Error_${holdResponse.errors?.error_list[0]?.code}`,
          );
        }
      }
      trackReviewEvent({ m_v22: holdResponse.errors?.error_list[0]?.code });
      return;
    }
    logAction('hold booking success', tripKey);
    const holdResponseData = holdResponse.response;
    if (holdRequest.type === initiateApprovalType) {
      trackReviewEvent('Mob_Cab_MyBiz_Approval_Request_Success');
      dispatch(setInitiateApprovalSuccessData(holdResponse));
      dispatch(holdSuccess());
      return;
    }
    pdtData.bookingDetails = holdResponseData;
    const tqPageData = {
      cab,
      holdResponse,
      tripHeaderData,
      pax: paxDetails,
      ePassChecklist,
      selectedPaymentOption,
      pdtData,
    };
    await prepareTqPageData(tqPageData);
    if (tripType !== TripType.HR.value && !request?.stopovers?.length) {
      skywalkerSectorConnector(holdRequest, CabsScreen.PAYMENT);
    }
    if (selectedPaymentOption === PaymentMode.zero || holdResponseData.full_voucher_payment) {
      // Open ThankYou page in case of Cash to driver booking or full voucher payment
      if (holdResponseData.cash_to_driver || holdResponseData.encrypted_mmt_booking_id) {
        dispatch(holdSuccess());
        dispatch(openThankYou());
        addParamsToUrl(userConsent)

      } else {
        dispatch(holdFailure(CABS_HOLD_FAILURE_MSG));
      }
    } else {
      dispatch(holdSuccess());
      dispatch(openPayment(holdResponse));
      addParamsToUrl(userConsent)

    }
  } catch (e) {
    console.error('hold failed', e);
    dispatch(holdFailure(CABS_HOLD_FAILURE_MSG));
  }
};

export const openThankYou = () => (dispatch, getState) => {
  const { holdStatus } = getState().cabsReview;
  if (holdStatus === Status.SUCCESS) {
    Actions.cabsThankYou();
  }
};

export const openPayment = (holdResponse) => async (dispatch, getState) => {
  const {
    cabsReview: { cab, tag },
    cabsListing: { headerProps },
  } = getState();

  openPaymentPage(holdResponse, headerProps, cab, tag);
};


const _handleDropAddressChange = (request) => (dispatch, getState) => {
  const {
    sourceData,
    destinationData,
    shouldPop = true,
    tripType,
    selectedAirportTypeIndex,
  } = request;
  const searchRequest = getState().cabsListing.searchServerRequest;
  const finalSourceData = searchRequest.source_location;
  const nearByLocation = searchRequest.destination_location;
  const isNearByCheckRequired = true;
  if (shouldPop && !_isEmpty(destinationData)) {
    const data = {
      sourceData: finalSourceData,
      destinationData,
      tripType,
      selectedAirportTypeIndex,
      isNearByCheckRequired,
      nearByLocation,
      screenName: CabsScreen.REVIEW,
    };
    const { error, errorEvent } = validateSearchRequest(data, true);
    if (!_isEmpty(error)) {
      trackReviewEvent(errorEvent);
      dispatch(CabsCityPickerAction.destClearAction());
      showShortToast(error, 'top', 'dark');
      return;
    }

    dispatch(handleReviewCityChange(destinationData, false));

    dispatch(setHyperlocationActivityIndicator(true));
    Actions.pop('cabsReview');
  }
};

const _handleAddressChange = (request) => (dispatch, getState) => {
  const {
    sourceData,
    destinationData,
    shouldPop = true,
    tripType,
    selectedAirportTypeIndex,
  } = request;
  const searchRequest = getState().cabsListing.searchRequest;
  const finalDestinationData = searchRequest.destination_location;
  const nearByLocation = searchRequest.source_location;
  const isNearByCheckRequired = true;
  if (shouldPop && !_isEmpty(sourceData)) {
    const data = {
      sourceData,
      destinationData: finalDestinationData,
      tripType,
      selectedAirportTypeIndex,
      isNearByCheckRequired,
      nearByLocation,
      screenName: CabsScreen.REVIEW,
    };
    const { error, errorEvent } = validateSearchRequest(data);
    if (!_isEmpty(error)) {
      trackReviewEvent(errorEvent);
      dispatch(CabsCityPickerAction.sourceClearAction());
      showShortToast(error, 'top', 'dark');
      return;
    }
    dispatch(handleReviewCityChange(sourceData));
    dispatch(setHyperlocationActivityIndicator(true));
    Actions.pop('cabsReview');
  }
};

export const openDestCityPicker = () => (dispatch, getState) => {
  const { searchRequest, tripType } = getState().cabsListing;
  const nearByLocation = searchRequest.destination_location;
  const {
    sourceData,
    selectedAirportTypeIndex,
  } = getTripDataFromState(getState());
  const destinationData = null;
  const cityPickerHeader = 'Enter Drop-off';
  const exactPickUpRequired = true;
  const destFocused = true;
  const request = {
    disableSource: true,
    tripType,
    sourceData,
    destinationData,
    destFocused,
    selectedAirportTypeIndex,
    handleAddressChange: _handleDropAddressChange,
    screenName: CabsScreen.REVIEW,
    cityPickerHeader,
    exactPickUpRequired,
    nearByLocation,
  };
  dispatch(CabsCityPickerAction.initCityPicker(request));
  Actions.cabsCityPicker();
};

export const openCityPicker = () => (dispatch, getState) => {
  const { searchRequest, tripType } = getState().cabsListing;
  const nearByLocation = searchRequest.source_location;
  const sourceData = null;
  const destinationData = null;
  const {
    selectedAirportTypeIndex,
  } = getTripDataFromState(getState());
  const cityPickerHeader = 'Enter Pick-up';
  const exactPickUpRequired = true;
  const destFocused = false;
  const request = {
    tripType,
    sourceData,
    destinationData,
    destFocused,
    selectedAirportTypeIndex,
    handleAddressChange: _handleAddressChange,
    screenName: CabsScreen.REVIEW,
    cityPickerHeader,
    exactPickUpRequired,
    nearByLocation,
  };
  dispatch(CabsCityPickerAction.initCityPicker(request));
  Actions.cabsCityPicker();
};

export const trackPdtBackPressed = () => (dispatch, getState) => {
  try {
    const { sourceData, destinationData, departDate, returnDate } = getState().cabsLanding;
    const { userDetails, deviceDetails } = getState().cabs;
    const { cabs, searchServerRequest } = getState().cabsListing;
    const { cab, locusInfo } = getState().cabsReview;
    const {
      source_city: sourceCityCode,
      destination_city: destinationCityCode,
      trip_type: tripType,
    } = searchServerRequest;
    const pdtData = {
      userDetails,
      deviceDetails,
      searchData: {
        sourceData,
        destinationData,
        departDate,
        returnDate,
        sourceCityCode,
        destinationCityCode,
        tripType,
        locusInfo,
      },
      cabs,
      cab,
    };
    CabsPdtReviewHelper.trackBackPressed(pdtData);
  } catch (e) {
    //  Ignore exception
  }
};

export const setBookingForTab = (paxType) => ({
  type: ACTION_SET_BOOKING_FOR_TAB,
  data: { paxType },
});

/**
 * this function adds params to the url 
 * @param {string} userConsent the param passed in the url 
 */
const addParamsToUrl = (userConsent) => {
  if (Platform.OS === 'web') {
    const extraQueryParams = {
      [GSTIN_USER_CONSENT]: userConsent ? 'true' : 'false'
    }
    const updatedURl = editParamsToUrl(window.location.href, 'append', null, extraQueryParams)
    window.history.replaceState(window.history.state, '', updatedURl)
  }
}