export const PROVISIONAL_HOB_REQUEST_TYPE = 'hobProvisionalRequest';

export const errIcon = 'https://jsak.mmtcdn.com/cabs_cdn_dt/image/addon-error-view-icon.png';

export const errActionCta = {
    text: 'BOOK ANOTHER CAB',
    url: 'https://cabs.makemytrip.com/?tripType=AT&source=ProvisionalExpiry',
};

export const API_STATUS = {
    INITIAL: 'initial',
    SUCCESS: 'success',
    LOADING: 'loading',
}

export const CONSTANTS = {
    SUCCESS :'SUCCESS',
    OW:'OW',
    holdBookingRequest:'holdBookingRequest',
    V3:'V3',
    ZERO_PAYMENT:'ZERO_PAYMENT',
    KIOSK:'KIOSK',
    hob:'hob',
    departure:'departure',
    thankYouRequest:'thankYouRequest',
    bookMode:'M',
    myTripsText:'GO TO MY Trips'
  }