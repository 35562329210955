import _ from 'lodash';
import { Platform } from 'react-native';
import fecha from 'fecha';
import * as constants from './cabsLandingActions';
import TripType, { AirportPickupType, TravelType } from '../types/TripType';
import { getNearestNextSlotForTripType, parseDateTimeString } from '../cabsDateTimeHelpers';
import { noRideNowAvailableErrMsg } from '../cabsConstants';
import cabsConfig from '../cabsConfig';
import { now, addHours } from '../../../Helpers/dateHelpers';
import { getDefaultRoundTripHrs, isInstantAvailable } from '../cabsDynamicConfig';
import { getSearchData } from '../utils/cabsSearchUtil';
import { ACTION_SET_ASSURANCE_DATA, ACTION_UPDATE_INSTANT_CAB_OFFER, ACTION_UPDATE_TRAVEL_TYPES } from '../cabsCommonActions';
import { isGooglePayPlatform, isPhonePePlatform } from "../utils/cabsCommonUtils";

const initialState = {
  tripType: TripType.OW.value,
  allTripTypes: [TripType.OW.value, TripType.RT.value],
  travelType: TravelType.OUTSTATION.name,
  //TODO @rajeshbatth Remove this
  // Sunsetting SD
  //allTravelTypes: (isGooglePayPlatform() || isPhonePePlatform()) ? [TravelType.OUTSTATION, TravelType.AIRPORT] : [TravelType.OUTSTATION, TravelType.AIRPORT, TravelType.SELF_DRIVE],
  allTravelTypes: (isGooglePayPlatform() || isPhonePePlatform()) ? [TravelType.OUTSTATION, TravelType.AIRPORT] : [TravelType.OUTSTATION, TravelType.AIRPORT],
  airportPickupTypes: [AirportPickupType.TO, AirportPickupType.FROM],
  additionalCityData: [],
  showDestination: false,
  recentSearch: null,
  viewState: constants.VIEW_STATE.INITIAL,
  showLoader: false,
  showToolTip: false,
  toolTipPosition: null,
  departDate: null,
  returnDate: null,
  showPageLoading: false,
  showFlightToolTip: false,
  flightDetail: null,
  selectedAirportTypeIndex: AirportPickupType.TO.value,
  isFlightAddOn: false,
  packageKey: null,
  recentSearchData: {},
  isRecentSearch: false,
  isInstantCab: false,
  shouldShowRoutePlanner: false,
  isInstantApplicable: false,
  offers: [],
  airportOffers: [],
  outstationOffers: [],
  rentalOffers: [],
  cancellationPolicy: null,
  instantCabOffer: [],
  primaryPaxError: false,
  cabConfigDefaultDate: fecha.format(new Date(Date.now() + 24 * 60 * 60 * 1000), 'DD-MM-YYYY'), // fetching next day date
  cabConfigDefaultTime: '10:00',
  noRideAvailableErr: noRideNowAvailableErrMsg,
  showQuickBookLoader: false,
  assuranceData: null,
};

const LandingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_SET_ASSURANCE_DATA: {
      return {
        ...state,
        assuranceData: action.data,
      }
    }

    case ACTION_UPDATE_TRAVEL_TYPES: {
      return {
        ...state,
        travelType: TravelType.AIRPORT.name,
        tripType: TripType.AT.value,
        allTravelTypes: [TravelType.AIRPORT],
        allTripTypes: [TripType.AT.value],
      };
    }

    case constants.ACTION_SET_PRIMARY_PAX_ERROR: {
      return {
        ...state,
        primaryPaxError: action.data,
      };
    }

    case constants.ACTION_REMOVE_SELF_DRIVE_FROM_TRAVEL_TYPES: {
      return {
        ...state,
        allTravelTypes: state.allTravelTypes.filter(
          (travelType) => travelType !== TravelType.SELF_DRIVE,
        ),
      };
    }

    case constants.ACTION_UPDATE_ACTIVE_TRAVEL_TYPES: {
      return {
        ...state,
        allTravelTypes: action.data,
      };
    }

    case constants.ACTION_SET_CAB_CONFIG_DEFAULT_DATE_TIME: {
      const { date, time } = action.data;
      let { departDate, returnDate, tripType } = state;
      if (Platform.OS === 'web') {
        if (!Boolean(departDate)) {
          departDate = parseDateTimeString(date, time);
        }
        const minReturnDate = addHours(departDate, getDefaultRoundTripHrs());
        if (!Boolean(returnDate) && tripType === TripType.RT.value) {
          returnDate = minReturnDate;
        }
      }
      return {
        ...state,
        cabConfigDefaultDate: date,
        cabConfigDefaultTime: time,
        departDate,
        returnDate
      };
    }

    case constants.ACTION_SET_RIDE_UNAVAILABLE_MSG: {
      return {
        ...state,
        noRideAvailableErr: action.data,
      };
    }

    case constants.BACK_CLICK_ACTION: {
      const hardwareBackPress = action.data;
      if (state.showLocationSettings) {
        return {
          ...state,
          showLocationSettings: false,
        };
      }
      if (state.showLocationPerm) {
        return {
          ...state,
          showLocationPerm: false,
        };
      }
      if (hardwareBackPress && state.showToolTip) {
        return {
          ...state,
          showToolTip: false,
        };
      }
      return {
        ...state,
        viewState: constants.VIEW_STATE.INITIAL,
        sourceFocused: false,
        destinationFocused: false,
        showSourceClear: false,
        showLocationSettings: false,
        locationLoading: false,
        showLocationPerm: false,
      };
    }

    case constants.ACTION_SHOW_NO_NETWORK: {
      return {
        ...state,
        viewState: action.data ? constants.VIEW_STATE.INITIAL : constants.VIEW_STATE.NO_NETWORK,
      };
    }

    case constants.ACTION_TRAVEL_TYPE: {
      const { allTravelTypes } = state;
      const newTravelType = allTravelTypes.filter((item) => item.name === action.data);
      const { allTripTypes, name: travelType, defaultTripType: tripType } = newTravelType[0];

      return {
        ...state,
        allTripTypes,
        tripType,
        travelType,
      };
    }

    case constants.ACTION_UPDATE_TRAVEL_TYPE: {
      const { tripType, travelType: newTravelType } = action.data;

      const { allTripTypes, name: travelType } = newTravelType;

      return {
        ...state,
        allTripTypes,
        tripType,
        travelType,
        showToolTip: false,
        isRecentSearch: false,
      };
    }

    case constants.ACTION_SHOW_TOOLTIP: {
      const { data: showIfAlreadyShown = false } = action;
      const { showedToolTip = false } = state;
      const shouldShowTooltip = !showedToolTip || showIfAlreadyShown;
      return {
        ...state,
        showedToolTip: true,
        showToolTip: shouldShowTooltip,
      };
    }

    case constants.ACTION_HIDE_TOOLTIP: {
      return {
        ...state,
        showToolTip: false,
      };
    }
    case constants.ACTION_SET_FLIGHT_ADDON_STATE: {
      return {
        ...state,
        isFlightAddOn: action.data.isFlightAddOn,
      };
    }
    case constants.ACTION_UPDATE_RECENT_SEARCH: {
      const recentSearchData = {};
      action.data.forEach((item) => {
        const { trip_type: searchTripType } = item;
        if (!(searchTripType in recentSearchData)) {
          recentSearchData[searchTripType] = item;
        }
      });

      return {
        ...state,
        isRecentSearch: true,
        recentSearchData,
      };
    }
    case constants.ACTION_RESET_CABS_LANDING: {
      return initialState;
    }

    case constants.ACTION_UPDATE_IS_INSTANT_APPLICABLE: {
      return { ...state, isInstantApplicable: action.data };
    }

    case constants.ACTION_SHOW_ERROR: {
      const { data: errorMessage } = action;
      return {
        ...state,
        errorMessage,
      };
    }

    case constants.ACTION_HIDE_ERROR: {
      return {
        ...state,
        errorMessage: null,
      };
    }

    case constants.ACTION_LANDING_DATE_CHANGED: {
      const { departDate, returnDate } = action.data;
      if (Boolean(returnDate)) {
        return {
          ...state,
          departDate,
          returnDate,
        };
      } else {
        return {
          ...state,
          departDate,
        };
      }
    }

    case constants.ACTION_UPDATE_DEFAULT_PICKUP: {
      let { departDate } = state;
      if (state.tripType === TripType.AT.value) {
        const nextSlot = getNearestNextSlotForTripType(state.tripType);
        if (departDate < nextSlot) {
          departDate = nextSlot;
        }
      }
      return {
        ...state,
        departDate,
      };
    }

    case constants.ACTION_UPDATE_SELECTED_AIRPORT_INDEX: {
      return {
        ...state,
        selectedAirportTypeIndex: action.data,
      };
    }

    case constants.ACTION_UPDATE_AIRPORT_PICKUP_TYPE: {
      return {
        ...state,
        selectedAirportTypeIndex: action.data,
      };
    }

    case constants.ACTION_SET_DEEPLINK_DATA: {
      const { deepLinkData, departDate, tripType, selectedAirportTypeIndex } = action.data;

      return {
        ...state,
        deepLinkData,
        departDate,
        tripType,
        selectedAirportTypeIndex,
        isRecentSearch: false,
      };
    }

    case constants.ACTION_SHOW_PAGE_LOADING: {
      return {
        ...state,
        showPageLoading: action.data,
      };
    }

    case constants.ACTION_UPDATE_AIRPORT_TOOL_TIP_STATUS: {
      const status = action.data;
      const flightDetail = status ? state.flightDetail : null;
      return {
        ...state,
        flightDetail,
        showFlightToolTip: action.data,
      };
    }

    case constants.ACTION_SET_AIRPORT_SUGGESTION_DATA: {
      const {
        location,
        from_airport,
        pickup_date,
        pickup_time,
        flight_number,
        carrier,
        duration_message,
      } = action.data;

      let departDate = now();
      if (!_.isNil(pickup_date)) {
        departDate = fecha.parse(pickup_date, 'DD/MM/YYYY');
      }

      let departTime = cabsConfig.defaultNextDayTimeFormatted;
      if (!_.isNil(pickup_time)) {
        departTime = fecha.parse(pickup_time, 'hh:mm');
      }
      departDate.setHours(departTime.getHours(), departTime.getMinutes(), 0, 0);

      const nearestLocalDepDate = getNearestNextSlotForTripType(state.tripType);
      if (departDate < nearestLocalDepDate) {
        departDate = nearestLocalDepDate;
      }

      const airportTypeIndex = from_airport
        ? AirportPickupType.FROM.value
        : AirportPickupType.TO.value;

      const flightDetail = {
        location,
        selectedAirportTypeIndex: airportTypeIndex,
        departDate,
        flightNumber: flight_number,
        carrier,
        duration_message,
      };

      return {
        ...state,
        showPageLoading: false,
        flightDetail,
      };
    }

    case constants.ACTION_FILL_AIRPORT_SUGGESTION_DATA: {
      // TODO Handle the pickup time in the landing action
      if (!_.isEmpty(state.flightDetail)) {
        const {
          sourceText = null,
          sourceData = null,
          destinationText = null,
          destinationData = null,
          fromAirport = false,
          selectedAirportTypeIndex = 0,
          nextDepartureDate = null,
        } = action.data;

        const newTripType = TripType.AT.value;
        const { allTravelTypes } = state;
        const newTravelType = _.find(
          allTravelTypes,
          ({ allTripTypes }) => _.indexOf(allTripTypes, newTripType) >= 0,
        );
        return {
          ...state,
          showFlightToolTip: true,
          allTripTypes: newTravelType.allTripTypes,
          tripType: newTripType,
          travelType: newTravelType.name,
          selectedAirportTypeIndex,
          viewState: constants.VIEW_STATE.INITIAL,
          sourceFocused: fromAirport,
          destinationFocused: !fromAirport,
          sourceText,
          sourceData,
          destinationText,
          destinationData,
          departDate: nextDepartureDate,
          showDestination: !fromAirport,
        };
      }
      return state;
    }

    case constants.ACTION_SET_LANDING_LOCATION: {
      const {
        sourceData,
        destinationData,
        sourceText,
        destinationText,
        selectedAirportTypeIndex,
      } = action.data;

      return {
        ...state,
        sourceData,
        destinationData,
        sourceText,
        destinationText,
        selectedAirportTypeIndex,
      };
    }

    case constants.ACTION_LANDING_SOURCE_CLEAR: {
      return {
        ...state,
        sourceText: '',
        sourceData: null,
      };
    }

    case constants.ACTION_LANDING_DESTINATION_CLEAR: {
      let { additionalCityData } = state;
      let destinationText = '';
      let destinationData = null;
      if (!_.isEmpty(additionalCityData)) {
        destinationText = additionalCityData[0].address;
        destinationData = additionalCityData[0];
        additionalCityData = [...additionalCityData.slice(1, additionalCityData.length)];
      }
      return {
        ...state,
        destinationText,
        destinationData,
        additionalCityData,
      };
    }

    case constants.ACTION_LANDING_ADDITIONAL_CITY_CLEAR: {
      const index = action.data;
      const { additionalCityData } = state;
      return {
        ...state,
        additionalCityData: [
          ...additionalCityData.slice(0, index),
          ...additionalCityData.slice(index + 1, additionalCityData.length),
        ],
      };
    }

    case constants.ACTION_LANDING_ROUTE_PLANER_STATE: {
      return {
        ...state,
        shouldShowRoutePlanner: action.data,
      };
    }

    case ACTION_UPDATE_INSTANT_CAB_OFFER: {
      const instantCabOffer = action.data;
      return {
        ...state,
        instantCabOffer,
      };
    }

    case constants.ACTION_LANDING_SET_OFFERS: {
      const {
        data: { offersList: offers },
      } = action;
      return {
        ...state,
        airportOffers: action.data.airportOffers,
        outstationOffers: action.data.outstationOffers,
        rentalOffers: action.data.rentalOffers,
      };
    }
    case constants.ACTION_SHOW_QUICK_BOOK_LOADER: {
      return {
        ...state,
        showQuickBookLoader: action.data
      }
    }
    case constants.ACTION_SET_SOURCE_CMP_DATA: {
      return {
        ...state,
        landingSourceAndCmp: action.data
      }
    }
    default: {
      return state;
    }
  }
};

export default LandingReducer;
