"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectedFilters = exports.sortCabs = exports.isCabEligibleByModelType = exports.isCabEligibleByCarType = exports.isCabEligibleByFuelType = exports.getCabTypeFilters = exports.createFiltersFromCabList = void 0;
var lodash_1 = require("lodash");
//@TODO @saurav optimise thi
exports.createFiltersFromCabList = function (cabs) {
    var carTypeFilters = {}, fuelTypeFilters = {}, carModelFilters = {};
    var allFiltersList = {};
    var cabTypes = lodash_1.groupBy(cabs, 'type_label');
    var cabModelTypes = lodash_1.groupBy(cabs.filter(function (cab) {
        return cab.vehicle_model_type !== 'GENERIC';
    }), 'vehicle_model');
    Object.keys(cabTypes).forEach(function (key) {
        if (key !== 'null')
            carTypeFilters[key] = { count: cabTypes[key].length, isSelected: false, isDisabled: false };
    });
    Object.keys(cabModelTypes).forEach(function (key) {
        if (key !== 'null')
            carModelFilters[key] = { count: cabModelTypes[key].length, isSelected: false, isDisabled: false };
    });
    if (Object.keys(carTypeFilters).length) {
        allFiltersList.carType = carTypeFilters;
    }
    if (Object.keys(carModelFilters).length) {
        allFiltersList.modelType = carModelFilters;
    }
    return allFiltersList;
};
exports.getCabTypeFilters = function (cabs) {
    return (lodash_1.groupBy(cabs, 'type_label'));
};
exports.isCabEligibleByFuelType = function (cab, fuelTypes) {
    if (!fuelTypes || !fuelTypes.length)
        return true;
    return fuelTypes.includes(cab.fuel_type);
};
exports.isCabEligibleByCarType = function (cab, carTypes) {
    if (!carTypes || !carTypes.length)
        return true;
    return carTypes.includes(cab.type_label);
};
exports.isCabEligibleByModelType = function (cab, modelTypes) {
    if (!modelTypes || !modelTypes.length)
        return true;
    return modelTypes.includes(cab.vehicle_model);
};
var PriorityOrder = ['SUV', 'SEDAN', 'HATCHBACK'];
exports.sortCabs = function (cabs, sortingKey) {
    try {
        switch (sortingKey) {
            case "HTLPrice":
                return cabs.sort(function (cabA, cabB) {
                    var cabAPrice = (cabA.cab_fare_detail.total_amount - cabA.cab_fare_detail.coupon_amount);
                    var cabBPrice = (cabB.cab_fare_detail.total_amount - cabB.cab_fare_detail.coupon_amount);
                    if (cabAPrice === cabBPrice) {
                        return -1;
                    }
                    else {
                        return cabAPrice - cabBPrice;
                    }
                }).reverse();
            case "LTHPrice":
                return cabs.sort(function (cabA, cabB) {
                    return (cabA.cab_fare_detail.total_amount - cabA.cab_fare_detail.coupon_amount) -
                        (cabB.cab_fare_detail.total_amount - cabB.cab_fare_detail.coupon_amount);
                });
            case "HTLRecommended":
                return cabs.sort(function (cabA, cabB) {
                    return cabA.ratings_display.no_of_reviews - cabB.ratings_display.no_of_reviews;
                });
            case "HTLRatings":
                return cabs.sort(function (cabA, cabB) {
                    if (cabA.ratings_display.overall_rating === cabB.ratings_display.overall_rating) {
                        return (cabB.cab_fare_detail.total_amount - cabB.cab_fare_detail.coupon_amount) -
                            (cabA.cab_fare_detail.total_amount - cabA.cab_fare_detail.coupon_amount);
                    }
                    else {
                        return (cabA.ratings_display.overall_rating - cabB.ratings_display.overall_rating);
                    }
                }).reverse();
            default:
                return cabs;
        }
    }
    catch (error) {
        return cabs;
    }
};
exports.getSelectedFilters = function (filters) {
    var _a;
    var selectedFilters = {}, isFilterApplied = false;
    (_a = Object.keys(filters)) === null || _a === void 0 ? void 0 : _a.forEach(function (filterLabel) {
        selectedFilters[filterLabel] = [];
        Object.keys(filters[filterLabel]).forEach(function (option) {
            // @ts-ignore
            if (filters[filterLabel][option].isSelected) {
                selectedFilters[filterLabel].push(option);
                isFilterApplied = true;
            }
        });
    });
    return { selectedFilters: selectedFilters, isFilterApplied: isFilterApplied };
};
