import {NativeModules, Platform} from 'react-native';
import { getRootTag } from '../RootTagHolder';

if (Platform.OS === 'ios') {
  const openPage = NativeModules.PaymentModule.openPaymentPage;
  NativeModules.PaymentModule.openPaymentPage = (paymentData) => {
    const reactTag = getRootTag();
    openPage(paymentData, reactTag);
  };
}
export default NativeModules.PaymentModule;
