import { getMmtAuth } from 'src/Native/UserSession/UserSessionModule';
import { Platform } from 'react-native';
import { LOB_PAGENAME_MAPPING_APPS, LOB_PAGENAME_MAPPING_MWEB} from './constants';

export const pageName = (lob) => {
    if (Platform.OS === 'web') {
        return LOB_PAGENAME_MAPPING_MWEB[lob];
    }

    return  LOB_PAGENAME_MAPPING_APPS[lob];
};

export const userServiceHeaders = async () => {
  const mmtAuth = await getMmtAuth();

  const userIdentifier = JSON.stringify({
    type: 'mmt-auth',
    deviceId: 'deviceId',
    os: Platform.OS,
    osVersion: Platform.Version,
    appVersion: 'appVersion',
    imie: 'imie',
    ipAddress: 'ipAddress',
    timeZone: 'timeZone',
    value: mmtAuth?.toString(),
  });

  return {
    'Content-type': 'application/json',
    Authorization: 'h4nhc9jcgpAGIjp',
    'User-Identifier': userIdentifier,
  };
};

export const userServiceBody = () => {
  return {
    "query": [
      [
        {
          "name": "extendedUser",
          "keys": [
            "profileId",
            "accountId",
            "profileType",
            "uuid",
            "loginInfoList",
            "addressDetails"
          ]
        }
      ],
      [
        {
          "name": "extendedUser"
        },
        {
          "name": "addressDetails",
          "keys": [
            "addressId",
            "city",
            "name",
            "category",
            "houseNo",
            "street",
            "state",
            "country",
            "postalCode",
            "landMark",
            "status",
            "lastUpdated",
            "address1",
            "stateId",
            "cityId"
          ],
          "filters": [
            {
              "name": "category",
              "type": "string",
              "value": "BILLING"
            },
            {
              "name": "status",
              "type": "string",
              "value": "ACTIVE"
            }
          ]
        }
      ]
    ]
  }
};