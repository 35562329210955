
// source - https://stackoverflow.com/questions/16037165/displaying-a-number-in-indian-format-using-javascript
export const rupeeFormatter = (amount) => {
  if (amount === undefined || Number.isNaN(amount)) {
    return '';
  }
  let formattedAmount = amount.toString();
  let afterPoint = '';
  if (formattedAmount.indexOf('.') > 0) {
    afterPoint = formattedAmount.substring(formattedAmount.indexOf('.'), formattedAmount.length);
  }
  formattedAmount = Math.floor(formattedAmount);
  formattedAmount = formattedAmount.toString();
  let lastThree = formattedAmount.substring(formattedAmount.length - 3);
  const otherNumbers = formattedAmount.substring(0, formattedAmount.length - 3);
  if (otherNumbers !== '') { lastThree = `,${lastThree}`; }
  return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;
};

export const rupeeAmount = rupees => (!Number.isNaN(rupees) ? `\u20B9 ${rupeeFormatter(rupees)}` : '');

export const rupeeFormatterWithLocale = (
    amount,
    maximumFractionDigits = 0,
    minimumFractionDigits = 0,
    Locale = 'en-IN',
    currency = 'INR',
) => {
    if (amount === undefined || Number.isNaN(amount)) {
        return '';
    }

    const currencyOptions = {style: 'currency', currency, maximumFractionDigits, minimumFractionDigits};
    return amount.toLocaleString(Locale, currencyOptions);
};

