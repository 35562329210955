import GenericTrackerModule from 'src/Native/GenericTrackerModule';
import { Platform } from 'react-native';
import isString from 'lodash/isString';
import mapValues from 'lodash/mapValues';

export const EVENT_NAME = 'event_name';
export const ATTRIBUTE_1 = Platform.OS === 'ios' ? 'attribute1' : 'attr1';
export const ATTRIBUTE_2 = Platform.OS === 'ios' ? 'attribute2' : 'attr2';
export const ATTRIBUTE_3 = Platform.OS === 'ios' ? 'attribute3' : 'attr3';
export const ATTRIBUTE_4 = Platform.OS === 'ios' ? 'attribute4' : 'attr4';
export const ATTRIBUTE_5 = Platform.OS === 'ios' ? 'attribute5' : 'attr5';
export const ATTRIBUTE_6 = Platform.OS === 'ios' ? 'attribute6' : 'attr6';

export const trackTune = (params) => {
  try {
    const _params = mapValues(params, (val) => {
      if (!isString(val)) {
        return `${val}`;
      }
      return val;
    });
    console.log('trackTune', _params);
    GenericTrackerModule.trackTune(_params);
  } catch (e) {
    console.log('error is', e);
  }
};

// Test function on how to populate data in Tune
export const testTuneTrack = () => {
  const params = {};
  params[EVENT_NAME] = 'test';
  params[ATTRIBUTE_1] = 'test1';
  params[ATTRIBUTE_2] = 'test2';
  params[ATTRIBUTE_3] = 'test3';
  params[ATTRIBUTE_4] = 'test4';
  params[ATTRIBUTE_5] = 'test5';
  trackTune(params);
};
