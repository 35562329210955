export const CabsPdtConstants = {
  CountryCode: 'IN',
  TemplateId: '23095',
  TopicId: '427',
  LobName: 'cab',
  FunnelStep: {
    LANDING: 'landing',
    LISTING: 'listing',
    REVIEW: 'review',
    THANKYOU: 'thankyou'
  },
  BaseEventAction: {
    LOAD_PAGE: 'on_page_load',
    EXIT_PAGE: 'on_page_exit',
    CLICK_BACK: 'on_click_back',
  },
  LandingEventAction: {
    CLICK_SEARCH: 'on_click_search'
  },
  ListingEventAction: {
    CLICK_CAB: 'on_click_cab'
  },
  ReviewEventAction: {
    CLICK_PAY: 'on_click_pay'
  }
};
