import _map from 'lodash/map';
import _replace from 'lodash/replace';

export default cabs => _map(cabs, (cab) => {
  const {
    utilities, type, vendor_name
  } = cab;
  console.log('type', type);
  let {vendor_display_name} = cab;
  if (!vendor_display_name) {
    vendor_display_name = vendor_name;
  }
  let {vehicle_model: displayName} = cab;
  let [cabType, ...otherUtilities] = utilities;
  if (type === 'TRAVELLER_17' || type === 'TRAVELLER_12' || type === 'TRAVELLER_15') {
    displayName = _replace(displayName, 'Tempo Traveller 17 seater', 'Tempo Traveller');
    displayName = _replace(displayName, 'Tempo Traveller 12 seater', 'Tempo Traveller');
    displayName = _replace(displayName, 'Tempo Traveller 15 seater', 'Tempo Traveller');
    cabType = displayName;
  }
  try {
    otherUtilities.map((item, index) => {
      if (item.toLowerCase().indexOf('bag') > -1) {
        otherUtilities.splice(index, 1);
      }
    })
  } catch (e) {
  //  Ignore
  }
  return {
    ...cab,
    vendor_display_name,
    utilities: [...otherUtilities, 'AC'],
    cabType,
    displayName
  };
});
