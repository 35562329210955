import {
  cabClientNewLogging,
  context,
  device,
  user
} from './PdtModel/CabsPdtTemplate';
import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';
import {ProfileType} from '../../../Native/UserSession/UserSessionModule';
import {now} from '../../../Helpers/dateHelpers';
import {Platform} from 'react-native';

export default class BaseGenericEvent {
  constructor(pdtData) {
    const {
      userDetails,
      deviceDetails,
      activityName,
      pageName,
      funnelStep
    } = pdtData;
    this.activityName = activityName;
    this.pageName = pageName;
    this.funnelStep = funnelStep;
    this.deviceDetails = deviceDetails;
    this.userDetails = userDetails;
  }

  _getUserDetails = () => {
    const marketingCloudId = (!_isEmpty(this.deviceDetails)) ? this.deviceDetails.mcId : null;
    if (_isEmpty(this.userDetails)) {
      return user({
        marketing_cloud_id: marketingCloudId
      });
    }
    const userDetailsObject = {
      uuid: this.userDetails.uuid,
      email_communication_id: this.userDetails.eComId,
      mobile_communication_id: this.userDetails.mComId,
      is_logged_in: this.userDetails.loggedIn,
      login_channel: this.userDetails.loginType,
      profile_type: this.userDetails.profileType,
      marketing_cloud_id: marketingCloudId,
      is_mobile_verified: !_isEmpty(this.userDetails.verifiedMobileNumber) && this.userDetails.verifiedMobileNumber.length > 0,
      mobile_country_code: _has(this.userDetails, 'mobile.countryCode') ? this.userDetails.mobile.countryCode : null,
      login_status: this.userDetails.loggedIn,
      is_corporate: this.userDetails.profileType === ProfileType.BUSINESS
    };
    return user(userDetailsObject);
  };

  _getDeviceDetails = () => {
    let deviceDetailsObject = {}
    if (_isEmpty(this.deviceDetails)) {
      deviceDetailsObject = {
        type: Platform.OS === 'web' ? 'pwa' : null,
        model: null,
        id: null,
        connection_type: null,
        operating_system_version: null,
        operating_system: null,
        application_version: null
      };
      if(deviceDetailsObject.type === null){
        return null
      }
    }else{
      deviceDetailsObject = {
        type: this.deviceDetails.deviceType,
        model: this.deviceDetails.deviceName,
        id: Platform.OS === 'android' ? this.deviceDetails.pdtDeviceId : this.deviceDetails.deviceId,
        connection_type: this.deviceDetails.networkType,
        operating_system_version: this.deviceDetails.osVersion,
        operating_system: this.deviceDetails.os,
        application_version: this.deviceDetails.appVersion
      };
    }
    return device(deviceDetailsObject);
  };

  _getContext = () => {
    const contextObject = {
      client_timestamp: now().getTime(),
      activity_name: this.activityName,
      page_name: this.pageName,
      funnel_step: this.funnelStep
    };
    return context(contextObject);
  };

  basePdtData = () => {
    const pdtObject = cabClientNewLogging();
    pdtObject.device = this._getDeviceDetails();
    pdtObject.user = this._getUserDetails();
    pdtObject.context = this._getContext();
    return pdtObject;
  };
}
