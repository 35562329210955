import {NativeModules, Platform} from 'react-native';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';

export const getOffers = (lob, funnel) => NativeModules.OffersModule.getOffers(lob, funnel);

/**
 * @deprecated Please use getOffersFromSkyWalker from 'src/Native/SkywalkerOffers'; 
 */

/*
*  This method tries to fetch Offers for Android/IOS
*  in Case of Android we are concatinating the offer
*  and then ordering by DISCOUNT Percent for priority of coupons
* */
export const getOffersList = async (lob, searchString) => {
  try {
    let offersList = await getOffers(lob, searchString);
    if (Platform.OS === 'android' && offersList.length > 0) {
      try {
        offersList = map(offersList, (obj) => {
          const newOfferObject = {};
          const originalOfferObj = JSON.parse(obj);
          let promoTitle = originalOfferObj.pTl;
          if (!isEmpty(originalOfferObj.dStxt) && !isEmpty(originalOfferObj.offerTxt)
            && !isEmpty(originalOfferObj.dEtxt)) {
            promoTitle = `${originalOfferObj.dStxt} ${originalOfferObj.offerTxt} ${originalOfferObj.dEtxt}`;
          }
          newOfferObject.id = originalOfferObj.id;
          newOfferObject.newHeroUrl = originalOfferObj.newHeroUrl;
          newOfferObject.cardCTAText = originalOfferObj.cardCTAText;
          newOfferObject.newHeroOfferCardUrl = originalOfferObj.newHeroOfferCardUrl;
          newOfferObject.promoTitle = promoTitle;
          newOfferObject.promoMessage = originalOfferObj.pTl;
          newOfferObject.promoCode = originalOfferObj.cc;
          newOfferObject.dis = originalOfferObj.dis;
          newOfferObject.url = originalOfferObj.url;
          newOfferObject.skyBigFullImgUrl = originalOfferObj.skyBigFullImgUrl;
          newOfferObject.lob = originalOfferObj.lob;
          newOfferObject.heroUrl = originalOfferObj.heroUrl;
          return newOfferObject;
        });
        offersList = orderBy(offersList, ['dis'], ['desc']);
      } catch (e) {
        offersList = [];
      }
    }
    return offersList;
  } catch (e) {
    return [];
  }
};

export default NativeModules.OffersModule;
