import _isEmpty from 'lodash/isEmpty';
import {CabsPdtConstants} from '../PdtHelper/CabsPdtConstants';
import { getSessionId } from '../../utils/cabsCommonUtils';

export const cabClientNewLogging = () => ({
  context: context(),
  device: device(),
  user: user(),
  experiment_details_list: experimentDetailsList(),
  car_category_vendor_selected: carCategoryVendorSelected(),
  car_category_vendor_not_selected: carCategoryVendorNotSelected(),
  cab_details: cabDetails(),
  applicable_coupon_details_list: applicableCouponDetailsList(),
  cab_search_context: cabSearchContext(),
  applied_coupon_details: appliedCouponDetails(),
  cab_pricing_details_list: cabPricingDetailsList(),
  cab_other_details: cabOtherDetails(),
  traveller_details_list: travellerDetailsList(),
  booking_details: bookingDetails(),
  error_details_list: errorDetailsList(),
  insurance_details_list: insuranceDetailsList()
});

export const device = (data) => {
  if (_isEmpty(data)) {
    return null;
  }
  const {
    type = null,
    model = null,
    id = null,
    connection_type = null,
    operating_system_version = null,
    operating_system = null,
    application_version = null
  } = data;
  return {
    latitude: null,
    type,
    manufacturer: null,
    carrier: null,
    browser: null,
    model,
    id,
    longitude: null,
    connection_type,
    traffic_type: null,
    traffic_source: null,
    operating_system_version,
    geo_city: null,
    operating_system,
    browser_version: null,
    user_agent: null,
    application_version
  };
};

export const user = (data) => {
  if (_isEmpty(data)) {
    return null;
  }
  const {
    uuid = null,
    email_id = null,
    is_logged_in = null,
    login_channel = null,
    profile_type = null,
    is_mobile_verified = null,
    mobile_country_code = null,
    login_status = null,
    is_corporate = null,
    email_communication_id = null,
    mobile_communication_id = null,
    marketing_cloud_id = null
  } = data;
  return {
    city: null,
    uuid,
    email_id,
    is_double_black_opted: null,
    wallet_bonus_expiry_amount: null,
    is_email_verified: null,
    is_corporate_admin: null,
    marketing_cloud_id,
    wallet_balance_total: null,
    wallet_cash_balance: null,
    is_black_opted: null,
    black_status: null,
    mobile_communication_id,
    is_logged_in,
    is_wallet_blocked: null,
    prime_status: null,
    login_channel,
    wallet_cash_expiry_amount: null,
    loyalty_status: null,
    profile_type,
    wallet_cash_expiry_datetime: null,
    wallet_bonus_balance: null,
    wallet_bonus_expiry_datetime: null,
    is_mobile_verified,
    mobile_country_code,
    legacy_omniture_visitor_id: null,
    is_corporate_policy_blocked: null,
    login_status,
    is_corporate_verified: null,
    email_communication_id,
    is_corporate,
    corporate_organization_id: null,
    double_black_status: null,
    wallet_bonus_blocked_amount: null
  };
};

export const context = (data) => {
  if (_isEmpty(data)) {
    return null;
  }
  const {
    client_timestamp = null,
    activity_name = null,
    page_name = null,
    funnel_step = null
  } = data;
  return {
    environment: null,
    domain: null,
    client_timestamp,
    activity_name,
    api_context_name: null,
    page_name,
    topic_name: CabsPdtConstants.TopicId,
    server_ip: null,
    whatsapp_session_key: null,
    is_columbus: true,
    correlation_key: null,
    client_ip: null,
    lob_code: null,
    funnel_step,
    session_mmt_id: null,
    server_timestamp: null,
    visit_count: null,
    session_id: getSessionId(),
    server_timezone: null,
    omniture_page_name: null,
    api_version: null,
    lob_name: CabsPdtConstants.LobName,
    api_name: null,
    template_id: CabsPdtConstants.TemplateId,
    row_id: null,
    search_session_key: null
  };
};

export const travellerDetailsList = (data) => {
  if (_isEmpty(data)) {
    return null;
  }
  const {
    email_communication_id = null,
    mobile_communication_id = null,
    mobile_country_code = null,
    first_name = null
  } = data;
  return [{
    nationality: null,
    gender: null,
    title: null,
    mobile_communication_id,
    pax_type: null,
    date_of_birth: null,
    last_name: null,
    email_communication_id,
    is_lead_traveller: null,
    mobile_country_code,
    first_name
  }];
};

export const cabSearchContext = (data) => {
  if (_isEmpty(data)) {
    return null;
  }
  const {
    pickup_time_epoch = null,
    return_date_epoch = null,
    from_city_name = null,
    drop_time_epoch = null,
    from_country_name = null,
    from_city_code = null,
    to_city_name = null,
    travel_duration = null,
    to_city_code = null,
    itinerary_details_list = null,
    advance_purchase = null,
    drop_time = null,
    to_country_name = null,
    pickup_time = null,
    return_date = null,
    journey_type = null,
    source_locus_code = null,
    source_locus_type = null,
    source_locus_city_code = null,
    destination_locus_code = null,
    destination_locus_type = null,
    destination_locus_city_code = null
  } = data;
  return {
    pickup_time_epoch,
    return_date_epoch,
    from_city_name,
    to_country_code: CabsPdtConstants.CountryCode,
    drop_time_epoch,
    from_country_name,
    from_city_code,
    to_city_name,
    pax_total_count: null,
    travel_duration,
    to_city_code,
    from_country_code: CabsPdtConstants.CountryCode,
    itinerary_details_list,
    advance_purchase,
    drop_time,
    to_country_name,
    pickup_time,
    journey_type,
    return_date,
    source_locus_code,
    source_locus_type,
    source_locus_city_code,
    destination_locus_code,
    destination_locus_type,
    destination_locus_city_code
  };
};

export const bookingDetails = (data) => {
  if (_isEmpty(data)) {
    return null;
  }
  const {
    booking_created_timestamp = null,
    booking_transaction_id = null,
    checkout_id = null,
    booking_confirmation_status = null
  } = data;
  return {
    is_wallet_applied: null,
    is_coupon_applied: null,
    booking_created_timestamp,
    is_fare_changed: null,
    is_insurance_attached: null,
    old_fare_amount: null,
    fare_refreshed_amount: null,
    booking_transaction_id,
    wallet_minimum_booking_amount: null,
    checkout_id,
    is_insurance_applied: null,
    is_coupon_attached: null,
    is_partial_amount_paid: null,
    is_location_enabled: null,
    booking_confirmation_status,
    is_wallet_attached: null
  };
};

export const cabPricingDetailsList = (data) => {
  if (_isEmpty(data)) {
    return null;
  }
  const {
    total_fare
  } = data;

  return {
    total_fare,
    fare_type: null
  };
};

export const applicableCouponDetailsList = (data) => {
  if (_isEmpty(data)) {
    return null;
  }
  const {
    coupon_code = null,
    coupon_message = null,
    coupon_recommended_message = null,
    coupon_discount_amount = null,
    coupon_success_message = null,
    is_preapplied_coupon = null
  } = data;
  return {
    coupon_double_discount_applicable: null,
    coupon_code,
    coupon_message,
    coupon_verification_required: null,
    coupon_recommended_message,
    coupon_discount_amount,
    coupon_success_message,
    coupon_blocked_payment_modes: null,
    is_preapplied_coupon,
    coupon_type: null,
    coupon_hybrid_discount_details_list: couponHybridDiscountDetailsList(),
    coupon_payment_message: null
  };
};

export const appliedCouponDetails = (data) => {
  if (_isEmpty(data)) {
    return null;
  }
  const {
    coupon_code = null,
    coupon_message = null,
    coupon_recommended_message = null,
    coupon_discount_amount = null,
    coupon_success_message = null
  } = data;

  return {
    coupon_double_discount_applicable: null,
    coupon_code,
    coupon_message,
    coupon_verification_required: null,
    coupon_recommended_message,
    coupon_discount_amount,
    coupon_success_message,
    coupon_blocked_payment_modes: null,
    coupon_type: null,
    coupon_hybrid_discount_details_list: couponHybridDiscountDetailsList(),
    coupon_payment_message: null
  };
};

export const cabDetails = (data) => {
  if (_isEmpty(data)) {
    return null;
  }

  const {
    cab_vendor_name = null,
    cab_rating = null,
    cab_model = null,
    cab_category = null,
    sitting_capacity = null,
    luggage_capacity = null
  } = data;

  return {
    cab_vendor_name,
    cab_rating,
    cab_model,
    cab_category,
    sitting_capacity,
    luggage_capacity
  };
};

export const cabOtherDetails = (data) => {
  if (_isEmpty(data)) {
    return null;
  }
  const {
    is_alternate_cab_booked = null,
    kilometers_included = null,
    extra_charges = null,
    is_multiple_vendor_shown = null
  } = data;

  return {
    is_alternate_cab_booked,
    kilometers_included,
    is_cancellation_free: null,
    extra_charges,
    is_price_include_tax: null,
    is_multiple_vendor_shown,
    package_type: null,
    is_terms_and_conditions_accepted: null,
    airport_pickup_type: null,
    payment_type: null,
    items_displayed_count: null,
    hours_included: null,
    free_cancellation_duration: null,
    is_preapplied_drools: null
  };
};

export const errorDetailsList = () => ([{
  error_message: null,
  error_severity: null,
  error_source: null,
  error_code: null
}]);

export const insuranceDetailsList = () => ([{
  insurance_plan_code: null,
  insurance_amount: null,
  pax_type: null,
  insurance_price_type: null,
  insurance_stay_period: null,
  insurance_status: null,
  insurance_policy: null,
  insurance_error_message: null,
  insurance_error_code: null,
  insurance_plan_type: null
}]);

export const experimentDetailsList = () => ([{
  experiment_name: null,
  experiment_value: null
}]);

export const carCategoryVendorSelected = (data) => {
  if (_isEmpty(data)) {
    return null;
  }

  const {
    vendor_price = null,
    vendor_rating = null,
    car_category = null
  } = data;

  return [{
    vendor_price,
    vendor_rating,
    vendor_cancellation_rate: null,
    car_category
  }];
};

export const carCategoryVendorNotSelected = () => ([{
  vendor_price: null,
  vendor_rating: null,
  vendor_cancellation_rate: null,
  car_category: null
}]);

export const itineraryDetailsList = (data) => {
  if (_isEmpty(data)) {
    return null;
  }
  const {
    from_city_name = null,
    departure_date = null,
    from_country_name = null,
    to_country_name = null,
    from_city_code = null,
    departure_date_epoch = null,
    to_city_name = null,
    to_city_code = null
  } = data;
  return [{
    from_country_code: CabsPdtConstants.CountryCode,
    from_city_name,
    to_country_code: CabsPdtConstants.CountryCode,
    departure_date,
    from_country_name,
    to_country_name,
    from_city_code,
    departure_date_epoch,
    to_city_name,
    to_city_code
  }];
};

export const couponHybridDiscountDetailsList = () => ([{
  coupon_discount_type: null,
  coupon_time_of_credit: null,
  coupon_discount_code: null,
  coupon_discount_shared_by_partner: null,
  coupon_discount_shared_by_makemytrip: null
}]);
