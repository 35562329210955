import AsyncStorage from '@react-native-async-storage/async-storage';

export const SET_PRIMARY_USER_DETAILS = 'SET_PRIMARY_USER_DETAILS';
export const SET_USER_AS_GUEST = 'SET_USER_AS_GUEST';
export const CABS_MYBIZ_PAX_RECENT_SEARCH = 'cabs_mybiz_primary_pax';

export const setPrimaryUserDetails = (userDetails) => (dispatch) => {
  dispatch({
    type: SET_PRIMARY_USER_DETAILS,
    data: userDetails,
  });
};

export const setPrimaryPaxGuest = () => ({
  type: SET_USER_AS_GUEST,
});

export const getRecentSearches = async () => {
  try {
    const result = await AsyncStorage.getItem(CABS_MYBIZ_PAX_RECENT_SEARCH);
    if (!result) return [];
    return JSON.parse(result);
  } catch (e) {
    return [];
  }
};

export const setRecentSearches = async (data) => {
  try {
    await AsyncStorage.setItem(CABS_MYBIZ_PAX_RECENT_SEARCH, JSON.stringify(data));
  } catch (e) {
    //
  }
};
