import fecha from 'fecha';
import { Platform } from 'react-native';
import _isEmpty from 'lodash/isEmpty';
import _trim from 'lodash/trim';
import _compact from 'lodash/compact';
import { addHours } from '../../../Helpers/dateHelpers';
import TripType from '../types/TripType';
import { getNearestNextSlot, getNearestNextSlotInMinutes } from '../cabsDateTimeHelpers';
import { getCabsOmnitureEVars, trackListingEvent, trackPaymentCheckout ,trackReviewEvent} from '../cabsAnalytics';
import PaymentModule from '../../../Native/PaymentModule';
import { getPaxDetails, setSessionId } from './cabsLocalStore';
import { CABS_COUPON_FAILURE_MSG, CABS_COUPON_REMOVAL_FAILURE_MSG } from '../cabsConstants';
import { getEmailSuggestion } from '../api/cabsApis';
import {
  isValidEmail,
  isValidIntlMobile,
  isValidMobile,
  isValidMobileCode,
} from '../../../Helpers/validationHelpers';
import { HyperlocationStates } from '../Review/PickUpDetails';
import CabsABConfigHelper from './CabsABConfigHelper';

export const Status = {
  INITIAL: 'initial',
  IN_PROGRESS: 'in_progress',
  SUCCESS: 'success',
  FAILED: 'failed',
};

export const PassengerDetailsStates = {
  DETAILS_EMPTY: 'details_empty',
  DETAILS_FILLED: 'details_filled',
  DETAILS_ERROR: 'details_error',
};

export const ToggleStatus = {
  IMPORTANT_INFO: 'ImportantInfo',
  EXTRA_CHARGES: 'ExtraCharges',
  PRICE_INCLUDED: 'Included',
  CANCELLATION_INFO: 'CancellationInfo',
  LUGGAGE_INFO: 'LuggageInfo',
};

export const FormValidationFailedType = {
  LOCATION_ERROR: 'locationError',
  TRAVELLER_INFO_ERROR: 'travellerInfoError',
};

export const validatePassengerDetails = (paxDetails) => {
  let newPaxDetails = paxDetails;
  const { name, email, mobile, gender, countryCode = {} } = newPaxDetails;
  let newIsPaxDataValid = true;
  if (!_trim(name.value)) {
    name.error = 'Please enter your name';
    name.state = PassengerDetailsStates.DETAILS_ERROR;
    newIsPaxDataValid = false;
    trackReviewEvent('Mob_Cab_Review_Name_Not_Entered')
  }
  if (!_trim(gender.value)) {
    gender.error = 'Please select the gender';
    gender.state = PassengerDetailsStates.DETAILS_ERROR;
    newIsPaxDataValid = false;
    trackReviewEvent('Mob_Cab_Review_Gender_Not_Entered')
  }

  if (_trim(name.value).length > 50) {
    name.error = 'Name is too long, maximum 50 chars allowed';
    name.state = PassengerDetailsStates.DETAILS_ERROR;
    newIsPaxDataValid = false;
  }
  if (!_trim(email.value)) {
    email.error = 'Enter email id';
    email.state = PassengerDetailsStates.DETAILS_ERROR;
    newIsPaxDataValid = false;
    trackReviewEvent('Mob_Cab_Review_Email_Not_Entered')
  }
  if (_isEmpty(email.error) && !isValidEmail(email.value)) {
    email.error = 'Invalid email id';
    email.state = PassengerDetailsStates.DETAILS_ERROR;
    newIsPaxDataValid = false;
  }
  if (!_trim(mobile.value)) {
    mobile.error = 'Enter mobile no.';
    mobile.state = PassengerDetailsStates.DETAILS_ERROR;
    newIsPaxDataValid = false;
    trackReviewEvent('Mob_Cab_Review_Mobile_Not_Entered')
  }
  if (_isEmpty(mobile.error) && !isValidIntlMobile(_trim(mobile.value))) {
    mobile.error = 'Invalid mobile no.';
    mobile.state = PassengerDetailsStates.DETAILS_ERROR;
    newIsPaxDataValid = false;
  }
  if (!_trim(countryCode.value)) {
    countryCode.error = 'Enter country code';
    countryCode.state = PassengerDetailsStates.DETAILS_ERROR;
    newIsPaxDataValid = false;
  }
  if (_isEmpty(countryCode.error) && !isValidMobileCode(_trim(countryCode.value))) {
    countryCode.error = 'Invalid country code';
    countryCode.state = PassengerDetailsStates.DETAILS_ERROR;
    newIsPaxDataValid = false;
  }
  if (
    !_isEmpty(name.error) ||
    !_isEmpty(email.error) ||
    !_isEmpty(mobile.error) ||
    !_isEmpty(countryCode.error)
  ) {
    newIsPaxDataValid = false;
  }
  if (newIsPaxDataValid) {
    newPaxDetails = {
      name: {
        ...name,
        state: PassengerDetailsStates.DETAILS_FILLED,
      },
      email: {
        ...email,
        state: PassengerDetailsStates.DETAILS_FILLED,
      },
      mobile: {
        ...mobile,
        state: PassengerDetailsStates.DETAILS_FILLED,
      },
      countryCode: {
        ...countryCode,
        state: PassengerDetailsStates.DETAILS_FILLED,
      },
      gender: {
        ...gender,
        state: PassengerDetailsStates.DETAILS_FILLED,
      },
      isPaxDataValid: true,
    };
  } else {
    newPaxDetails = {
      ...newPaxDetails,
      name,
      email,
      countryCode,
      mobile,
      gender,
      isPaxDataValid: false,
    };
  }
  return newPaxDetails;
};
export const _isDepartureTimePassed = (request, minDepartHrs) => {
  let timePassed = false;
  let newDepartDate = null;
  try {
    const { departure_date, pickup_time, trip_type } = request;
    const dateStr = `${departure_date} ${pickup_time}`;
    let currentDepartDate = fecha.parse(dateStr, 'DD:MM:YYYY hh:mm');
    // Adding a difference of 1 min to handle corner cases in time check
    currentDepartDate = addHours(currentDepartDate, 0.02);

    // Calculate next available slot trip type
    const minTatHrs = minDepartHrs >= 0 ? minDepartHrs : TripType[trip_type].defaultAP;
    const nearestDepartDate = getNearestNextSlot(minTatHrs);

    // Check if the departure date is passed
    newDepartDate = currentDepartDate;
    if (currentDepartDate < nearestDepartDate) {
      newDepartDate = nearestDepartDate;
      timePassed = true;
    }
  } catch (e) {
    console.log(e);
  }

  return { timePassed, newDepartDate };
};

export const openPaymentPage = async (holdResponse, headerProps, cab, tag) => {
  const paymentRequest = {
    lob: 'CAB', // DO NOT CHANGE THIS
    request: holdResponse.request,
    response: holdResponse.response,
    tripHeaderData: headerProps,
    cab,
    rootTag: tag,
    omnitureData: getCabsOmnitureEVars(),
  };

  await trackPaymentCheckout();
  await setSessionId();
  if (Platform.OS === 'ios') {
    PaymentModule.openPaymentPage(paymentRequest, tag);
  } else if (Platform.OS === 'android') {
    PaymentModule.openPaymentPage(paymentRequest);
  } else {
    window.location.href = holdResponse.response.checkout_url;
  }
};

export const getGender = (gender) => {
  let userGender = null;
  if (!_isEmpty(gender)) {
    userGender = String(gender).toLowerCase();
    if (!(['male', 'female', 'other'].includes(userGender))) {
      userGender = null;
    }
  }
  return userGender;
};

export const fetchPaxDetailsHelper = async (getState) => {
  let pax = await getPaxDetails();
  const { userDetails = {}, } = getState().cabs;
  const userDetailsEmpty = _isEmpty(userDetails);
  if (pax !== null && (userDetailsEmpty || userDetails.profileType === pax.profileType)) {
    pax.hasCompleteFields = !(_isEmpty(pax.email) || _isEmpty(pax.mobile) || _isEmpty(pax.gender));
    pax.countryCode = pax.countryCode || '91';
  } else if (!userDetailsEmpty) {
    try {
      const { firstName, lastName, email, mobile, gender = null } = userDetails;
      let mobileNumber,
        countryCode = '';
      if (mobile) {
        mobileNumber = mobile.mobileNumber;
        countryCode = mobile.countryCode;
      }
      const userGender = getGender(gender);
      const hasCompleteFields = !(_isEmpty(email) || _isEmpty(mobileNumber));
      const fullName = _compact([firstName, lastName]).join(' ');
      pax = {
        name: fullName,
        email,
        mobile: mobileNumber,
        countryCode: countryCode || '91',
        gender: userGender,
        hasCompleteFields,
      };
    } catch (e) {
      pax = {
        name: '',
        email: '',
        mobile: '',
        countryCode: '91',
        gender: '',
        hasCompleteFields: false,
      };
      // Ignore exception
    }
  } else {
    pax = {
      name: '',
      email: '',
      mobile: '',
      countryCode: '91',
      gender: '',
      hasCompleteFields: false,
    };
  }
  return pax;
};

export const getUpdatedCab = async (response, cab, isDeleteCoupon = false) => {
  let trackEventMessage;
  let message;
  let validateResponseStatus;
  let updatedCab = {};
  try {
    const validateResponse = await response.json();
    if (validateResponse.status === 'FAILURE' || validateResponse.errors !== null) {
      if (isDeleteCoupon) {
        message = CABS_COUPON_REMOVAL_FAILURE_MSG;
      } else {
        message = CABS_COUPON_FAILURE_MSG;
        trackEventMessage = 'Mob_Cabs_Coupon_Apply_Fail';
      }
      validateResponseStatus = 'FAILURE';
    } else {
      const couponDetail = validateResponse.response[0] || {};
      message = couponDetail.message;
      if (isDeleteCoupon && couponDetail.couponRemoved === false) {
        validateResponseStatus = 'FAILURE';
      } else if (isDeleteCoupon === false && couponDetail.is_coupon_applied === false) {
        trackEventMessage = 'Mob_Cabs_Coupon_Apply_Fail';
        validateResponseStatus = 'FAILURE';
      } else {
        validateResponseStatus = 'SUCCESS';
        if (isDeleteCoupon === false) {
          trackEventMessage = 'Mob_Cabs_Coupon_Apply_Success';
        }
      }
      const {
        fare_details,
        total_amount,
        coupon_code,
        coupon_amount = 0,
        coupon_percent = 0,
        full_payment_message,
        partial_payable_amount,
        payment_message,
        fare_breakup_list,
        wallet_discount_enabled = true,
        applicable_only_full_payment = false,
        user_message = '',
        is_cab_upgraded: isCabUpgraded = false,
        strike_off_discount_textV2,
        strike_price,
        highlighted_price,
        tolls_and_taxes_text,
        full_payable_amount,
        bs_partial_payment_label,
        bs_full_payment_label,
        bs_inclusive_label,
        bs_partial_payment_subLabel = '',
      } = couponDetail;
      const display_properties = {
        ...cab.display_properties,
        fare_details,
      };
      const cab_fare_detail = {
        ...cab.cab_fare_detail,
        bs_partial_payment_label,
        bs_full_payment_label,
        bs_inclusive_label,
        bs_partial_payment_subLabel,
        total_amount,
        coupon_code,
        coupon_amount,
        coupon_percent,
        full_payment_message,
        partial_payable_amount,
        payment_message,
        wallet_discount_enabled,
        applicable_only_full_payment,
        strike_off_discount_textV2,
        strike_price,
        highlighted_price,
        tolls_and_taxes_text,
        full_payable_amount,
      };
      updatedCab = {
        ...cab,
        isCabUpgraded,
        display_properties,
        cab_fare_detail,
        fare_breakup_list,
      };
    }
  } catch (e) {
    validateResponseStatus = 'FAILURE';
    if (isDeleteCoupon) {
      message = CABS_COUPON_REMOVAL_FAILURE_MSG;
    } else {
      trackEventMessage = 'Mob_Cabs_Coupon_Apply_Fail';
      message = CABS_COUPON_FAILURE_MSG;
    }
  }
  return {
    validateResponseStatus,
    message,
    trackEventMessage,
    updatedCab,
  };
};

export const fetchEmailSuggestions = async (query, userDetails) => {
  console.log('fetchEmailSuggestions called');
  if (!_isEmpty(userDetails)) {
    const response = await getEmailSuggestion(
      query,
      userDetails.mmtAuth,
      userDetails.organizationId,
    );
    console.log(response);
    return response;
  }
  return null;
};

export const initialisePaxData = (pax) => {
  const paxDetails = {
    name: {
      value: '',
      state: PassengerDetailsStates.DETAILS_EMPTY,
    },
    email: {
      value: '',
      state: PassengerDetailsStates.DETAILS_EMPTY,
    },
    mobile: {
      value: '',
      state: PassengerDetailsStates.DETAILS_EMPTY,
    },
    gender: {
      value: '',
      state: PassengerDetailsStates.DETAILS_EMPTY,
    },
    countryCode: {
      value: '91',
      state: PassengerDetailsStates.DETAILS_EMPTY,
    },
    bookingFor: {
      value: '',
      state: PassengerDetailsStates.DETAILS_EMPTY,
    },
    isPaxDataValid: false,
  };

  if (_isEmpty(pax)) {
    return paxDetails;
  }
  if (!_isEmpty(pax.name)) {
    paxDetails.name.value = pax.name;
  }
  if (!_isEmpty(pax.email)) {
    paxDetails.email.value = pax.email;
  }
  if (!_isEmpty(pax.countryCode)) {
    paxDetails.countryCode.value = pax.countryCode;
  }
  if (!_isEmpty(pax.mobile)) {
    paxDetails.mobile.value = pax.mobile;
  }
  if (!_isEmpty(pax.gender)) {
    paxDetails.gender.value = pax.gender;
  }
  if (!_isEmpty(pax.bookingFor)) {
    paxDetails.bookingFor.value = pax.bookingFor;
  }
  return paxDetails;
};

export const setPaxState = (paxDetails, state) => {
  let newPaxDetails = paxDetails;
  newPaxDetails = {
    name: {
      ...newPaxDetails.name,
      state,
    },
    email: {
      ...newPaxDetails.email,
      state,
    },
    mobile: {
      ...newPaxDetails.mobile,
      state,
    },
    gender: {
      ...newPaxDetails.gender,
      state,
    },
    bookingFor: {
      ...newPaxDetails.bookingFor,
      state
    },
    isPaxDataValid: newPaxDetails.isPaxDataValid,
  };
  return newPaxDetails;
};

export const handleOnNextClickForTripDetails = (
  hyperlocationState,
  hyperlocationDropState,
  paxDetails,
  screenName,
  isDestRequired = false,
) => {
  let newHyperlocationState = hyperlocationState;
  let newHyperlocationDropState = hyperlocationDropState;
  let isScrollRequired = false;
  let shouldReturn = false;
  let newPaxDetails = paxDetails;
  let event = '';
  let errorType = '';

  if (
    paxDetails.name.state === PassengerDetailsStates.DETAILS_EMPTY ||
    paxDetails.email.state === PassengerDetailsStates.DETAILS_EMPTY ||
    paxDetails.mobile.state === PassengerDetailsStates.DETAILS_EMPTY
  ) {
    newPaxDetails = validatePassengerDetails(newPaxDetails);
    if (!newPaxDetails.isPaxDataValid) {
      isScrollRequired = true;
      shouldReturn = true;
      errorType = FormValidationFailedType.TRAVELLER_INFO_ERROR;
    }
  } else {
    newPaxDetails = validatePassengerDetails(paxDetails);
    if (!newPaxDetails.isPaxDataValid) {
      isScrollRequired = true;
      event = `Mob_Cab_${screenName}_Traveler_Add_Fail`;
      shouldReturn = true;
      errorType = FormValidationFailedType.TRAVELLER_INFO_ERROR;
    } else {
      event = `Mob_Cab_${screenName}_Traveler_Add_Success`;
    }
  }

  if (hyperlocationState === HyperlocationStates.HYPERLOCATION_REQUIRED) {
    newHyperlocationState = HyperlocationStates.HYPERLOCATION_ERROR;
    isScrollRequired = true;
    shouldReturn = true;
    errorType = FormValidationFailedType.LOCATION_ERROR;
  } else if (hyperlocationState === HyperlocationStates.HYPERLOCATION_ERROR) {
    isScrollRequired = true;
    newHyperlocationState = HyperlocationStates.HYPERLOCATION_ERROR;
    shouldReturn = true;
    errorType = FormValidationFailedType.LOCATION_ERROR;
  }
  if (isDestRequired) {
    if (hyperlocationDropState === HyperlocationStates.HYPERLOCATION_REQUIRED) {
      newHyperlocationDropState = HyperlocationStates.HYPERLOCATION_ERROR;
      isScrollRequired = true;
      shouldReturn = true;
      errorType = FormValidationFailedType.LOCATION_ERROR;
    } else if (hyperlocationDropState === HyperlocationStates.HYPERLOCATION_ERROR) {
      isScrollRequired = true;
      newHyperlocationDropState = HyperlocationStates.HYPERLOCATION_ERROR;
      shouldReturn = true;
      errorType = FormValidationFailedType.LOCATION_ERROR;
    }
  }
  return {
    shouldReturn,
    event,
    isScrollRequired,
    newPaxDetails,
    newHyperlocationState,
    newHyperlocationDropState,
    errorType,
  };
};

/**
 * Fetch state for gst widget from Pickup OR Drop location entered by user on Review page.
 * @param {boolean} showA2CDestination
 * @param {string} tripType
 * @param {object} sourceLocation
 * @param {object} destinationLocation
 * @returns {string}
 */
export const getStateForGst = (
  showA2CDestination,
  tripType,
  sourceLocation,
  destinationLocation,
) => {
  let locationState;
  if (showA2CDestination && tripType === TripType.AT.value && sourceLocation.is_airport) {
    locationState = destinationLocation?.state
  } else {
    locationState = sourceLocation?.state
  }
  return locationState || '';
};

