import {NativeModules} from 'react-native';

export const botmanSensorHeader = async () => {
  try {
    const {NetworkModule} = NativeModules;
    // This will return map of sensor data and user agent.
    const sensorData = await NetworkModule.getSensorData();
    if (sensorData) {
      return sensorData;
    }
    return {};
  } catch (e) {
    return {};
  }
};

export default NativeModules.NetworkModule;
