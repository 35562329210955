import BaseGenericEvent from '../../BaseGenericEvent';
import {getSearchEventData} from '../../PdtHelper/CabsPdtUtils';
import {CabsPdtConstants} from '../../PdtHelper/CabsPdtConstants';

export default class CabsPdtLandingEvent extends BaseGenericEvent {
  constructor(pdtData) {
    const data = pdtData;
    data.pageName = CabsPdtConstants.FunnelStep.LANDING;
    data.funnelStep = CabsPdtConstants.FunnelStep.LANDING;
    super(data);
    const {
      sourceData,
      destinationData,
      departDate,
      returnDate,
      tripType
    } = pdtData;

    this.searchData = {
      sourceData,
      destinationData,
      departDate,
      returnDate,
      tripType
    };
  }

  trackLandingEvent = () => {
    const basePdtObject = this.basePdtData();
    basePdtObject.cab_search_context = getSearchEventData(this.searchData);
    return basePdtObject;
  }
}
