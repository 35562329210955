export const ACTION_UPDATE_DESTINTION_NAME = 'ACTION_UPDATE_DESTINTION_NAME';
export const ACTION_SET_DESTINATION_LOCATION =
  'ACTION_SET_DESTINATION_LOCATION';
export const ACTION_UPDATE_SOURCE_NAME = 'ACTION_UPDATE_SOURCE_NAME';
export const ACTION_SET_SOURCE_LOCATION = 'ACTION_SET_SOURCE_LOCATION';
export const ACTION_SET_HOTEL_ID = 'ACTION_SET_HOTEL_ID';
export const ACTION_UPDATE_API_RESPONSE = 'ACTION_UPDATE_API_RESPONSE';
export const ACTION_SET_HOTEL_OFFERS = 'ACTION_SET_HOTEL_OFFERS';
export const ACTION_UPDATE_RECENT_SEARCH = 'ACTION_UPDATE_RECENT_SEARCH';
export const ACTION_SWAP_SRC_DEST = 'ACTION_SWAP_SRC_DEST';

export const updateDestinationName = (destinationName) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: ACTION_UPDATE_DESTINTION_NAME,
    data: destinationName,
  });
};

export const updateDestinationLocation = (destinationLocation) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: ACTION_SET_DESTINATION_LOCATION,
    data: destinationLocation,
  });
};

export const updateSourceName = (sourceName) => (dispatch, getState) => {
  dispatch({
    type: ACTION_UPDATE_SOURCE_NAME,
    data: sourceName,
  });
};

export const updateSourceLocation = (sourceLocation) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: ACTION_SET_SOURCE_LOCATION,
    data: sourceLocation,
  });
};

export const updateRecentSearch = data => (dispatch, getState) => {
  dispatch({
    type: ACTION_UPDATE_RECENT_SEARCH,
    data: data
  })
}

export const setHotelId = (hotel_Id) => (dispatch, getState) => {
  dispatch({
    type: ACTION_SET_HOTEL_ID,
    data: hotel_Id,
  });
};

export const updateApiResponse = (response) => (dispatch, getState) => {
  dispatch({
    type: ACTION_UPDATE_API_RESPONSE,
    data: response,
  });
};

export const setOffers = (offers = async (dispatch) => {
  dispatch({
    type: ACTION_SET_HOTEL_OFFERS,
    data: offers,
  });
});

export const swapSrcDest = data => (dispatch, getState) => {
  dispatch({
    type: ACTION_SWAP_SRC_DEST,
    data: data,
  });
};
