import { Platform } from 'react-native';
import { AbConfigKeyMappings, getPokusConfig } from '../../../Native/AbConfig/AbConfigModule';
import { PokusLobs } from '../../../Native/AbConfig/AbConfigKeyMappings';

export default class CabsABConfigHelper {
  static multiCityEnabled() {
    return false; // not doing pokus cleanup on this flag since required effort will be high and this repo will be deprecated soon.
  }
  static showAppDownloadWidget = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.cabAppDownloadWidget, false);
  };
  static getBlusmartVariant = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.bluSmartListing, 0);
  };
  static showNewTagHR = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.cabsNewTagHR, false);
  };
  static isHotelMarketingCheckFareExp = () => {
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.hotelMarketingCheckFare, false);
  }
  static showReviewAssistedWidget = () =>{
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.reviewAssistedWidget, false);
  }
  static showListingAssistedWidget = () =>{
    return getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.listingAssistedWidget, false);
  }
}
