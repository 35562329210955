import { Platform } from 'react-native';
import _isEmpty from 'lodash/isEmpty';
import { Actions } from 'src/Routes/Cabs/Navigation';
import {
  getMmtAuth,
  getUserDetails,
  isPersonalUser,
  isUserLoggedIn,
} from '../../Native/UserSession/UserSessionModule';
import { getCompleteUserDetails } from './api/cabsApis';
import { getTravelTypeVisibleStatus, createDeviceDetails, getUserLoggedInType } from './utils/cabsCommonUtils';
import { setIsLoggedInTracker } from './cabsAnalytics';

export const ACTION_UPDATE_USER_DETAILS = 'ACTION_UPDATE_USER_DETAILS';
export const ACTION_UPDATE_INSTANT_CAB_OFFER = 'ACTION_UPDATE_INSTANT_CAB_OFFER';
export const ACTION_UPDATE_TRAVEL_TYPES = 'ACTION_UPDATE_TRAVEL_TYPES';
export const ACTION_UPDATE_DEVICE_DETAILS = 'ACTION_UPDATE_DEVICE_DETAILS';
export const ACTION_UPDATE_THEME = 'ACTION_UPDATE_THEME';
export const ACTION_SET_COMMON_POKUS_FLAGS = 'ACTION_SET_COMMON_POKUS_FLAGS';
export const ACTION_SET_ASSISTED_FLOW_DATA = 'ACTION_SET_ASSISTED_FLOW_DATA';
export const ACTION_SET_ASSURANCE_DATA = 'ACTION_SET_ASSURANCE_DATA';
// Actions creators
export let _isPersonalUser = true;

export const getValueByUserType = (defaultValue, myBizValue) => {
  if (_isPersonalUser) {
    return defaultValue;
  } else {
    return myBizValue;
  }
};

export const setCommonPokusFlags = (data) => ({
  type: ACTION_SET_COMMON_POKUS_FLAGS,
  data: data,
});

export const updateUserDetails = (data) => async (dispatch, getState) => {
  _isPersonalUser = true;
  if (data) {
    if (!isPersonalUser(data)) {
      _isPersonalUser = false;
      const corpBlockLobs = data?.corpData?.employee?.blockLobs || [];
      const [hideTravelTypes, blockTravelTypes] = getTravelTypeVisibleStatus(corpBlockLobs);
      data.hideTravelTypes = hideTravelTypes;
      data.blockTravelTypes = blockTravelTypes;
    }
  }

  dispatch({
    type: ACTION_UPDATE_USER_DETAILS,
    data: {
      userDetails: data,
      isPersonalUser: _isPersonalUser
    },
  });
};
export const updateInstantCabOffer = (data) => async (dispatch) => {
  dispatch({
    type: ACTION_UPDATE_INSTANT_CAB_OFFER,
    data,
  });
};

export const updateDeviceDetails = (data) => ({
  type: ACTION_UPDATE_DEVICE_DETAILS,
  data,
});

// Actions
export const getNativeUserDetails = async (dispatch) => {
  const loggedIn = await isUserLoggedIn();
  let userDetails = null;
  if (loggedIn) {
    try {
      userDetails = await getUserDetails();
      dispatch(updateUserDetails(userDetails));
    } catch (e) {
      //
    }
  } else {
    dispatch(updateUserDetails({}));
  }
  return userDetails;
};

export const getRNWUserDetails = () => async (dispatch) => {
  let userDetails;
  if (Platform.OS === 'web') {
    const token = await getMmtAuth();
    if (!_isEmpty(token)) {
      try {
        userDetails = await getCompleteUserDetails(token);
        dispatch(updateUserDetails(userDetails));
      } catch (e) {
        console.log('unable to fetch user details');
      }
    }
    // for tracking the user login status in omniture 
    const screen = window.location.href.includes('cabsTripReview') ? 'Review' : 'Landing'
    setIsLoggedInTracker(getUserLoggedInType(userDetails, screen));
  }
  return userDetails;
};

export const getDeviceDetails = async (dispatch) => {
  let deviceInfo = null;
  try {
    deviceInfo = await createDeviceDetails();
    dispatch(updateDeviceDetails(deviceInfo));
  } catch (e) {
    console.log('unable to fetch device details');
  }
  return deviceInfo;
};

export const prepareDataAndOpenRating = (cab, tripType) => {
  try {
    if (
      !(cab?.ratings_display?.overall_rating > 0 && cab?.ratings_display?.rating_show) ||
      !tripType
    ) {
      return false;
    }
    let ratingsBreakUp = { ...cab.ratings_display };
    delete ratingsBreakUp.rating_show;
    let data = {
      cabDisplay: cab.displayName,
      cabType: cab.cabType,
      tripType,
      similarMessage: cab.similar_message,
      ratingsCount: ratingsBreakUp.no_of_reviews,
      verifiedRatingsCount: 0,
      ratings: ratingsBreakUp,
      fuelType: cab.fuel_type || null,
    };
    Actions.cabsRating({ data: data });
    return true;
  } catch (e) {
    return false;
  }
};

export const setAssistedFlowData = (data) => async (dispatch) => {
  dispatch({
    type: ACTION_SET_ASSISTED_FLOW_DATA,
    data,
  });
};