import {CabsPdtConstants} from './CabsPdtConstants';
import CabsPdtReviewEvent from '../PdtModel/PdtEvents/CabsPdtReviewEvent';
import {getComIds, pushPdtData} from '../../api/cabsApis';

export default class CabsPdtReviewHelper {
  static trackPageLoad(data) {
    try {
      const pdtData = data;
      pdtData.activityName = CabsPdtConstants.BaseEventAction.LOAD_PAGE;
      const pdtObject = new CabsPdtReviewEvent(pdtData).trackReviewEvent();
      pushPdtData(pdtObject);
    } catch (e) {
      // ignore
    }
  }

  static trackPageExit(data) {
    try {
      let pdtData = {...data};
      pdtData.pax = {...data.pax};
      const {eComId = '', mComId = ''} = getComIds(data.pax.email, data.pax.mobile);
      pdtData.pax.email = eComId;
      pdtData.pax.mobile = mComId;
      pdtData.activityName = CabsPdtConstants.BaseEventAction.EXIT_PAGE;
      const pdtObject = new CabsPdtReviewEvent(pdtData).trackReviewEvent();
      pushPdtData(pdtObject);
    } catch (e) {
      // ignore
    }
  }

  static trackBackPressed(data) {
    try {
      const pdtData = data;
      pdtData.activityName = CabsPdtConstants.BaseEventAction.CLICK_BACK;
      const pdtObject = new CabsPdtReviewEvent(pdtData).trackReviewEvent();
      pushPdtData(pdtObject);
    } catch (e) {
      // ignore
    }
  }

  static async trackPerformHold(data) {
    try {
      // let pdtData = JSON.parse(JSON.stringify(data));
      let pdtData = {...data};
      pdtData.pax = {...data.pax};
      const {eComId = '', mComId = ''} = await getComIds(data.pax.email, data.pax.mobile);
      pdtData.pax.email = eComId;
      pdtData.pax.mobile = mComId;
      pdtData.activityName = CabsPdtConstants.ReviewEventAction.CLICK_PAY;
      const pdtObject = new CabsPdtReviewEvent(pdtData).trackReviewEvent();
      pushPdtData(pdtObject);
    } catch (e) {
      // ignore
    }
  }
}
