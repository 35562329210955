"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepareGSTDataForHold = exports.prepareTripTagDataForHold = exports.formatTripTagResponse = exports.formatGSTDetails = void 0;
exports.formatGSTDetails = function (gstData) {
    var gstn = gstData.gstn, orgId = gstData.orgId, organizationName = gstData.organizationName, address1 = gstData.address1, address2 = gstData.address2, city = gstData.city, pinCode = gstData.pinCode;
    return {
        gstn: gstn,
        orgId: orgId,
        organizationName: organizationName,
        address: (address1 || '') + " " + (address2 || '') + " " + (city || '') + " " + (pinCode || '')
    };
};
exports.formatTripTagResponse = function (tripTagRes) {
    var _a, _b;
    var tripTagData = null;
    if (tripTagRes &&
        tripTagRes.status === "success" &&
        tripTagRes.attributeList && tripTagRes.attributeList.length) {
        var tripTagAttributes = tripTagRes.attributeList.map(function (item) {
            return __assign(__assign({}, item), { attributeValue: null, isAttributeErrorState: false });
        });
        tripTagData = {
            header: (_a = tripTagRes === null || tripTagRes === void 0 ? void 0 : tripTagRes.tripTagSectionDetails) === null || _a === void 0 ? void 0 : _a.header,
            description: (_b = tripTagRes === null || tripTagRes === void 0 ? void 0 : tripTagRes.tripTagSectionDetails) === null || _b === void 0 ? void 0 : _b.description,
            tripTagAttributeList: tripTagAttributes
        };
    }
    return tripTagData;
};
exports.prepareTripTagDataForHold = function (tripTagData) {
    if (!tripTagData)
        return null;
    return {
        header: tripTagData.header,
        description: tripTagData.description,
        is_personal: false,
        attribute_list: tripTagData.tripTagAttributeList && tripTagData.tripTagAttributeList.length ? __spreadArrays(tripTagData.tripTagAttributeList.map(function (item) { return ({
            attribute_id: item.attributeId,
            attribute_name: item.attributeName,
            attribute_selected_value: typeof (item.attributeValue) === 'string' ? [item.attributeValue] : (item.attributeValue || []),
            mandatory_check: item.mandatoryCheck || false,
        }); })) : null
    };
};
exports.prepareGSTDataForHold = function (gstData) {
    if (!gstData)
        return null;
    return {
        header: gstData.header,
        description: gstData.description,
        gstn: gstData.gstn,
        org_id: gstData.orgId,
        organization_name: gstData.organizationName,
        address: gstData.address
    };
};
