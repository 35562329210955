import React from 'react';
import PropTypes from 'prop-types';
import {View} from 'react-native';
import {colors} from '../../Styles/globalStyles';

const TouchableRipple = ({
  feedbackColor, onPress, children, style, disabled, ...otherProps
}) => (
  <View
    onClick={disabled ? () => {}: onPress}
    style={style}
    {...otherProps}
  >
    {children}
  </View>
);

TouchableRipple.propTypes = {
  feedbackColor: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};


TouchableRipple.defaultProps = {
  feedbackColor: colors.grayBg,
  style: {},
  disabled: false,
};

export default TouchableRipple;
