export const ADDON_UPDATE_ERROR_MESSAGE = 'Unable to update booking details. Please try again later';
export const ADDON_BOOKING_NOT_FOUND_ERROR_MESSAGE = 'Booking details not found. Please try again later';
export const ADDON_EMPTY_LOCATION_MESSAGE = 'Please enter a valid address';
export const ADDON_INVALID_DATE_MESSAGE = 'The selected date/time doesn\'t match with the flight timing. Please check!';

export const AddonParentScreen = Object.freeze({
  MIMA_FLIGHT_DETAILS: 'mimaFlightDetails',
  MIMA_CAB_DETAILS: 'mimaCabDetails'
});

export const RESERVATION_TEXT = 'Reservation Amount';
