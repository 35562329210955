import { NativeModules } from 'react-native'
import _ from 'lodash'


export const _getOffersList = async (lob, searchString) => {
  try {
    let offersList = await getOfferDetails(lob);
    const lobUpperCase = lob.toUpperCase();
    let newOfferList = _.filter(offersList, (offer) => {
      return offer.lob.indexOf(lobUpperCase) >= 0;
    });
    if (newOfferList.length > 0) {
      try {
        newOfferList = _.map(newOfferList, (originalOfferObj) => {
          const newOfferObject = {};
          let promoTitle = originalOfferObj.pTl;
          if (!_.isEmpty(originalOfferObj.dStxt) && !_.isEmpty(originalOfferObj.offerTxt)
            && !_.isEmpty(originalOfferObj.dEtxt)) {
            promoTitle = `${originalOfferObj.dStxt} ${originalOfferObj.offerTxt} ${originalOfferObj.dEtxt}`;
          }
          newOfferObject.id = originalOfferObj.id;
          newOfferObject.newHeroUrl = originalOfferObj.newHeroUrl;
          newOfferObject.cardCTAText = originalOfferObj.cardCTAText;
          newOfferObject.newHeroOfferCardUrl = originalOfferObj.newHeroOfferCardUrl;
          newOfferObject.promoTitle = promoTitle;
          newOfferObject.promoMessage = originalOfferObj.pTl;
          newOfferObject.promoCode = originalOfferObj.cc;
          newOfferObject.dis = originalOfferObj.dis;
          newOfferObject.url = originalOfferObj.url;
          newOfferObject.skyBigFullImgUrl = originalOfferObj.skyBigFullImgUrl;
          newOfferObject.lob = originalOfferObj.lob;
          newOfferObject.heroUrl = originalOfferObj.heroUrl;
          return newOfferObject;
        });
        newOfferList = _.orderBy(newOfferList, ['dis'], ['desc']);
      } catch (e) {
        newOfferList = [];
      }
    }
    return newOfferList;
  } catch (e) {
    return [];
  }
};


const getOfferDetails = async (lob) => {
  const response = await fetch('//cabs.makemytrip.com/getOffers', {
    headers: {
      'Authorization': 'DwEjF4vuXy01',
      'Accept': 'application/json',
      'os': 'pwa',
    }
  });
  const data = await response.json()
  const lobOffers = _getLobOffers(data, lob);
  return lobOffers;
}

const _getLobOffers = (data, lob) => {
  const lobCapitalised = lob.toUpperCase();
  const offerCategories = data.cardData.Offers_Listing.categoryOffersSequenceMap[lobCapitalised];
  const lobOffers = [...offerCategories.active, ...(offerCategories.upcoming || [])];
  return lobOffers.map(offerId => data.cardData.Offers_Listing.promoMessagesMap[offerId])
}


NativeModules.OffersModule = {
  getOffers: _getOffersList
};