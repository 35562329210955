const _rootTag = [];
export const pushRootTag = rootTag => {
  _rootTag.push(rootTag);
};
export const popRootTag = () => {
  if (_rootTag.length) {
    _rootTag.pop();
  }
};
export const getRootTag = () => {
  if (_rootTag.length) {
    return _rootTag[_rootTag.length - 1];
  }
  return 1;
};
