import {PixelRatio, Platform} from 'react-native';

export const PIXEL_RATIO = PixelRatio.get();
export const SCREEN_RESOLUTION_LDPI = 'ldpi';
export const SCREEN_RESOLUTION_MDPI = 'mdpi';
export const SCREEN_RESOLUTION_HDPI = 'hdpi';
export const SCREEN_RESOLUTION_XHDPI = 'xhdpi';
export const SCREEN_RESOLUTION_XXHDPI = 'xxhdpi';
export const SCREEN_RESOLUTION_XXXHDPI = 'xxxhdpi';
export const SCREEN_RESOLUTION_2X = '2x';
export const SCREEN_RESOLUTION_3X = '3x';
/**
 * returns device density name for android from ldpi to xxxhdpi
 */

export const getScreenDensityName = () => {
  if (Platform.OS === 'ios') {
    if (PIXEL_RATIO > 2.9) {
      return SCREEN_RESOLUTION_3X;
    }
    if (PIXEL_RATIO > 1.9) {
      return SCREEN_RESOLUTION_2X;
    }
    return SCREEN_RESOLUTION_2X;
  }
  if (PIXEL_RATIO >= 3.5) {
    return SCREEN_RESOLUTION_XXXHDPI;
  }
  if (PIXEL_RATIO >= 2.5) {
    return SCREEN_RESOLUTION_XXHDPI;
  }
  if (PIXEL_RATIO >= 2.0) {
    return SCREEN_RESOLUTION_XHDPI;
  }
  if (PIXEL_RATIO >= 1.5) {
    return SCREEN_RESOLUTION_HDPI;
  }
  if (PIXEL_RATIO >= 1.0) {
    return SCREEN_RESOLUTION_MDPI;
  }
  return SCREEN_RESOLUTION_LDPI;
};

// eslint-disable-next-line import/no-unresolved
export const backIcon = Platform.OS === 'ios' ? require('../Assets/ic_back_ios.webp') : require('../Assets/ic_back_arrow.webp');
