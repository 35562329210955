import {
  eventHeaderForTripType,
  CabsPageTrackerFunc, trackListingErrorEvent
} from '../cabsAnalytics';
import { CabsScreen } from '../config/cabsScreen';
import {ViewStates} from "../Listing/cabsListingReducer";

// Constants
export const DateSelectMode = {
  DEPART: 'DEPART',
  RETURN: 'RETURN',
  PICKUP: 'PICKUP'
};

// Actions
export const ACTION_SET_DATE_TIME = 'ACTION_SET_DATE_TIME';
export const ACTION_DEPART_DATE_TAB_CLICKED = 'ACTION_DEPART_DATE_TAB_CLICKED';
export const ACTION_RETURN_DATE_TAB_CLICKED = 'ACTION_RETURN_DATE_TAB_CLICKED';
export const ACTION_PICKUP_TIME_TAB_CLICKED = 'ACTION_PICKUP_TIME_TAB_CLICKED';
export const ACTION_DATE_CLICKED = 'ACTION_DATE_CLICKED';
export const ACTION_CLEAR_RETURN_DATE = 'ACTION_CLEAR_RETURN_DATE';
export const ACTION_TIME_CHANGED = 'ACTION_TIME_CHANGED';
export const ACTION_RESET_CALENDAR = 'ACTION_RESET_CALENDAR';
export const ACTION_HIDE_RT_TOAST = 'ACTION_HIDE_RT_TOAST';
export const ACTION_TIME_PICKER_REFRESH_COMPLETE = 'ACTION_TIME_PICKER_REFRESH_COMPLETE';
export const SET_INITIAL_DATA = 'SET_INITIAL_DATA';

export const resetCalendar = searchRequest => (dispatch) => {
  dispatch({
    type: ACTION_RESET_CALENDAR,
    data: {searchRequest}
  });
};

export const initWithDates =
  (
    initialDateSelectMode,
    departDate,
    returnDate,
    tripType,
    dateChangedHandler,
    disableReturn = false,
    screen = CabsScreen.LANDING,
    isMulticitySearch = false,
  ) => ({
    type: SET_INITIAL_DATA,
    data: {
      initialDateSelectMode,
      departDate,
      returnDate,
      tripType,
      dateChangedHandler,
      disableReturn,
      screen,
      isMulticitySearch,
    }
  });

export const onDepartDateTabClicked = () => (dispatch) => {
  dispatch(triggerCalenderEvent(CalenderEvent.PickupDateClick));
  dispatch({
    type: ACTION_DEPART_DATE_TAB_CLICKED
  });
};

export const onReturnDateTabClicked = () => (dispatch) => {
  dispatch(triggerCalenderEvent(CalenderEvent.ReturnDateClick));
  dispatch({
    type: ACTION_RETURN_DATE_TAB_CLICKED
  });
};
export const onPickupTimeTabClicked = () => (dispatch) => {
  dispatch(triggerCalenderEvent(CalenderEvent.HeaderPickupTimeClick));
  dispatch({
    type: ACTION_PICKUP_TIME_TAB_CLICKED
  });
};

export const onReturnDateCleared = ({
  type: ACTION_CLEAR_RETURN_DATE
});

export const hideRTToast = () => ({
  type: ACTION_HIDE_RT_TOAST
});

let _lastChangeTime = 0;
export const onTimeChanged = (time, isPickUpTimeSelected = false) => (dispatch, getState) => {
  const {
    cabsCalendar: {
      dateSelectMode,
      disableReturn
    }
  } = getState();
  const departSelected = dateSelectMode !== DateSelectMode.RETURN;
  if (Date.now() - _lastChangeTime > 3000) {
    _lastChangeTime = Date.now();
    const event = (departSelected ? CalenderEvent.PickupTimeClick : CalenderEvent.DropTimeClick);
    dispatch(triggerCalenderEvent(event));
  }
  dispatch({
    type: ACTION_TIME_CHANGED,
    data: time
  });
  // Mark the pickup time section as selected
  if (departSelected && disableReturn && isPickUpTimeSelected) {
    dispatch({
      type: ACTION_PICKUP_TIME_TAB_CLICKED
    });
  }
};

export const onDateSelected = date => (dispatch, getState) => {
  const {
    cabsCalendar: {
      dateSelectMode,
      disableReturn
    }
  } = getState();
  const departSelected = dateSelectMode !== DateSelectMode.RETURN;
  const event = (departSelected ? CalenderEvent.ScrollPickupDateClick : CalenderEvent.ScrollReturnDateClick);
  dispatch(triggerCalenderEvent(event));
  dispatch({
    type: ACTION_DATE_CLICKED,
    data: date
  });

  // Mark the start date section as selected
  if (!departSelected && disableReturn) {
    dispatch({
      type: ACTION_DEPART_DATE_TAB_CLICKED
    });
  }
};

export const onTimePickerRefreshComplete = ({
  type: ACTION_TIME_PICKER_REFRESH_COMPLETE
});

export const onDoneClicked = () => async (dispatch, getState) => {
  const {departDate, returnDate, dateChangedHandler} = getState().cabsCalendar;
  if (dateChangedHandler) {
    dispatch(dateChangedHandler(departDate, returnDate));
  }
};

export const CalenderEvent = {
  PickupDateClick: 'Calendar_Header_Pickup_Date_Click',
  ReturnDateClick: 'Calendar_Header_Return_Date_Click',
  HeaderPickupTimeClick: 'Calendar_Header_Pickup_Time_Click',
  PickupTimeClick: 'Calendar_Pickup_Time_Click',
  DropTimeClick: 'Calendar_Drop_Time_Click',
  ScrollPickupDateClick: 'Calendar_Scroll_Pickup_Date_Click',
  ScrollReturnDateClick: 'Calendar_Scroll_Return_Date_Click',
  BackClick: 'Calendar_Back_Click',
  DoneClick: 'Mob_Cab_Calendar_Done_Click',
  CalenderBackArrowClick: 'Calendar_Back_Arrow_Dates',
  CalenderForwardArrowClick: 'Calendar_Forward_Arrow_Dates',
  CalenderPmToAmClick: 'Calendar_PM_to_AM_Time_Click',
  CalenderAmToPmClick: 'Mob_Cab_Calendar_AM_to_PM_Time_Click'
};

export const triggerCalenderEvent = event => (dispatch, getState) => {
  const {
    cabsCalendar: {
      tripType,
      screen
    },
    cabsListing: {
      viewState = ViewStates.VIEW_CABS
    }
  } = getState();
  calenderEvent(tripType, screen, event, viewState);
};

export const calenderEvent = (tripType, screen, event, cabsAvailabilityState) => {
  try {
    const header = eventHeaderForTripType(tripType);
    if (header !== '') {
      const trackEvent = header + event;
      if (screen === CabsScreen.LISTING && cabsAvailabilityState === ViewStates.VIEW_NO_CABS) {
        trackListingErrorEvent(trackEvent);
      } else {
        CabsPageTrackerFunc[screen](trackEvent);
      }
    }
  } catch (e) {
    // Ignore
  }
};
