"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteStop = exports.addStop = exports.updateTripType = exports.updateTripInfo = exports.updateAirportTripType = exports.swapSearch = exports.updateTripDate = exports.updateStopLocation = exports.updateLocation = exports.updateStopOvers = exports.updateTravelType = exports.ACTION_UPDATE_SELECTED_AIRPORT_INDEX = exports.ACTION_UPDATE_STOP_OVERS = exports.ACTION_UPDATE_TRAVEL_TYPE = exports.ACTION_DELETE_STOP = exports.ACTION_ADD_STOP = exports.ACTION_SWAP_SEARCH = exports.ACTION_UPDATE_TRIP_TYPE = exports.ACTION_SET_STATE = exports.ACTION_UPDATE_DATE_TIME = exports.ACTION_UPDATE_STOP_LOCATION = exports.ACTION_UPDATE_LOCATION = void 0;
var cabsCommonUtils_1 = require("./utils/cabsCommonUtils");
exports.ACTION_UPDATE_LOCATION = '@CABS/TRIP_INFO/ACTION_UPDATE_LOCATION';
exports.ACTION_UPDATE_STOP_LOCATION = '@CABS/TRIP_INFO/ACTION_UPDATE_STOP_LOCATION';
exports.ACTION_UPDATE_DATE_TIME = '@CABS/TRIP_INFO/ACTION_UPDATE_DATE_TIME';
exports.ACTION_SET_STATE = '@CABS/TRIP_INFO/ACTION_SET_STATE';
exports.ACTION_UPDATE_TRIP_TYPE = '@CABS/TRIP_INFO/ACTION_UPDATE_TRIP_TYPE';
exports.ACTION_SWAP_SEARCH = '@CABS/TRIP_INFO/ACTION_SWAP_SEARCH';
exports.ACTION_ADD_STOP = '@CABS/TRIP_INFO/ACTION_ADD_STOP';
exports.ACTION_DELETE_STOP = '@CABS/TRIP_INFO/ACTION_DELETE_STOP';
exports.ACTION_UPDATE_TRAVEL_TYPE = '@CABS/TRIP_INFO/ACTION_UPDATE_TRAVEL_TYPE';
exports.ACTION_UPDATE_STOP_OVERS = '@CABS/TRIP_INFO/ACTION_UPDATE_STOP_OVERS';
exports.ACTION_UPDATE_SELECTED_AIRPORT_INDEX = '@CABS/TRIP_INFO/ACTION_UPDATE_SELECTED_AIRPORT_INDEX';
exports.updateTravelType = function (travelType) { return ({
    type: exports.ACTION_UPDATE_TRAVEL_TYPE,
    payload: travelType,
}); };
exports.updateStopOvers = function (data) { return ({
    type: exports.ACTION_UPDATE_STOP_OVERS,
    payload: data,
}); };
exports.updateStopOvers = function (data) { return ({
    type: exports.ACTION_UPDATE_STOP_OVERS,
    payload: data,
}); };
exports.updateLocation = function (_a) {
    var sourceData = _a.sourceData, destinationData = _a.destinationData, stops = _a.stops, airportIndex = _a.airportIndex, _b = _a.travelType, travelType = _b === void 0 ? null : _b;
    return ({
        type: exports.ACTION_UPDATE_LOCATION,
        payload: { sourceData: sourceData, destinationData: destinationData, stops: stops, airportIndex: airportIndex, travelType: travelType },
    });
};
exports.updateStopLocation = function (stopLocation, id) { return ({
    type: exports.ACTION_UPDATE_STOP_LOCATION,
    payload: { stopLocation: stopLocation, id: id },
}); };
exports.updateTripDate = function (_a) {
    var departDate = _a.departDate, returnDate = _a.returnDate, _b = _a.travelType, travelType = _b === void 0 ? null : _b;
    return ({
        type: exports.ACTION_UPDATE_DATE_TIME,
        payload: { departDate: departDate, returnDate: returnDate, travelType: travelType },
    });
};
exports.swapSearch = function () { return ({
    type: exports.ACTION_SWAP_SEARCH,
}); };
exports.updateAirportTripType = function (selectedAirportTypeIndex) { return ({
    type: exports.ACTION_UPDATE_SELECTED_AIRPORT_INDEX,
    payload: { selectedAirportTypeIndex: selectedAirportTypeIndex },
}); };
exports.updateTripInfo = function (tripType, tripInfo) { return function (dispatch, getState) {
    var _a;
    var currState = getState().cabsTripInfo;
    var travelType = cabsCommonUtils_1.getTravelTypeFromTripType(tripType);
    currState = __assign(__assign({}, currState), (_a = {}, _a[travelType] = __assign(__assign({}, currState[travelType]), tripInfo), _a));
    dispatch({
        type: exports.ACTION_SET_STATE,
        payload: currState,
    });
}; };
exports.updateTripType = function (tripType) { return ({
    type: exports.ACTION_UPDATE_TRIP_TYPE,
    payload: tripType,
}); };
exports.addStop = function () { return ({
    type: exports.ACTION_ADD_STOP,
}); };
exports.deleteStop = function (id) { return ({
    type: exports.ACTION_DELETE_STOP,
    payload: id,
}); };
