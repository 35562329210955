

import {
  getNativeGeocodes,
  getNativePredictions,
  getNativePredictionsNearByLocation,
  getNativeReverseGeocodes
} from './mapsAPINativeUtil';

let autocompleteService;
let geocodeService;

export const onMapsLoaded = () => {
  autocompleteService = new google.maps.places.AutocompleteService();
  geocodeService = new google.maps.Geocoder();
};

export const getPredictions = async (query, googleApiKey) => getNativePredictions(query, googleApiKey);
export const getPredictionsNearByLocation = async (query, location) => getNativePredictionsNearByLocation(query, location);
export const getGeocodes = async placeId => getNativeGeocodes(placeId);
export const getReverseGeocodes = async (lat, lng) => getNativeReverseGeocodes(lat, lng);
