import { Platform, NativeModules } from 'react-native';

export const VAR_MYBIZ_CABS = 'm_v1';
export const VAR_SRC_DEST_CODES = 'm_v2';
export const VAR_SRC_DEST = 'm_v3';
export const VAR_START_DATE = 'm_v4';
export const VAR_END_DATE = 'm_v5';
export const VAR_DURATION = 'm_v6';
export const VAR_TRIP_DATE = 'm_v7';
export const VAR_TRIP_TYPE = 'm_v9';
export const VAR_SEARCH_PAX = 'm_v11';
export const VAR_PAGE_NAME = 'm_v15';
export const VAR_PURCHASE_ID = 'm_v16';
export const VAR_V75 = 'm_v75';
export const VAR_PREVIOUS_PAGENAME = 'm_c23';
export const VAR_LOB_KEY = 'm_v24';
export const VAR_AP = 'm_v7'; // Advance Purchase
export const VAR_ERROR_KEY = 'm_v22';
export const VAR_PRODUCT_ID = 'm_v40';
export const VAR_PRICE = 'm_v46';
export const VAR_ADDON_NUMBER_OF_CABS = 'm_v63';
export const VAR_CAMPAIGN = 'm_v81';
export const VAR_REFERRER = 'm_v71';
export const VAR_JS_VERSION = 'm_list2';
export const VAR_CHANNEL = 'm_ch';
export const VAR_PWA_CHANNEL = 'channel';
export const VAR_USER_LOGIN_STATUS = 'm_v39';
export const VAR_BUS_OPERATOR = 'm_v36';
export const VAR_BUS_RATING = 'm_v42';
export const VAR_CABS_STATUS = 'm_v55';
export const VAR_USER_CONTEXT = 'm_v50';
export const VAR_NUMBER_SEATS_SELECTED = 'm_v27';
export const VAR_PREVIOUS_PAGE_FOR_TY = 'm_v23';
export const VAR_NUMBER_SEARCH_RESULTS = 'm_v13';
export const VAR_BOOKING_ID = 'm_v16';
export const PurchaseID = 'purchaseID';
export const BRC_ABTrackingKey = 'm_v97';
export const VAR_BUS_LISTING = 'm_v61';
export const USER_SEGMENT_KEY = 'm_v94';
export const PROP_1 = 'm_c1';
export const PROP_3 = 'm_c3';
export const PROP_24 = 'm_c24';
export const PROP_54 = 'm_c54';
export const PROP_61 = 'm_c61';
export const PROP_65 = 'm_c65';
export const PROP_30 = 'm_c30';
export const PROP_22 = 'm_c22';
export const PROP_35 = 'm_c35';
export const PROP_42 = 'm_c42';
export const PROP_50 = 'm_c50';
export const PROP_67 = 'm_c67';
export const EVAR_15 = 'm_v15';
export const EVAR_24 = 'm_v24';
export const EVAR_47 = 'm_v47';
export const EVAR_106 = 'm_v106';
export const EVAR_60 = 'm_v60';
export const EVAR_59 = 'm_v59';
export const EVAR_41 = 'm_v41';
export const EVAR_67 = 'm_v67';
export const EVAR_17 = 'm_v17';
export const EVAR_71 = 'm_v71';
export const EVAR_97 = 'm_v97';
export const EVAR_44 = 'm_v44';

export const trackOmniture = (pagename, data, isLoadingEvent) => {
  console.log('trackOmniture', pagename, data);
  const { OmnitureModule } = NativeModules;
  if (!OmnitureModule || !OmnitureModule.trackState) {
    return;
  }
  if (Platform.OS === 'web') {
    OmnitureModule.trackState(pagename, _addBuildInfoTrackingParams(data), isLoadingEvent);
  } else {
    OmnitureModule.trackState(pagename, _addBuildInfoTrackingParams(data));
  }
};

export const trackPageVisits = (pageName, params) => {
  params.pagename = pageName;
  params.m_v15 = pageName;
  console.log('Omniture PageVisit', JSON.stringify(params));
  trackOmniture(pageName, params, true);
};

export const trackClick = (pageName, clickSource, params) => {
  params.pagename = pageName;
  params.m_c50 = clickSource;
  console.log('Omniture Click', JSON.stringify(params));
  trackOmniture(pageName, params);
};

export const trackClickAndPage = (pageName, clickSource, params) => {
  params.pagename = pageName;
  params.m_c50 = clickSource;
  params.m_v15 = pageName;
  console.log('Omniture Click', JSON.stringify(params));
  trackOmniture(pageName, params);
};

export const trackClickEvent = (pageName, clickEvent, params = {}) => {
  params.pageName = pageName;
  params.m_v15 = pageName;
  params.m_c54 = clickEvent;
  console.log('Omniture Click Event', JSON.stringify(params));
  trackOmniture(pageName, params);
};

export const trackViewEvent = (pageName, viewEvent, params = {}) => {
  params.pageName = pageName;
  params.m_v15 = pageName;
  params.m_c20 = viewEvent;
  console.log('Omniture View Event', JSON.stringify(params));
  trackOmniture(pageName, params);
};

export const trackClickEventProp61 = (pageName, eventName) => {
  const params = {
    pageName,
    m_v15: pageName,
    m_c61: eventName,
    m_v24: 'rails',
  };
  trackOmniture(pageName, params);
};

// NEXT VERSION SHOULD BE 25.0
// NEXT CODE PUSH VERSION SHOULD BE 24.1
export const JS_VERSION = '24.0';

const _addBuildInfoTrackingParams = (data) => ({
  ...data,
  [VAR_JS_VERSION]: JS_VERSION,
});

export const trackOmnitureOverrideList2 = (pagename, data, isLoadingEvent) => {
  const { OmnitureModule } = NativeModules;
  if (!OmnitureModule || !OmnitureModule.trackState) {
    return;
  }
  if (Platform.OS === 'web') {
    OmnitureModule.trackState(pagename, data, isLoadingEvent);
  } else {
    OmnitureModule.trackState(pagename, data);
  }
};

export const trackAdLoad = (pageName, adShown) => {
  const params = {
    pageName: pageName,
    m_v97: adShown,
  };
  console.log('Omniture trackAdLoad Event', JSON.stringify(params));
  trackOmniture(pageName, params);
};


export const trackPageLoad = (pageName, eventName) => {
  const params = {
    pageName: pageName,
    m_v98: eventName,
  };
  console.log('Omniture pageLoad Event', JSON.stringify(params));
  trackOmniture(pageName, params);
};