import fecha from 'fecha';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _indexOf from 'lodash/indexOf';
import {CABS_DATE_FMT, CABS_TIME_FMT} from '../cabsConstants';
import * as constants from './cabsCalendarActions';
import {addDays, addHours, diffDays, now, today} from '../../../Helpers/dateHelpers';
import cabsConfig from '../cabsConfig';
import {
  getNearestNextSlot,
  getNearestNextSlotInMinutes,
  getNearestReturnDate
} from '../cabsDateTimeHelpers';
import TripType, {TravelType} from '../types/TripType';
import * as landing from '../Landing/cabsLandingActions';
import {
  getDefaultRoundTripHrs,
  getMaxCalenderDays,
  getMinimumAP,
  getMinimumAPInMinutes,
  getMinTravelTimeHrs
} from '../cabsDynamicConfig';


const initialState = {
  dateSelectMode: constants.DateSelectMode.DEPART,
  refreshTimePicker: false,
  disableReturnDate: false,
  shouldShowRTToast: false,
  isMulticitySearch: false,
};

const _parseDatesFromRequest = (searchRequest = {}) => {
  const {
    pickup_time = cabsConfig.defaultNextDayTimeFormatted,
    drop_time,
    departure_date = fecha.format(now(), CABS_DATE_FMT),
    return_date,
    trip_type = TripType.OW.value
  } = searchRequest;
  const departDate = fecha.parse(departure_date, CABS_DATE_FMT);
  const departTime = fecha.parse(pickup_time, CABS_TIME_FMT);
  departDate.setHours(departTime.getHours(), departTime.getMinutes(), 0, 0);
  let returnDate = null;
  if (trip_type === TripType.RT.value) {
    returnDate = fecha.parse(return_date, CABS_DATE_FMT);
    let _drop_time = drop_time;
    if (_isEmpty(drop_time)) {
      _drop_time = cabsConfig.defaultDropTime;
    }
    const dropTime = fecha.parse(_drop_time, CABS_TIME_FMT);
    returnDate.setHours(dropTime.getHours(), dropTime.getMinutes(), 0, 0);
  }
  const minPickupDate = getNearestNextSlot(getMinimumAP(trip_type));
  const minReturnDate = addHours(departDate, getMinTravelTimeHrs());
  return {
    departDate,
    returnDate,
    minPickupDate,
    minDate: minPickupDate,
    minReturnDate
  };
};

const _copyFullYear = (originalDate, newDate) => {
  if (_isNil(originalDate)) {
    return originalDate;
  }
  const date = new Date(originalDate);
  date.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
  return date;
};

const _copyHours = (originalDate, newDate) => {
  if (_isNil(originalDate)) {
    return originalDate;
  }
  const date = new Date(originalDate);
  date.setHours(newDate.getHours(), newDate.getMinutes(), 0, 0);
  return date;
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.ACTION_RESET_CALENDAR: {
      return {
        ...initialState,
        ..._parseDatesFromRequest(action.data),
        dateSelectMode: constants.DateSelectMode.DEPART
      };
    }
    case constants.SET_INITIAL_DATA: {
      const {
        initialDateSelectMode,
        departDate,
        tripType,
        dateChangedHandler,
        disableReturn,
        screen,
        isMulticitySearch = false,
      } = action.data;

      let { returnDate } = action.data;

      const minPickupDate = getNearestNextSlotInMinutes(getMinimumAPInMinutes(tripType));
      if (
        tripType === TripType.AT.value ||
        tripType === TripType.HR.value ||
        (tripType === TripType.OW.value && !isMulticitySearch)
      ) {
        returnDate = null;
      }

      const minReturnDate = addHours(departDate, getMinTravelTimeHrs());
      if (!Boolean(returnDate) && tripType === TripType.RT.value) {
        returnDate = minReturnDate;
      }
      return {
        ...initialState,
        departDate,
        returnDate,
        minPickupDate,
        tripType,
        screen,
        minDate: minPickupDate,
        minReturnDate,
        disableReturn,
        dateChangedHandler,
        dateSelectMode: initialDateSelectMode,
        hideEndDateClearBtn: isMulticitySearch,
        isMulticitySearch,
      };
    }
    case constants.ACTION_DATE_CLICKED: {
      const {departDate, returnDate, dateSelectMode, isMulticitySearch} = state;
      const isDepartDateSelected = dateSelectMode !== constants.DateSelectMode.RETURN;
      const selectedDate = action.data;

      const currDate = today();
      let newDepartDate = departDate;
      let newReturnDate = returnDate;
      if (isDepartDateSelected) {
        if (selectedDate > newReturnDate) {
          newDepartDate = selectedDate;
          newReturnDate = getNearestReturnDate(selectedDate);
          if (diffDays(newReturnDate, currDate) >= getMaxCalenderDays()) {
            newReturnDate = newDepartDate;
          }
        } else {
          newDepartDate = selectedDate;
        }
      } else if (selectedDate < departDate) {
        newReturnDate = selectedDate;
        // newDepartDate = addDays(selectedDate, -1);
        if (newDepartDate < currDate) {
          newDepartDate = currDate;
        }
      } else {
        newReturnDate = selectedDate;
      }

      // const minDepartDate = addHours(currDate, cabsConfig.defaultMinOutstationSearchTatHrs);
      // if (newDepartDate && newDepartDate < minDepartDate) {
      //   newDepartDate = minDepartDate;
      // }

      newReturnDate = _copyFullYear(returnDate, newReturnDate);
      newDepartDate = _copyFullYear(departDate, newDepartDate);
      let minReturnDate = addHours(newDepartDate, getMinTravelTimeHrs());

      let shouldShowRTToast = false;
      if (newReturnDate && newReturnDate < minReturnDate) {
        newReturnDate = minReturnDate;
        shouldShowRTToast = true;
      }

      if (isMulticitySearch) {
        if (newReturnDate < newDepartDate) {
          newReturnDate = minReturnDate;
        }
        minReturnDate = newDepartDate;
        shouldShowRTToast = false;
      }

      // Handle tab change when calender opened from landing page
      let newDateSelectMode = dateSelectMode;
      if (dateSelectMode === constants.DateSelectMode.PICKUP) {
        newDateSelectMode = constants.DateSelectMode.DEPART;
      }
      return {
        ...state,
        refreshTimePicker: true,
        returnDate: newReturnDate,
        departDate: newDepartDate,
        dateSelectMode: newDateSelectMode,
        minReturnDate,
        shouldShowRTToast
      };
    }

    case constants.ACTION_DEPART_DATE_TAB_CLICKED: {
      if (state.dateSelectMode === constants.DateSelectMode.DEPART) {
        return state;
      }

      return {
        ...state,
        refreshTimePicker: true,
        dateSelectMode: constants.DateSelectMode.DEPART
      };
    }

    case constants.ACTION_RETURN_DATE_TAB_CLICKED: {
      const emptyReturnDate = _isNil(state.returnDate);
      if (!emptyReturnDate && state.dateSelectMode === constants.DateSelectMode.RETURN) {
        return state;
      }
      const {departDate} = state;
      let {returnDate} = state;
      if (emptyReturnDate) {
        returnDate = getNearestReturnDate(departDate);
        if (diffDays(returnDate, today()) >= getMaxCalenderDays()) {
          returnDate = departDate;
        }
      }
      return {
        ...state,
        returnDate,
        refreshTimePicker: true,
        dateSelectMode: constants.DateSelectMode.RETURN
      };
    }
    case constants.ACTION_PICKUP_TIME_TAB_CLICKED: {
      return {
        ...state,
        dateSelectMode: constants.DateSelectMode.PICKUP
      };
    }


    case constants.ACTION_TIME_CHANGED: {
      const {departDate, returnDate} = state;
      const {data: time} = action;
      if (!_isEmpty(time)) {
        const timeObj = fecha.parse(time, CABS_TIME_FMT);
        if (state.dateSelectMode !== constants.DateSelectMode.RETURN) {
          const newDepartDate = _copyHours(departDate, timeObj);
          const minReturnDate = addHours(newDepartDate, getMinTravelTimeHrs());
          let newReturnDate = returnDate;
          if (returnDate && returnDate < minReturnDate) {
            newReturnDate = minReturnDate;
          }
          return {
            ...state,
            departDate: newDepartDate,
            returnDate: newReturnDate,
            minReturnDate
          };
        }
        return {
          ...state,
          returnDate: _copyHours(returnDate, timeObj)
        };
      }
      return state;
    }

    case constants.ACTION_CLEAR_RETURN_DATE: {
      return {
        ...state,
        returnDate: null,
        refreshTimePicker: true,
        dateSelectMode: constants.DateSelectMode.DEPART
      };
    }

    case constants.ACTION_TIME_PICKER_REFRESH_COMPLETE: {
      return {
        ...state,
        refreshTimePicker: false
      };
    }

    case constants.ACTION_HIDE_RT_TOAST: {
      return {
        ...state,
        shouldShowRTToast: false
      };
    }

    case landing.ACTION_TRIP_TYPE: {
      const tripType = action.data;
      let {returnDate} = state;
      if (_indexOf(TravelType.OUTSTATION.allTripTypes, tripType) !== -1) {
        returnDate = null;
      }
      return {
        ...state,
        tripType,
        returnDate
      };
    }

    case landing.ACTION_TRAVEL_TYPE: {
      const travelType = action.data;
      let {returnDate} = state;
      if (travelType !== 0) {
        // Local transfer
        returnDate = null;
      }
      return {
        ...state,
        returnDate
      };
    }
    case landing.ACTION_UPDATE_TRAVEL_TYPE: {
      const {travelType, tripType} = action.data;
      let {returnDate} = state;
      if (travelType.defaultTripType !== TripType.OW.value) {
        // Local transfer
        returnDate = null;
      }
      return {
        ...state,
        tripType,
        returnDate
      };
    }

    default:
      return state;
  }
};

export default reducer;
