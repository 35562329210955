import { Platform } from 'react-native';
import fecha from 'fecha';

// URLs
const API_SERVER_DOMAIN = 'cabs.makemytrip.com';
const SERVER_URL =
  Platform.OS === 'web' ? `//${API_SERVER_DOMAIN}` : `https://${API_SERVER_DOMAIN}`;
//const SERVER_URL = 'http://172.16.47.147';
//  const SERVER_URL = 'http://172.16.48.70';
// const SERVER_URL = 'http://192.168.43.220:8080';
// const SERVER_URL = 'http://localhost:8080';
const APP_CONFIG_URL = `${SERVER_URL}/cabConfiguration/`;
const SEARCH_URL = `${SERVER_URL}/searchV2`;
const REVIEW_URL = `${SERVER_URL}/reviewPageDetailsV2`;
let SERVER_URL_USER_SERVICE = 'https://userservice.makemytrip.com';
if (Platform.OS === 'web') {
  SERVER_URL_USER_SERVICE = 'https://mapi.makemytrip.com';
}
const LOCATION_REQUEST_URL = `${SERVER_URL}/getLocationForCityCode`;
const PROVISIONAL_BOOKING_DATA = `${SERVER_URL}/provisional/detail`;
const PROVISIONAL_BOOKING_DATA_NON_KIOSK = `${SERVER_URL}/provisional/detail/non-kiosk`;
const PROVISIONAL_BOOKING_DATA_HOB = `${SERVER_URL}/provisional/detail/hob`;
const CHECK_FARE = `${SERVER_URL}/provisional/checkFare`;
const CHECK_FARE_NON_KIOSK = `${SERVER_URL}/provisional/checkFare/non-kiosk`;
const CHECK_FARE_HOB = `${SERVER_URL}/provisional/checkFare/hob`;
// const SEARCH_URL = `${SERVICE_URL}:8501/cabs/nativeSearch`;
const ALTERNATE_SEARCH_URL = `${SERVER_URL}/alternateCabs`;
const HOLD_URL = `${SERVER_URL}/nativeHold`;
const MYBIZ_OFFLINE_FLOW = `${SERVER_URL}/mybiz/cabs/offline`;
const MYBIZ_INIT_APPROVAL = `${SERVER_URL}/myBizInitiateApproval`;
const AUTO_COMPLETE = `${SERVER_URL}/airportAutoComplete`;
const AUTO_COMPLETEV2 = `${SERVER_URL}/airportAutoCompleteV2`;
const ADDRESS_DETAIL = `${SERVER_URL}/addressDetails`;
const FETCH_COUPON = `${SERVER_URL}/fetchCouponsV2`;
const VALIDATE_COUPON = `${SERVER_URL}/validateCouponV2`;
const DELETE_COUPON = `${SERVER_URL}/invalidateCoupon`;
const SEND_OTP = `${SERVER_URL}/sendOtp`;
const VERIFY_OTP = `${SERVER_URL}/verifyOtp`;
const EASYPAY_URL = 'https://payments.makemytrip.com/easypay/';
const WALLET_BALANCE = `${SERVER_URL}/walletBalance`;
const FETCH_AIRPORT_SUGGESTION = `${SERVER_URL}/airportSuggestion`;
const FETCH_EMAIL_SUGGESTION = 'https://corpcb.makemytrip.com/v2/user/autosuggest';
const INVITE_EMPLOYEE_MYBIZ = 'https://corpcb.makemytrip.com/v1/employee/auth/invite';
const MY_TRIPS_DEEPLINK_URL = `${SERVER_URL}/mojoMyTripsDeeplink`;
const MY_TRIPS_SUPPORT_URL = 'https://supportz.makemytrip.com/';
const MY_TRIPS_PWA_URL = 'https://pwa-supportz.makemytrip.com';
const BOOKING_DETAILS_API = `${SERVER_URL}/rnw/thankYouV2`;
const HOB_THANK_YOU_API = `${SERVER_URL}/provisional/thankyou/hob`;
const HOB_DRIVER_API = `${SERVER_URL}/provisional/driver-detail/hob`;
const BOOKING_FARE_DETAILS_API = `${SERVER_URL}/bookingFareDetails/`;
const USER_DETAILS_URL = `${SERVER_URL}/getUserDetails`;
const VOYAGER_URL = `${SERVER_URL}/voyager/search`;
const PLACE_LOCATION_URL = `${SERVER_URL}/fetchLocation`;
const HYDRA_SEGMENT_URL = `${SERVER_URL}/user/segments?`;
const GET_OFFERS = `${SERVER_URL}/fetchOffers`;
const HOTELS_CROSS_SELL = `${SERVER_URL}/provisional/hotel/`;
const GET_MY_BIZ_TRIP_TAG_URL = `${SERVER_URL}/fetchTripTagData`;
const GET_LANDING_CARDS_URL = `${SERVER_URL}/landingPlaceholders/v2`;
const GET_TRIP_RECOMMENDATION_URL = `${SERVER_URL}/airportTransferPersonalisationInfo`;

const FLIGHT_ADD_ON_DETAIL_URL = `${SERVER_URL}/getAddonBookingDetails`;
const FLIGHT_ADD_ON_UPDATED_DETAIL_URL = `${SERVER_URL}/getAddonUpdatedBookingDetails`;
const UPDATE_FLIGHT_ADD_ON_DETAIL_URL = `${SERVER_URL}/addonUpdateBookings`;
const CONFIRM_FLIGHT_ADD_ON_URL = `${SERVER_URL}/addon/addonConfirmBookings`;
const FLIGHT_ADD_ON_THANK_YOU_URL = `${SERVER_URL}/addon/getAddonThankYouData`;
const FLIGHT_BOOKING_DETAILS_URL = `${SERVER_URL}/crosssell/flight/details/`;
const GET_FEEDBACK_URL = `${SERVER_URL}/bookingDetails/review/overallRating`;
const SET_FEEDBACK_URL = `${SERVER_URL}/pwa/cabs/booking/review`;
const GET_ECOM_ID_URL = `${SERVER_URL}/getEncodedCommId`;
const FETCH_CAB_REVIEW_URL = `${SERVER_URL}/fetchCabReviews`;
const GET_APPROVAL_DETAILS_URL = `${SERVER_URL}/mybiz/bookingdetails`;
const APPROVE_REJECT_ACTION_URL = `${SERVER_URL}/mybiz/updateApproval`;
const SKIP_OR_PAY_ACTION_URL = `${SERVER_URL}/myBiz/requesterHold`;
const QUICK_BOOK_CABS = `${SERVER_URL}/search/quickbook`;
const GET_USER_DETAILS_URL = `${SERVER_URL_USER_SERVICE}/ext/web/pwa/user/details`;
const OS_ASSISTED_FLOW_URL = `${SERVER_URL}/callTripAssistantAPI`;
const AUTO_COMPLETE_V3 = `${SERVER_URL}/autocomplete/v3`;
const RTrevampText = 'Since its a round trip, you can keep the car for the whole day';
const MG_USER_DETAILS_URL = `${SERVER_URL}/mygate-user-info`;
const ASSURANCE_DATA_URL = `${SERVER_URL}/getAssuranceData/`;

const ROUTE_PLANNER_URL =
  'https://routeplannerv2.makemytrip.com/rp-core/api/curated-search-page-url?' +
  'curatedSearchReason=cab_unavailability';

const PDT_URL = 'https://pdt.makemytrip.com/dts/s/da/n';

const PRIVACY_POLICY_URL = 'https://www.makemytrip.com/legal/privacy_policy.html';
const TNC_URL = 'https://www.makemytrip.com/legal/user_agreement.html#tos-cabs';
const USER_AGREEMENT_URL = 'https://www.makemytrip.com/legal/user_agreement.html';

// Keys
// const GOOGLE_API_KEY_OLD = 'AIzaSyDS5iqXnnDcX5Qm8RFF9-6FXNLaMKTL7_o';
const GOOGLE_API_KEY = 'AIzaSyBJU4uwaQoXmdcAE7opwszx1kMpDLQr7_c';
const GOOGLE_API_KEY_MAPI = 'AIzaSyBJU4uwaQoXmdcAE7opwszx1kMpDLQr7_c';
const GOOGLE_MAPS_URL = 'https://maps.googleapis.com/maps/api';

const stringToDecimal = (str) => {
  const date = fecha.parse(str, 'HH:mm');
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return hours + minutes / 60;
};

// Core
export const MIN_TAT_HRS = 1;
export const DEFAULT_PICKUP_TIME = '07:30';
export const DEFAULT_DROP_TIME = '23:30';
export const DEFAULT_PICKUP_TIME_RAW = stringToDecimal && stringToDecimal(DEFAULT_PICKUP_TIME);

const cabsConfig = Object.freeze({
  googleApiKey: GOOGLE_API_KEY,
  googleApiKeyWeb: GOOGLE_API_KEY,
  googleApiKeyMapi: GOOGLE_API_KEY_MAPI,
  googleMapsURL: GOOGLE_MAPS_URL,
  configUrl: APP_CONFIG_URL,
  searchUrl: SEARCH_URL,
  reviewUrl: REVIEW_URL,
  voyagerUrl: VOYAGER_URL,
  placeLocationUrl: PLACE_LOCATION_URL,
  walletBalanceURL: WALLET_BALANCE,
  locationRequestUrl: LOCATION_REQUEST_URL,
  provisionalBooking: PROVISIONAL_BOOKING_DATA,
  provisionalBookingNonKiosk: PROVISIONAL_BOOKING_DATA_NON_KIOSK,
  provisionalBookingHOB: PROVISIONAL_BOOKING_DATA_HOB,
  autoCompleteUrl: AUTO_COMPLETE,
  autoCompleteUrlV2: AUTO_COMPLETEV2,
  addressDetail: ADDRESS_DETAIL,
  flightBookingDetailsUrl: FLIGHT_BOOKING_DETAILS_URL,
  alternateSearchUrl: ALTERNATE_SEARCH_URL,
  fetchAirportSuggestion: FETCH_AIRPORT_SUGGESTION,
  fetchEmailSuggestion: FETCH_EMAIL_SUGGESTION,
  inviteEmployeeMyBiz: INVITE_EMPLOYEE_MYBIZ,
  fetchCouponUrl: FETCH_COUPON,
  validCouponUrl: VALIDATE_COUPON,
  deleteCouponUrl: DELETE_COUPON,
  myTripsDeepLinkURL: MY_TRIPS_DEEPLINK_URL,
  myTripsSupportURL: MY_TRIPS_SUPPORT_URL,
  myTripsPWAURL: MY_TRIPS_PWA_URL,
  sendOTP: SEND_OTP,
  verifyOTP: VERIFY_OTP,
  paymentURL: EASYPAY_URL,
  holdUrl: HOLD_URL,
  myBizOfflineFlow: MYBIZ_OFFLINE_FLOW,
  getOffers: GET_OFFERS,
  myBizInitApprovalUrl: MYBIZ_INIT_APPROVAL,
  hydraSegmentUrl: HYDRA_SEGMENT_URL,
  getOffers: GET_OFFERS,
  minTatHrs: MIN_TAT_HRS,
  cabsCalendarMaxDays: 90,
  locationSearchRadius: 60,
  is_location_based_search: true,
  additionalCitiesCount: 1,
  defaultRoundTripDuration: 24,
  minTravelTimeHrs: 4,
  defaultDropTime: DEFAULT_DROP_TIME,
  defaultNextDayTime: DEFAULT_PICKUP_TIME_RAW,
  defaultNextDayTimeFormatted: DEFAULT_PICKUP_TIME,
  bookingDetailsApi: BOOKING_DETAILS_API,
  hob_ThankYouApi: HOB_THANK_YOU_API,
  hob_DriverApi: HOB_DRIVER_API,
  bookingFareDetailsApi: BOOKING_FARE_DETAILS_API,
  userDetailsUrl: USER_DETAILS_URL,
  pdtUrl: PDT_URL,
  routePlannerUrl: ROUTE_PLANNER_URL,
  flightAddOnDetailUrl: FLIGHT_ADD_ON_DETAIL_URL,
  flightAddOnUpdatedBookingDetailUrl: FLIGHT_ADD_ON_UPDATED_DETAIL_URL,
  updateFlightAddOnDetailUrl: UPDATE_FLIGHT_ADD_ON_DETAIL_URL,
  confirmFlightAddOnUrl: CONFIRM_FLIGHT_ADD_ON_URL,
  flightAddOnThankYouDataUrl: FLIGHT_ADD_ON_THANK_YOU_URL,
  tncUrl: TNC_URL,
  userAgreementUrl: USER_AGREEMENT_URL,
  privacyPolicyUrl: PRIVACY_POLICY_URL,
  getBookingFeedbackUrl: GET_FEEDBACK_URL,
  setBookingFeedbackUrl: SET_FEEDBACK_URL,
  getEcomIdUrl: GET_ECOM_ID_URL,
  fetchCabReviewsUrl: FETCH_CAB_REVIEW_URL,
  checkFare: CHECK_FARE,
  checkFareNonKiosk: CHECK_FARE_NON_KIOSK,
  checkFareHOB: CHECK_FARE_HOB,
  hotelsCrossSell: HOTELS_CROSS_SELL,
  getMyBizTripTagUrl: GET_MY_BIZ_TRIP_TAG_URL,
  getApprovalDetailsUrl: GET_APPROVAL_DETAILS_URL,
  approveRejectActionUrl: APPROVE_REJECT_ACTION_URL,
  skipOrPayActionUrl: SKIP_OR_PAY_ACTION_URL,
  getLandingCardsUrl: GET_LANDING_CARDS_URL,
  personalizedTripRecommendation: GET_TRIP_RECOMMENDATION_URL,
  quickBookCabs: QUICK_BOOK_CABS,
  fetchUserDetailsUrl: GET_USER_DETAILS_URL,
  osAssistedFlowUrl: OS_ASSISTED_FLOW_URL,
  liteAutoCompleteUrl: AUTO_COMPLETE_V3,
  rtRevampText: RTrevampText,
  mgUserDetailsUrl: MG_USER_DETAILS_URL,
  getAssuranceDataUrl: ASSURANCE_DATA_URL,
});

export default cabsConfig;
