"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOffersFromSkyWalker = void 0;
//@ts-ignore reason : path import error
var react_native_1 = require("react-native");
//@ts-ignore reason : path import error
var genericHelper_1 = require("src/Helpers/genericHelper");
//@ts-ignore reason : path import error
var OffersModule_1 = require("src/Native/OffersModule");
/*
 * USAGE
 * import { getOffersFromSkyWalker } from 'src/Native/SkywalkerOffers';
 * const offersList = await getOffersFromSkyWalker('BUS');
 */
/*
 * Creates the lob specific API
 * NOTE: The value should match with the API created by skywalker team. for new API please contact them.
 */
var LOB_API_MAP = {
    BUS: 'buslanding',
    CABS: 'cabslanding',
    RAILS: 'railslanding',
    SELFDRIVE: 'selfdrivelanding',
};
var BASE_URL = 'https://skywalker.makemytrip.com';
var getLobAPI = function (lob, currency, language) {
    return BASE_URL + "/empeiria/api/v1/" + LOB_API_MAP[lob] + "?currency=" + currency + "&language=" + language + "&brand=MMT";
};
var checkIfUpcomingOffer = function (startDate, endDate) {
    var currentDate = new Date();
    var offerStartDate = new Date(startDate);
    var offerEndDate = new Date(endDate);
    return (offerStartDate.getTime() >= currentDate.getTime() &&
        currentDate.getTime() <= offerEndDate.getTime());
};
/*
 * This maps the raw API response to more readable response.
 */
var mapOffers = function (offers, lob) {
    var _a, _b, _c, _d;
    //Key name will be diff for each lob, so getting it from response.
    var offerLobKey = offers === null || offers === void 0 ? void 0 : offers.sequenceData.BASE_SHEET.cardSequence.data[0].id;
    //@ts-ignore Reason : reading dynamic key
    var pMoffers = ((_d = (_c = (_b = (_a = offers === null || offers === void 0 ? void 0 : offers.data) === null || _a === void 0 ? void 0 : _a.BASE_SHEET) === null || _b === void 0 ? void 0 : _b.cardData[offerLobKey]) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.pM) || [];
    try {
        var offersList = pMoffers.map(function (originalOfferObj) {
            var promoTitle = (originalOfferObj === null || originalOfferObj === void 0 ? void 0 : originalOfferObj.dStxt) && (originalOfferObj === null || originalOfferObj === void 0 ? void 0 : originalOfferObj.offerTxt) && (originalOfferObj === null || originalOfferObj === void 0 ? void 0 : originalOfferObj.dEtxt)
                ? originalOfferObj.dStxt + " " + originalOfferObj.offerTxt + " " + originalOfferObj.dEtxt
                : originalOfferObj.pTl;
            var isUpcomingOffer = checkIfUpcomingOffer(originalOfferObj.st, originalOfferObj.et);
            var mappedOfferItem = {
                promoTitle: promoTitle,
                id: originalOfferObj.id,
                newHeroUrl: originalOfferObj.newHeroUrl,
                cardCTAText: originalOfferObj.cardCTAText,
                newHeroOfferCardUrl: originalOfferObj.newHeroOfferCardUrl,
                promoMessage: originalOfferObj.pTl,
                promoCode: originalOfferObj.cc,
                dis: originalOfferObj.dis,
                url: originalOfferObj.url,
                skyBigFullImgUrl: originalOfferObj.skyBigFullImgUrl,
                lob: originalOfferObj.lob,
                isUpcomingOffer: isUpcomingOffer,
            };
            return mappedOfferItem;
        });
        return offersList.sort(function (offerItem1, offerItem2) {
            return (offerItem2 === null || offerItem2 === void 0 ? void 0 : offerItem2.dis) - (offerItem1 === null || offerItem1 === void 0 ? void 0 : offerItem1.dis);
        });
    }
    catch (error) {
        console.error('Error while mapping offer response', error);
        return [];
    }
};
/*
 * @param {LOB} lob - Pass lobs name in upper case.
 */
exports.getOffersFromSkyWalker = function (lob, currency, language) {
    if (currency === void 0) { currency = 'inr'; }
    if (language === void 0) { language = 'eng'; }
    return __awaiter(void 0, void 0, Promise, function () {
        var commonHeaders, headers, body, response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 8, , 9]);
                    if (!lob) {
                        throw 'Please pass the lob name';
                    }
                    console.log("Getting offers from skywalker for " + lob);
                    if (!(react_native_1.Platform.OS === 'web')) return [3 /*break*/, 2];
                    return [4 /*yield*/, OffersModule_1.getOffersList(lob, 'Search')];
                case 1: return [2 /*return*/, _a.sent()];
                case 2: return [4 /*yield*/, genericHelper_1.getSkyWalkerHeaders()];
                case 3:
                    commonHeaders = _a.sent();
                    headers = __assign(__assign({}, commonHeaders), { language: language });
                    return [4 /*yield*/, genericHelper_1.getSkyWalkerBody()];
                case 4:
                    body = _a.sent();
                    return [4 /*yield*/, fetch(getLobAPI(lob, currency, language), {
                            method: 'POST',
                            headers: headers,
                            body: body,
                        })];
                case 5:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 6:
                    data = _a.sent();
                    return [2 /*return*/, mapOffers(data, lob)];
                case 7: return [3 /*break*/, 9];
                case 8:
                    error_1 = _a.sent();
                    console.error("Error while getting offers from skywalker for " + lob, error_1);
                    return [2 /*return*/, []];
                case 9: return [2 /*return*/];
            }
        });
    });
};
