import BaseGenericEvent from '../../BaseGenericEvent';
import {
  getCabData,
  getCabOtherData,
  getCabPricingDetailList,
  getCarCategoryVendorSelected,
  getSearchEventData
} from '../../PdtHelper/CabsPdtUtils';
import {CabsPdtConstants} from '../../PdtHelper/CabsPdtConstants';

export default class CabsPdtListingEvent extends BaseGenericEvent {
  constructor(pdtData) {
    const data = pdtData;
    data.pageName = CabsPdtConstants.FunnelStep.LISTING;
    data.funnelStep = CabsPdtConstants.FunnelStep.LISTING;
    super(data);
    this.cabData = pdtData.cab;
    this.cabsData = pdtData.cabs;
    this.searchData = pdtData.searchData;
  }

  trackListingEvent = () => {
    const basePdtObject = this.basePdtData();
    basePdtObject.cab_search_context = getSearchEventData(this.searchData);
    basePdtObject.cab_details = getCabData(this.cabData);
    basePdtObject.cab_other_details = getCabOtherData(this.cabData);
    basePdtObject.car_category_vendor_selected = getCarCategoryVendorSelected(this.cabData);
    basePdtObject.cab_pricing_details_list = getCabPricingDetailList(this.cabsData);
    return basePdtObject;
  }
}
