export const LOADING_STATE = {
    NOT_STARTED: "Not Started",
    IN_PROGRESS: "In Progress",
    VERIFICATION_FAILED: "Verification Failed",
    SUCCESS: "Success",
    NETWORK_ERROR: "Network Error",
}

export const NETWORK_ERR = {
    ERR_TYPE: "Network Error",
    ERR_MSG: "Something went wrong",
}

export const PAY_LATER_API = `https://bus.makemytrip.com/bus/common-payment-web-iframe/api/paylater/eligibility`;

export const API_BODY_CONSTANTS = {
    PAY_MODE: 'PL',
    CURRENCY: 'INR',
    REQ_TYPE: 'PL_LAZY_LOB',
}

export const LOGIN_EVENT_RN = 'login_event_rn';
export const LOGIN_EVENT = 'login_event';
export const EDIT_PROFILE_EVENT = 'edit_profile_event';
export const VERIFIED_MOBILE_EVENT = 'mobile_verified_event';

export const BnplOmnitureSchema = {
    CHECK_ELIGIBILITY_CLICK: {
        key: null,
        value: null
    },
    BNPL_SUCCESS: {
        key: null,
        value: null
    },
    BNPL_FAILURE: {
        key: null,
        value: null,
    },
    BNPL_TIMEOUT: {
        key: null,
        value: null
    },
    BNPL_SHOWN: {
        key: null,
        value: null
    },
    REPAYMENT_DETAILS_CLICKED: {
        key: null,
        value: null
    },
    BNPL_FAILED_CLOSE: {
        key: null,
        value: null
    },
    BNPL_TIMEOUT_CLOSE: {
        key: null,
        value: null
    },
    REPAYMENT_DETAILS_CLOSED: {
        key: null,
        value: null
    },
    ADD_MOBILE_NUMBER_CLICK: {
        key: null,
        value: null
    },
    LOGIN_CLICK: {
        key: null,
        value: null
    },
    BNPL_SUCCESS_CONVERSION: {
        key: null,
        value: null
    },
    BNPL_FAILURE_CONVERSION: {
        key: null,
        value: null
    },
    BNPL_TIMEOUT_CONVERSION: {
        key: null,
        value: null
    }
  }