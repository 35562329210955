import { NativeModules, DeviceEventEmitter } from 'react-native';
import _ from 'lodash';
import mappings from './AbConfigKeyMappings';

let _abConfigData = null;
let _pokusConfigData = null;
let _abConfigCalled = false;
let _pokusConfigCalled = false;

const _fetchAbConfig = async () => {
  try {
    const { AbConfigModule } = NativeModules;
    const abConfigRaw = await AbConfigModule.getAbConfig(_.values(mappings));
    if (_.isObject(abConfigRaw)) {
      _abConfigData = abConfigRaw;
    } else if (_.isString(abConfigRaw)) {
      _abConfigData = JSON.parse(abConfigRaw);
    }
    _abWaitingPromiseResolvers.forEach(resolver => resolver());
    _abWaitingPromiseResolvers.length = 0; // empty the array
  } catch (e) {
    console.log('error while retrieving AbConfig', e);
  }
};

const _fetchPokusConfig = async () => {
  try {
    const { AbConfigModule } = NativeModules;
    const pokusConfigRaw = await AbConfigModule.getPokusConfig();
    if (_.isObject(pokusConfigRaw)) {
      _pokusConfigData = pokusConfigRaw;
    } else if (_.isString(pokusConfigRaw)) {
      _pokusConfigData = JSON.parse(pokusConfigRaw);
    }
    _pokusWaitingPromiseResolvers.forEach(resolver => resolver());
    _pokusWaitingPromiseResolvers.length = 0; // empty the array
  } catch (e) {
    console.log('error while retrieving PokusConfig', e);
  }
};


export const getAbConfig = (configName, defaultValue) => {
  // if (__DEV__ && AbConfigKeyMappings[configName] === undefined) {
  //   throw new Error(`${configName} not declared in AbConfigKeyMappings`);
  // }
  if (_.isNil(_abConfigData)) { // AB is not loaded, so return default value
    return defaultValue;
  }
  return _.get(_abConfigData, configName, defaultValue);
};

export const getAbConfigFetchIfNeeded = async (configName, defaultValue) => {
  if (_.isNil(_abConfigData)) { // AB is not loaded, so fetch from native
    await _fetchAbConfig();
    return getAbConfig(configName, defaultValue);
  }
  return getAbConfig(configName, defaultValue);
};


export const getPokusConfigFetchIfNeeded = async (lob, experimentKey, defaultValue) => {
  if (_.isNil(_pokusConfigData)) { // Pokus is not loaded, so fetch from native
    await _fetchPokusConfig();
    return getPokusConfig(lob, experimentKey, defaultValue);
  }
  return getPokusConfig(lob, experimentKey, defaultValue);
};

export const getPokusConfig = (lob, experimentKey, defaultValue) => {
  if (_.isNil(_pokusConfigData)) { // AB is not loaded, so return default value
    return defaultValue;
  }
  return _.get(_pokusConfigData, ['perLobMap', lob, 'metadataValues', experimentKey], defaultValue);
};

export const getPokusExpVarientKey = (lob, defaultValue = '') => {
  if (_.isNil(_pokusConfigData)) { // AB is not loaded, so return default value
    return defaultValue;
  }
  return _.get(_pokusConfigData, ['perLobMap', lob, 'variantKey'], defaultValue);
};

export const getPokusConfigExpDetailsList = (lob, defaultValue = []) => {
  if (_.isNil(_pokusConfigData)) {
    return defaultValue;
  }
  return _.get(_pokusConfigData, ['perLobMap', lob, 'exp_experiment_details_list'], defaultValue);
};

export const initAbConfig = async () => {
  if (!_abConfigCalled) {
    _abConfigCalled = true;
    await _fetchAbConfig();
    DeviceEventEmitter && DeviceEventEmitter.addListener('EV_AB_CONFIG_LOADED', async () => {
      await _fetchAbConfig();
    });
  }
  if (!_pokusConfigCalled) {
    _pokusConfigCalled = true;
    await _fetchPokusConfig();
    DeviceEventEmitter && DeviceEventEmitter.addListener('EV_POKUS_CONFIG_LOADED', async () => {
      await _fetchPokusConfig();
    });
  }
};

export const initAbConfigUsingPokusHLD = async () => {
  if (!_pokusConfigCalled) {
    _pokusConfigCalled = true;
    await _fetchPokusConfig();
    DeviceEventEmitter && DeviceEventEmitter.addListener('EV_POKUS_CONFIG_LOADED', async () => {
      await _fetchPokusConfig();
    });
  }
};

const _abWaitingPromiseResolvers = [];
const _pokusWaitingPromiseResolvers = [];

export const getAbConfigWaitingPromise = (timeout = 10000) => {
  if (!_.isEmpty(_abConfigData)) {
    return Promise.resolve();
  }
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(reject, timeout);
  });
  const fulfillingPromise = new Promise((resolve) => {
    _abWaitingPromiseResolvers.push(resolve);
  });
  return Promise.race([fulfillingPromise, timeoutPromise]);
};

export const getPokusConfigWaitingPromise = (timeout = 10000) => {
  if (!_.isEmpty(_pokusConfigData)) {
    return Promise.resolve();
  }
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(reject, timeout);
  });
  const fulfillingPromise = new Promise((resolve) => {
    _pokusWaitingPromiseResolvers.push(resolve);
  });
  return Promise.race([fulfillingPromise, timeoutPromise]);
};

export const AbConfigKeyMappings = mappings;
export default getAbConfig;
