import BaseGenericEvent from '../../BaseGenericEvent';
import {
  getApplicableCouponDetailsList,
  getAppliedCouponDetails,
  getCabData,
  getCabOtherData,
  getCabPricingDetailList,
  getCarCategoryVendorSelected,
  getSearchEventData,
  getTravellerDetailsList
} from '../../PdtHelper/CabsPdtUtils';
import {CabsPdtConstants} from '../../PdtHelper/CabsPdtConstants';

export default class CabsPdtReviewEvent extends BaseGenericEvent {
  constructor(pdtData) {
    const data = pdtData;
    data.pageName = CabsPdtConstants.FunnelStep.REVIEW;
    data.funnelStep = CabsPdtConstants.FunnelStep.REVIEW;
    super(data);
    this.cabData = pdtData.cab;
    this.cabsData = pdtData.cabs;
    this.couponData = pdtData.coupons;
    this.searchData = pdtData.searchData;
    this.travellerData = pdtData.pax;
  }

  trackReviewEvent = () => {
    const basePdtObject = this.basePdtData();
    basePdtObject.cab_search_context = getSearchEventData(this.searchData);
    basePdtObject.cab_details = getCabData(this.cabData);
    basePdtObject.cab_other_details = getCabOtherData(this.cabData);
    basePdtObject.car_category_vendor_selected = getCarCategoryVendorSelected(this.cabData);
    basePdtObject.cab_pricing_details_list = getCabPricingDetailList(this.cabsData);
    basePdtObject.applicable_coupon_details_list = getApplicableCouponDetailsList(this.cabData, this.couponData);
    basePdtObject.applied_coupon_details = getAppliedCouponDetails(this.cabData, this.couponData);
    basePdtObject.traveller_details_list = getTravellerDetailsList(this.travellerData);
    return basePdtObject;
  }
}
