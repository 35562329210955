import fecha from 'fecha';
import _isEmpty from 'lodash/isEmpty';
import { Platform } from 'react-native';
import { getTomorrow, now } from '../../../Helpers/dateHelpers';
import {
  CABS_DATE_FMT,
  CABS_TIME_FMT,
  CabsMultiVendorTags,
  myBizTravellerTypes,
} from '../cabsConstants';
import CabsABConfigHelper from '../utils/CabsABConfigHelper';
import TripType from '../types/TripType';
import cabsConfig from '../cabsConfig';
import {
  adjustHourToSlot,
  diffMinutes,
  getNearestNextSlotInMinutes,
  getNearestReturnDate,
  hoursToDecimal,
  getNearestNextSlotForTripType,
  getNextDay10Am,
} from '../cabsDateTimeHelpers';
import { getMinimumAP, getMinimumAPInMinutes, getMinTravelTimeHrs } from '../cabsDynamicConfig';
import { getGender } from '../utils/cabReviewActionsUtils';
import { fetchAppVersion } from '../utils/cabsCommonUtils';
import { getUserDetails, ProfileType } from '../../../Native/UserSession/UserSessionModule';
import {PROVISIONAL_HOB_REQUEST_TYPE} from '../ProvisionalBookingHOB/constants'

export const PlatformConfig = {
  ...Platform.select({
    android: {
      bookMode: 'A',
      bookingDevice: 'Android',
      appVersion: '7.2.5',
    },
    ios: {
      bookMode: 'I',
      bookingDevice: 'iOS',
      appVersion: '6.0.2',
    },
    web: {
      bookMode: 'M',
      bookingDevice: 'msite',
      appVersion: '1.0.0',
    },
  }),
};

const getGenderPrefix = (pax) => {
  let gender = null;
  try {
    if (pax.gender) {
      gender = getGender(pax.gender)[0].toUpperCase();
    }
  } catch (e) {
    // Ignore
  }
  return gender;
};

export const getHoldRequest = async (holdRequestData) => ({
  flight_number: holdRequestData.flightNumber || null,
  flight_booking_id: holdRequestData.flightBookingId || '',
  version: 'V2',
  is_full_payment: holdRequestData.is_full_payment,
  is_alternate_cab: holdRequestData.isAlternateCab,
  departure_date: holdRequestData.departure_date,
  pickup_time: holdRequestData.pickup_time,
  return_date: holdRequestData.return_date,
  drop_time: holdRequestData.drop_time,
  source_city: holdRequestData.source_city,
  destination_city: holdRequestData.destination,
  trip_key: holdRequestData.tripKey,
  mmt_id: '',
  otp: holdRequestData.otp,
  type: 'holdBookingRequest',
  passenger_details: {
    first_name: holdRequestData.pax.name,
    last_name: '',
    gender: getGenderPrefix(holdRequestData.pax),
  },
  contact_details: {
    email_id: holdRequestData.pax.email,
    mobile: holdRequestData.pax.mobile,
    country_code: `+${holdRequestData.pax.countryCode}`,
  },
  trip_type: holdRequestData.tripType,
  tracking_params: {
    bookMode: PlatformConfig.bookMode,
    trackingId: holdRequestData.transactionKey,
    userEmailId: holdRequestData.pax.email,
  },
  cache_version: holdRequestData.request.cache_version,
  stopovers: holdRequestData.request.stopovers,
  vendor_id: holdRequestData.cab.vendor_id,
  cab_type: holdRequestData.cab.type,
  is_cab_upgraded: holdRequestData.cab.isCabUpgraded || false,
  fuel_type: holdRequestData.cab.fuel_type || null,
  is_mysafety_kit_available: holdRequestData.cab.is_mysafety_kit_available || false,
  cab_model: holdRequestData.cab.vehicle_model,
  source_location: holdRequestData.request.source_location,
  destination_location: holdRequestData.request.destination_location,
  stopovers: holdRequestData.request.stopovers,
  pickup_details: {
    address: holdRequestData.request.source_location.address,
    lat_lng: `${holdRequestData.request.source_location.latitude},${holdRequestData.request.source_location.longitude}`,
  },
  advance_type: holdRequestData.advance_type,
  coupon_code: holdRequestData.cab.cab_fare_detail.coupon_code,
  booking_device: PlatformConfig.bookingDevice,
  app_version: await fetchAppVersion(),
  email: holdRequestData.userEmail,
  session_token: holdRequestData.sessionToken,
  sector: `${holdRequestData.request.source_name}-${holdRequestData.request.destination_name}`,
  device_id:
    Platform.OS === 'android'
      ? holdRequestData.deviceDetails.pdtDeviceId
      : holdRequestData.deviceDetails.deviceId,
  visitor_id: null,
  package_key: holdRequestData.package_key,
  package_duration: holdRequestData.package_duration,
  package_distance: holdRequestData.package_distance,
  profile_type: holdRequestData.profile_type,
  operatingSystem: holdRequestData.deviceDetails.os,
  operatingSystemVersion: holdRequestData.deviceDetails.osVersion,
  model: holdRequestData.deviceDetails.deviceName,
  isEmailVerified: holdRequestData.emailVerified,
  clientTimeStamp: now().getTime(),
  loginType: holdRequestData.loginType,
  is_multicity_request: holdRequestData.isMulticityRequest,
  intermediate_locations: holdRequestData.isMulticityRequest
    ? holdRequestData.intermediate_locations
    : null,
  intermediate_cities: holdRequestData.isMulticityRequest
    ? holdRequestData.intermediate_cities
    : null,
  is_short_funnel: !!holdRequestData.isShortFunnel,
  is_instant_cab: !!holdRequestData.isInstantCab,
  driver_lang_pref: holdRequestData.travelRequest.languagePreferences,
  route_familiarity: holdRequestData.travelRequest.routeFamilarity,
  my_biz_data: holdRequestData.myBizData,
  package_key: holdRequestData?.selectedHRCabPackage || null,
  addons: holdRequestData.cab?.travel_addons?.filter((addon) => addon?.selected)?.map((addon) => {
    const selectedOption = addon?.options?.find((child) => child?.selected);
    return selectedOption ? selectedOption?.id : addon?.id;
  })
});

export const getCheckFareRequest = async (data) => ({
  type: PROVISIONAL_HOB_REQUEST_TYPE,
  tracking_params: null,
  device_info: null,
  flight_id: data?.flightId,
  destination_location: data?.destinationLocation,
  source_param: data?.source,
  is_departure: data?.departure,
  pickup_time: data?.pickupTime != null ? parseFloat(data?.pickupTime) : null,
  source_message :data?.source_message,
});

export const getValidateCouponRequest = async (
  cab,
  request,
  transactionKey,
  activeDurationFilter,
  userDetails,
  code,
  package_key,
  deviceDetails,
  isShortFunnel = false,
  isInstantCab = false,
) => {
  const is_alternate_cab = cab.multi_vendor_tag !== CabsMultiVendorTags.RECOMMENDED;
  return {
    type: 'validateCouponV2',
    coupon_code: code,
    book_mode: PlatformConfig.bookMode,
    trip_type: request.trip_type,
    pickup_time: request.pickup_time,
    drop_time: request.drop_time,
    stopovers: request?.stopovers || null,
    departure_date: request.departure_date,
    return_date: request.return_date,
    cab_type: cab.type,
    vendor_id: cab.vendor_id,
    is_mysafety_kit_available: cab.is_mysafety_kit_available || false,
    fuel_type: cab.fuel_type || null,
    source_location: request.source_location,
    destination_location: request.destination_location,
    transaction_key: transactionKey,
    booking_device: PlatformConfig.bookingDevice,
    app_version: await fetchAppVersion(),
    // TODO: Fetch these params from native for current user
    email: userDetails.email,
    session_token: userDetails.mmtAuth,
    profile_type: userDetails && userDetails.profileType ? userDetails.profileType : null,
    sector: `${request.source_city}-${request.destination_city}`,
    visitor_id: null,
    device_id: Platform.OS === 'android' ? deviceDetails.pdtDeviceId : deviceDetails.deviceId,
    operatingSystem: deviceDetails.os,
    operatingSystemVersion: deviceDetails.osVersion,
    model: deviceDetails.deviceName,
    isEmailVerified: userDetails.emailVerified,
    clientTimeStamp: now().getTime(),
    loginType: userDetails.loginType,
    package_key,
    is_alternate_cab,
    is_short_funnel: isShortFunnel,
    is_instant_cab: request.is_instant_search,
    is_custom_payment_allowed: true,
  };
};

export const getWalletBalanceRequest = (userDetails, deviceDetails) => ({
  type: 'walletBalanceRequest',
  email: userDetails.email,
  session_token: userDetails.mmtAuth,
  is_simplified_wallet: true,
  device_id: Platform.OS === 'android' ? deviceDetails.pdtDeviceId : deviceDetails.deviceId,
  operatingSystem: deviceDetails.os,
  operatingSystemVersion: deviceDetails.osVersion,
  model: deviceDetails.deviceName,
  isEmailVerified: userDetails.emailVerified,
  clientTimeStamp: now().getTime(),
  loginType: userDetails.loginType,
});

export const getFetchCouponRequest = async (
  cab,
  request,
  transactionKey,
  userDetails,
  deviceDetails,
  isShortFunnel = false,
  isInstantCab = false,
) => ({
  type: 'fetchCoupons',
  fetch_coupons_new_flow: true,
  source_location: request?.source_location || null,
  destination_location: request?.destination_location || null,
  stopovers: request?.stopovers || null,
  category_id: cab?.category_id || null,
  is_intercity_2: request?.is_intercity_2 || false,
  departure_date: request.departure_date,
  return_date: request.return_date,
  pickup_time: request.pickup_time,
  drop_time: request.drop_time,
  trip_type: request.trip_type,
  cab_type: cab.type,
  vendor_id: cab.vendor_id,
  transaction_key: transactionKey,
  email: userDetails.email,
  session_token: userDetails.mmtAuth,
  profile_type: userDetails && userDetails.profileType ? userDetails.profileType : null,
  sector: `${request.source_city}-${request.destination_city}`,
  visitor_id: null,
  booking_device: PlatformConfig.bookingDevice,
  app_version: await fetchAppVersion(),
  device_id: Platform.OS === 'android' ? deviceDetails.pdtDeviceId : deviceDetails.deviceId,
  operatingSystem: deviceDetails.os,
  operatingSystemVersion: deviceDetails.osVersion,
  model: deviceDetails.deviceName,
  isEmailVerified: userDetails.emailVerified,
  clientTimeStamp: now().getTime(),
  loginType: userDetails.loginType,
  is_short_funnel: isShortFunnel,
  is_instant_cab: request.is_instant_search,
  is_custom_payment_allowed: true,
  distance: cab?.journey_detail?.overall_trip_distance_in_kms || null,
  supplier_id: cab?.supplier_id || ''
});

export const getSearchCabsRequest = async (data) => {
  const {
    from,
    to,
    tripType,
    atCrossSellRequest = false,
    departDate,
    returnDate = null,
    userDetails,
    deviceDetails,
    intermediateCities,
    packageKey = null,
    requestSource = null,
    isDefaultSearch = false,
    isShortFunnel = false,
    isInstantCab = false,
    connectorSearch = false,
    marketingHotelId = null,
    is_guest_user = false,
    primary_pax_details = null,
    funnel_source = null,
    stopovers = [],
  } = data;
  const isMulticityEnabled = CabsABConfigHelper.multiCityEnabled();
  const isMulticityRequest =
    isMulticityEnabled && !_isEmpty(intermediateCities) && tripType === TripType.RT.value;
  const currDate = now();
  let defaultSearch = isDefaultSearch;
  let pickupTime = cabsConfig.defaultNextDayTimeFormatted;
  let reqDepartDate = getNearestNextSlotForTripType(tripType);
  let reqReturnDate = returnDate;

  if (Boolean(departDate) && diffMinutes(departDate, currDate) > 0) {
    reqDepartDate = departDate;
  } else {
    defaultSearch = true;
  }

  if (isInstantCab) {
    defaultSearch = true;
    // isInstantCab at BE wont use defaultSearch but at FE to not show PopUp we are sending true
  }

  if (tripType === TripType.HR.value) {
    defaultSearch = false; // Default search should always be false in case of HR becuase we show date and time before proceeding to listing
  }

  // The near pickup slot will be used as depart date and pickup time if the current time is < 4PM
  // If the time

  // Update the time to next available slot if current time selected.
  const minutesBeforePickup = diffMinutes(reqDepartDate, currDate);
  if (minutesBeforePickup < 0) {
    reqDepartDate = getNextDay10Am();
    const hours = hoursToDecimal(reqDepartDate);
    if (hours > 23.5) {
      reqDepartDate = getTomorrow(reqDepartDate);
    } else if (hours > cabsConfig.defaultNextDayTime) {
      adjustHourToSlot(reqDepartDate);
      pickupTime = !!isInstantCab
        ? fecha.format(now(), CABS_TIME_FMT)
        : fecha.format(reqDepartDate, CABS_TIME_FMT);
    }
  } else {
    pickupTime = !!isInstantCab
      ? fecha.format(now(), CABS_TIME_FMT)
      : fecha.format(reqDepartDate, CABS_TIME_FMT);
  }
  if (isInstantCab) {
    reqDepartDate = now();
  }
  let mybizTravellerEmail = null;
  let mybizTravellerType = null;
  if (userDetails?.profileType === ProfileType.BUSINESS) {
    try {
      if (is_guest_user) {
        mybizTravellerType = myBizTravellerTypes.GUEST;
      } else if (userDetails?.email === primary_pax_details?.businessEmailId) {
        mybizTravellerType = myBizTravellerTypes.SELF;
        mybizTravellerEmail = primary_pax_details?.businessEmailId;
      } else {
        mybizTravellerType = myBizTravellerTypes.COLLEAGUE;
        mybizTravellerEmail = primary_pax_details?.businessEmailId;
      }
    } catch (e) {
      //
    }
  }
  let request = {
    is_instant_searchV2: true,
    is_e_pass_enabled: true,
    source_location: from,
    destination_location: to,
    airport_cabs_enabled: true,
    hr_cabs_enabled: true,
    departure_date: fecha.format(reqDepartDate, CABS_DATE_FMT),
    pickup_time: pickupTime,
    trip_type: tripType,
    is_short_funnel: isShortFunnel,
    is_instant_search: isInstantCab,
    // TODO: Fetch these params from native for current user
    book_mode: PlatformConfig.bookMode,
    booking_device: PlatformConfig.bookingDevice,
    app_version: await fetchAppVersion(),
    email: userDetails.email,
    session_token: userDetails.mmtAuth,
    profile_type: userDetails.profileType,
    sector: null,
    is_default_search: defaultSearch,
    visitor_id: null,
    device_id: Platform.OS === 'android' ? deviceDetails.pdtDeviceId : deviceDetails.deviceId,
    operatingSystem: deviceDetails.os,
    operatingSystemVersion: deviceDetails.osVersion,
    model: deviceDetails.deviceName,
    isEmailVerified: userDetails.emailVerified,
    clientTimeStamp: now().getTime(),
    loginType: userDetails.loginType,
    package_key: packageKey,
    at_cross_sell_request: atCrossSellRequest,
    is_multicity_request: isMulticityRequest,
    intermediate_locations: isMulticityRequest ? intermediateCities : null,
    connector_search: connectorSearch,
    misc_attributes: {
      marketing_hotel_id: marketingHotelId
    },
    mybiz_traveller_email: mybizTravellerEmail,
    mybiz_traveller_type: mybizTravellerType,
    funnel_source,
    stopovers: formatStopovers(stopovers),
  };
  if (tripType === TripType.RT.value) {
    const minReturnDate = getMinTravelTimeHrs(reqDepartDate);
    if (reqReturnDate === null || reqReturnDate < minReturnDate) {
      reqReturnDate = minReturnDate;
      adjustHourToSlot(reqReturnDate);
    }
    request = {
      ...request,
      return_date: fecha.format(reqReturnDate, CABS_DATE_FMT),
      drop_time: null,
    };
  } else if (request.trip_type === TripType.HR.value) {
    request = {
      ...request,
      destination_location: null,
      fetch_package_list: false,
    };
  } else if (stopovers.length > 0) {
    request = {
      ...request,
      return_date: fecha.format(reqReturnDate, CABS_DATE_FMT),
      drop_time: fecha.format(reqReturnDate, CABS_TIME_FMT),
    };
  }
  return { request, reqDepartDate, reqReturnDate, initiator: requestSource };
};

const formatStopovers = (stopovers) => {
  if(!Boolean(stopovers?.length)) {
    return null;
  }
  return (
    stopovers?.map((item) => ({
      city_code: '',
      city_name: '',
      address_name: '',
      stopover: { ...item.location },
    })) || null
  );
};

export const getAddOnUpdateAddressRequest = async (data) => {
  const flight_booking_id = data.flightBookingId;
  const bookingDetails = Object.keys(data.travelDetails).reduce((result, key) => {
    const trip = data.travelDetails[key];
    const departure_date = fecha.format(trip.travel_date, CABS_DATE_FMT);
    const pickup_time = fecha.format(trip.travel_date, CABS_TIME_FMT);
    result.push({
      booking_id: trip.booking_id,
      cab_id: trip.cab_id,
      addon_type: trip.addon_type,
      selected_location: trip.location,
      departure_date,
      pickup_time,
    });
    return result;
  }, []);
  return {
    type: 'updateAddonBookingDetailsRequest',
    flight_booking_id,
    booking_details: bookingDetails,
    app_version: await fetchAppVersion(),
    book_mode: PlatformConfig.bookMode,
  };
};

export const sendApprovalActionRequestBody = async (data) => {
  const { workflowId, type, action, categoryId, authCode, reason, comment } = data;
  const { mmtAuth = null, email = null } = await getUserDetails();
  return {
    type,
    workflow_id: workflowId,
    session_token: mmtAuth,
    action,
    category_id: categoryId,
    email,
    booking_device: PlatformConfig.bookingDevice,
    app_version: await fetchAppVersion(),
    auth_token: authCode,
    reason,
    comment,
  };
};

export const getSkipAndPayRequestBody = async (data) => {
  const { workflowId, type, action, categoryId, authCode } = data;
  const { mmtAuth = null, email = null } = await getUserDetails();
  return {
    type,
    workflow_id: workflowId,
    session_token: mmtAuth,
    action,
    category_id: categoryId,
    email,
    booking_device: PlatformConfig.bookingDevice,
    book_mode: PlatformConfig.bookMode,
    app_version: await fetchAppVersion(),
    auth_token: authCode,
  };
};

export const getApprovalDataRequest = async (data) => {
  const { workflowId = null, authCode = null } = data;
  const { mmtAuth = null, email = null } = await getUserDetails();
  return {
    workflow_id: workflowId,
    auth_token: authCode,
    session_token: mmtAuth,
    email,
    type: 'myBizBookingDetailsRequest',
    book_mode: PlatformConfig.bookMode,
  };
};
