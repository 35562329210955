import {NativeModules, Platform} from 'react-native';
import {getSkywalkerSearchParams, getSectorInformation, getCommonsSearchParams} from './util';
import sendRecentSearchData from 'MMT-UI/recentSearch'; 

// add object type when search is refactored in ts
export const skywalkerSearchConnector = (data) => {
  const {GenericModule} = NativeModules;
  if (!GenericModule || !GenericModule.updateSearchEvents) {
    return;
  }
  GenericModule.updateSearchEvents('cabs', getSkywalkerSearchParams(data));
};

export const skywalkerSectorConnector = (data, pageName) => {
  const {GenericModule} = NativeModules;
  if (!GenericModule || !GenericModule.updateSectorInfo) {
    return;
  }
  GenericModule.updateSectorInfo('cabs', getSectorInformation(data, pageName));
};

export const commonsSearchConnector = async (data) => {
  try {
    if (Platform.OS === 'web') {
      const searchContext = await getCommonsSearchParams(data);
      const dataToSend = await sendRecentSearchData([{ 
        column: 'cab',
        searchContext
      }]); 
    } else {
      const { UserSessionModule } = NativeModules;
      if (!UserSessionModule?.updateRecentSearch) {
        return;
      }
      const searchContext = await getCommonsSearchParams(data);
      UserSessionModule.updateRecentSearch('cab', searchContext);
    }
  } catch (error) {
    console.error('Error while saving recent searches into commons', error);
  }
};