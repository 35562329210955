import * as constants from './PrimaryPaxActions';

const initialState = {
  primary_pax_details: null,
  recently_searched: [],
  is_guest_user: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_PRIMARY_USER_DETAILS: {
      return {
        ...state,
        primary_pax_details: action.data,
        is_guest_user: false
      };
    }
    case constants.SET_USER_AS_GUEST: {
      return {
        ...state,
        primary_pax_details: null,
        is_guest_user: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
