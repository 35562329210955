import {Dimensions, NativeModules, PermissionsAndroid, Platform} from 'react-native';
import { ActionConst } from 'react-native-router-flux';
import NetworkModule from '../../Native/NetworkModule';
import {getScreenDensityName} from '../../Helpers/displayHelper';
import {isNonEmpty} from './StringUtils';
import cookie from 'react-cookies';

export default function renderIf(condition, content) {
  if (condition) {
    return content;
  }
  return null;
}

export const isNetworkAvailable = () => {
  if (!NetworkModule) return Promise.resolve(true);
  return NetworkModule.isNetworkAvailable();
};

/**
 * @param url
 * @returns screen density formatted url after checking url validity
 */
export const getDensityImageUrl = (url) => {
  const density = getScreenDensityName();
  return isNonEmpty(url) ? url.replace('%s', density).replace('%S', density) : 'null';
};

/**
 *
 * Clear cookies
 */
export async function clearCookies() {
  const RCTNetworkingNative = NativeModules.Networking;
  RCTNetworkingNative.clearCookies(() => {
    console.log('Cookies cleared');
  });
}

/**
 *
 * Clear cookie by name
 */
export async function clearCookieByName(cookieName, cookieDomain) {
  cookie.remove(cookieName, {
    path: '/',
    domain: cookieDomain
  });
}

function extension(filename) {
  return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
}

const requestPermission = async () => {
  return new Promise((resolve, reject) => {
    try {
      PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
        {
          title: 'Need Permission',
          message: 'App needs access to your storage to download',
          buttonNeutral: 'Ask Me Later',
          buttonNegative: 'Cancel',
          buttonPositive: 'OK',
        }
      ).then((granted) => {
        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          resolve();
        } else {
          reject(['Error', 'storage permission denied']);
        }
      });
    } catch (err) {
      reject(['Error', `Unable get required Storage Permission${err}`]);
    }
  });
};

export const animationType = Platform.OS === 'ios' ? ActionConst.PUSH : '';
export const animationDirection = Platform.OS === 'ios' ? 'horizontal' : '';
export const animationDuration = Platform.OS === 'ios' ? 250 : 0;
export const DEVICE_WINDOW = Platform.OS === 'ios' ? Dimensions.get('screen') : Dimensions.get('window');
export const ERROR_MSG =
  'Looks like we are facing some technical issue, please try again in some time.';
