import _ from 'lodash';
import fecha from 'fecha';
import { getAddOnUpdateAddressRequest } from 'src/Routes/Cabs/api/cabsRequestBuilder';
import {
  getFlightAddOnDetail,
  updateFlightAddOnDetail,
  confirmFlightAddOnBooking,
  getUpdatedAddOnBookingDetail,
} from 'src/Routes/Cabs/api/cabsApis';
import {
  ADDON_BOOKING_NOT_FOUND_ERROR_MESSAGE,
  ADDON_EMPTY_LOCATION_MESSAGE,
  ADDON_UPDATE_ERROR_MESSAGE,
} from '../Constants/CabAddOnConstants';
import { getDateFromDateTime } from 'src/Routes/Cabs/cabsDateTimeHelpers';
import { setFlightBookingId } from 'src/Routes/Cabs/utils/cabsLocalStore';
import { createAddonFunnelEvars, trackAddOnDetailLoad, trackError, setInternationalFlow } from 'src/Routes/Cabs/cabsAnalytics';
import CabOmnitureEvent from 'src/Routes/Cabs/cabsOmnitureEvents';
import { diffDays, today } from 'src/Helpers/dateHelpers';
import { CABS_DATE_FMT, ErrorCodes } from 'src/Routes/Cabs/cabsConstants';
import { fetchAppVersion } from 'src/Routes/Cabs/utils/cabsCommonUtils';
import { PlatformConfig } from 'src/Routes/Cabs/api/cabsRequestBuilder';

export const ACTION_ADD_ON_UPDATE_BOOKING_DETAILS = 'ACTION_ADD_ON_UPDATE_BOOKING_DETAILS';
export const ACTION_ADD_ON_PARENT_DETAILS = 'ACTION_ADD_ON_PARENT_DETAILS';
export const ACTION_UPDATE_ADD_ON_TRAVEL_DETAILS = 'ACTION_UPDATE_ADD_ON_TRAVEL_DETAILS';

export const setUpdatedBookingDetails = (data) => ({
  type: ACTION_ADD_ON_UPDATE_BOOKING_DETAILS,
  data,
});

export const setParentDetails = (data) => ({
  type: ACTION_ADD_ON_PARENT_DETAILS,
  data,
});

export const updateAddOnDetails = (data) => ({
  type: ACTION_UPDATE_ADD_ON_TRAVEL_DETAILS,
  data,
});

export const updateAddOnBookingDetails = async (flightBookingId, travelDetails) => {
  let error = null;
  let bookingResp = null;
  try {
    const request = await getAddOnUpdateAddressRequest({
      flightBookingId,
      travelDetails,
    });
    const response = await updateFlightAddOnDetail(request);
    const result = await response.json();
    if (result.status !== 'FAILURE' && !Boolean(result.errors)) {
      bookingResp = result;
    } else {
      error =
        result?.response?.booking_error_details && result?.response?.booking_error_details.length
          ? {
            errorType: 'specific',
            errorList: result?.response?.booking_error_details,
          }
          : {
            errorType: 'generic',
            errorList: result?.errors?.error_list[0],
          };
    }
  } catch (e) {
    // Ignore
    error = ADDON_UPDATE_ERROR_MESSAGE;
  }
  return {
    success: !error,
    flightBookingId,
    bookingResp,
    error,
  };
};

export const fetchUpdatedAddOnBookingDetails = async (flightBookingId) => {
  let errorMessage = null;
  let bookingResp = null;
  try {
    const request = {
      type: 'updateAddonBookingDetailsRequest',
      flight_booking_id: flightBookingId,
      app_version: await fetchAppVersion(),
      book_mode: PlatformConfig.bookMode,
    };
    const response = await getUpdatedAddOnBookingDetail(request);
    const result = await response.json();
    if (result.status !== 'FAILURE' && !result.errors) {
      bookingResp = result;
    } else {
      errorMessage = result.errors.error_list[0].message;
    }
  } catch (e) {
    // Ignore
  }
  if (!bookingResp) {
    trackError(CabOmnitureEvent.ADD_ON_REVIEW_LOAD_ERROR);
    if (!errorMessage) {
      errorMessage = ADDON_BOOKING_NOT_FOUND_ERROR_MESSAGE;
    }
  } else {
    setInternationalFlow(bookingResp?.booking_details?.some((booking) => booking.international_trip));
  }
  return {
    success: !errorMessage,
    flightBookingId,
    errorMessage,
    bookingResp,
  };
};

export const confirmAddOnBookings = async (flightBookingId) => {
  let success = false;
  let error = null;
  let error_code = null;
  try {
    const request = {
      type: 'confirmAddonBookingRequest',
      flight_booking_id: flightBookingId,
    };
    const response = await confirmFlightAddOnBooking(request);
    const result = await response.json();
    setFlightBookingId(flightBookingId);
    success = !Boolean(result.errors);
    if (!success) {
      error =
        !Boolean(result.errors) || !Boolean(result.errors?.error_list?.length)
          ? ADDON_UPDATE_ERROR_MESSAGE
          : result.errors.error_list[0].message;
      error_code =
        !Boolean(result.errors) || !Boolean(result.errors?.error_list?.length)
          ? error_code
          : result.errors.error_list[0].code;
    }
  } catch (e) {
    error = ADDON_UPDATE_ERROR_MESSAGE;
  }
  return {
    success,
    error,
    error_code,
  };
};

export const fetchAddOnDetails = async (flightBookingId) => {
  const request = {
    type: 'getAddonBookingDetailsRequest',
    flight_booking_id: flightBookingId,
  };
  let cabData = null;
  let errorCode = null;
  let errorMessage = null;
  let success = false;
  let errors = null;
  try {
    const response = await getFlightAddOnDetail(request);
    const result = await response.json();
    if (result.status !== 'FAILURE' && !result.errors) {
      const showDateTime = result?.booking_details?.show_response_date_time;
      cabData = _processCabData(result, showDateTime);
      if (cabData && !_.isEmpty(cabData.tripLegs)) {
        success = true;
      }
    } else if (result.errors !== null && !_.isEmpty(result?.errors?.error_list)) {
      errorMessage = result.errors.error_list[0].message;
      errorCode = result.errors.error_list[0].code;
      errors = result.errors;
    }
  } catch (e) {
    // Ignore
  }

  if (success) {
    // if any one cab booking is international, then the whole session is tracked as international funnel
    const isInternational = cabData?.tripLegs?.some((trip)=>trip.data?.some((booking)=>booking.international_trip));
    setInternationalFlow(isInternational);
    const trackingData = _prepareOmnitureData(cabData);
    createAddonFunnelEvars(trackingData);
    trackAddOnDetailLoad();
  } else {
    trackError(CabOmnitureEvent.ADD_ON_DETAIL_LOAD_ERROR);
    if (!errorMessage) {
      errorMessage = ADDON_BOOKING_NOT_FOUND_ERROR_MESSAGE;
      errorCode = ErrorCodes.BOOKING_NOT_FOUND_ERROR_CODE;
    }
  }

  return {
    success,
    cabData,
    errorMessage,
    errorCode,
    errors,
  };
};

export const setAddOnTravelDetails = (tripLegs, travelDetails) => (dispatch) => {
  const addOnDetailsNextEnabled = validateTravelDetails(travelDetails).isValid;
  const data = {
    tripLegs,
    travelDetails,
    addOnDetailsNextEnabled,
  };
  dispatch(updateAddOnDetails(data));
};

export const updateAddOnTravelDetails = (travelDetail) => (dispatch, getState) => {
  const { tripLegs, travelDetails } = getState().cabsAddOn;
  if (travelDetail) {
    const updatedTravelDetails = { ...travelDetails };
    updatedTravelDetails[travelDetail.booking_id] = travelDetail;
    const isNextEnabled = validateTravelDetails(updatedTravelDetails).isValid;
    const data = {
      tripLegs,
      travelDetails: updatedTravelDetails,
      addOnDetailsNextEnabled: isNextEnabled,
    };
    dispatch(updateAddOnDetails(data));
  }
};

export const validateTravelDetails = (travelDetails) => {
  let invalidBooking = null;
  try {
    const emptyDetail = _.filter(travelDetails, (e) => !Boolean(e.location));
    if (!_.isEmpty(emptyDetail)) {
      invalidBooking = {
        booking_id: emptyDetail[0].booking_id,
        message: ADDON_EMPTY_LOCATION_MESSAGE,
      };
    }
  } catch (e) {
    // Ignore
  }
  return {
    isValid: !invalidBooking,
    invalidBooking,
  };
};

const _processCabData = (data, showDateTime) => {
  const tripLegs = [];
  const travelDetails = {};
  try {
    data.booking_details.trip_legs.forEach((trip) => {
      tripLegs.push({
        title: { flight_details: trip.flight_details },
        data: trip.cab_bookings,
      });
      trip.cab_bookings.forEach((booking) => {
        travelDetails[booking.booking_id] = {
          cab_id: booking.cab_id,
          booking_id: booking.booking_id,
          addon_type: booking.addon_type,
          location: booking.trip_details.user_location,
          airport_location: booking.trip_details.airport_location,
          travel_date: getDateFromDateTime(
            booking.trip_details.departure_date,
            booking.trip_details.pickup_time,
          ),
          updated_date: showDateTime
            ? getDateFromDateTime(
              booking.trip_details.departure_date,
              booking.trip_details.pickup_time,
            )
            : null,
        };
      });
    });
  } catch (e) {
    // Ignore exception
  }
  return {
    tripLegs,
    travelDetails,
  };
};

const _prepareOmnitureData = (cabData) => {
  try {
    const { tripLegs } = cabData;
    let numberOfCabs = 0;
    const flightDetails = tripLegs?.[0]?.title?.flight_details;
    let originDestination = `${flightDetails.source_airport}_${flightDetails.destination_airport}`;
    originDestination = tripLegs.length > 1 ? `${originDestination}_RT` : `${originDestination}_OW`;

    const departureDate = fecha.parse(flightDetails.departure_date, CABS_DATE_FMT);
    const advancePurchaseDays = parseInt(diffDays(departureDate, today()), 10);

    let cabDetails = '';
    tripLegs.forEach((tripLeg) => {
      tripLeg.data.forEach((data) => {
        cabDetails += `${data.cab_display_name}_${data.trip_details.airport_city}_${data.addon_type}_`;
        numberOfCabs += 1;
      });
    });
    cabDetails = cabDetails.slice(0, cabDetails.length - 1);
    cabDetails = `${numberOfCabs}_${cabDetails}`;
    const trackingData = {
      originDestination,
      advancePurchaseDays,
      cabDetails,
      numberOfCabs,
    };
    return trackingData;
  } catch (e) {
    // ignore
    return {};
  }
};
