import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';
import _compact from 'lodash/compact';
import _cloneDeep from 'lodash/cloneDeep';
import fecha from 'fecha';
import { formatDay, now, truncateHours } from 'src/Helpers/dateHelpers';
import * as constants from './cabsListingActions';
import cabsListDecorator from '../utils/cabsListDecorator';
import TripType from '../types/TripType';
import {
  trackListingLoad,
  trackListingEvent,
  trackListingLoadErrorEvent,
  setOnHoldTrackingFunc,
} from '../cabsAnalytics';
import { getDurationFromMinute, getNearestNextSlotForTripType } from '../cabsDateTimeHelpers';
import { getCityNameFromLocation, getHeaderDate, getTravelDateTime } from '../utils/cabsSearchUtil';
import CabsABConfigHelper from '../utils/CabsABConfigHelper';
import CabsPdtListingHelper from '../PdtAnalytics/PdtHelper/CabsPdtListingHelper';
import { sortCabs } from '../Filters/utils';
import { MyBizTripChangeErrCode, ErrorCodes } from '../cabsConstants';

export const ViewStates = {
  VIEW_LOADING: 'view_loading',
  VIEW_CABS: 'view_cabs',
  VIEW_NO_INTERNET: 'view_no_internet',
  VIEW_NO_CABS: 'view_no_cabs',
};

/*
 *
 * Plain prop types for dates
 *
 * */
const initialState = {
  flightNumber: null,
  flightBookingId: null,
  shouldShowCoachMark: false,
  cabs: [],
  previewCabs: [],
  displayCabs: [],
  cancellationPolicies: [],
  transactionKey: '',
  mmtApproxDistance: null,
  viewState: ViewStates.VIEW_LOADING,
  departDate: getNearestNextSlotForTripType(TripType.OW.value),
  tripType: null,
  activeDurationFilter: null,
  durationFilters: [],
  walletBalance: null,
  walletBurnRules: [],
  walletLoading: true,
  searchErrorMessage: '',
  searchErrorCode: '',
  searchErrorHeader: '',
  searchErrorSubContent: '',
  searchErrorMoreLink: '',
  searchErrorSubContentClickable: '',
  isFutureCabs: false,
  ePassChecklist: null,
  ePassErrorInfo: null,
  isEPassRequired: false,
  protectiveShieldModalDetails: null,
  instantCabQualities: null,
  showListingWidget: false,
  isIntermediateEPassVisible: false,
  informationCard: null,
  covidPrecautions: null,
  covidData: null,
  persuasionData: null,
  tripBenefits: null,
  searchRequest: { is_instant_search: false },
  mmtBlackPersuasion: null,
  selectedFilters: {},
  isFilterApplied: false,
  filters: {},
  previewFilters: null,
  sortingKey: null,
  storyFeeds: null,
  storyStaticFeeds: null,
  tripGuarantee: null,
  listingPageOffers: [],
  headerProps: {
    title: '',
    subTitle: '',
  },
  selectedCabPackage: null,
  bpgInfo: null,
  distance: 0,
  supplier_id: '',
  marketingHotelId: null,
  isDefaultDateTimeUpdated: false,
  travelDateTime: [truncateHours(now())], // TODO Remove travelDateTime from state,
  selectedHRCabPackage: null,
  hrPackages: null,
  fuelTypePokusKey: null,
  claimPersuasionsData: null,
  prefferedCabPartner: null, // Maintained for Rap or Non-rap,
  notifyMessage: null,
  trip_distance_and_duration: null,
  isIntermediatePage: false,
  sourceAndCmp: null,
  bnplTripTypes: [],
  bnplLimitData: null,
  listingAlertData: null,
  nonRapCabData: null,
  shouldSwapCardsIntermediateScreen: false, // To toggle rap and nonrap card in intermediate screen
  brandInfo: null,
  tripPersuationData: null,
  show_listing_trip_assistant: false,
  whatsapp_chat_details: null,
};

export const formatDate = (fullDate) => {
  if (!fullDate) {
    return null;
  }
  const [date, month, year] = fecha.format(fullDate, 'DD-MMM-YYYY').split('-');
  return {
    date,
    month,
    day: formatDay(fullDate),
    year,
    raw: truncateHours(fullDate),
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.ACTION_INITIAL_DATA: {
      const request = action.data;
      const [departDate, returnDate = null] = getTravelDateTime(request);
      const currentTripType = TripType[request.trip_type];
      const fromCity = getCityNameFromLocation(request.source_location);
      const toCity = getCityNameFromLocation(request.destination_location);
      let title = _isEmpty(fromCity) ? '' : `${fromCity}`;
      let subTitle = currentTripType.name;
      let activeDurationFilter = null;
      if (request.package_key !== null) {
        if (!_isEmpty(state.durationFilters)) {
          const tripFilters = state.durationFilters.filter(
            (filter) => filter.package_id === request.package_key,
          );
          activeDurationFilter = _isEmpty(tripFilters) ? state.durationFilters[0] : tripFilters[0];
        }
      }

      if (
        currentTripType.value !== TripType.HR.value &&
        !(_isEmpty(fromCity) || _isEmpty(toCity))
      ) {
        title = `${fromCity} to ${toCity}`;
      } else {
        subTitle = `${getHeaderDate(departDate)} | ${currentTripType.name}`;
      }
      const headerProps = {
        title,
        subTitle,
      };
      const viewState = ViewStates.VIEW_LOADING;
      return {
        ...state,
        viewState,
        tripType: currentTripType.value,
        headerProps,
        protectiveShieldModalDetails: null,
        activeDurationFilter,
        searchRequest: request,
        departDate,
        returnDate,
        notifyMessage: null,
        show_listing_trip_assistant: false
      };
    }
    case constants.ACTION_UPDATE_HEADER_INFO_LABEL: {
      return {
        ...state,
        headerInfoLabel: null,
      };
    }
    case constants.ACTION_ERROR: {
      return {
        ...state,
        searchErrorCode: '',
        searchErrorMessage: '',
        searchErrorHeader: '',
        searchErrorSubContent: '',
        searchErrorSubContentClickable: '',
        searchErrorMoreLink: '',
        searchRequest: action.data.request,
        viewState: ViewStates.VIEW_NO_CABS,
      };
    }
    case constants.ACTION_SET_CAB_CONFIG_HR_PACKAGE: {
      return {
        ...state,
        hrPackages: action.data,
      };
    }
    case constants.ACTION_SET_TRIP_PERSUASION_DATA: {
      return {
        ...state,
        tripPersuationData: action.data,
      }
    }
    case constants.ACTION_SET_CAB_BRAND_INFO: {
      return {
        ...state,
        brandInfo: action.data,
      };
    }
    case constants.ACTION_SET_BNPL_TRIP_TYPES: {
      return {
        ...state,
        bnplTripTypes: action.data,
      };
    }
    case constants.ACTION_SET_NON_RAP_DATA: {
      return { ...state, nonRapCabData: action.data };
    }
    case constants.ACTION_UPDATE_SEARCH_REQUEST: {
      return {
        ...state,
        searchRequest: action.data,
      };
    }
    case constants.ACTION_TOGGLE_IS_DEFAULT_DATE_UPDATED: {
      return {
        ...state,
        isDefaultDateTimeUpdated: action.data,
      };
    }
    case constants.ACTION_SET_HOTEL_ID: {
      return {
        ...state,
        marketingHotelId: action.data
      }
    }
    case constants.ACTION_TOGGLE_E_PASS_INTERMEDIATE_SCREEN : {
      return {
        ...state,
        isIntermediateEPassVisible: action.data
      }
    }
    case constants.ACTION_SET_SELECTED_CAB_PACKAGE: {
      return {
        ...state,
        selectedCabPackage: action.data,
      };
    }
    case constants.ACTION_UPDATE_SELECTED_PACKAGE: {
      return {
        ...state,
        selectedHRCabPackage: action.data,
      };
    }
    case constants.ACTION_SET_CAB_BNPL_LIMIT_DATA: {
      return {
        ...state,
        bnplLimitData: action.data,
      };
    }
    case constants.ACTION_ADD_ON_UPDATE_FLIGHT_BOOKING_ID: {
      return { ...state, flightBookingId: action.data };
    }
    case constants.ACTION_SET_PREFFERED_CAB_PARTNER: {
      const isIntermediatePage = action.data === null;
      return { ...state, prefferedCabPartner: action.data, isIntermediatePage };
    }
    case constants.ACTION_SET_SOURCE_AND_CAMPAIGN: {
      return { ...state, sourceAndCmp: action.data };
    }
    case constants.ACTION_RESET_LISTING_ALERT_DATA: {
      return {
        ...state,
        listingAlertData: null,
      };
    }
    case constants.ACTION_SET_VIEW_STATE_LOADING: {
      return {
        ...state,
        viewState: ViewStates.VIEW_LOADING,
      };
    }
    case constants.SET_SEARCH_DATA: {
      const { searchRequest, response } = action.data;
      let instantCabQualities = null;
      let informationCard = null;
      const isMulticityEnabled = CabsABConfigHelper.multiCityEnabled();
      let headerInfoLabel = null;
      if (response.status !== 'SUCCESS') {
        let newTripType = TripType[state.tripType];
        let headerProps = {};
        if (response.request !== null) {
          newTripType = TripType[response.request.trip_type];
          const title = response.request.header_title;
          const subTitle = newTripType.name;
          headerProps = { title, subTitle };
          headerInfoLabel = response.request.header_info_label;
        }
        let searchErrorMessage = '';
        let searchErrorCode = '';
        let searchErrorHeader = '';
        let searchErrorSubContent = '';
        let searchErrorMoreLink = '';
        let searchErrorSubContentClickable = '';
        if (response.errors !== null) {
          const errorList = response.errors.error_list;
          if (!_isEmpty(errorList)) {
            searchErrorMessage = errorList[0].message;
            searchErrorCode = errorList[0].code;
            try {
              searchErrorHeader = errorList[0].header;
              searchErrorSubContent = errorList[0].sub_content;
              searchErrorSubContentClickable = errorList[0].sub_content_clickable;
              searchErrorMoreLink = errorList[0].know_more;
            } catch (errorData) {
              searchErrorHeader = '';
              searchErrorSubContent = '';
              searchErrorSubContentClickable = '';
              searchErrorMoreLink = '';
            }
            searchErrorHeader = errorList[0].title;
          }
        }

        if (searchErrorCode === ErrorCodes.MYBIZ_NO_CABS_FOUND) {
          trackListingEvent('Mob_Cab_MyBiz_Offline_Form_Entry_Shown');
        }

        const showLoadingPlaceHolder = searchErrorCode === MyBizTripChangeErrCode;

        return {
          ...state,
          tripType: newTripType.value,
          headerProps,
          searchErrorCode,
          searchErrorHeader,
          searchErrorSubContent,
          searchErrorSubContentClickable,
          searchErrorMoreLink,
          covidData: null,
          persuasionData: null,
          ePassErrorInfo: null,
          searchErrorMessage,
          protectiveShieldModalDetails: null,
          isEPassRequired: false,
          mmtBlackPersuasion: null,
          storyFeeds: null,
          storyStaticFeeds: null,
          viewState: showLoadingPlaceHolder ? ViewStates.VIEW_LOADING : ViewStates.VIEW_NO_CABS,
          searchServerRequest: response.request,
          searchRequest,
          sortingKey: null,
          headerInfoLabel,
        };
      }

      const mmtApproxDistance = response.oneway_distance_in_kms;
      const mmtApproxDuration = response.oneway_duration_in_minutes;
      let hrPackages = state.hrPackages;
      if (
        Boolean(response?.hr_packages?.length) &&
        response?.request?.trip_type === TripType.HR.value
      ) {
        hrPackages = response?.hr_packages;
      }
      const selectedHRCabPackage = response?.selected_package || null;
      const cabResponse = response.response;
      const fuelTypePokusKeyFromResponse = response?.request?.fuel_type_pokus_config || null;
      let defaultCabs = cabResponse?.cab_list || [];
      let rapCabs = cabResponse?.rap_cab_list || [];
      const rapBanner = cabResponse?.rap_banner || null;
      const defaultBanner = cabResponse?.non_rap_banner;
      const prefferedPartner = response?.request?.cab_partner_pref || null;
      const notifyMessage =
        cabResponse?.listing_notify_message || cabResponse?.cab_partner_change_popup || null;

      const cancellationPolicies = !_isEmpty(cabResponse.cancellation_details)
        ? cabResponse.cancellation_details.cancellation_policy
        : null;
      const freeCancellationMessage = !_isEmpty(cabResponse.cancellation_details)
        ? cabResponse.cancellation_details.free_cancellation_message
        : null;
      const airport_prime_features = !_isEmpty(cabResponse.airport_prime_features)
        ? cabResponse.airport_prime_features
        : [];
      const covidData = cabResponse.covid_data || null;
      const persuasionData = cabResponse.persuasion_data || null;
      const durationFilters = cabResponse.package_duration_filter;
      const transactionKey = cabResponse.transaction_key;
      const storyFeeds = response?.safety_feed?.story_feeds || null;
      const storyStaticFeeds = response?.safety_feed?.static_feeds || null;
      const tripGuarantee = response?.response?.trip_guarantee || null;
      const viewState =
        _isEmpty(defaultCabs) && _isEmpty(rapCabs) ? ViewStates.VIEW_NO_CABS : ViewStates.VIEW_CABS;
      const covidPrecautions = _has(cabResponse, 'safety_note') ? cabResponse.safety_note : null;
      const tripBenefits = cabResponse.trip_benefits;
      const mmtBlackPersuasion = cabResponse.mmt_black_persuasion || null;
      const { request } = response;
      const { source_city: sourceCityCode, destination_city: destinationCityCode } = request;
      const [departDate] = getTravelDateTime(request);
      const currentTripType = TripType[request.trip_type];
      let subTitle = currentTripType.name;
      if (!_isEmpty(defaultCabs) || !_isEmpty(rapCabs)) {
        const duration = getDurationFromMinute(mmtApproxDuration);
        if (!_isEmpty(duration) && request.trip_type === TripType.OW.value) {
          subTitle = `${duration} | ${subTitle}`;
        }
      }
      if (mmtApproxDistance) {
        const distance = Math.round(parseInt(mmtApproxDistance, 10));
        const distanceText = currentTripType.value === TripType.RT.value ? 'kms (one side)' : 'kms';
        subTitle = `${distance}${distanceText} | ${subTitle}`;
      }

      let ePassChecklist = cabResponse?.e_pass_checklist || null;
      let isEPassRequired = cabResponse?.e_pass_required || false;
      let ePassErrorInfo = cabResponse?.e_pass_error_info || null;

      let title = `${request.source_name}`;
      let { activeDurationFilter } = state;
      if (currentTripType.value !== TripType.HR.value) {
        title = response?.request?.header_title || null;
      }
      headerInfoLabel = response?.request?.header_info_label || null;
      if (response?.response?.cancellation_message_new) {
        informationCard = response.response.cancellation_message_new;
      }
      if (response?.response?.govt_covid_guidelines) {
        informationCard = response.response.govt_covid_guidelines;
      }
      try {
        if (response.response.instant_cab_qualities) {
          instantCabQualities = response.response.instant_cab_qualities;
        }
      } catch (error) {
        instantCabQualities = null;
      }
      const specificModelsList = [];
      const packagesList = []; //Std/Prime/Flexi
      const allCabsList = [...rapCabs, ...defaultCabs];
      try {
        if (allCabsList?.length > 0) {
          allCabsList.map((item) => {
            const isGeneric = item.vehicle_model_type === 'GENERIC';
            if (!isGeneric) {
              specificModelsList.push(item.vehicle_model_type);
            }
            const valueModelsPresent = item.package_data !== null;
            if (valueModelsPresent) {
              item.package_data.packages.map((packageData, index) => {
                if (!packagesList.includes(packageData.package)) {
                  packagesList.push(packageData.package);
                }
              });
            }
            const showMoreCabsPresent = item.other_cabs !== null;
            if (showMoreCabsPresent) {
              packagesList.push('MoreCabs');
            }
          });
        }
      } catch (e) {
        // error
      }
      const intermediateCities = request.intermediate_cities_name;
      if (
        isMulticityEnabled &&
        currentTripType.value === TripType.RT.value &&
        !_isEmpty(intermediateCities)
      ) {
        subTitle = 'via ';
        const { length } = intermediateCities;
        for (let i = 0; i < length; i += 1) {
          subTitle += `${intermediateCities[i]}, `;
        }
        subTitle = subTitle.slice(0, subTitle.length - 2);
      }

      const showIncludedKms = currentTripType.value === TripType.RT.value;
      const headerProps = {
        title,
        subTitle,
      };
      defaultCabs = cabsListDecorator(defaultCabs);
      rapCabs = cabsListDecorator(rapCabs);
      if (state.isFutureCabs) {
        trackListingLoadErrorEvent();
      } else {
        const responsePokusValues = response?.request?.pokus_flags;
        let cabPartnerTracker = null;
        if (rapCabs?.length && defaultCabs?.length) {
          cabPartnerTracker = 'RAP + Non RAP';
        } else if (rapCabs?.length) {
          cabPartnerTracker = 'RAP only';
        } else if (defaultCabs?.length) {
          cabPartnerTracker = 'Non RAP only';
        }
        if (Boolean(response?.request?.stopovers?.length)) {
          cabPartnerTracker += `|MC_${response?.request?.stopovers?.length}`;
        }
        if (state.showListingWidget) {
          setOnHoldTrackingFunc(() =>
            trackListingLoad(
              mmtApproxDistance,
              currentTripType.value === TripType.HR.value ? activeDurationFilter : null,
              isEPassRequired,
              state.showListingWidget,
              searchRequest,
              specificModelsList,
              packagesList,
              fuelTypePokusKeyFromResponse,
              responsePokusValues,
              cabPartnerTracker,
              selectedHRCabPackage,
              response,
              true
            ),
          );
        } else {
          trackListingLoad(
            mmtApproxDistance,
            currentTripType.value === TripType.HR.value ? activeDurationFilter : null,
            isEPassRequired,
            state.showListingWidget,
            searchRequest,
            specificModelsList,
            packagesList,
            fuelTypePokusKeyFromResponse,
            responsePokusValues,
            cabPartnerTracker,
            selectedHRCabPackage,
            response,
            true
          );
        }
      }
      const { userDetails, deviceDetails, returnDate, sourceData, destinationData } = searchRequest;

      const pdtData = {
        userDetails,
        deviceDetails,
        defaultCabs,
        searchData: {
          departDate: searchRequest.departDate,
          returnDate,
          sourceData,
          destinationData,
          sourceCityCode:
            sourceCityCode && sourceCityCode.toLowerCase() !== 'proxy' ? sourceCityCode : null,
          destinationCityCode:
            destinationCityCode && destinationCityCode.toLowerCase() !== 'proxy'
              ? destinationCityCode
              : null,
          tripType: currentTripType.value,
          locusInfo: response?.request?.locus_info,
        },
      };
      const claimPersuasionsData = cabResponse?.claim_persuasions;
      const isIntermediatePage = prefferedPartner === null;
      const shouldSwapCardsIntermediateScreen = cabResponse?.is_swapping_cards_applicable || false;
      CabsPdtListingHelper.trackPageLoad(pdtData);
      return {
        ...state,
        tripType: currentTripType.value,
        headerProps,
        showIncludedKms,
        ePassChecklist,
        isEPassRequired,
        ePassErrorInfo,
        viewState,
        cancellationPolicies,
        freeCancellationMessage,
        airport_prime_features,
        mmtApproxDistance,
        durationFilters,
        activeDurationFilter,
        protectiveShieldModalDetails: null,
        transactionKey,
        covidPrecautions,
        covidData,
        persuasionData,
        tripBenefits,
        instantCabQualities,
        storyFeeds,
        storyStaticFeeds,
        mmtBlackPersuasion,
        informationCard,
        searchServerRequest: action.data.response.request,
        searchRequest,
        searchErrorCode: '',
        searchErrorMessage: '',
        searchErrorHeader: '',
        searchErrorSubContent: '',
        searchErrorSubContentClickable: '',
        searchErrorMoreLink: '',
        defaultCabs,
        rapCabs,
        tripGuarantee,
        sortingKey: null,
        hrPackages,
        selectedHRCabPackage,
        fuelTypePokusKey: fuelTypePokusKeyFromResponse,
        claimPersuasionsData,
        prefferedCabPartner: prefferedPartner,
        rapBanner,
        defaultBanner,
        notifyMessage,
        trip_distance_and_duration: action.data?.response?.trip_distance_and_duration,
        isIntermediatePage,
        listingAlertData: cabResponse?.listing_alert,
        headerInfoLabel,
        shouldSwapCardsIntermediateScreen,
        show_listing_trip_assistant: response?.show_listing_trip_assistant,
        whatsapp_chat_details: cabResponse?.whatsapp_chat_details
      };
    }
    case constants.ACTION_SET_CAB_LIST_BY_PARTNER: {
      return {
        ...state,
        cabs: action.data,
        previewCabs: action.data,
        displayCabs: action.data,
      };
    }
    case constants.ACTION_UPDATE_FLIGHT_NUMBER: {
      return { ...state, flightNumber: action.data };
    }
    case constants.ACTION_UPDATE_IS_FUTURE_CAB: {
      return { ...state, isFutureCabs: action.data };
    }
    case constants.ACTION_LOADING_CABS: {
      return { ...state, viewState: ViewStates.VIEW_LOADING, cabs: [] };
    }
    case constants.ACTION_ADD_ON_UPDATE_IS_INSTANT_CAB: {
      return { ...state, isInstantCab: action.data };
    }
    case constants.ACTION_TOGGLE_LISTING_WIDGET: {
      return { ...state, showListingWidget: action.data };
    }
    case constants.ACTION_DATE_TIME_CHANGED: {
      const [newDepartDate, newReturnDate] = action.data;
      const travelDateTime = _compact([formatDate(newDepartDate), formatDate(newReturnDate)]);
      return {
        ...state,
        departDate: newDepartDate,
        returnDate: newReturnDate,
        travelDateTime,
      };
    }
    case constants.ACTION_DURATION_FILTER_CHANGED: {
      return {
        ...state,
        activeDurationFilter: action.data,
      };
    }
    case constants.ACTION_LISTING_SHOW_ERROR: {
      const {
        data: { tripChangeMessage = null, errorMessage = null, searchErrorCode = '' },
      } = action;
      return {
        ...state,
        tripChangeMessage,
        errorMessage,
        searchErrorCode,
      };
    }
    case constants.ACTION_LISTING_SET_OFFERS: {
      return {
        ...state,
        listingPageOffers: action.data,
      };
    }
    case constants.ACTION_LISTING_HIDE_ERROR: {
      return {
        ...state,
        tripChangeMessage: null,
        errorMessage: null,
      };
    }
    case constants.ACTION_UPDATE_WALLET_BALANCE: {
      return {
        ...state,
        walletBalance: action.data.walletBalance,
        walletBurnRules: action.data.walletBurnRules,
        walletLoading: false,
      };
    }
    case constants.ACTION_SET_BPG_INFO: {
      return {
        ...state,
        bpgInfo: action.data,
      };
    }
    case constants.ACTION_SET_DISTANCE: {
      return {
        ...state,
        distance: action.data,
      };
    }
    case constants.ACTION_SET_SUPPLIER_ID: {
      return {
        ...state,
        supplier_id: action.data,
      };
    }
    /*-------------------Filters Reducers ----------------*/
    case constants.ACTION_UPDATE_FILTERS: {
      return {
        ...state,
        filters: _cloneDeep(action.data.filters),
        selectedFilters: _cloneDeep(action.data.selectedFilters),
        isFilterApplied: action.data.isFilterApplied,
      };
    }
    case constants.ACTION_UPDATE_SINGLE_GROUP_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.data.group]: {
            ...action.data.filterGroupData,
          },
        },
      };
    }
    case constants.ACTION_APPLY_AND_UPDATE_FILTERS: {
      let toUpdateCabs = state.previewCabs;
      const isCorporateUser = action.data;
      toUpdateCabs = sortCabs(
        toUpdateCabs,
        state.sortingKey || (isCorporateUser ? null : 'LTHPrice'),
      );
      return {
        ...state,
        displayCabs: toUpdateCabs,
      };
    }
    case constants.ACTION_UPDATE_PREVIEW_CABS: {
      return {
        ...state,
        previewCabs: action.data,
      };
    }
    case constants.ACTION_CLEAR_ALL_FILTERS: {
      return {
        ...state,
        filters: _cloneDeep(action.data),
      };
    }
    case constants.ACTION_UPDATE_SORTING_KEY: {
      return {
        ...state,
        sortingKey: action.data,
      };
    }
    /*-------------------Filters Reducers Ends Here ----------------*/
    default: {
      return state;
    }
  }
};

export default reducer;
