import {NativeModules} from 'react-native';

export const getAbConfig = async () => {
  let abConfigStr = window.localStorage.getItem('brc_ab');
  try {
    return JSON.parse(abConfigStr)
  } catch (e) {
    return null;
  }
}

export const getPokusConfig = async () => {
  let abConfigStr = window.localStorage.getItem('cabs_pokus');
  try {
    return JSON.parse(abConfigStr)
  } catch (e) {
    return null;
  }
}

NativeModules.AbConfigModule = {
  getAbConfig,
  getPokusConfig,
};
