import _ from 'lodash';
import { CabsScreen } from '../config/cabsScreen';
import TripType from '../types/TripType';

const _validateLandingData = (data) => {
  if (!data) {
    return false;
  }

  const {
    tripType,
    selectedAirportTypeIndex,
    departDate,
    pickupTime,
    returnDate,
    dropTime
  } = data;
  if (!_.isDate(departDate)) {
    return false;
  }
  if (_.isEmpty(pickupTime) || !_.isString(pickupTime)) {
    return false;
  }
  if (tripType === TripType.RT.value) {
    if (!_.isDate(returnDate)) {
      return false;
    }
    if (_.isEmpty(dropTime) || !_.isString(dropTime)) {
      return false;
    }
  } else if (tripType === TripType.AT.value && !_.isNumber(selectedAirportTypeIndex)) {
    return false;
  }
  return true;
};

const _validateListingData = (data) => {
  const isValid = _validateLandingData(data);
  if (isValid) {
    const {
      fromAddress = null,
      toAddress = null,
      tripType
    } = data;
    if ((_.isEmpty(fromAddress) || (tripType !== TripType.HR.value && _.isEmpty(toAddress)))) {
      return false;
    }
  }
  return isValid;
};

const _validateReviewData = (data) => {
  const isValid = _validateListingData(data);
  if (isValid) {
    if (_.isEmpty(data.cabType)) {
      return false;
    }
  }
  return isValid;
};

const validateDeepLinkData = (data, screen) => {
  let isValid = false;
  switch (screen) {
    case CabsScreen.LANDING:
      isValid = _validateLandingData(data);
      break;
    case CabsScreen.LISTING:
    case CabsScreen.FLIGHTADDON:
      isValid = _validateListingData(data);
      break;
    case CabsScreen.REVIEW:
      isValid = _validateReviewData(data);
      break;
    default: break;
  }
  return isValid;
};

export default validateDeepLinkData;
