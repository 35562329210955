import { DeviceEventEmitter, PermissionsAndroid, Platform } from 'react-native';
import LocationHelperModule from 'src/Native/LocationHelperModule';

export const NO_LOCATION_PERM = 'NO_LOCATION_PERM';
export const PERMISSION_NOT_GRANTED = 'PERMISSION_NOT_GRANTED';
export const GPS_OFF = 'GPS_OFF';

export const isLocationSettingsOn = async () => LocationHelperModule ? LocationHelperModule.isLocationServicesEnabled() : false;

export async function getCurrentLatLng() {
  if (Platform.OS === 'android') {
    return await LocationHelperModule.fetchCurrentLocation();
  } else {
    return await LocationHelperModule.forcedFetchCurrentLocation();
  }
}

export async function ensureLocationPermGranted() {
  const hasPermission = await checkForLocationPermission()
  if (!hasPermission) {
    throw new Error(NO_LOCATION_PERM);
  }
}

// Returns Promise<void>, if perm is not granted the return promise will be rejected
export const requestLocationPerm = async () => {
  if (Platform.OS === "android") {
    const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);
    if (granted !== PermissionsAndroid.RESULTS.GRANTED) {
      throw new Error(PERMISSION_NOT_GRANTED);
    }
  } else {
    return new Promise((resolve, reject) => {
      LocationHelperModule.enableLocationServices();
      const callback = async () => {
        DeviceEventEmitter.removeListener(IOS_FOREGROUND_CALLBACK_EVENT, callback);
        const hasPerm = await LocationHelperModule.isLocationServicesEnabled();
        if (!hasPerm) {
          reject(new Error(NO_LOCATION_PERM));
        } else {
          resolve();
        }
      };
      DeviceEventEmitter.addListener(IOS_FOREGROUND_CALLBACK_EVENT, callback);
    });

  }
}

const IOS_FOREGROUND_CALLBACK_EVENT = 'EVENT_APPLICATON_WILL_ENTER_FOREGROUND';

export async function getLastKnownLatLng() {
  return await LocationHelperModule.fetchLastKnownLocation();
}

export const getCurrentLatLngWithPerm = async () => {
  let latLong;
  try {
    const hasPerm = await checkForLocationPermission();
    if (!hasPerm) {
      const hasPermNow = await requestForLocationPermission();
      if (!hasPermNow) return {};
    }
    latLong = {};
    if (Platform.OS === 'android') {
      latLong = await LocationHelperModule.fetchCurrentLocation();
    } else {
      latLong = await LocationHelperModule.forcedFetchCurrentLocation();
    }
    return latLong;
  } catch (e) {
    return {};
  }
};

export const checkForLocationPermission = async () => {
  let hasPerm = false;
  try {
    if (Platform.OS === 'android') {
      hasPerm = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);
    } else if (LocationHelperModule) {
      hasPerm = await LocationHelperModule.isLocationServicesEnabled();
    }
  } catch (e) {
    hasPerm = false;
  }
  return hasPerm;
};

// FIXME This seems like duplicate to `requestLocationPerm()`. Remove one of these
export const requestForLocationPermission = async () => {
  let hasPerm = false;
  try {
    if (Platform.OS === 'android') {
      hasPerm = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
      );
    } else if (LocationHelperModule) {
      // TODO @anurag.singh does this work fine? earlier code used `DeviceEventEmitter.addListener(IOS_FOREGROUND_CALLBACK_EVENT`
      await LocationHelperModule.enableLocationServices();
      hasPerm = await LocationHelperModule.isLocationServicesEnabled();
    }
  } catch (e) {
    hasPerm = Platform.OS === 'android' ? PermissionsAndroid.RESULTS.NEVER_ASK_AGAIN : false;
  }
  return hasPerm;
};
