
const VOYAGER_ENDPOINT_SEARCH = "https://cabs.makemytrip.com/voyager/search"
const VOYAGER_ENDPOINT_PLACE_DETAIL = "https://cabs.makemytrip.com/fetchLocation"

const _getPlaceDetail = async (url) => {
  let result = [];
  try {
    const response = await fetch(url);
    if (response.ok) {
      const responseBody = await response.json();
      [result] = responseBody.results;
    }
  } catch (e) { //
  }
  return result;
};

const _getServerPlaceDetail = async (url) => {
  let result = {};
  try {
    const response = await fetch(url);
    const responseBody = await response.json();
    if (responseBody.success) {
      result = responseBody.data;
    }
  } catch (e) { //
    return {};
  }
  return result;
};

const _getPrediction = async (query, url) => {
  let result;
  try {
    const response = await fetch(url);
    result = await response.json();
  } catch (e) {
    return [];
  }
  if (!result.success) {
    return [];
  }
  const {data = []} = result;
  return data;
};

export const getNativePredictions = async (query, googleApiKey, path = VOYAGER_ENDPOINT_SEARCH) => {
  const autoCompleteUrl = `${path}?query=${query}`;
  return _getPrediction(query, autoCompleteUrl);
};

export const getNativePredictionsNearByLocation = async (query, location, path = VOYAGER_ENDPOINT_SEARCH) => {
  const autoCompleteUrl = `${path}?query=${query}`;
  return _getPrediction(query, autoCompleteUrl);
};

export const getNativeGeocodes = async (placeId, path = VOYAGER_ENDPOINT_PLACE_DETAIL) => {
  const url = `${path}?place_id=${placeId}`;
  return _getPlaceDetail(url);
};

export const getNativeReverseGeocodes = async (lat, lng, path = VOYAGER_ENDPOINT_PLACE_DETAIL) => {
  const url = `${path}?latlng=${lat},${lng}`;
  return _getPlaceDetail(url);
};
