import { postService } from './api';
import { getUserData } from './BnplUtils';

export const CHECK_ELIGIBILITY_START = '@bnpl/Check Eligibility Start';
export const CHECK_ELIGIBILITY_SUCCESS = '@bnpl/Check Eligibility Success';
export const CHECK_ELIGIBILITY_FAILED = '@bnpl/Check Eligibility Failed';
export const RESET_BNPL = '@bnpl/Reset Bnpl';
export const FORCE_RESET_BNPL = '@bnpl/Force Reset Bnpl';
export const SET_USER_DATA = '@bnpl/Set User Data';
export const RESET_ERROR = '@bnpl/RESET_ERROR';
export const HIDE_WIDGET = '@bnpl/HIDE_WIDGET';

const success = (response) => {
  return {
    type: CHECK_ELIGIBILITY_SUCCESS,
    payload: response,
  };
};

export const hideWidget = () => {
  return {
    type: HIDE_WIDGET
  }
}

const error = (error) => {
  return {
    type: CHECK_ELIGIBILITY_FAILED,
    payload: error,
  };
};

export const resetError = () => (dispatch) => {
  dispatch({type: RESET_ERROR});
}

export const initialise = (forceReset = false) => async (dispatch) => {
  if (forceReset) {
    dispatch({ type: FORCE_RESET_BNPL });
  } else {
    const data = await getUserData();
    if(data.isUserLoggedin) {
      dispatch({ type: RESET_BNPL, payload: data });
    } else {
      dispatch({ type: RESET_BNPL });
      dispatch({ type: SET_USER_DATA, data: null})
    }
  }
};

export const checkBnplEligibility = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: CHECK_ELIGIBILITY_START,
    });
    const data = await postService(payload);
    if (data && data.status) {
      dispatch(success(data));
    } else {
      if (!payload?.memoize) {
        dispatch(error(data));
      } else {
        dispatch({
          type: RESET_BNPL,
        });
      }
    }
  };
};

export const setUserData = () => async (dispatch) => {
  const data = await getUserData();
  dispatch({
    type: SET_USER_DATA,
    data: data,
  });
};
