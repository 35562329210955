import { Dimensions, PixelRatio, Platform } from 'react-native';


const appFonts = {
  black: 'Lato-Black',
  bold: 'Lato-Bold',
  italic: 'Lato-Italic',
  regular: 'Lato-Regular',
  medium: 'Lato-Medium',
  light: 'Lato-Light',
  semiBold: 'Lato-Semibold',
  boldItalic: 'Lato-BoldItalic',
  heavy: 'Lato-Heavy'
};

const webFonts = {
  black: 'Lato-Black',
  bold: 'Lato-Bold',
  italic: 'Lato-Italic',
  regular: 'Lato-Regular',
  medium: 'Lato-Medium',
  light: 'Lato-Light',
  semiBold: 'Lato-SemiBold',
  boldItalic: 'Lato-BoldItalic',
  heavy: 'Lato-Heavy'
};

export const fonts = Platform.OS === 'web' ? webFonts : appFonts;

export const colors = {
  black: '#000000',
  black3: '#242424',
  primary: '#008CFF',
  black1: '#212121',
  white: '#ffffff',
  floralWhite: '#fff9f0',
  textGrey: '#4A4A4A',
  smokeWhite: '#f6f6f6',
  grayBg: '#f2f2f2',
  greyBookedSeat: '#e7e7e7',
  transparent: 'transparent',
  azure: '#008cff',
  azureBlue: '#008bff',
  azureBlue1: '#038cff',
  azureBlue2: '#001F33',
  azureBlue3: '#032134',
  azureBlue4: '#022135',
  azureLight: '#0084ff',
  azureLight2: '#f2a330',
  azureSemi: '#2399ff',
  azureSemiTrans: 'rgba(0,140,255, 0.3)',
  bubbles: '#E8F7FF',
  lightGrey: '#e7e7e7',
  lightGrey1: '#505050',
  lightGrey6: '#f8f8f8',
  lightGrey8: '#e2e2e2',
  lightSilver: '#D8D8D8',
  rippleColor: '#d7d7d7',
  grey: '#7a7a7a',
  grey1: '#7D7D7D',
  grey2: '#f1f1f1',
  grey3: '#f3f3f3',
  grey4: '#d3d3d3',
  grey5: '#f5f5f5',
  grey6: '#d9d9d9',
  grey7: '#f9f9f9',
  grey8: '#ddd',
  grey9: '#d4d4d4',
  grey10: '#afafaf',
  grey11: '#f4f4f4',
  grey12: '#e9e6e6',
  grey13: '#fafafa',
  grey14: '#f7f7f7',
  grey15: '#bfb7b7b7',
  grey16: '#f4ebe5',
  grey17: '#2d2d2d',
  grey18: '#e6e6e6',
  grey19: '#e1e1e1',
  grey21: '#e8e8e8',
  grey23: '#b5b5b5',
  grey24: '#ccc',
  grey25: '#BDBDBD',
  grey26: '#dfdfdf',
  grey27: '#DEDEDE',
  pink1: '#9F0469',
  grayDark: '#363636',
  easternBlue: '#26b5a9',
  cruise: '#c1f1dd',
  defaultTextColor: '#4a4a4a',
  darkGrey: '#292828',
  darkGrey1: '#1f1f1f',
  lightTextColor: '#9b9b9b',
  greyText1: '#757575',
  lighterTextColor: '#a5a5a5',
  lightestTextColor: '#c9c9c9',
  lightSeaGreenTextColor: '#249995',
  lightGreen: '#31a498',
  lightGreen2: '#fbfffe',
  lightGreen3: '#bfe5d9',
  lightGreen4: '#33d18f',
  lightGreen5: '#228b22',
  lightGreen6: '#d4ebea',
  lightGreen7: '#d7f6e9',
  lightGreen8: '#f0fdfc',
  lightGreen9: '#43e1a8',
  lightGreen10: '#219393',
  lightGreen11: '#239f99',
  lightGreenHotel: '#26bd99',
  lightGreen12: '#DFE9E9',
  lightGreen13: '#2D9994',
  lightGreen14: '#E8FFF7',
  lightGreen15: '#007E7D',
  hummingBird: '#cdf6e8',
  redDiscount: '#d3000d',
  red: '#eb2026',
  red2: '#ec1c24',
  red3: '#ffe8e9',
  red4: '#d0021b',
  red5: '#fff6f6',
  red6: '#e53442',
  red7: '#ff6f00',
  red8: '#ff684a',
  red9: '#ff4959',
  red10: '#ff664b',
  red11: '#ff7527',
  red12: '#ffcab5',
  red13: '#FFCCC7',
  red14: '#EB2226',
  red15: '#F36F40',
  red16: '#F85E66',
  red17: '#ec2127',
  red18: '#DA4E52',
  red19: '#FF5D50',
  red20: '#FF3434',
  red21: '#AE3333',
  red22: '#FF7F3F',
  red23: '#FF3E5E',
  lightRed: '#ffe3d5',
  lightRed1: '#fbe6e9',
  lightRed2: '#f06d6d',
  black2f: '#2f2f2f',
  errorRed: '#d0011b',
  errorRed2: '#e62120',
  disabledButton: '#c2c2c2',
  brightGreen: '#ace143',
  brown: '#371F31',
  brown2: '#cf8100',
  veryLightGrey: '#eaeaea',
  darkGrey2: '#979797',
  newGray: '#cbcbcb',
  linkBlue: '#004cde',
  linkBlue2: '#00244d',
  disabledBtnBg: '#9b9b9b',
  purpleyGrey: '#9b9b9b',
  purpleyGrey2: "#6877B5",
  lightPurple: '#053cb2',
  black04: '#040404',
  darkBlue: '#065af3',
  darkBlue1: '#1f3f66',
  darkBlue2: '#1c5185',
  darkBlue3: '#5715f9',
  darkBlue4: '#4a90e2',
  darkBlue5: '#003399',
  darkBlue6: '#1c73f6',
  darkBlue7: '#7FB4FE',
  darkBlue8: '#2F4F6E',
  darkBlue9: '#547898',
  darkBlue10: '#1b269f',
  lightBlue: '#53b2fe',
  lightBlue2: '#e6f4ff',
  lightBlue3: '#c3e4ff',
  lightBlue4: '#b5dcff',
  lightBlue5: '#f9fcff',
  lightBlue6: '#edf7ff',
  lightBlue7: '#edf7ff',
  faintBlue: '#E3F1FF',
  hawkesBlue: '#e1f1fd',
  lightBlue8: '#d8e8f6',
  lightBlue9: '#d4f3fd',
  lightBlue22: '#CCE8FF',
  hawkesBlue: '#e1f1fd',
  lightBlue10: '#f4faff',
  lightBlue11: '#7fc5ff',
  lightBlue12: '#e1e7ee',
  lightBlue13: '#325E93',
  lightBlue14: '#B9D5EC',
  lightBlue15: '#E1F2FF',
  lightBlue16: '#667eea',
  governorBay: '#3023ae',
  seance: '#9416aa',
  lavender: '#c86dd7',
  cabsGreyBg: '#E5E5E5',
  gray1: '#ededed',
  gray2: '#f0f0f0',
  gray3: '#cccccc',
  gray4: '#c7c7c7',
  gray5: "#EDEDF2",
  gray6: '#EFF3F8',
  gray7: '#d1d1d1',
  yellow: '#f2b343',
  yellow1: '#f5a623',
  yellow2: '#f0772c',
  yellow3: '#fef8e5',
  yellow4: '#f95776',
  yellow5: '#ffcf03',
  yellow6: '#fcdd5f',
  goldenYellow: '#f3d452',
  lightYellow: '#f2b635',
  lightYellow1: '#FFF0E5',
  goldenYellow2: '#f9d71a',
  goldenYellow3: '#fff3d1',
  goldenYellow5: '#f27a20',
  goldenYellow6: '#f2771a',
  goldenYellow7: '#f17530',
  goldenYellow8: '#ff6544',
  goldenYellow9: '#fe664b',
  goldenYellow10: '#f27337',
  goldenYellow11: '#f85a6e',
  goldenYellow12: '#ffcc00',
  lightGoldenYellow1: '#fdf1e8',
  lightGoldenYellow2: '#fad5c0',
  lightGoldenYellow: '#fce4d1',
  lightGoldenYellow3: '#fff9f7',
  deepBlue: '#3b385e',
  deepBlue1: '#135AFF',
  lighterBlue: '#eaf5ff',
  lightestBlue: '#e7f3fe',
  skyBlue: '#def0ff',
  skyBlue1: '#3a7bd5',
  skyBlue2: '#00d2ff',
  skyBlue3: 'rgba(180,220,250,0.5)',
  skyBlue4: '#DDE8FF',
  skyBlue5: '#D4EBFE',
  skyBlue6: '#ecf7ff',
  lightWhite: 'rgba(255,255,255,0.7)',
  lightBlack: 'rgba(0,0,0,0.7)',
  lightGrey2: 'rgba(151,151,151,0.16)',
  black28: '#282828',
  black29: '#0F0F0F',
  black54: '#545454',
  lightBlack1: '#AF0067',
  lightBlack2: '#3A3838',
  pink: '#ff40bf',
  pink2: '#ffe0db',
  pink3: '#FFDED9',
  darkPink: '#fc4192',
  greyLight: '#747474',
  modalgrey: 'rgba(0,0,0,0.7)',
  dividerGrey: '#ededed',
  radicalRed: '#ff3e5e',
  brightOrange: '#f2c544',
  brightOrange2: '#FDF0EC',
  orange: '#ff7f3f',
  orange2: '#ef9749',
  orange3: '#F17531',
  orange4: '#ff674b',
  orange5: '#FFEAE1',
  greyOrange: '#ECDBC1',
  padua: '#EBD1BF',
  strongOrange: '#cc7f01',
  yello: '#f09819',
  cyan: '#00a19c',
  softCyan: '#B6ECE2',
  lightCyan: '#c8f8f6',
  darkCyan: '#1F4941',
  lightPink: '#ffd3d4',
  lightYello: '#cf8100',
  creamWhite: '#ffedd1',
  creamWhite2: '#fffaf2',
  creamWhite3: '#ffecd1',
  creamWhite4: '#fff5ea',
  creamWhite5: '#ffe4c7',
  creamWhite6: '#ffecef',
  creamWhite7: '#fffbf9',
  lightGray: '#d8d8d8',
  green: '#1a7971',
  green1: '#5e9a1a',
  green2: '#3a7771',
  green3: '#02c674',
  aquamarine: '#A0FFD7',
  cngColor1: '#43e1a8',
  cngColor2: '#28beb2',
  lightGrey3: '#e4e4e4',
  darkGrey3: '#6f6f6f',
  lightGrey4: '#666666',
  cardBorderColor: 'rgba(0,0,0,0.1)',
  lightGrey5: '#e7f1fd',
  lightGrey7: '#C4C4C4',
  successGreen: '#26b5a9',
  referralGold: '#ff9f00',
  activeBg: '#f1f8fe',
  activeBorder: '#d1e2f2',
  darkGreyish: '#484848',
  greyish: '#6d7278',
  faintgrey: '#dddddd',
  buttonBlue: '#008CBA',
  palePink: '#fedcde',
  purple: '#b979c6',
  purple2: '#613393',
  green2: '#93d51f',
  green3: '#209593',
  green4: '#cedcc5',
  green5: '#35CFAD',
  green6: '#23A596',
  lightGrey9: '#F4F9FF',
  lightGrey10: '#E9F3FF',
  lightGrey11: '#AAAAAA',
  lightGrey12: '#BCBCBC',
  lightGrey13: '#FBFBFB',
  lightGrey14: '#bbbbbb',
  lightGrey15: '#CDCDCD',
  lightGrey16: 'rgba(245, 245, 245, 0.896812)',
  lightPurple1: '#F4F2FF',
  darkCharcoal: '#333',
  greyBrdr1: '#c4c4c4',
  headerGradientStart: '#6E0D1D',
  inputActiveBrdr: '#EEC7AF',
  greyBrdr2: '#EFEFEF',
  royalBlue: '#3380E5',
  selago: '#E7F2FC',
  limeGreen: '#d1f8e6',
  deepRed: '#A9112F',
  darkGrey4: '#222',
  darkBrown: '#955000',
  cyan2: '#b6ebe2',
  cyan3: '#2276E3',
  darkGreen: '#1F4941',
  paleBlue: '#F7FBFF',
  gainsBoro: '#dcdcdc',
  skeltonGradientStartColor: '#eeeeee',
  skeltonGradientEndColor: '#d8d8d8',
  lightViolet: '#994BFF',
  grey22: '#fff',
  grey20: 'rgba(0,0,0,0.5)',
  black2: '#f2f9ff',
  blackRussian: '#141823',
  orangeLight: '#ffe8d9',
  whiteTransparent: '#ffffff29',
  pinkTransparent: '#b42b7a14',
  lightBrown: '#773D3C',
  darkBrown1: '#3E322B',
  borderGray: '#DBDBDB',
  fadeBlack: '#00000080',
  darkBrown1: '#3E322B',
  borderGray: '#DBDBDB',
  blackStartColor: '#1F1c2c',
  blackEndColor: '#928dab',
  lightRed3: '#fd0c0c',
  lightBlue17: '#DCE3EB',
  darkShade: '#222222',
  lightShade: '#777777',
  darkBrown2: '#794800',
  "4d4d4d": "#4D4D4D",
  "343434": "#343434",
  darkGrey5: "#263238",
  lightBlue18:'#cce8ff'
};

export const STATUS_BAR_HEIGHT = 20;
const pixelRatio = PixelRatio.get();
export const deviceWidth = Dimensions.get('screen').width;
const deviceHeight = Dimensions.get('screen').height;

/* Normalise 3x/XXHDPI font and return appropriate size of other DPI phones */
export const normalisePx = (pixels) => {
  if (Platform.OS !== 'ios') return pixels;
  return PixelRatio.getPixelSizeForLayoutSize(PixelRatio.roundToNearestPixel(pixels / 3));
};

export const normaliseFont = (size) => {
  if (Platform.OS !== 'ios') return size;
  // IPhoneX
  if (deviceHeight === 812) {
    return size;
  }
  if (pixelRatio === 2) {
    // iphone 5s and older Androids
    if (deviceWidth < 360) {
      return size * 0.95;
    }
    // iphone 5
    if (deviceHeight < 667) {
      return size;
      // iphone 6-6s
    } else if (deviceHeight >= 667 && deviceHeight <= 735) {
      return size * 1.15;
    }
    // older phablets
    return size * 1.25;
  }
  if (pixelRatio === 3) {
    // catch Android font scaling on small machines
    // where pixel ratio / font scale ratio => 3:3
    if (deviceWidth <= 360) {
      return size;
    }
    // Catch other weird android width sizings
    if (deviceHeight < 667) {
      return size * 1.15;
      // catch in-between size Androids and scale font up
      // a tad but not too much
    }
    if (deviceHeight >= 667 && deviceHeight <= 736) {
      return size * 1.2;
    }
    // catch larger devices
    // ie iphone 6s plus / 7 plus / mi note 等等
    return size * 1.27;
  }
  if (pixelRatio === 3.5) {
    // catch Android font scaling on small machines
    // where pixel ratio / font scale ratio => 3:3
    if (deviceWidth <= 360) {
      return size;
      // Catch other smaller android height sizings
    }
    if (deviceHeight < 667) {
      return size * 1.20;
      // catch in-between size Androids and scale font up
      // a tad but not too much
    }
    if (deviceHeight >= 667 && deviceHeight <= 735) {
      return size * 1.25;
    }
    // catch larger phablet devices
    return size * 1.40;
  }
  // if older device ie pixelRatio !== 2 || 3 || 3.5
  return size;
};

export const isIPhoneX = Platform.OS === 'ios' && deviceHeight >= 812;
export const statusBarHeightForIphone = Platform.select({
  ios: isIPhoneX ? 50 : 20,
  android: 0
});
export const statusBarBootomHeightForIphone = Platform.select({
  ios: isIPhoneX ? 34 : 0,
  android: 0
});

export const fontSizes = {
  xs: 9,
  sm: 11,
  reg: 12,
  md: 14,
  lg: 16,
  xl: 18,
  xxl: 24,
  xxxl: 32
};
