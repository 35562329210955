import {CabsPdtConstants} from './CabsPdtConstants';
import CabsPdtListingEvent from '../PdtModel/PdtEvents/CabsPdtListingEvent';
import {pushPdtData} from '../../api/cabsApis';

export default class CabsPdtListingHelper {
  static trackPageLoad(data) {
    try {
      const pdtData = data;
      pdtData.activityName = CabsPdtConstants.BaseEventAction.LOAD_PAGE;
      const pdtObject = new CabsPdtListingEvent(pdtData).trackListingEvent();
      pushPdtData(pdtObject);
    } catch (e) {
      // ignore
    }
  }

  static trackPageExit(data) {
    try {
      const pdtData = data;
      pdtData.activityName = CabsPdtConstants.BaseEventAction.EXIT_PAGE;
      const pdtObject = new CabsPdtListingEvent(pdtData).trackListingEvent();
      pushPdtData(pdtObject);
    } catch (e) {
      // ignore
    }
  }

  static trackBackPressed(data) {
    try {
      const pdtData = data;
      pdtData.activityName = CabsPdtConstants.BaseEventAction.CLICK_BACK;
      const pdtObject = new CabsPdtListingEvent(pdtData).trackListingEvent();
      pushPdtData(pdtObject);
    } catch (e) {
      // ignore
    }
  }

  static trackCabClicked(data) {
    try {
      const pdtData = data;
      pdtData.activityName = CabsPdtConstants.ListingEventAction.CLICK_CAB;
      const pdtObject = new CabsPdtListingEvent(pdtData).trackListingEvent();
      pushPdtData(pdtObject);
    } catch (e) {
      // ignore
    }
  }
}
