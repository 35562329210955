import _replace from 'lodash/replace';
import _isEmpty from 'lodash/isEmpty';
import { Actions } from 'src/Routes/Cabs/Navigation';
import { Keyboard, Linking, Platform } from 'react-native';
import fecha from 'fecha';

import { sleep } from 'src/Helpers/misc';
import { isNetworkAvailable } from 'src/Common/utils/AppUtils';
import cabsConfig from '../cabsConfig';
import { diffHours, now } from '../../../Helpers/dateHelpers';
import {
  adjustHourToSlot,
  getDateFromDateTime,
  getNearestNextSlotForTripType,
  getNearestReturnDate,
} from '../cabsDateTimeHelpers';
import { getRecentSearches } from '../utils/cabsLocalStore';
import * as cabsListingActions from '../Listing/cabsListingActions';
import { setCabRequest } from '../Listing/cabsListingActions';
import {
  eventHeaderForTripType,
  setReceiverSourceAndCmp,
  setUserDetailsInCabsAnalytics,
  trackLandingAbEvent,
  trackLandingEvent,
  trackUniversalSearchEvent,
  cabsSource,
  setIsLoggedInTracker
} from '../cabsAnalytics';
import { initWithDates } from '../Calendar/cabsCalendarActions';
import { updateSelectedHRPackage } from '../Listing/cabsListingActions';
import TripType, { AirportPickupType, TravelType } from '../types/TripType';
import { getUserBookingDetails } from '../../../Native/UpcomingTripsModule';
import OffersModule from '../../../Native/OffersModule';
import GenericModule, { updateRecentSearchHistory } from 'src/Native/GenericModule';
import { initCityPicker, setCityPickerLocation } from './Components/CityPickerAction';
import { getAirportTypeFromCityCode, getSearchData, validateSearchRequest } from '../utils/cabsSearchUtil';
import { showShortToast } from '../../../Common/Components/Toast';
import { getLocationFromCityCode } from './cabsPlaces';
import { processDeepLinkData } from '../DeepLink/DeepLinkManager';
import { initCabs } from '../cabsInit';
import CabsABConfigHelper from '../utils/CabsABConfigHelper';
import CabsPdtLandingHelper from '../PdtAnalytics/PdtHelper/CabsPdtLandingHelper';
import { getCommonHeaders } from '../PdtAnalytics/PdtHelper/CabsPdtUtils';
import {
  getDeviceDetails,
  getNativeUserDetails,
  updateDeviceDetails,
  updateUserDetails,
} from '../cabsCommonActions';
import promiseAlert from '../../../Common/Components/Alert/promiseAlert';
import { getComIds, getQuickBookCabs, getRoutePlannerResponse, initialDeeplinkByBookingId } from '../api/cabsApis';
import CabOmnitureEvent from '../cabsOmnitureEvents';
import { CABS_DATE_FMT, CABS_TIME_FMT, DeeplinkParentScreen, LOCAL_NOTIFICATION_TIMESTAMP, TRAVEL_TYPE_OFFERS_KEYS } from '../cabsConstants';
import { isInstantAvailable } from '../cabsDynamicConfig';
import { getUserDetails, isCorporateUser, ProfileType } from '../../../Native/UserSession/UserSessionModule';
import { getDataFromStorage } from '../../../LocalStorage';
import { VAR_REFERRER } from '../../../Common/utils/OmnitureTrackerUtils';
import { getPokusConfigWaitingPromise } from 'src/Native/AbConfig/AbConfigModule';
import { isPhonePePlatform } from "../utils/cabsCommonUtils";
import { getOffersFromSkyWalker } from 'src/Native/SkywalkerOffers';
import {
  addStop,
  deleteStop,
  swapSearch,
  updateLocation,
  updateStopLocation,
  updateTravelType,
  updateTripDate,
  updateTripInfo,
  updateTripType,
} from '../cabsTripInfoAction';
import { getTravelTypeFromTripType, getTripDataFromState } from '../utils/cabsCommonUtils';
import { CabsScreen } from '../config/cabsScreen';

const whiteBackIcon = require('../../../Assets/white_backarrow.webp');

export const BACK_CLICK_ACTION = 'BACK_CLICK_ACTION';
export const ACTION_SHOW_NO_NETWORK = 'ACTION_SHOW_NO_NETWORK';
export const ACTION_TRIP_TYPE = 'ACTION_TRIP_TYPE';
export const ACTION_TRAVEL_TYPE = 'ACTION_TRAVEL_TYPE';
export const ACTION_SHOW_TOOLTIP = 'ACTION_SHOW_TOOLTIP';
export const ACTION_HIDE_TOOLTIP = 'ACTION_HIDE_TOOLTIP';
export const ACTION_SET_RECENT_SEARCHES = 'ACTION_SET_RECENT_SEARCHES';
export const ACTION_SET_TEXT_POSITION = 'ACTION_SET_TEXT_POSITION';
export const ACTION_CLEAR_TEXT_POSITION = 'ACTION_CLEAR_TEXT_POSITION';
export const ACTION_AUTO_COMPLETE_RESULTS_SELECT = 'ACTION_AUTO_COMPLETE_RESULTS_SELECT';
export const ACTION_AUTO_COMPLETE_RESULTS = 'ACTION_AUTO_COMPLETE_RESULTS';
export const ACTION_LOCATION_LOADING = 'ACTION_LOCATION_LOADING';
export const ACTION_LOCATION_ERROR = 'ACTION_LOCATION_ERROR';
export const ACTION_LOCATION_SELECT = 'ACTION_LOCATION_SELECT';
export const ACTION_SET_LAST_KNOWN_LOCATION = 'ACTION_SET_LAST_KNOWN_LOCATION';
export const ACTION_RESET_CABS_LANDING = 'ACTION_RESET_CABS_LANDING';
export const ACTION_SWAP_SEARCH = 'ACTION_SWAP_SEARCH';
export const ACTION_SHOW_ERROR = 'ACTION_SHOW_ERROR';
export const ACTION_HIDE_ERROR = 'ACTION_HIDE_ERROR';
export const ACTION_LANDING_DATE_CHANGED = 'ACTION_LANDING_DATE_CHANGED';
export const ACTION_UPDATE_DEFAULT_PICKUP = 'ACTION_UPDATE_DEFAULT_PICKUP';
export const ACTION_UPDATE_AIRPORT_PICKUP_TYPE = 'ACTION_UPDATE_AIRPORT_PICKUP_TYPE';
export const ACTION_SET_DEEPLINK_DATA = 'ACTION_SET_DEEPLINK_DATA';
export const ACTION_SHOW_PAGE_LOADING = 'ACTION_SHOW_PAGE_LOADING';
export const ACTION_SET_AIRPORT_SUGGESTION_DATA = 'ACTION_SET_AIRPORT_SUGGESTION_DATA';
export const ACTION_FILL_AIRPORT_SUGGESTION_DATA = 'ACTION_FILL_AIRPORT_SUGGESTION_DATA';
export const ACTION_UPDATE_SELECTED_AIRPORT_INDEX = 'ACTION_UPDATE_SELECTED_AIRPORT_INDEX';
export const ACTION_UPDATE_AIRPORT_TOOL_TIP_STATUS = 'ACTION_UPDATE_AIRPORT_TOOL_TIP_STATUS';
export const ACTION_LANDING_ADDRESS_CHANGED = 'ACTION_LANDING_ADDRESS_CHANGED';
export const ACTION_UPDATE_DATE_SELECTED_STATE = 'ACTION_UPDATE_DATE_SELECTED_STATE';
export const ACTION_SET_FLIGHT_ADDON_STATE = 'ACTION_SET_FLIGHT_ADDON_STATE';
export const ACTION_SET_FLIGHT_NUMBER = 'ACTION_SET_FLIGHT_NUMBER';
export const ACTION_UPDATE_TRAVEL_TYPE = 'ACTION_UPDATE_TRAVEL_TYPE';
export const ACTION_UPDATE_RECENT_SEARCH = 'ACTION_UPDATE_RECENT_SEARCH';
export const ACTION_SET_LANDING_LOCATION = 'ACTION_SET_LANDING_LOCATION';
export const ACTION_LANDING_SOURCE_CLEAR = 'ACTION_LANDING_SOURCE_CLEAR';
export const ACTION_LANDING_DESTINATION_CLEAR = 'ACTION_LANDING_DESTINATION_CLEAR';
export const ACTION_LANDING_ADDITIONAL_CITY_CLEAR = 'ACTION_LANDING_ADDITIONAL_CITY_CLEAR';
export const ACTION_LANDING_ROUTE_PLANER_STATE = 'ACTION_LANDING_ROUTE_PLANER_STATE';
export const ACTION_LANDING_SET_OFFERS = 'ACTION_LANDING_SET_OFFERS';
export const ACTION_LANDING_OFFERS_CLICK = 'ACTION_LANDING_OFFERS_CLICK';
export const ACTION_UPDATE_IS_INSTANT_APPLICABLE = 'ACTION_UPDATE_IS_INSTANT_APPLICABLE';
export const ACTION_UPDATE_IS_NEW_AIRPORT_FLAG = 'ACTION_UPDATE_IS_NEW_AIRPORT_FLAG';
export const ACTION_SET_TRAVEL_TYPE = 'ACTION_SET_TRAVEL_TYPE';
export const ACTION_REMOVE_SELF_DRIVE_FROM_TRAVEL_TYPES = 'ACTION_REMOVE_SELF_DRIVE_FROM_TRAVEL_TYPES';
export const ACTION_SET_PRIMARY_PAX_ERROR = 'ACTION_SET_PRIMARY_PAX_ERROR';
export const ACTION_UPDATE_ACTIVE_TRAVEL_TYPES = 'ACTION_UPDATE_ACTIVE_TRAVEL_TYPES';
export const ACTION_SET_CAB_CONFIG_DEFAULT_DATE_TIME = 'ACTION_SET_CAB_CONFIG_DEFAULT_DATE_TIME';
export const ACTION_SET_RIDE_UNAVAILABLE_MSG = 'ACTION_SET_RIDE_UNAVAILABLE_MSG';
export const ACTION_SHOW_QUICK_BOOK_LOADER = 'ACTION_SHOW_QUICK_BOOK_LOADER'
export const ACTION_SET_SOURCE_CMP_DATA = 'ACTION_SET_SOURCE_CMP_DATA';

export const SCHEDULE_OPTIONS = {
  SCHEDULE: 'SCHEDULE',
  INSTANT: 'INSTANT'
};

export const ADD_NEW_CITY = -1;

export const VIEW_STATE = {
  LOADING: 'LOADING',
  INITIAL: 'INITIAL',
  NO_NETWORK: 'NO_NETWORK'
};

// action creators
export const showNoNetworkAction = hasNetwork => ({
  type: ACTION_SHOW_NO_NETWORK,
  data: hasNetwork
});

export const showPageLoading = isLodaing => ({
  type: ACTION_SHOW_PAGE_LOADING,
  data: isLodaing
});

export const updateRoutePlannerState = state => ({
  type: ACTION_LANDING_ROUTE_PLANER_STATE,
  data: state
});

export const backClickAction = hardwareBack => ({
  type: BACK_CLICK_ACTION,
  data: hardwareBack
});

export const fillAirportSuggestion = data => ({
  type: ACTION_FILL_AIRPORT_SUGGESTION_DATA,
  data
});

export const updateLandingAddress = (sourceData, destinationData, additionalCityData, airportIndex) => (dispatch) => {
  dispatch({
    type: ACTION_LANDING_ADDRESS_CHANGED,
    data: {
      sourceData,
      destinationData,
      additionalCityData,
      airportIndex,
    }
  })
};

export const updateAirportToolTipStatus = status => ({
  type: ACTION_UPDATE_AIRPORT_TOOL_TIP_STATUS,
  data: status
});

export const landingAddressChanged = (sourceData, destinationData, additionalCityData) => (
  dispatch,
  getState,
) => {
  const { selectedAirportTypeIndex } = getTripDataFromState(getState());
  dispatch(
    updateLocation({
      sourceData,
      destinationData,
      airportIndex: selectedAirportTypeIndex,
    }),
  );
};

export const sourceClearAction = () => (dispatch) => {
  dispatch({
    type: ACTION_LANDING_SOURCE_CLEAR
  });
};

export const destinationClearAction = () => (dispatch) => {
  dispatch({
    type: ACTION_LANDING_DESTINATION_CLEAR
  });
};

export const additionalCityClearAction = index => (dispatch) => {
  dispatch({
    type: ACTION_LANDING_ADDITIONAL_CITY_CLEAR,
    data: index
  });
};

export const removeSelfDriveFromTravelType = () => (dispatch) => {
  dispatch({
    type: ACTION_REMOVE_SELF_DRIVE_FROM_TRAVEL_TYPES
  });
}

export const setPrimaryPaxError = (isError) => (dispatch) => {
  dispatch({
    type: ACTION_SET_PRIMARY_PAX_ERROR,
    data: isError
  })
}

export const setTravelType = (travelType) => (dispatch) => {
  dispatch(updateTravelType(travelType));
}

export const setCabConfigDefaultDateTime = (date, time) => (dispatch) => {
  dispatch({
    type: ACTION_SET_CAB_CONFIG_DEFAULT_DATE_TIME,
    data: {
      date,
      time,
    },
  });
};

export const setRideNotAvailableError = (err) => (dispatch) => {
  dispatch({
    type: ACTION_SET_RIDE_UNAVAILABLE_MSG,
    data: err
  })
}

const _uploadDeeplinkParams = (data) => (dispatch) => {
  const {
    tripType,
    fromAddress,
    toAddress,
    selectedAirportTypeIndex,
    departDate,
    returnDate,
    packageKey,
  } = data;

  const tripData = {
    sourceData: fromAddress,
    destinationData: toAddress,
    departDate,
    returnDate,
    selectedAirportTypeIndex,
    selectedHRPackage: packageKey,
  };
  if (fromAddress || toAddress) {
    dispatch(updateTripInfo(tripType, tripData));
    dispatch(updateTripType(tripType));
  }
};

export const setDeepLinkData = (deepLinkData, userDetails = null, isFromLanding = false) => async (dispatch, getState) => {
  dispatch(showPageLoading(true));
  const {
    tripType = TripType.OW.value,
    cityCode = '',
    flightBookingId = '',
    cityLocus = ''
  } = deepLinkData;
  let {
    searchRequest = {}
  } = getState().cabsListing;
  const { allTravelTypes } = getState().cabs;
  let { selectedAirportTypeIndex: airportTypeFromProps } = getState().cabsLanding;
  airportTypeFromProps = airportTypeFromProps ? AirportPickupType.FROM.name : AirportPickupType.TO.name;
  const requestDetail = await processDeepLinkData(deepLinkData, allTravelTypes, userDetails, isFromLanding);
  let deeplinkDataFromBookingId = null;

  let {
    isValid,
    departDate,
    selectedAirportTypeIndex,
    travelType,
    source='',
    cmp = '',
  } = requestDetail;
  if (isValid) {
    if (flightBookingId) {
      deeplinkDataFromBookingId = await initialDeeplinkByBookingId(flightBookingId);
      departDate = getDateFromDateTime(deeplinkDataFromBookingId.pickup_date, deeplinkDataFromBookingId.pickup_time);
      selectedAirportTypeIndex = !_isEmpty(deeplinkDataFromBookingId.airport_pickup_type) ? (deeplinkDataFromBookingId.airport_pickup_type.toLowerCase() === AirportPickupType.FROM.name ? 1 : 0) : selectedAirportTypeIndex;
    }

    dispatch(travelTypeChangeAction(travelType));
    try {
      if (getState().cabsLanding.selectedAirportTypeIndex !== selectedAirportTypeIndex &&
        deepLinkData.parentScreen === DeeplinkParentScreen.UNIVERSAL_SEARCH && tripType === TripType.AT.value
      ) {
        dispatch(onSearchSwapClicked(false));
      }
    } catch (e) {
      //  Ignore exception
    }
    if (!Boolean(departDate)) {
      departDate = getState().cabsLanding.departDate;
    }
    dispatch({
      type: ACTION_SET_DEEPLINK_DATA,
      data: {
        deepLinkData,
        departDate,
        tripType,
        selectedAirportTypeIndex
      }
    });
    setReceiverSourceAndCmp(source, cmp);
    const sourceCmp = {
      source,
      cmp
    }
    dispatch(setSourceAndCmp(sourceCmp));

    dispatch(_uploadDeeplinkParams(requestDetail));

    if (deepLinkData && ((deepLinkData.parentScreen === DeeplinkParentScreen.FIS_LANDING_PAGE) || (deepLinkData.parentScreen === DeeplinkParentScreen.UNIVERSAL_SEARCH))) {
      dispatch(showPageLoading(false));
      return;
    }
    if (tripType === TripType.AT.value) {
      if (!(deeplinkDataFromBookingId)) {
        if (!_isEmpty(cityCode) || !_isEmpty(cityLocus)) {
          const sendDeeplinkData = getState().cabsLanding.deepLinkData;
          fetchLocationData(sendDeeplinkData).then((dataToSend) => {
            dispatch(setLocationInfo(dataToSend));
          });
        } else {
          dispatch(fetchUpcomingTrips());
        }
        searchRequest = { ...searchRequest, is_instant_search: false };
        dispatch(cabsListingActions.updateSearchRequest(searchRequest));
        //TODO: generalize in next version
        // if ((deepLinkData && ((deepLinkData.parentScreen !== DeeplinkParentScreen.FLIGHT_TRIP_DETAILS)))) {
        //   dispatch(openCityPicker());
        // }
      } else {
        if (!_isEmpty(deeplinkDataFromBookingId.location)) {
          searchRequest = { ...searchRequest, is_instant_search: !!deeplinkDataFromBookingId.is_instant_cab };
          dispatch(cabsListingActions.updateSearchRequest(searchRequest));
          dispatch(cabsListingActions.setFlightBookingId(flightBookingId));
          if (deeplinkDataFromBookingId.flight_number) {
            dispatch(cabsListingActions.setFlightNumberDetail(deeplinkDataFromBookingId.flight_number));
          }
          if (deeplinkDataFromBookingId.is_instant_cab) {
            trackLandingAbEvent('AT_Instant_Funnel');
          } else {
            trackLandingAbEvent('AT_Short_Funnel');
          }
          let dataToSend = {
            sourceData: null,
            destinationData: null,
            sourceText: '',
            destinationText: '',
            selectedAirportTypeIndex: 0
          };
          dataToSend.selectedAirportTypeIndex = selectedAirportTypeIndex;
          if (dataToSend.selectedAirportTypeIndex) {
            dataToSend.sourceData = deeplinkDataFromBookingId.location;
            dataToSend.sourceText = deeplinkDataFromBookingId.location.address;
          } else {
            dataToSend.destinationData = deeplinkDataFromBookingId.location;
            dataToSend.destinationText = deeplinkDataFromBookingId.location.address;
          }
          dispatch(openCityPicker());
          dispatch(setLocationInfo(dataToSend));
        }
      }
    }
  }
  dispatch(showPageLoading(false));
};

export const travelTypeChangeAction = (travelType) => (dispatch, getState) => {
  const { selectedHRCabPackage, hrPackages } = getState().cabsListing;
  const travelTypeName = _replace(travelType, ' ', '');
  trackLandingEvent(`Mob_Cabs_Landing_${travelTypeName}_Click`);
  if (travelType === TravelType.RENTALS.name && !Boolean(selectedHRCabPackage)) {
    dispatch(updateSelectedHRPackage(hrPackages?.[0]?.package_id, true));
  }
  dispatch(updateTravelType(travelType));
};

export const tripTypeChangeAction = (tripType, shouldTrackEvent = true) => (dispatch, getState) => {
  if (shouldTrackEvent) {
    switch (tripType) {
      case TripType.OW.value:
        trackLandingEvent('Mob_Cab_Landing_One_Way_Click');
        break;
      case TripType.RT.value:
        trackLandingEvent('Mob_Cab_Landing_Round_Trip_Click');
        break;
      case TripType.AT.value:
        trackLandingEvent('Mob_Cabs_Landing_AirportTransfers_Click');
        break;
      case TripType.HR.value:
        trackLandingEvent('Mob_Cabs_Landing_HourlyRental_Click');
        break;
      default:
        break;
    }
  }
  dispatch(updateTripType(tripType));
};

export const landingTravelTypeChangeAction = (segment, index) => async (dispatch) => {
  if (Platform.OS === 'web') {
    if (segment === TravelType.OUTSTATION.name) {
      window.history.replaceState(null, document.title, '/?tripType=OS')
    } else if (segment === TravelType.AIRPORT.name) {
      window.history.replaceState(null, document.title, '/?tripType=AT')
    } else if (segment === TravelType.RENTALS.name) {
      window.history.replaceState(null, document.title, '/?tripType=HR')
    }
  }
  if (segment === TravelType.SELF_DRIVE.name) {
    trackLandingEvent('Mob_Cab_Landing_Self_Drive_Click');
    if (Platform.OS === 'web') {
      window.location = '/self-drive';
    } else {
      null;
      //SDActions.openSDLanding();
      // GenericModule.openDeepLink('mmyt://react/?page=self-drive');  
    }
    return;
  }
  dispatch(travelTypeChangeAction(segment));
  if (segment === TravelType.AIRPORT.name) {
    dispatch(fillAirportSuggestion({}));
  }
};

export const landingTripTypeChange = (tripType, shouldTrackEvent = true) => (dispatch) => {
  dispatch(tripTypeChangeAction(tripType, shouldTrackEvent));
  if (tripType === TripType.AT.value) {
    dispatch(fillAirportSuggestion({}));
  }
};

export const setFlightAddOnState = isFlightAddOn => (dispatch) => {
  dispatch({
    type: ACTION_SET_FLIGHT_ADDON_STATE,
    data: {
      isFlightAddOn
    }
  });
};

export const openCityPicker = () => (dispatch, getState) => {
  const { tripType, sourceData, destinationData, selectedAirportTypeIndex, stops = [] } = getTripDataFromState(
    getState(),
  );
  const destFocused = !_isEmpty(sourceData);
  const showLoading = true;
  const showToolTip = true;
  const request = {
    tripType,
    sourceData,
    destinationData,
    destFocused,
    selectedAirportTypeIndex,
    handleAddressChange: _handleAddressChange,
    screenName: CabsScreen.LANDING,
    showLoading,
    handleBackPress: _handleBackPress,
    showToolTip,
    showSingleInputView: stops?.length > 0,
  };
  dispatch(initCityPicker(request));
  Actions.cabsCityPicker();
};

export const sourceClickAction = () => (dispatch, getState) => {
  const { errorMessage = null } = getState().cabsLanding;
  const { showListingWidget = false } = getState().cabsListing;
  const {
    tripType,
    sourceData,
    destinationData,
    selectedAirportTypeIndex,
    stops,
  } = getTripDataFromState(getState());
  if (showListingWidget) {
    trackUniversalSearchEvent('Mob_Cab_From_Select');
  } else {
    sendLandingEventForType(tripType, LandingEvent.PickupClick);
  }
  const isMulticityTrip = stops.length > 0;
  const request = {
    tripType,
    sourceData,
    destinationData,
    destFocused: false,
    selectedAirportTypeIndex,
    handleAddressChange: isMulticityTrip ? _handleSourceChange : _handleAddressChange,
    showSingleInputView: stops?.length > 0,
  };
  if (errorMessage) {
    dispatch(hideError);
  }
  dispatch(initCityPicker(request));
  Actions.cabsCityPicker();
};

export const destinationClickAction = () => (dispatch, getState) => {
  const { additionalCityData, errorMessage = null } = getState().cabsLanding;
  const {
    tripType,
    sourceData,
    destinationData,
    selectedAirportTypeIndex,
    stops,
  } = getTripDataFromState(getState());
  const {
    showListingWidget = false
  } = getState().cabsListing;
  if (showListingWidget) {
    trackUniversalSearchEvent('Mob_Cab_To_Select');
  } else {
    sendLandingEventForType(tripType, LandingEvent.DestinationClick);
  }
  let request = {};
  if (stops.length > 0) {
    request = {
      tripType,
      sourceData: destinationData,
      sourceFocused: true,
      handleAddressChange: _handleDestinationChange,
      showSingleInputView: true,
      sourceTitle: 'To',
    };
  } else {
    request = {
      tripType,
      sourceData,
      destinationData,
      additionalCityData,
      destFocused: true,
      selectedAirportTypeIndex,
      handleAddressChange: _handleAddressChange,
      additionalCityIndex: _isEmpty(additionalCityData) ? 0 : additionalCityData.length,
    };
  }

  if (errorMessage) {
    dispatch(hideError);
  }
  dispatch(initCityPicker(request));
  Actions.cabsCityPicker();
};


export const onStopClicked = (id) => (dispatch, getState) => {
  const { tripType, stops } = getTripDataFromState(getState());
  const { errorMessage = null } = getState().cabsLanding;
  const stopIndex = stops.findIndex((item) => item.id === id);
  const stopData = stops[stopIndex];
  const request = {
    tripType,
    showSingleInputView: true,
    sourceData: stopData.location,
    handleAddressChange: (request) => dispatch1 => {
      dispatch(_handleAddressChangeForStops(request, id));
    },
    sourceTitle: `STOP ${stopIndex + 1}`,
  };
  if (errorMessage) {
    dispatch(hideError);
  }
  dispatch(initCityPicker(request));
  Actions.cabsCityPicker();
  console.log(stopData);
};

export const _addStop = () => (dispatch, getState) => {
  const { errorMessage = null } = getState().cabsLanding;
  if (errorMessage) {
    dispatch(hideError);
  }
  dispatch(addStop());
};

export const _deleteStop = (id) => (dispatch, getState) => {
  const { errorMessage = null } = getState().cabsLanding;
  if (errorMessage) {
    dispatch(hideError);
  }
  dispatch(deleteStop(id));
};

export const addCityClickAction = (additionalCityIndex = ADD_NEW_CITY) => (dispatch, getState) => {
  const {
    tripType,
    additionalCityData,
    selectedAirportTypeIndex
  } = getState().cabsLanding;
  let updatedAdditionalCityIndex = additionalCityIndex;
  let addNewCity = false;
  let sourceData = null;
  if (additionalCityIndex === ADD_NEW_CITY) {
    addNewCity = true;
    updatedAdditionalCityIndex = _isEmpty(additionalCityData) ? -1 : additionalCityData.length - 1;
  } else {
    sourceData = additionalCityData[additionalCityIndex];
  }
  const destinationData = null;
  const showSingleInputView = true;
  sendLandingEventForType(tripType, LandingEvent.DestinationClick);
  const request = {
    tripType,
    sourceData,
    destinationData,
    additionalCityData,
    destFocused: false,
    selectedAirportTypeIndex,
    additionalCityIndex: updatedAdditionalCityIndex,
    handleAddressChange: _handleAddressChangeForMulticity,
    showSingleInputView,
    addNewCity
  };
  dispatch(initCityPicker(request));
  Actions.cabsCityPicker();
};

const _handleBackPress = () => (dispatch, getState) => {
  const isFlightAddOn = getState().cabsLanding;
  if (isFlightAddOn) {
    dispatch(setFlightAddOnState(false));
  }
};

const _handleAddressChangeForStops = (request, id) => (dispatch, getState) => {
  const { sourceData } = request;
  dispatch(updateStopLocation(sourceData, id));
  Actions.pop();
};

const _handleSourceChange = (request, id) => (dispatch, getState) => {
  const { sourceData, shouldPop = true } = request;
  const { destinationData } = getTripDataFromState(getState());
  dispatch(
    _handleAddressChange({
      sourceData,
      destinationData,
      shouldPop,
      multicityPop: true,
    }),
  );
}

const _handleDestinationChange = (request, id) => (dispatch, getState) => {
  const { sourceData: destinationData, shouldPop = true } = request;
  const { sourceData } = getTripDataFromState(getState());
  dispatch(
    _handleAddressChange({
      sourceData,
      destinationData,
      shouldPop,
      multicityPop: true,
    }),
  );
};

const _handleAddressChange = (request) => (dispatch, getState) => {
  const {
    sourceData,
    destinationData,
    additionalCityData: requestAdditionalCityData,
    shouldPop = true,
    multicityPop = false,
    handleMulticityAddressChange = false,
  } = request;
  const { tripType, selectedAirportTypeIndex, departDate } = getTripDataFromState(getState());
  const { additionalCityData, isFlightAddOn, deepLinkData } = getState().cabsLanding;
  const { fromCityPicker } = getState().cabsCityPicker
  const {
    flightNumber,
    searchRequest: { is_instant_search: isInstantCab = false },
  } = getState().cabsListing;
  let updateAdditionalCityData = requestAdditionalCityData;
  const isMultiCityEnabled = CabsABConfigHelper.multiCityEnabled();
  if (isMultiCityEnabled && !handleMulticityAddressChange) {
    updateAdditionalCityData = additionalCityData;
  }
  const flightNumberUpdate = flightNumber
    ? flightNumber
    : !_isEmpty(deepLinkData)
      ? deepLinkData.flightNumber
      : null;
  if (isFlightAddOn) {
    if (
      shouldPop &&
      !_isEmpty(sourceData) &&
      (!_isEmpty(destinationData) || tripType === TripType.HR.value)
    ) {
      const data = {
        sourceData,
        destinationData,
        tripType,
        selectedAirportTypeIndex,
      };
      const { error, errorEvent } = validateSearchRequest(data);
      if (!_isEmpty(error)) {
        trackLandingEvent(errorEvent);
        showShortToast(error, 'top', 'dark');
        return;
      }
      if (sourceData.is_airport && tripType === TripType.AT.value) {
        dispatch(updateInstantApplicable(isInstantAvailable(sourceData.city_code)));
      } else {
        dispatch(updateInstantApplicable(false));
      }
      dispatch(setFlightAddOnState(false));

      // Setup Add-on screen
      dispatch(initialiseCabsFlightAddOn(sourceData, destinationData, departDate, isInstantCab));
      if (!isInstantCab) {
        dispatch(cabsListingActions.setFlightNumberDetail(flightNumberUpdate));
      }
      //TODO: generalize in next release
      if (deepLinkData && deepLinkData.parentScreen !== DeeplinkParentScreen.FLIGHT_TRIP_DETAILS && !fromCityPicker) {
        Actions.cabsListing({ type: 'replace', parentScreen: CabsScreen.LANDING });
      } else {
        Actions.pop();
      }
    }
  } else if (
    // Check is either of both address to be non-empty or trip type should be HR or it should be a multicity city picker
    shouldPop &&
    ((!_isEmpty(sourceData) && !_isEmpty(destinationData)) ||
      tripType === TripType.HR.value ||
      multicityPop)
  ) {
    Actions.pop();
  }
  dispatch(landingAddressChanged(sourceData, destinationData, updateAdditionalCityData));
};

const _handleAddressChangeForMulticity = request => (dispatch, getState) => {
  const {
    sourceData,
    additionalCityIndex,
    addNewCity = false
  } = request;
  let { shouldPop = true } = request;
  let updateAdditionalCityData = [];
  const handleMulticityAddressChange = true;
  let multicityPop = true;
  let { additionalCityData, destinationData, sourceData: updatedSourceData } = getState().cabsLanding;
  if (addNewCity && !_isEmpty(sourceData)) {
    updateAdditionalCityData = [destinationData, ...additionalCityData];
    destinationData = sourceData;
  } else if (_isEmpty(sourceData)) {
    multicityPop = false;
    shouldPop = false;
    updateAdditionalCityData = [...additionalCityData.slice(0, additionalCityIndex), ...additionalCityData.slice(additionalCityIndex + 1, additionalCityData.length)];
  } else {
    updateAdditionalCityData = [...additionalCityData.slice(0, additionalCityIndex), sourceData, ...additionalCityData.slice(additionalCityIndex + 1, additionalCityData.length)];
  }
  dispatch(_handleAddressChange({
    ...request,
    shouldPop,
    additionalCityData: updateAdditionalCityData,
    destinationData,
    multicityPop,
    handleMulticityAddressChange,
    sourceData: updatedSourceData
  }));
};

export const updateInstantApplicable = value => ({
  type: ACTION_UPDATE_IS_INSTANT_APPLICABLE,
  data: value
});

export const updateAvailableTripsForMyBiz = () => async (dispatch) => {
  const isCorporate = await isCorporateUser();
  if (isCorporate) {
    dispatch(removeSelfDriveFromTravelType());
  }
};

export const checkIfInstantApplicable = () => (dispatch, getState) => {
  const { sourceData, destinationData, selectedAirportTypeIndex, tripType } = getTripDataFromState(getState());
  if (sourceData &&
    destinationData &&
    selectedAirportTypeIndex === AirportPickupType.FROM.value &&
    tripType === TripType.AT.value
  ) {
    if (!selectedAirportTypeIndex) {
      dispatch(updateSelectedAirportIndexState(1));
    }
    dispatch(updateInstantApplicable(isInstantAvailable(sourceData.city_code)));
    return isInstantAvailable(sourceData.city_code);
  }
  dispatch(updateInstantApplicable(false));
  return false;
};

export const airportPickupTypeChange = data => (dispatch) => {
  const event = data === 0 ? 'Mob_Cabs_AT_Landing_To_Airport_Radio_Button' : 'Mob_Cabs_AT_Landing_From_Airport_Radio_Button';
  trackLandingEvent(event);
  dispatch(swapSearch());
  // dispatch({
  //   type: ACTION_SWAP_SEARCH
  // });
  // dispatch({
  //   type: ACTION_UPDATE_AIRPORT_PICKUP_TYPE,
  //   data
  // });
};

// Actions
export const checkNetworkConn = () => async (dispatch) => {
  await sleep(500);
  const hasNetwork = await isNetworkAvailable();
  dispatch(showNoNetworkAction(hasNetwork));
};

export const airtportRecommendationAutofill = (tripData) => async (dispatch) => {
  const { sourceLocation, destinationLocation, departDate, selectedAirportTypeIndex, travelType } = tripData;
  dispatch(updateLocation({
    sourceData: sourceLocation,
    destinationData: destinationLocation,
    stops: [],
    airportIndex: selectedAirportTypeIndex,
    travelType
  }));
  dispatch(_handleDateChanged(departDate, null, travelType));
};




export const checkAndEnableTravelTypes = () => async (dispatch, getState) => {
  const { commonPokusFlags } = getState().cabs;
  // To Enable Rentals or Self Drive
  if (Platform.OS !== 'web') {
    await getPokusConfigWaitingPromise(2000);
  }
  const showHRInB2C = commonPokusFlags?.HR_landing;
  const showHRInB2B = commonPokusFlags?.mybiz_hourly_rentals_new;
  const isMyBizUser = Platform.OS === 'web' ? false : await isCorporateUser();
  let travelTypes = [TravelType.OUTSTATION, TravelType.AIRPORT];
  if (isMyBizUser && showHRInB2B) {
    travelTypes = [...travelTypes, TravelType.RENTALS];
  } else if (!isMyBizUser && showHRInB2C) {
    travelTypes = [...travelTypes, TravelType.RENTALS];
  }

  if (!isMyBizUser) {
    // Sunsetting SD
    //travelTypes = [...travelTypes, TravelType.SELF_DRIVE];
    travelTypes = [...travelTypes];
  }

  dispatch({
    type: ACTION_UPDATE_ACTIVE_TRAVEL_TYPES,
    data: travelTypes,
  });
};

export const initCabsLanding = () => async (dispatch) => {
  dispatch(getCabOffers);
  try {
    const LOCAL_NOTIFICATION = 'Provisional_Local_Notification';
    const getLocalNotificationTimestamp = await getDataFromStorage(LOCAL_NOTIFICATION_TIMESTAMP);
    if (getLocalNotificationTimestamp) {
      const currDate = now();
      const hrsDiffFromNotificationTime = diffHours(
        currDate,
        new Date(getLocalNotificationTimestamp)
      );
      if (hrsDiffFromNotificationTime < 2) {
        const trackingData = {
          [VAR_REFERRER]: LOCAL_NOTIFICATION
        };
        setReceiverSourceAndCmp(LOCAL_NOTIFICATION, null);
        trackLandingEvent(trackingData);
      }
    }
  } catch (e) {
    //  Ignore exception
  }
};

let isRecentSearchCalled = false;

export const getRecentSearchData = () => async (dispatch) => {
  if (isRecentSearchCalled) {
    return;
  }
  isRecentSearchCalled = true;
  const recentSearches = await getRecentSearches();
  const recentSearchData = {};
  recentSearches?.forEach((searchData) => {
    const travelType = getTravelTypeFromTripType(searchData.trip_type);
    if (recentSearchData[travelType]) {
      return;
    } else {
      const processedTripData = getSearchData(searchData);
      recentSearchData[travelType] = processedTripData;
      dispatch(updateTripInfo(searchData.trip_type, processedTripData));
    }
  });
};

export const updateRecentSearchData = () => async (dispatch) => {
  const recentSearches = await getRecentSearches();
  if (!_isEmpty(recentSearches)) {
    dispatch({
      type: ACTION_UPDATE_RECENT_SEARCH,
      data: recentSearches
    });
  }
};

const getCabOffers = async (dispatch) => {
  const offersList = await getOffersFromSkyWalker('CABS');
  // const offersList = await getOffersList('Cabs', 'Search');
  if (_isEmpty(offersList)) {
    const userDetails = await getNativeUserDetails(dispatch);
    setUserDetailsInCabsAnalytics(userDetails);
    trackLandingEvent(CabOmnitureEvent.Landing_Zero_Offers);
  }
  try {
    const seggregatedOffers = _seggregateOffersByTripType(offersList);
    dispatch({
      type: ACTION_LANDING_SET_OFFERS,
      data: seggregatedOffers
    });
  } catch (e) {
    //  Ignore exception
  }
};

const _seggregateOffersByTripType = (offers) => {
  let airportOffers = [], outstationOffers = [], rentalOffers = [];
  offers.forEach(offer => {
    if (offer?.lob?.toLowerCase()?.indexOf(TRAVEL_TYPE_OFFERS_KEYS.AIRPORT) > -1) {
      offer.omnitureKey = 'Mob_Cabs_AT_Landing_Offers_Click';
      airportOffers.push(offer);
    }
    if (offer?.lob?.toLowerCase()?.indexOf(TRAVEL_TYPE_OFFERS_KEYS.OUTSTATION) > -1) {
      offer.omnitureKey = 'Mob_Cabs_OS_Landing_Offers_Click';
      outstationOffers.push(offer);
    }
    if (offer?.lob?.toLowerCase()?.indexOf(TRAVEL_TYPE_OFFERS_KEYS.HOURLY_RENTALS) > -1) {
      offer.omnitureKey = 'Mob_Cabs_HR_Landing_Offers_Click';
      rentalOffers.push(offer);
    }
  })
  return { airportOffers, outstationOffers, rentalOffers };
};

export const onOfferClicked = (item, instantCabCard = false) => {
  if (instantCabCard) {
    trackLandingEvent(CabOmnitureEvent.Instant_Offer_Card_Click);
  } else {
    trackLandingEvent(item.omnitureKey);
  }
  try {
    if (Boolean(item?.url)) {
      if (Platform.OS === 'web') {
        Actions.openWebView({
          url: item.url,
          headerText: 'Terms and Conditions',
          headerIcon: whiteBackIcon
        });
      } else {
        Actions.openWebView({
          url: item.url,
          headerText: 'Offer Details',
          headerIcon: whiteBackIcon
        });
      }
    }
  }
  catch (e) {
  }

  return ({
    type: ACTION_LANDING_OFFERS_CLICK,
    data: []
  });
};

export const showError = errorMessage => ({
  type: ACTION_SHOW_ERROR,
  data: errorMessage
});

export const hideError = ({
  type: ACTION_SHOW_ERROR
});

export const updateSelectedAirportIndex = (source, isFromAirport = false) => (dispatch, getState) => {
  const { selectedAirportTypeIndex } = getState().cabsLanding;
  const newAirportIndex = getAirportTypeFromCityCode(source);

  if (newAirportIndex !== selectedAirportTypeIndex) {
    if (isFromAirport) {
      dispatch(updateSelectedAirportIndexState(1));
    } else {
      dispatch(updateSelectedAirportIndexState(newAirportIndex));
    }
  }
};

export const updateSelectedAirportIndexState = (newAirportIndex = 0) => (dispatch) => {
  dispatch({
    type: ACTION_UPDATE_SELECTED_AIRPORT_INDEX,
    data: newAirportIndex
  });
};


export const initialiseCabsFlightAddOn = (sourceData, destinationData, departDate, isInstantCab = false) => (dispatch) => {
  const request = {
    from: sourceData, to: destinationData, tripType: TripType.AT.value, isInstantCab, isDefaultSearch: true
  };
  trackLandingEvent('Mob_Cabs_Landing_Search_Success');
  dispatch(cabsListingActions.setLoadingCabsAction);
  dispatch(landingAddressChanged(sourceData, destinationData));
  dispatch(cabsListingActions.setCabRequest(request, departDate));
};

export const setHRPackages = (data) => (dispatch) => {
  dispatch(cabsListingActions.setHRPackages(data))
}

export const setTripPersuationData = (data) => (dispatch) => {
  dispatch(cabsListingActions.setTripPersuationData(data))
}

export const setBnplTripTypes = (data) => (dispatch) => {
  dispatch(cabsListingActions.setBnplTripTypes(data))
}

export const onSearchClick = (isInstantCab = false, connectorSearch = false) => async (dispatch, getState) => {
  const currState = getState();
  const {
    flightDetail,
    showFlightToolTip,
    additionalCityData,
    errorMessage
  } = currState.cabsLanding;
  let {
    tripType,
    sourceData,
    destinationData,
    departDate,
    returnDate,
    selectedAirportTypeIndex,
    stops: stopOvers,
  } = getTripDataFromState(getState());
  if (stopOvers.length === 0 && tripType !== TripType.RT.value) {
    returnDate = null;
  }
  const { userDetails, deviceDetails } = getState().cabs;
  const { profileType = ProfileType.PERSONAL } = userDetails || {};
  const { selectedHRCabPackage = null } = getState().cabsListing;
  dispatch(cabsListingActions.showingFutureCabs(false));
  sendLandingEventForType(tripType, LandingEvent.SearchClick);
  let departureDate = now();
  const isSourceEmpty = _isEmpty(sourceData);
  const isDestinationEmpty = _isEmpty(destinationData);
  if (isSourceEmpty) {
    trackLandingEvent('Mob_Cabs_Landing_Search_Error_Source_loc_empty');
    dispatch(sourceClickAction());
    // dispatch(showError('Please enter pick-up address/turn on location'));
    return;
  }
  if (tripType !== TripType.HR.value) {
    if (isDestinationEmpty) {
      trackLandingEvent('Mob_Cabs_Landing_Search_Error_Destination_loc_empty');
      dispatch(destinationClickAction());
      // dispatch(showError('Please enter drop address/nearby area'));
      return;
    }
  }
  const data = {
    sourceData,
    destinationData,
    tripType,
    selectedAirportTypeIndex,
    additionalCityData,
    stopOvers,
  };
  const { error, errorEvent } = validateSearchRequest(data);
  if (errorMessage) {
    dispatch(hideError);
  }
  if (!_isEmpty(error)) {
    trackLandingEvent(errorEvent);
    dispatch(showError(error));
    return;
  }
  if (departDate) {
    departureDate = departDate;
  }
  if ((tripType === TripType.AT.value && !_shouldShowToolTip({
    flightDetail,
    showFlightToolTip,
    selectedAirportTypeIndex,
    sourceData,
    destinationData,
    departDate
  })) || (tripType !== TripType.AT.value && showFlightToolTip)) {
    dispatch(updateAirportToolTipStatus(false));
  }

  if (tripType !== TripType.AT.value) {
    isInstantCab = false;
  }
  const request = {
    from: sourceData,
    to: destinationData,
    intermediateCities: additionalCityData,
    stopovers: stopOvers,
    tripType,
    isInstantCab,
    isDefaultSearch: (tripType === TripType.HR.value) ? false : Boolean(departDate) ? false : true,
    connectorSearch
  };
  trackLandingEvent('Mob_Cabs_Landing_Search_Success');
  const pdtData = {
    userDetails,
    deviceDetails,
    sourceData,
    destinationData,
    departDate,
    returnDate,
    tripType
  };
  CabsPdtLandingHelper.trackSearchClicked(pdtData);
  CabsPdtLandingHelper.trackPageExit(pdtData);
  dispatch(updateRoutePlannerState(true));
  await dispatch(setCabRequest(
    request,
    departureDate,
    returnDate,
    selectedHRCabPackage
  ));
  Keyboard.dismiss();
  const {
    showListingWidget = false
  } = getState().cabsListing;
  if (showListingWidget) {
    dispatch(cabsListingActions.toggleListingWidget(false));
  }
  try {
    const departDateStr = fecha.format(departureDate, 'DD-MM-YYYY');
    const sourceDeeplink = sourceData.city_code ? `from=${sourceData.city_code}` : `fromPlaceId=${sourceData.place_id}`;
    const destinationDeeplink = destinationData.city_code ? `to=${destinationData.city_code}` : `toPlaceId=${destinationData.place_id}`;
    let deeplink = `mmyt://react/?page=cabsListing&deeplink=true&${sourceDeeplink}&${destinationDeeplink}&tripType=${tripType}&isUniversalSearch=true&openWidget=true&startDate=${departDateStr}`;
    if (tripType === TripType.AT.value) {
      deeplink += `&airportPickupType=${selectedAirportTypeIndex}`;
    }
    const fromCity = sourceData.mainText ? sourceData.mainText : sourceData.city;
    const toCity = destinationData.mainText ? destinationData.mainText : destinationData.city;
    const tripDate = departureDate.getTime();
    let recentData = {
      from: fromCity,
      to: toCity,
      startDate: (tripType === TripType.AT.value && isInstantCab) ? new Date().getTime() : tripDate,
      deeplink
    };
    if (tripType === TripType.RT.value) {
      let returnDateObj = returnDate || null;
      if (!returnDateObj) {
        returnDateObj = getNearestReturnDate(departureDate);
        adjustHourToSlot(returnDateObj);
      }
      let returnDateStr = fecha.format(returnDateObj, 'DD-MM-YYYY');
      recentData.returnDate = returnDateObj.getTime();
      deeplink += `&returnDate=${returnDateStr}`;
      recentData.deeplink = deeplink;
    }
    if (profileType === ProfileType.BUSINESS) {
      deeplink += '&isCorporate=true';
      recentData.deeplink = deeplink;
    }
    const isMulticityTrip = stopOvers?.length > 0;
    const isHRTrip = tripType === TripType.HR.value;
    if (isHRTrip || isMulticityTrip) {
      // Don't send any event to native until configured for these
    } else {
      updateRecentSearchHistory('cab', recentData);
    }
  } catch (e) {
    //  Ignore
  }
  dispatch(cabsListingActions.setHeaderInforLabel(null));
  dispatch(cabsListingActions.setPrefferedCabPartner(null));
  Actions.cabsListing({ deeplink: 'false', parentScreen: CabsScreen.LANDING });
};
export const onQuickBookClick = (scheduleType) => async (dispatch, getState) => {
  let isInstantCab = false;
  switch (scheduleType) {
    case SCHEDULE_OPTIONS.SCHEDULE: {
      isInstantCab = false;
      break;
    }
    case SCHEDULE_OPTIONS.INSTANT: {
      isInstantCab = true;
      break;
    }
    default: {
      isInstantCab = false;
      break;
    }
  }
  const currState = getState();
  const {
    flightDetail,
    showFlightToolTip,
    additionalCityData,
    errorMessage
  } = currState.cabsLanding;
  let {
    tripType,
    sourceData,
    destinationData,
    departDate,
    returnDate,
    selectedAirportTypeIndex,
    stops: stopOvers,
    packageKey
  } = getTripDataFromState(getState());
  if (stopOvers.length === 0 && tripType !== TripType.RT.value) {
    returnDate = null;
  }
  let departureDate = now();
  const data = {
    sourceData,
    destinationData,
    tripType,
    selectedAirportTypeIndex,
    additionalCityData,
    stopOvers,
  };
  const isSourceEmpty = _isEmpty(sourceData);
  const isDestinationEmpty = _isEmpty(destinationData);
  if (isSourceEmpty) {
    trackLandingEvent('Mob_Cabs_Landing_Search_Error');
    dispatch(sourceClickAction());
    return;
  }
  if (tripType !== TripType.HR.value) {
    if (isDestinationEmpty) {
      trackLandingEvent('Mob_Cabs_Landing_Search_Error');
      dispatch(destinationClickAction());
      return;
    }
  }
  const { error, errorEvent } = validateSearchRequest(data);
  if (errorMessage) {
    dispatch(hideError);
  }
  if (!_isEmpty(error)) {
    trackLandingEvent(errorEvent);
    dispatch(showError(error));
    return;
  }
  if (departDate) {
    departureDate = departDate;
  }
  if ((tripType === TripType.AT.value && !_shouldShowToolTip({
    flightDetail,
    showFlightToolTip,
    selectedAirportTypeIndex,
    sourceData,
    destinationData,
    departDate
  })) || (tripType !== TripType.AT.value && showFlightToolTip)) {
    dispatch(updateAirportToolTipStatus(false));
  }

  if (tripType !== TripType.AT.value) {
    isInstantCab = false;
  }
  const request = {
    source_location: sourceData,
    destination_location: destinationData,
    stopovers: stopOvers,
    trip_type: tripType,
    departure_date: fecha.format(departureDate, CABS_DATE_FMT),
    pickup_time: (tripType === TripType.AT.value && isInstantCab) ? new Date().getTime() : fecha.format(departureDate, CABS_TIME_FMT),
    return_date: returnDate ? fecha.format(returnDate, CABS_DATE_FMT) : null,
    drop_time: returnDate ? fecha.format(returnDate, CABS_TIME_FMT) : null,
    source: "quickbook_mweb",
    package_key: packageKey ? packageKey : null
  };
  callQucikBook(dispatch, request)
}
export const showLoadingInQuickBook = showLoader => ({
  type: ACTION_SHOW_QUICK_BOOK_LOADER,
  data: showLoader,
})

const callQucikBook = async (dispatch, request) => {
  if (request) {
    await dispatch(showLoadingInQuickBook(true))
    const res = await getQuickBookCabs(request)
    if (res && res.status === 'SUCCESS') {
      await dispatch(showLoadingInQuickBook(false))
      Linking.openURL(res?.review_redirection_link);
    }
  }
}
export const onSearch = (scheduleType) => (dispatch, getState) => {
  let isInstantCab = false;
  switch (scheduleType) {
    case SCHEDULE_OPTIONS.SCHEDULE: {
      trackLandingEvent('Mob_Cabs_Landing_Schedule_Later_Click');
      isInstantCab = false;
      break;
    }
    case SCHEDULE_OPTIONS.INSTANT: {
      trackLandingEvent('Mob_Cabs_Landing_Ride_Now_Click');
      isInstantCab = true;
      break;
    }
    default: {
      isInstantCab = false;
      break;
    }
  }
  dispatch(onSearchClick(isInstantCab));
};

const _shouldShowToolTip = (request) => {
  let shouldShow = true;
  try {
    if (!_isEmpty(request.flightDetail) && request.showFlightToolTip) {
      const location = request.selectedAirportTypeIndex === AirportPickupType.TO.value ? request.destinationData : request.sourceData;
      if (request.flightDetail.selectedAirportTypeIndex !== request.selectedAirportTypeIndex ||
        request.departDate.getTime() !== request.flightDetail.departDate.getTime() ||
        request.flightDetail.location.address !== location.address) {
        shouldShow = false;
      }
    }
  } catch (e) {
    // Ignore error
    shouldShow = false;
  }
  return shouldShow;
};

// This func check the return date received from cabs calendar and update trip type if required
const updateTripTypeBasedOnReturnDateInput = (returnDate) => (dispatch, getState) => {
  const { tripType } = getTripDataFromState(getState());
  if (returnDate) {
    dispatch(updateTripType(TripType.RT.value));
  } else if (tripType === TripType.RT.value) {
    dispatch(updateTripType(TripType.OW.value));
  }
};

const _handleDateChanged = (departDate, returnDate, travelType = null) => (dispatch, getState) => {
  const { tripType, stops } = getTripDataFromState(getState());
  const isMulticityTrip = stops?.length > 0;
  if ((tripType === TripType.OW.value || tripType === TripType.RT.value) && !isMulticityTrip) {
    dispatch(updateTripTypeBasedOnReturnDateInput(returnDate));
  }
  dispatch(cabsListingActions.toggleIsDefaultDateTimeUpdated(true));
  dispatch(updateTripDate({ departDate, returnDate, travelType }));
};


export const openCalendar = (initialDateSelectMode, disableReturn = true) => (
  dispatch,
  getState,
) => {
  const { tripType, departDate, returnDate, stops } = getTripDataFromState(getState());
  dispatch(
    initWithDates(
      initialDateSelectMode,
      departDate,
      returnDate,
      tripType,
      _handleDateChanged,
      disableReturn,
      CabsScreen.LANDING,
      stops?.length > 0, //This flag is for determining whether its multicity or not
    ),
  );
  Actions.cabsCalendar();
};

export const onSearchSwapClicked = (trackEvent = true) => (dispatch, getState) => {
  const isUniversalWidgetVisible = getState().cabsListing.showListingWidget;
  if (trackEvent && !isUniversalWidgetVisible) {
    trackLandingEvent('Mob_Cabs_Landing_Swap_Search_Params');
  }
  dispatch({
    type: ACTION_SWAP_SEARCH
  });
  dispatch(swapSearch());
};

export const getFlightDetails = () => (dispatch, getState) => {
  const { flightDetail } = getState().cabsLanding;
  if (!_isEmpty(flightDetail)) {
    const {
      location,
      selectedAirportTypeIndex,
      departDate
    } = flightDetail;

    const nearestLocalDepDate = getNearestNextSlotForTripType(TripType.AT.value);
    let nextDepartureDate = departDate;
    if (nextDepartureDate < nearestLocalDepDate) {
      nextDepartureDate = nearestLocalDepDate;
    }

    let sourceText = '';
    let destinationText = '';
    let sourceData = null;
    let destinationData = null;
    let fromAirport = false;

    if (selectedAirportTypeIndex === AirportPickupType.FROM.value) {
      fromAirport = true;
      sourceText = location.address;
      sourceData = location;
    } else {
      destinationText = location.address;
      destinationData = location;
    }
    return {
      fromAirport,
      sourceText,
      sourceData,
      destinationText,
      destinationData,
      selectedAirportTypeIndex,
      nextDepartureDate
    };
  }
  return {};
};

export const setLocationInfo = data => async (dispatch) => {
  dispatch(setCityPickerLocation(data));
  dispatch({
    type: ACTION_SET_LANDING_LOCATION,
    data
  });
};

export const fetchUpcomingTrips = () => async (dispatch, getState) => {
  const { deepLinkData } = getState().cabsLanding;
  const flightDetail = await getUserBookingDetails();
  let data = {
    sourceData: null,
    destinationData: null,
    sourceText: '',
    destinationText: '',
    selectedAirportTypeIndex: AirportPickupType.TO.value
  };
  try {
    if ((!_isEmpty(deepLinkData.airportPickupType) &&
      deepLinkData.airportPickupType.toLowerCase() === AirportPickupType.FROM.name)) {
      data.selectedAirportTypeIndex = 1;
    }
    if (!_isEmpty(flightDetail)) {
      dispatch(showPageLoading(true));
      const request = {
        type: 'airportSuggestionRequest',
        flight_bookings: flightDetail
      };
      const response = await fetch(cabsConfig.fetchAirportSuggestion, {
        method: 'post',
        body: JSON.stringify(request),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        timeout: 5000
      });
      const airportResponse = await response.json();
      if (airportResponse.status !== 'FAILURE' && airportResponse.errors === null) {
        dispatch({
          type: ACTION_SET_AIRPORT_SUGGESTION_DATA,
          data: airportResponse
        });
        data = dispatch(getFlightDetails());
        const { tripType } = getState().cabsLanding;
        if (tripType === TripType.AT.value) {
          dispatch(fillAirportSuggestion(data));
        }
      }
    }
  } catch (e) {
    // Ignore exception
  }
  dispatch(showPageLoading(false));
  dispatch(setLocationInfo(data));
};

export const fetchLocationData = async (deepLinkData) => {
  let sourceData = null;
  let destinationData = null;
  let sourceText = '';
  let destinationText = '';
  let selectedAirportTypeIndex = AirportPickupType.TO.value;
  if ((!_isEmpty(deepLinkData.airportPickupType) &&
    deepLinkData.airportPickupType.toLowerCase() === AirportPickupType.FROM.name)) {
    selectedAirportTypeIndex = AirportPickupType.FROM.value;
  }

  let cityCode = '';
  if (deepLinkData.isLocus === 'true' || deepLinkData.isLocus === true) {
    cityCode = `${deepLinkData.cityLocus}_AIR_LOCUS`;
  } else {
    cityCode = deepLinkData.cityCode;
  }

  try {
    const location = await getLocationFromCityCode(cityCode, deepLinkData.terminal);
    if (location) {
      location.is_airport = true;
      if (selectedAirportTypeIndex === AirportPickupType.FROM.value) {
        sourceData = location;
        sourceText = sourceData.address;
      } else {
        destinationData = location;
        destinationText = destinationData.address;
      }
    }
  } catch (e) { //
  }

  const data = {
    sourceData,
    destinationData,
    sourceText,
    destinationText,
    selectedAirportTypeIndex
  };
  return data;

};

export const landingSearchComplete = (request, listingAvailable) => async (dispatch, getState) => {
  try {
    if (!_isEmpty(request)) {
      const timeout = new Promise((resolve) => {
        setTimeout(resolve, 1000, '');
      });
      const response = await Promise.race([
        timeout,
        getRoutePlannerResponse(request.source_city, request.destination_city)
      ]);
      const isUrlPresent = !_isEmpty(response) && !_isEmpty(response.url);
      const { shouldShowRoutePlanner } = getState().cabsLanding;
      if (shouldShowRoutePlanner && isUrlPresent && !listingAvailable) {
        const action = await promiseAlert('', 'No direct cabs available. View alternate travel options!', 'Continue');
        const routePlannerUrl = `${response.url}&source=cab_na`;
        Actions.pop();
        Actions.openWebView({
          url: routePlannerUrl,
          headerText: 'Back To Search',
          headerIcon: whiteBackIcon,
          onBackPress: () => { Actions.pop(); }
        });
      }
    }
  } catch (e) {
    // ignore
  }
  dispatch(updateRoutePlannerState(false));
};

/*
  Common events that needs to fired for all trip types
*/
export const LandingEvent = {
  PickupClick: 'Landing_Pickup_Click',
  DestinationClick: 'Landing_Destination_Click',
  SearchClick: 'Landing_Search_Click'
};

export const sendLandingEventForType = (tripType, event) => {
  const header = eventHeaderForTripType(tripType);
  if (header !== '') {
    trackLandingEvent(header + event);
  }
};

export const trackPdtBackPressed = () => (dispatch, getState) => {
  const {
    sourceData,
    destinationData,
    departDate,
    returnDate
  } = getState().cabsLanding;
  const {
    userDetails,
    deviceDetails
  } = getState().cabs;
  const pdtData = {
    userDetails,
    deviceDetails,
    sourceData,
    destinationData,
    departDate,
    returnDate
  };
  CabsPdtLandingHelper.trackBackPressed(pdtData);
};

export const trackPdtLandingLoad = (userDetails) => async (dispatch) => {
  try {
    if (userDetails && (userDetails.email || userDetails.mobile.mobileNumber)) {
      const { eComId = '', mComId = '' } = await getComIds(userDetails.email, userDetails.mobile.mobileNumber);
      userDetails.eComId = eComId;
      userDetails.mComId = mComId;
      dispatch(updateUserDetails(userDetails));
    }
  } catch (e) {
    //  Ignore exception
  }
  const deviceDetails = await getDeviceDetails(dispatch);
  if (Platform.OS !== 'web') {
    const commonHeaders = await getCommonHeaders();
    if (!_.isEmpty(deviceDetails)) {
      deviceDetails.mcId = commonHeaders.mcId;
    }
  } else {
    if (!_.isEmpty(deviceDetails)) {
      deviceDetails.mcId = null;
    }
  }
  dispatch(updateDeviceDetails(deviceDetails));
  const pdtData = {
    userDetails,
    deviceDetails
  };
  CabsPdtLandingHelper.trackPageLoad(pdtData);
};

export const trackLandingLoad = (data) => async (dispatch, getState) => {
  const userDetails = await getNativeUserDetails(dispatch);
  setUserDetailsInCabsAnalytics(userDetails);
  trackLandingEvent(data, true);
  const event = CabsABConfigHelper.multiCityEnabled() ?
    CabOmnitureEvent.MultiCity_Present :
    CabOmnitureEvent.MultiCity_Absent;
  trackLandingAbEvent(event);
};

export const fetchUserDetails = () => async () => {
  let res = null;
  try {
    res = await getUserDetails()
  } catch (error) {
    console.log(error)
  }
  return res;
}

export const setSourceAndCmp = (data) => ({
  type: ACTION_SET_SOURCE_CMP_DATA,
  data,
});