import {
  ACTION_UPDATE_DEVICE_DETAILS,
  ACTION_UPDATE_USER_DETAILS,
  ACTION_UPDATE_TRAVEL_TYPES,
  ACTION_SET_COMMON_POKUS_FLAGS,
  ACTION_SET_ASSISTED_FLOW_DATA,
} from './cabsCommonActions';
import TripType, { TravelType } from './types/TripType';
import { CORP_THEME, DEFAULT_THEME } from './CabsTheme';

const initialState = {
  userDetails: {},
  deviceDetails: {},
  allTravelTypes: [TravelType.OUTSTATION, TravelType.AIRPORT, TravelType.RENTALS],
  theme: DEFAULT_THEME,
  allTripTypes: [TripType.OW.value, TripType.RT.value],
  commonPokusFlags: {
    Cab_Ask_Flight_Number: false,
    LandingPage_SnackBar: true,
    HR_landing: true,
    cab_sort_filter: true,
    HR_new_icon: true,
    mybiz_hourly_rentals_new: false,
    Ride_Now_Switch: true,
    showGSTInB2C: false,
    showA2CDestination: false,
  },
  assisted_flow_data:{
    assistedFormOpenDelay: 60000,
    showCallUsOption: false,
    atvToShowAssistedFlow: 5000,
    customerCareNumber: '',
    showAssistedFlowNudge: false,
    callbackRequested: false,
    showCallBack: false,
    showWhatsAppChat: false,
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_UPDATE_TRAVEL_TYPES: {
      return {
        ...state,
        allTravelTypes: [TravelType.AIRPORT],
        allTripTypes: [TripType.AT.value],
      };
    }
    case ACTION_UPDATE_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.data.userDetails,
        theme: action.data.isPersonalUser ? DEFAULT_THEME : CORP_THEME
      };
    }
    case ACTION_UPDATE_DEVICE_DETAILS: {
      return {
        ...state,
        deviceDetails: action.data,
      };
    }
    case ACTION_SET_COMMON_POKUS_FLAGS: {
      return {
        ...state,
        commonPokusFlags: action.data,
      };
    }
    case ACTION_SET_ASSISTED_FLOW_DATA:{
      return {
        ...state,
        assisted_flow_data: action.data,
      }
    }
    default: {
      return state;
    }
  }
};

export default reducer;
