import React from 'react';
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router';
import {View} from 'react-native';
import {Router} from 'react-router-dom';
import {CookiesProvider} from 'react-cookie';

import store, {history} from './webStore';
import './WebModules';

// Reducers
import cabsCommonReducer from "@rn/Routes/Cabs/cabsCommonReducer";
import cabsLandingReducer from "@rn/Routes/Cabs/Landing/cabsLandingReducer";
import CityPickerReducer from '@rn/Routes/Cabs/Landing/Components/CityPickerReducer';
import ProvisionalBookingReducer from '@rn/Routes/Cabs/ProvisionalBooking/ProvisionalBookingReducer';
import ProvisionalBookingNonKioskReducer from '@rn/Routes/Cabs/ProvisionalBookingNonKiosk/reducer';
import HotelMarketingReducer from '@rn/Routes/Cabs/HotelMarketing/HotelMarketingReducer';
import cabsCalendarReducer from '@rn/Routes/Cabs/Calendar/cabsCalendarReducer';
import cabsListingReducer from '@rn/Routes/Cabs/Listing/cabsListingReducer';
import ProvisionalBookingHOBReducer from '@rn/Routes/Cabs/ProvisionalBookingHOB/reducer'
import cabsReviewReducer from '@rn/Routes/Cabs/Review/cabsReviewReducer';
import cabsAddOnReducer from '@mmt/cabs/src/addon/Flight/Redux/cabsAddOnReducer'; //TODO_VS: this path has been updated as per modularization
import cabsMyBizPrimaryPax from '@rn/Routes/Cabs/MyBiz/PrimaryPax/PrimaryPaxReducer';
import bnplReducer from '@rn/Common/Components/BookNowPayLater/bnplReducer';
import cabsTripInfoReducer from '@rn/Routes/Cabs/cabsTripInfoReducer';
import gstinReducer from '../Mobile-mmt-react-native/packages/legacy-commons/Common/Components/GSTIN/Store/GSTINreducer';

import {
  CabAddOnDetailsWeb,
  CabAddOnReviewWeb,
  CabAddOnThankYouWeb,
  CabRepresentativeImages,
  CabReview,
  CabsCalendar,
  CabsCorporate,
  CabsLanding,
  CabsListing,
  CabsRating,
  CabsThankYouWeb,
  CancellationRules,
  CityPicker,
  DestinationPicker,
  DestinationPickerNonKiosk,
  DestinationPickerHOB,
  ProvisionalBookingHOB,
  ThankYouPageHOB,
  ListingPageHOB,
  FareBreakupModal,
  HotelCityPicker,
  HotelMarketing,
  InstaStory,
  KioskImageBurstMode,
  Marketing,
  ProvisionalBooking,
  ProvisionalBookingNonKiosk,
  SelfDrive,
  WebViewWrapper,
} from './asyncRoutes';

import {injectAsyncReducer} from "@rn/asyncStore";

export const withRouterState = Component => props =>
  <Component {...props.location.state} {...props.match.params} {...props} />;

injectAsyncReducer('cabs', cabsCommonReducer);
injectAsyncReducer('cabsLanding', cabsLandingReducer);
injectAsyncReducer('cabsCityPicker', CityPickerReducer);
injectAsyncReducer('cabsCalendar', cabsCalendarReducer);
injectAsyncReducer('cabsListing', cabsListingReducer);
injectAsyncReducer('cabsReview', cabsReviewReducer);
injectAsyncReducer('cabsAddOn', cabsAddOnReducer);
injectAsyncReducer('cabsProvisonalReducer', ProvisionalBookingReducer)
injectAsyncReducer('cabsProvisonalNonKioskReducer', ProvisionalBookingNonKioskReducer)
injectAsyncReducer('cabsProvisonalHOBReducer', ProvisionalBookingHOBReducer)
injectAsyncReducer('cabsHotelMarketing', HotelMarketingReducer)
injectAsyncReducer('cabsMyBizPrimaryPax', cabsMyBizPrimaryPax);
injectAsyncReducer('bnplReducer', bnplReducer);
injectAsyncReducer('cabsTripInfo', cabsTripInfoReducer);
injectAsyncReducer('gstinReducer', gstinReducer);

const WebRouter = () => (
  <CookiesProvider>
    <Provider store={store}>
      <Router history={history} forceRefresh>
        <View style={{flex: 1}}>
          <Switch>
            <Route exact path="/" component={withRouterState(CabsLanding)} />
            <Route exact path="/cabsSearch" component={withRouterState(CabsLanding)} />
            <Route exact path="/cabInstaStory" component={withRouterState(InstaStory)} />
            <Route exact path="/rnw/cabsCityPicker" component={withRouterState(CityPicker)} />
            <Route exact path="/provisionalDestPicker" component={withRouterState(DestinationPicker)} />
            <Route exact path="/provisionalDestPickerNonKiosk" component={withRouterState(DestinationPickerNonKiosk)} />
            <Route exact path="/provisionalDestPickerHOB" component={withRouterState(DestinationPickerHOB)} />
            <Route exact path="/listingPageHOB" component={withRouterState(ListingPageHOB)} />
            <Route exact path="/thankyouPageHOB" component={withRouterState(ThankYouPageHOB)} />
            <Route exact path="/provisionalBookingHOB" component={withRouterState(ProvisionalBookingHOB)} />
            <Route exact path="/hotelMarketingCityPicker" component={withRouterState(HotelCityPicker)} />
            <Route exact path="/rnw/cabsCalendar" component={withRouterState(CabsCalendar)} />
            <Route exact path="/cabsListing" component={withRouterState(CabsListing)} />
            <Route exact path="/cabsTripReview" component={withRouterState(CabReview)} />
            <Route exact path="/rnw/cabsFareBreakup" component={withRouterState(FareBreakupModal)} />
            <Route exact path="/rnw/cancellationRules" component={withRouterState(CancellationRules)} />
            <Route exact path="/rnw/openWebView" component={withRouterState(WebViewWrapper)} />
            <Route exact path="/cabsThankYou" component={withRouterState(CabsThankYouWeb)} />
            <Route exact path="/cabAddOnDetails" component={withRouterState(CabAddOnDetailsWeb)} />
            <Route exact path="/cabAddOnReview" component={withRouterState(CabAddOnReviewWeb)} />
            <Route exact path="/cabsAddOnThankYou" component={withRouterState(CabAddOnThankYouWeb)} />
            <Route exact path="/rnw/cabsRating" component={withRouterState(CabsRating)} />
            <Route exact path="/provisionalBooking" component={withRouterState(ProvisionalBooking)} />
            <Route exact path="/provisionalBookingNonKiosk" component={withRouterState(ProvisionalBookingNonKiosk)} />
            <Route exact path="/kioskImageBurstMode" component={withRouterState(KioskImageBurstMode)} />
            <Route exact path="/cabRepresentativeImages" component={withRouterState(CabRepresentativeImages)} />
            <Route exact path="/marketing" component={withRouterState(Marketing)} />
            <Route exact path="/cabsCorporate" component={withRouterState(CabsCorporate)} />
            <Route exact path="/cabsHotelMarketing" component={withRouterState(HotelMarketing)}/>
            <Route path="/self-drive" component={SelfDrive} />
          </Switch>
        </View>
      </Router>
    </Provider>
  </CookiesProvider>
);


export default WebRouter;

