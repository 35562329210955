import { CabsScreen } from '../config/cabsScreen';
import TripTypes from '../types/TripType';
import fecha from 'fecha';
import { DEFAULT as skywalkerConstant } from './constants';
import { isCorporateUser } from 'src/Native/UserSession/UserSessionModule';

export const getSkywalkerSearchParams = (response: any) => {
  const {
    source_location: sourceLocation,
    destination_location: destinationLocation,
    trip_type: tripType,
    departure_date: departureDate,
    pickup_time: pickupTime,
    return_date: returnDate,
    drop_time: returnTime,
    source_city: sourceCityCode = null,
    destination_city: destinationCityCode = null,
    source_city_name,
    destination_city_name,
  } = response.request;

  const searchParams = {
    lastSearchContext: {
      searchContext: {
        lob: 'cabs',
        lobCategory: tripType === TripTypes.AT.value ? 'cabs-AT' : 'cabs-OC',
        fromDateTime: {
          str: `${departureDate} ${pickupTime}:00`,
        },
        from: {
          lat: sourceLocation.latitude,
          lng: sourceLocation.longitude,
          lobCity: sourceCityCode,
          lobCountry: 'India',
          countryCode: 'IN',
          placeId: sourceLocation.place_id,
          cityName: source_city_name,
        },
        to: {
          lat: destinationLocation.latitude,
          lng: destinationLocation.longitude,
          lobCity: destinationCityCode,
          lobCountry: 'India',
          countryCode: 'IN',
          placeId: destinationLocation.place_id,
          cityName: destination_city_name,
        },
        journeyType: tripType === TripTypes.RT.value ? 'RT' : 'OW',
        tripType: tripType === TripTypes.AT.value ? 'AT' : 'OS',
        timestamp: new Date().getTime(),
      },
    },
  };
  if (returnDate && returnTime) {
    searchParams.lastSearchContext.searchContext.toDateTime = {
      str: `${returnDate} ${returnTime}:00`,
    };
  }
  return searchParams;
};

export const getSectorInformation = (response, pageName = CabsScreen.LISTING) => {
  const {
    cab_type: cabType = null,
    source_city: sourceCityCode = null,
    destination_city: destinationCityCode = null,
  } = response;
  const sectorDetails = {
    lob: 'cabs',
    sector: `${sourceCityCode}-${destinationCityCode}`,
    pageName: pageName.toLowerCase(),
  };
  if (cabType) {
    sectorDetails.categories = [cabType];
  }
  return sectorDetails;
};

export const getCommonsSearchParams = async (data) => {
  const {
    source_location: sourceLocation,
    destination_location: destinationLocation,
    trip_type: tripType,
    departure_date: departureDate,
    pickup_time: pickupTime,
    return_date: returnDate,
    drop_time: returnTime,
    source_city: sourceCityCode = null,
    destination_city: destinationCityCode = null,
    source_city_name,
    destination_city_name,
  } = data;
  const sourceString = encodeURIComponent(JSON.stringify(sourceLocation));
  const destinationString = encodeURIComponent(JSON.stringify(destinationLocation));
  const isUserCorporate = await isCorporateUser();
  const departDateObj = fecha.parse(`${departureDate} ${pickupTime}`, 'DD-MM-YYYY HH:mm');
  let deeplink = `https://cabs.makemytrip.com/cabsListing?tripType=${tripType}&from=${sourceString}&to=${destinationString}&departDate=${departureDate}&pickupTime=${pickupTime}&deeplink=true`;
  let cabRecentSearches = {
    language: skywalkerConstant.LANGUAGE,
    region: skywalkerConstant.COUNTRY_CODE.toLowerCase(),
    profile: isUserCorporate ? 'corporate' : 'personal',
    timestamp: new Date().getTime(),
    from: {
      lat: sourceLocation.latitude,
      lng: sourceLocation.longitude,
      lobCity: sourceCityCode,
      lobCountry: skywalkerConstant.COUNTRY,
      countryCode: skywalkerConstant.COUNTRY_CODE,
      placeId: sourceLocation.place_id,
      cityName: source_city_name,
    },
    fromDateTime: {
      ts: departDateObj.getTime(),
      zone: skywalkerConstant.ZONE,
      str: `${departureDate} ${pickupTime}:00`,
    },
    to: {
      lat: destinationLocation.latitude,
      lng: destinationLocation.longitude,
      lobCity: destinationCityCode,
      lobCountry: skywalkerConstant.COUNTRY,
      countryCode: skywalkerConstant.COUNTRY_CODE,
      placeId: destinationLocation.place_id,
      cityName: destination_city_name,
    },
    journeyType: tripType === TripTypes.RT.value ? 'RT' : 'OW',
    tripType: tripType === TripTypes.AT.value ? 'AT' : 'OS',
    lob: skywalkerConstant.LOB,
    lobCategory: tripType === TripTypes.AT.value ? 'cabs-AT' : 'cabs-OC',
  };
  if (returnDate && returnTime) {
    const returnDateObj = fecha.parse(`${returnDate} ${returnTime}`, 'DD-MM-YYYY HH:mm');
    deeplink += `&returnDate=${returnDate}&dropTime=${returnTime}`;
    cabRecentSearches.toDateTime = {
      ts: returnDateObj.getTime(),
      zone: skywalkerConstant.ZONE,
      str: `${returnDate} ${returnTime}:00`,
    };
  }
  cabRecentSearches.meta = {
    deeplink,
    universalText: `${sourceLocation?.address} to ${destinationLocation?.address} cab`,
  };
  return cabRecentSearches;
};
