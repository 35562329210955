"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT = void 0;
exports.DEFAULT = {
    LANGUAGE: 'eng',
    LOB: 'cabs',
    COUNTRY: 'India',
    COUNTRY_CODE: 'IN',
    JOURNEY_TYPE: 'OW',
    ZONE: 'IST',
};
