import AsyncStorage from '@react-native-async-storage/async-storage';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import fecha from 'fecha';
import { setDataInStorage, getDataFromStorage } from 'src/LocalStorage';
import TripType from '../types/TripType';
import CabsABConfigHelper from './CabsABConfigHelper';
import { LISTING_EXISTING_USER } from '../cabsConstants';
import { getSessionId } from './cabsCommonUtils';
import { getUserInfoPIIClient } from 'MMT-UI/getPIIData/client'

const APP_CONFIG = 'cabs_config';
const RECENT_SEARCH = 'cabs_recent_search';
const SHOW_LANDING_SHOWCASE = 'cabs_show_landing_showcase';
const SHOW_LISTING_SHOWCASE = 'cabs_show_listing_showcase';
const SHOW_LISTING_SHOWCASE_HR = 'cabs_show_listing_showcase_hr';
const SHOW_FILTER_OVERLAY_SHOWCASE = 'cabs_show_filter_showcase';
const CAB_PAX_DETAILS = 'cabs_pax_details';
const CAB_DETAILS = 'cab_details';
const CAB_RNW_FUNNEL = 'cab_rnw_funnel';
const FLIGHT_BOOKING_ID = 'flight_booking_id';
const CAB_REVIEW_BACK_POPUP_CLOSE = 'cab_review_back_popup_close';
const CAB_SESSION_ID = 'cab_session_id';
const SHOW_TOAST_COUNT = 'show_toast_count';

export const getAppConfiguration = async () => {
  try {
    const result = await AsyncStorage.getItem(APP_CONFIG);
    if (!result) {
      return {};
    }
    const parse = JSON.parse(result);
    return parse;
  } catch (e) {
    return {};
  }
};

export const setAppConfiguration = async (config) => {
  await AsyncStorage.setItem(APP_CONFIG, JSON.stringify(config));
};

export const setSessionId = async () => {
  const sessionId = getSessionId();
  await AsyncStorage.setItem(CAB_SESSION_ID, sessionId);
};

export const getSessionIdfromLocalStore = async () => {
  try {
    const sessionId = await AsyncStorage.getItem(CAB_SESSION_ID);
    return sessionId;
  } catch (e) {
    return null;
  }
};

export const getRecentSearches = async () => {
  try {
    const result = await AsyncStorage.getItem(RECENT_SEARCH);
    if (!result) {
      return [];
    }
    const parse = JSON.parse(result);
    return parse;
  } catch (e) {
    return [];
  }
};

export const addToRecentSearches = async (searchRequest) => {
  let recentSearches = await getRecentSearches();
  const { source_location, destination_location, trip_type } = searchRequest;
  let id = `${source_location.city}`;
  if (destination_location !== null) {
    id = `${source_location.city}_${destination_location.city}`;
  }
  id = `${id}_${trip_type}`;
  recentSearches = _filter(recentSearches, (e) => id !== e.id);
  recentSearches = [
    {
      id,
      ...searchRequest,
    },
    ...recentSearches,
  ];
  await AsyncStorage.setItem(RECENT_SEARCH, JSON.stringify(recentSearches));
};

export const shouldShowLandingShowcase = async () => {
  const result = await AsyncStorage.getItem(SHOW_LANDING_SHOWCASE);
  return result !== 'false';
};

export const shouldShowFilterShowcase = async () => {
  const result = await AsyncStorage.getItem(SHOW_FILTER_OVERLAY_SHOWCASE);
  return result !== 'false';
};

export const getShouldScrollCabs = async () => {
  const result = await AsyncStorage.getItem('cabs_scroll_add_on');
  return !result || result !== 'hide';
};

export const setShouldScrollCabs = async (value) => {
  AsyncStorage.setItem('cabs_scroll_add_on', value);
};

export const setLandingShowcaseShow = async () => {
  AsyncStorage.setItem(SHOW_LANDING_SHOWCASE, 'false');
};

export const setFilterShowcaseShow = async () => {
  AsyncStorage.setItem(SHOW_FILTER_OVERLAY_SHOWCASE, 'false');
};

export const shouldShowListingShowcase = async (tripType) => {
  if (TripType.AT.value === tripType) {
    return false;
  }
  const key = TripType.HR.value === tripType ? SHOW_LISTING_SHOWCASE_HR : SHOW_LISTING_SHOWCASE;
  const result = await AsyncStorage.getItem(key);
  return result !== 'false';
};

export const shouldShowProtectiveModal = async () => {
  const isExistingListingUser = await getDataFromStorage(LISTING_EXISTING_USER);
  if (!isExistingListingUser) {
    setDataInStorage(LISTING_EXISTING_USER, true);
    return true;
  }
  return false;
};

export const setListingShowcaseShow = async (tripType) => {
  const key = TripType.HR.value === tripType ? SHOW_LISTING_SHOWCASE_HR : SHOW_LISTING_SHOWCASE;
  AsyncStorage.setItem(key, 'false');
};

export const savePaxDetails = async (pax) => {
  await AsyncStorage.setItem(CAB_PAX_DETAILS, JSON.stringify(pax));
};

export const getPaxDetails = async () => {
  try {
    const userInfo = await getUserInfoPIIClient();
    if(userInfo){

    }
    const paxDetailsStr = {
      bookingFor:'',
      countryCode:userInfo?.countryCode,
      email: userInfo?.email,
      gender: userInfo?.personalDetails.gender,
      mobile: userInfo?.mobileNumber,
      name: `${userInfo?.firstName} ${userInfo?.lastName}`,
      profileType: userInfo?.profileType,
    }
    if (!paxDetailsStr) {
      return null;
    }
    return paxDetailsStr;
  } catch (e) {
    return null;
  }
};

export const getCabDetailsForTqPage = async () => {
  try {
    const result = await AsyncStorage.getItem(CAB_DETAILS);
    if (!result) return {};
    return JSON.parse(result);
  } catch (e) {
    return {};
  }
};

export const setCabDetailsForTqPage = async (cab) => {
  await AsyncStorage.setItem(CAB_DETAILS, JSON.stringify(cab));
};

const CABS_OMNITURE_EVARS = 'cabs_omniture_evars';
export const saveOmnitureEVars = async (eVars) => {
  await AsyncStorage.setItem(CABS_OMNITURE_EVARS, JSON.stringify(eVars));
};

export const getOmnitureEVars = async () => {
  try {
    const eVarsStr = await AsyncStorage.getItem(CABS_OMNITURE_EVARS);
    if (!eVarsStr) return {};
    return JSON.parse(eVarsStr);
  } catch (e) {
    return {};
  }
};

export const setCabsRNWFunnel = async () => {
  await AsyncStorage.setItem(CAB_RNW_FUNNEL, true);
};

export const isCabsRNWFunnel = async () => {
  try {
    const result = await AsyncStorage.getItem(CAB_RNW_FUNNEL);
    return result === 'true';
  } catch (e) {
    return false;
  }
};

export const setFlightBookingId = async (flightBookingId) => {
  await AsyncStorage.setItem(FLIGHT_BOOKING_ID, flightBookingId);
};

export const getFlightBookingId = async () => {
  try {
    return await AsyncStorage.getItem(FLIGHT_BOOKING_ID);
  } catch (e) {
    return null;
  }
};

export const setReviewBackPopupClose = async () => {
  await AsyncStorage.setItem(CAB_REVIEW_BACK_POPUP_CLOSE, 'true');
};

export const isReviewBackPopupClose = async () => {
  try {
    const result = await AsyncStorage.getItem(CAB_REVIEW_BACK_POPUP_CLOSE);
    return result === 'true';
  } catch (e) {
    return false;
  }
};

export const prepareTqPageData = async (data) => {
  const {
    cab,
    holdResponse,
    tripHeaderData,
    pax,
    paymentMode,
    pdtData,
    ePassChecklist = null,
    isFlightAddOn = false,
    isInstantCab = false,
  } = data;
  if (holdResponse.status === 'SUCCESS') {
    const tqdata = {};
    const holdRequest = holdResponse.request;
    const holdCabResponse = holdResponse.response;
    const fareDetails = cab.cab_fare_detail;
    const isMulticityEnabled = CabsABConfigHelper.multiCityEnabled();
    tqdata.encrypted_mmt_booking_id = holdCabResponse.encrypted_mmt_booking_id;
    tqdata.bookingId = holdCabResponse.mmt_booking_id;
    tqdata.mmtBlackPersuasion = holdCabResponse.mmt_black_persuasion || null;
    tqdata.paymentMode = paymentMode;
    tqdata.fullVoucherPayment = holdCabResponse.full_voucher_payment;
    tqdata.cabImageUrl = cab.vehicle_image;
    tqdata.utilities = cab.utilities;
    tqdata.cabModel = cab.vehicle_model;
    tqdata.mySafetyAccessories = cab.my_safety_accessories || null;
    tqdata.bestPriceGuaranteeDetails = cab.best_price_guarantee_details || null;
    tqdata.mySafety = !!cab.my_safety;
    tqdata.mySafetyText = cab?.my_safety_tag || '';
    tqdata.mySafetyDesc = holdCabResponse?.my_safety_text || '';
    tqdata.ePassChecklist = ePassChecklist;
    tqdata.similarMessage = cab.similar_message;
    tqdata.cabDriverDetails = cab.cab_driver_details;
    tqdata.overallRating = cab.ratings_display.overall_rating;
    tqdata.fareDetails = cab.fare_breakup_list;
    tqdata.totalPaidAmount = holdCabResponse.advance_payable_amount;
    tqdata.driverDetailsNotificationHrs = holdCabResponse.driver_details_notification_hrs;
    tqdata.driverDetailsNotificationTime = holdCabResponse.driver_details_notification_time;
    tqdata.inclusiveMessage = cab.inclusive_message;
    tqdata.pickupAddress = holdRequest.source_location.address;
    tqdata.pickupTime = holdRequest.pickup_time;
    tqdata.pickupTimeVal = holdRequest.pickup_time;
    tqdata.dropAddress = _isEmpty(holdRequest.destination_location)
      ? ''
      : holdRequest.destination_location.address;
    tqdata.totalPayableAmount = fareDetails.total_amount - fareDetails.coupon_amount;
    tqdata.departureDate = holdRequest.departure_date;
    tqdata.pickupTimeAMPM = 'AM';
    tqdata.managerMailId = holdResponse.response.manager_email_id || null;
    const pickUpHour = tqdata.pickupTimeVal.split(':')[0];
    const pickUpMin = tqdata.pickupTimeVal.split(':')[1];
    if (pickUpHour > 12) {
      tqdata.pickupTimeVal = `${pickUpHour - 12}:${pickUpMin}`;
      tqdata.pickupTimeAMPM = 'PM';
    } else if (pickUpHour === '12') {
      tqdata.pickupTimeAMPM = 'PM';
    }
    tqdata.fromToStr = tripHeaderData.title;
    tqdata.tripTypeDurationStr = `${tripHeaderData.subTitle} | ${fecha.format(
      fecha.parse(holdRequest.departure_date, 'DD-MM-YYYY'),
      'MMM D',
    )}`;
    if (holdRequest.trip_type === TripType.RT.value) {
      tqdata.tripTypeDurationStr = `${tqdata.tripTypeDurationStr} to ${fecha.format(
        fecha.parse(holdRequest.return_date, 'DD-MM-YYYY'),
        'MMM D',
      )}`;
      if (isMulticityEnabled && tripHeaderData.subTitle.includes('via')) {
        tqdata.tripTypeDurationStr = tripHeaderData.subTitle;
      }
    }
    tqdata.paxDetail = pax;
    tqdata.isAssured = fareDetails.is_assured;
    tqdata.isFlightAddOn = isFlightAddOn;
    tqdata.isInstantCab = isInstantCab;
    tqdata.pdtData = pdtData;
    await setCabDetailsForTqPage(tqdata);
  }
};

/**
 * Function to set show the toast for given frequency number of times
 * @param {frequency} - indicates the number of times the toast should be shown 
 * @returns boolean value weather to show the toast or not
 */

export const showToastWithGivenFrequency = async (frequency) => {
  try {
    let counter = parseInt(await AsyncStorage.getItem(SHOW_TOAST_COUNT)) || 0; 
    if (counter < frequency) {
      counter++;
      await AsyncStorage.setItem(SHOW_TOAST_COUNT, counter.toString());
      return true;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};