import { Platform } from 'react-native';
import cabsConfig from '../cabsConfig';
import { isRawClient } from '../PdtAnalytics/PdtHelper/CabsPdtUtils';
import { botmanSensorHeader } from '../../../Native/NetworkModule';
import { isCorporateUser } from 'src/Native/UserSession/UserSessionModule';
import { getSessionId, getPdtInfoForBE } from '../utils/cabsCommonUtils';
import { getSessionIdfromLocalStore } from '../utils/cabsLocalStore';

const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  session_id: getSessionId(),
};

const RESPONSE_STATUS = {
  SUCCESS: 200,
};

export const getHoldResponse = async (waitForCTD, isInitiateApproval = false, holdRequest) => {
  const sensorHeader = await botmanSensorHeader();
  return Promise.all([
    waitForCTD,
    _post(isInitiateApproval ? cabsConfig.myBizInitApprovalUrl : cabsConfig.holdUrl, holdRequest, {
      ...sensorHeader,
      ...DEFAULT_HEADERS,
      'x-source': holdRequest.misc_attributes.funnel_source,
    }).then((holdResponse) => holdResponse.json()),
  ]);
};

export const myBizOfflineFlowRequestor = async (request, mmtAuth) => {
  const requestHeaders = {
    'mmt-auth': mmtAuth,
    'Content-Type': 'application/json',
    session_id: getSessionId(),
  };
  const resp = await _post(cabsConfig.myBizOfflineFlow, request, requestHeaders);
  return await resp.json();
};

export const getProvisionalData = async (request) => {
  try {
    const res = await _post(cabsConfig.provisionalBooking, request);
    return await res.json();
  } catch (e) {
    return null;
  }
};

export const getProvisionalDataNonKiosk = async (request) => {
  try {
    const res = await _post(cabsConfig.provisionalBookingNonKiosk, request);
    return await res.json();
  } catch (e) {
    return null;
  }
};

export const getProvisionalDataHOB = async (request) => {
  try {
    const res = await _post(cabsConfig.provisionalBookingHOB, request);
    return await res.json();
  } catch (e) {
    return null;
  }
};

export const reserveNonKioskCab = async (url, request) => {
  try {
    const res = await _post(url, request);
    return await res.json();
  } catch (e) {
    return null;
  }
};

export const getValidateCouponResponse = async (validCouponRequest) => {
  const sensorHeader = await botmanSensorHeader();
  return _post(cabsConfig.validCouponUrl, validCouponRequest, {
    ...sensorHeader,
    ...DEFAULT_HEADERS,
  });
};

export const getDeleteCouponResponse = async (deleteCouponRequest) => {
  const sensorHeader = await botmanSensorHeader();
  return _post(cabsConfig.deleteCouponUrl, deleteCouponRequest, {
    ...sensorHeader,
    ...DEFAULT_HEADERS,
  });
};

export const getWalletBalanceResponse = (request) => _post(cabsConfig.walletBalanceURL, request);

export const getFetchCouponResponse = (fetchCouponRequest) =>
  _post(cabsConfig.fetchCouponUrl, fetchCouponRequest);

export const getSearchCabsResponse = async (request) => {
  const sensorHeader = await botmanSensorHeader();
  return _post(cabsConfig.searchUrl, request, { ...sensorHeader, ...DEFAULT_HEADERS });
};

export const getCabDetailResponse = (request) => _post(cabsConfig.reviewUrl, request);

export const getCheckFareResponse = async (request) => {
  const resp = await _post(
    cabsConfig.checkFare,
    request
  );
  return await resp.json();
};

export const getCheckFareResponseNonKiosk = async (request) => {
  const resp = await _post(
    cabsConfig.checkFareNonKiosk,
    request
  );
  return await resp.json();
};

export const getAssuranceData = async () => {
  const response = await _get(cabsConfig.getAssuranceDataUrl);
  return response.json();
};

export const getCheckFareHOB = async (request) => {
  const resp = await _post(
    cabsConfig.checkFareHOB,
    request
  );
  return await resp.json();
};

export const getHoldResponseForHOB =  async (request)=>{
  const resp = await _post(
    cabsConfig.holdUrl,
    request
  );
  return await resp.json();
};


export const getMyTripsDeepLink = (request) => _get(request, null);

export const getHotelMarketingResponse = async (bookingId) => {
  const resp = await _get(`${cabsConfig.hotelsCrossSell}${bookingId}`);
  return resp.json();
};

export const getHotelsCheckFareResponse = async (request) => {
  const resp = await _post(
    `${cabsConfig.hotelsCrossSell}checkFare`,
    request
  );
  return await resp.json();
}

export const getComIds = async (email = '', mobile = '') => {
  const request = {
    commIdList: [
      {
        value: email,
        type: 'F',
        action: 'ENCODE',
      },
      {
        value: mobile,
        type: 'N',
        valueCode: 91,
        action: 'ENCODE',
      },
    ],
  };

  try {
    const resp = await _post(cabsConfig.getEcomIdUrl, request);
    let response = await resp.json();
    response = response.commIdList;

    return {
      eComId: response[0].result,
      mComId: response[1].result,
    };
  } catch (e) {
    return {
      eComId: '',
      mComId: '',
    };
  }
};

export const getEmailSuggestion = async (query, mmtAuth, organizationId) => {
  const autoSuggestUrl = `${cabsConfig.fetchEmailSuggestion}/${query}?viewManagerDetails=false&from=0&pagesize=20&orgId=${organizationId}`;
  const auth = `mmtAuth="${mmtAuth}"`;
  const requestHeaders = {
    Cookie: auth,
    'Content-Type': 'application/json',
  };
  const response = await _getWithoutCredential(autoSuggestUrl, requestHeaders);
  return response.json();
};

export const inviteEmployeeMyBiz = async (request, mmtAuth) => {
  const requestHeaders = {
    'mmt-auth': mmtAuth,
    'Content-Type': 'application/json',
  };
  const resp = await _post(cabsConfig.inviteEmployeeMyBiz, request, requestHeaders);
  return resp.json();
};

export const getCompleteUserDetails = async (mmtAuth, type = 'userDetailsRequest') => {
  const resp = await _post(cabsConfig.userDetailsUrl, {
    mmtAuth,
    type,
  });
  return resp.json();
};

export const pushPdtData = async (pdtData) => {
  const PDT_ARRAY = [];
  PDT_ARRAY.push(pdtData);
  const apiDataArr = JSON.stringify(PDT_ARRAY);
  const xmlhttp = new XMLHttpRequest();
  xmlhttp.open('POST', cabsConfig.pdtUrl, true);
  xmlhttp.setRequestHeader('Content-type', 'application/json');
  xmlhttp.setRequestHeader('Content-Encoding', 'gzip');
  xmlhttp.setRequestHeader('Request-Data-Encoding', 'json');
  if (isRawClient()) {
    const pako = require('pako');
    const output = pako.gzip(apiDataArr);
    xmlhttp.send(output);
  } else {
    xmlhttp.send(apiDataArr);
  }
};

export const getBookingData = async (request) => {
  const sessionId = await getSessionIdfromLocalStore();
  const requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    session_id: sessionId,
  };
  const resp = await _post(`${cabsConfig.bookingDetailsApi}`, request, requestHeaders);
  return resp.json();
};

export const getHOBThankYouData = async (request) => {
  const sessionId = await getSessionIdfromLocalStore();
  const requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    session_id: sessionId,
  };
  const resp = await _post(`${cabsConfig.hob_ThankYouApi}`, request, requestHeaders);
  return resp.json();
};

export const getHOBDriverData = async (request) => {
  const sessionId = await getSessionIdfromLocalStore();
  const requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    session_id: sessionId,
  };
  const resp = await _post(`${cabsConfig.hob_DriverApi}`, request, requestHeaders);
  return resp.json();
};

export const getPersonalizedAirportRecommendation = async (uuid, isCorporate) => {
  const TIMEOUT = 1500; //ms 
  const request = { uuid, isCorporate, type: 'atPersonalisationRequest' };
  const promiseTimer = new Promise((resolve) => {
    setTimeout(resolve, TIMEOUT);
  });
  try {
    const response = await Promise.race([promiseTimer, fetchTripRecommendation(request)]);
    return response;

  } catch (e) {
    return {};
  }
};

const fetchTripRecommendation = async (request) => {
  try {
    const response = await _post(cabsConfig.personalizedTripRecommendation, request);
    return await response.json();
  } catch (e) {
    return {};
  }
};

export const getLandingCardsData = async (platform) => {
  const userType = platform === 'web' ? 'PERSONAL' : (await isCorporateUser()) ? 'BUSINESS' : 'PERSONAL';
  const resp = await _get(
    `${cabsConfig.getLandingCardsUrl}?platform=${platform}&userType=${userType}`,
  );
  return resp.json();
};

export const getBookingFareDetails = async (bookingId) => {
  const resp = await _get(`${cabsConfig.bookingFareDetailsApi}${bookingId}`);
  return resp.json();
};

export const getAddOnBookingData = async (flightBookingId) => {
  const request = {
    type: 'getAddonBookingDetailsRequest',
    flight_booking_id: flightBookingId,
  };

  const resp = await _post(cabsConfig.flightAddOnThankYouDataUrl, request);
  return resp.json();
};

export const initialDeeplinkByBookingId = async (bookingId) => {
  const request = {
    type: 'getAddonBookingDetailsRequest',
    flight_booking_id: bookingId,
  };
  try {
    const resp = await _post(cabsConfig.flightBookingDetailsUrl, request);
    return resp.json();
  } catch (e) {
    return null;
  }
};

export const getMyBizTripTagData = async (mmtAuth, vendorState, primaryPaxEmail) => {
  const request = {
    primary_pax_email_id: primaryPaxEmail,
    vendor_state: vendorState,
    book_mode: Platform.OS === 'android' ? 'A' : 'I',
    search_id: `SEARCH_ID_TRIP_TAG_${Math.random() * 9999999}`,
    type: 'myBizTripTagRequest',
  };
  const headers = {
    ...DEFAULT_HEADERS,
    'mmt-auth': mmtAuth,
  };
  try {
    const resp = await _post(cabsConfig.getMyBizTripTagUrl, request, headers);
    return resp.json();
  } catch (e) {
    return null;
  }
};

export const getRoutePlannerResponse = async (source, destination) => {
  const routePlannerUrl = `${cabsConfig.routePlannerUrl}&src=${source}&dst=${destination}`;
  try {
    const response = await _get(routePlannerUrl);
    const result = await response.json();
    return result;
  } catch (e) {
    // ignore
  }
};

export const getFlightAddOnDetail = (request) => _post(cabsConfig.flightAddOnDetailUrl, request);

export const updateFlightAddOnDetail = (request) =>
  _post(cabsConfig.updateFlightAddOnDetailUrl, request);

export const getUpdatedAddOnBookingDetail = request => _post(
  cabsConfig.flightAddOnUpdatedBookingDetailUrl,
  request
);

export const confirmFlightAddOnBooking = (request) =>
  _post(cabsConfig.confirmFlightAddOnUrl, request);

export const getBookingFeedbackDetails = async (bookingId) => {
  try {
    const response = await _get(`${cabsConfig.getBookingFeedbackUrl}?booking_id=${bookingId}`);
    const result = await response.json();
    return result;
  } catch (e) {
    // ignore
    return {};
  }
};

export const sendBookingFeedbackDetails = async (feedback) => {
  try {
    const response = await _post(`${cabsConfig.setBookingFeedbackUrl}`, feedback);
    const result = await response.json();
    if (
      'response-status' in result &&
      'payload' in result &&
      JSON.parse(result.payload).status.toLowerCase() === 'success'
    ) {
      return result;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const getCabReviews = async (request) => {
  try {
    const response = await _post(`${cabsConfig.fetchCabReviewsUrl}`, request);
    const result = await response.json();
    if ('reviews_and_ratings' in result) {
      return result.reviews_and_ratings || [];
    }
    return [];
  } catch (e) {
    return [];
  }
};

export const getOffers = async (request) => {
  try {
    const response = await _post(cabsConfig.getOffers, request);
    return await response.json();
  } catch (e) {
    return {};
  }
};

export const getApprovalScreenData = async (request) => {
  try {
    const response = await _post(cabsConfig.getApprovalDetailsUrl, request);
    return await response.json();
  } catch (e) {
    return {};
  }
};

export const getApprovalActionRes = async (request) => {
  try {
    const response = await _post(cabsConfig.approveRejectActionUrl, request);
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const getSkipAndPayActionRes = async (request) => {
  try {
    const response = await _post(cabsConfig.skipOrPayActionUrl, request);
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const getHydraSegment = async (mmtAuth, profileType) => {
  const resp = await _get(
    `${cabsConfig.hydraSegmentUrl}profile=${profileType}&combined_segments=true`,
    { 'mmt-auth': mmtAuth },
  );
  return await resp.json();
};

const _post = async (url, body, headers = DEFAULT_HEADERS) => {
  if (Platform.OS === 'web') {
    return _postWithPdtInfo(url, body, headers);
  }
  const { pdtDeviceInfo = null, pdtUserDetails = null } = await getPdtInfoForBE();
  body = {
    ...body,
    pdtDeviceInfo,
    pdtUserDetails
  }
  return _postWithPdtInfo(url, body, headers);
};

const _postWithPdtInfo = (url, body, headers = DEFAULT_HEADERS) =>
  fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  });

const _get = (request, headers = DEFAULT_HEADERS) =>
  fetch(request, {
    method: 'get',
    headers,
  });

const _getWithoutCredential = (request, headers = DEFAULT_HEADERS) =>
  fetch(request, {
    method: 'get',
    credentials: 'omit',
    headers,
  });


export const getQuickBookCabs = async (req) => {
  try {
    const resp = await _post(cabsConfig.quickBookCabs, req);
    return resp.json();
  } catch (error) {
    console.log(error)
  }
};

export const callOSAssistedFlowAPI = async (request) => {
  try {
    let response = await _post(cabsConfig.osAssistedFlowUrl, request, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    response = await response?.json();
    return response;
  } catch (e) {
    console.log('Error while fetching assited flow data: ', e);
    return [];
  }
};

export const serviceDeskRequestCall = async (request) => {
  try {
    let response = await _post(cabsConfig.serviceMyDeskUrl, request, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return response;
  } catch (e) {
    console.log('Error while calling service desk request: ', e);
    return [];
  }
};



export const getMgUserDetails= async (request) => {
  try {
    const response = await _get(`${cabsConfig.mgUserDetailsUrl}?token=${request.token}`, request,{
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-source':request.source
    });
    return await response.json();
  } catch (e) {
    return {};
  }
}