import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import { CityPickerAction, PICKER_STATE } from './CityPickerAction';
import { CabsScreen } from '../../config/cabsScreen';
import TripType, { AirportPickupType } from '../../types/TripType';
import CabsABConfigHelper from '../../utils/CabsABConfigHelper';

const initialState = {
  viewState: PICKER_STATE.INITIAL,
  showSourceLoading: false,
  showDestinationLoading: false,
  showLocationSettings: false,
  locationLoading: false,
  autoCompleteResults: [],
  recentPlaces: [],
  popularPlaces: [],
  sourceData: null,
  destinationData: null,
  sourcePlaceData: null,
  destinationPlaceData: null,
  selectedAirportTypeIndex: null,
  sourceFocused: false,
  destinationFocused: false,
  warnEmptySource: false,
  sourceText: '',
  destinationText: '',
  tripType: '',
  cityPickerHeader: null,
  sourceTitle: null,
  sourcePlaceholder: null,
  lastKnownLocation: null,
  handleAddressChange: null,
  currentLocation: null,
  screenName: CabsScreen.LANDING,
  handleBackPress: null,
  isDismissed: false,
  showRecentPlaces: true,
  fromCityPicker: false,
  isAddon: false,
  referenceLocation: null
};

const CityPickerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CityPickerAction.SET_INITIAL_PICKER_DATA: {
      const {
        disableSource,
        sourceData,
        destinationData,
        additionalCityData,
        destFocused,
        tripType,
        selectedAirportTypeIndex,
        additionalCityIndex,
        handleAddressChange,
        showLoading,
        handleBackPress,
        screenName,
        showToolTip,
        cityPickerHeader,
        sourceTitle,
        sourcePlaceholder,
        exactPickUpRequired,
        nearByLocation,
        showSingleInputView,
        addNewCity,
        showRecentPlaces,
        isAddon,
        referenceLocation,
      } = action.data;
      const sourceEmpty = _isEmpty(sourceData);
      const destinationEmpty = _isEmpty(destinationData);

      let destinationFocused = !sourceEmpty ? destFocused : false;
      let sourceFocused = !destinationFocused;
      const sourceText = sourceEmpty ? '' : sourceData.address;
      const destinationText = destinationEmpty ? '' : destinationData.address;

      if (showLoading) {
        sourceFocused = false;
        destinationFocused = false;
      }

      return {
        ...state,
        disableSource,
        viewState: PICKER_STATE.INITIAL,
        showSourceLoading: false,
        showDestinationLoading: false,
        showLocationSettings: false,
        locationLoading: false,
        autoCompleteResults: [],
        recentPlaces: [],
        sourceData,
        destinationData,
        additionalCityData,
        selectedAirportTypeIndex,
        additionalCityIndex,
        sourceFocused,
        destinationFocused,
        warnEmptySource: false,
        sourceText,
        destinationText,
        tripType,
        lastKnownLocation: null,
        handleAddressChange,
        showLoading,
        handleBackPress,
        screenName,
        showToolTip,
        cityPickerHeader,
        sourceTitle,
        sourcePlaceholder,
        exactPickUpRequired,
        nearByLocation,
        showSingleInputView,
        addNewCity,
        showRecentPlaces,
        isDismissed: false,
        isAddon,
        referenceLocation,
      };
    }
    case CityPickerAction.ACTION_SOURCE_CLEAR: {
      return {
        ...state,
        sourceText: '',
        sourceData: null,
        sourcePlaceData: null,
        sourceFocused: true,
        destinationFocused: false,
        autoCompleteResults: [],
        showDestination: state.destinationText.length > 0,
        viewState: PICKER_STATE.SUGGESTIONS
      };
    }
    case CityPickerAction.ACTION_DEST_CLEAR: {
      const enableDest = !_isNil(state.sourceData);
      return {
        ...state,
        destinationText: '',
        destinationData: null,
        destinationPlaceData: null,
        showDestination: enableDest,
        destinationFocused: enableDest,
        sourceFocused: !enableDest,
        autoCompleteResults: [],
        viewState: PICKER_STATE.INITIAL
      };
    }
    case CityPickerAction.ACTION_SOURCE_FOCUS: {
      const { additionalCityIndex, tripType, showSingleInputView } = state;
      const isMulticityEnabled = CabsABConfigHelper.multiCityEnabled();
      let { cityPickerHeader } = state;
      if (isMulticityEnabled && tripType === TripType.RT.value) {
        cityPickerHeader = 'Enter Pick-Up';
        if (_isNumber(additionalCityIndex) && showSingleInputView) {
          cityPickerHeader = 'Add City';
        }
      }
      return {
        ...state,
        viewState: PICKER_STATE.SUGGESTIONS,
        sourceFocused: true,
        warnEmptySource: false,
        destinationFocused: false,
        showHeader: false,
        cityPickerHeader
      };
    }
    case CityPickerAction.ACTION_DEST_FOCUS: {
      const { sourceData, tripType } = state;
      const isMulticityEnabled = CabsABConfigHelper.multiCityEnabled();
      const cityPickerHeader = isMulticityEnabled && tripType === TripType.RT.value ?
        'Enter Drop' :
        '';

      if (_isNil(sourceData)) {
        return {
          ...state,
          warnEmptySource: true,
          sourceFocused: false,
          destinationFocused: true,
          viewState: PICKER_STATE.SUGGESTIONS,
          cityPickerHeader
        };
      }
      return {
        ...state,
        sourceFocused: false,
        destinationFocused: true,
        showHeader: false,
        autoCompleteResults: [],
        viewState: PICKER_STATE.INITIAL,
        cityPickerHeader
      };
    }
    case CityPickerAction.ACTION_SOURCE_TEXT_CHANGE: {
      const hasText = action.data.length > 1;
      return {
        ...state,
        sourceText: action.data,
        locationLoading: false,
        showSourceLoading: hasText,
        viewState: hasText ? PICKER_STATE.AUTOCOMPLETE : PICKER_STATE.SUGGESTIONS,
        showSourceClear: hasText,
        showToolTip: false
      };
    }
    case CityPickerAction.ACTION_DEST_TEXT_CHANGE: {
      const hasText = action.data.length > 1;
      return {
        ...state,
        showDestinationLoading: hasText,
        destinationText: action.data,
        viewState: hasText ? PICKER_STATE.AUTOCOMPLETE : PICKER_STATE.INITIAL,
        showDestinationClear: hasText,
        showToolTip: false
      };
    }
    case CityPickerAction.ACTION_LOCATION_SELECT: {
      const { destinationData } = state;
      const showHeader = !_isNil(destinationData);
      const viewState = showHeader ? PICKER_STATE.INITIAL : PICKER_STATE.SUGGESTIONS;
      const { data } = action;
      return {
        ...state,
        locationLoading: false,
        showLocationPerm: false,
        showLocationSettings: false,
        showSourceLoading: false,
        sourceFocused: false,
        destinationFocused: !showHeader,
        currentLocation: data,
        lastKnownLocation: data,
        sourceText: data.address,
        sourceData: {
          ...data,
          type: 'location'
        },
        sourcePlaceData: null,
        showDestination: true,
        viewState,
        showHeader
      };
    }
    case CityPickerAction.ACTION_SET_LAST_KNOWN_LOCATION: {
      const { data: lastKnownLocation } = action;
      if (_isNil(lastKnownLocation)) {
        return state;
      }
      return {
        ...state,
        lastKnownLocation
      };
    }
    case CityPickerAction.ACTION_RECENT_PLACE_CLICKED: {
      const { place, address: addressObj, recentPlaceClicked } = action.data;
      const { sourceFocused, destinationFocused, destinationData } = state;
      if (sourceFocused) {
        return {
          ...state,
          sourceData: addressObj,
          sourceText: addressObj.address,
          sourcePlaceData: place,
          destinationFocused: _isEmpty(destinationData),
          sourceFocused: false,
          fromCityPicker: recentPlaceClicked
        };
      } else if (destinationFocused) {
        return {
          ...state,
          destinationData: addressObj,
          destinationPlaceData: place,
          destinationText: addressObj.address,
          sourceFocused: false,
          destinationFocused: false,
          fromCityPicker: recentPlaceClicked
        };
      }
      break;
    }
    case CityPickerAction.ACTION_AUTO_COMPLETE_RESULTS: {
      return {
        ...state,
        showSourceLoading: false,
        showDestinationLoading: false,
        autoCompleteResults: action.data
      };
    }
    case CityPickerAction.ACTION_AUTO_COMPLETE_RESULTS_SELECT: {
      const { place, placeDetails } = action.data;
      const { destinationData, sourceFocused, destinationFocused } = state;
      const showHeader = !_isNil(destinationData);
      const viewState = showHeader ? PICKER_STATE.INITIAL : PICKER_STATE.SUGGESTIONS;
      if (sourceFocused) {
        return {
          ...state,
          sourceFocused: false,
          destinationFocused: true,
          sourceText: place.name,
          sourceData: placeDetails,
          sourcePlaceData: place,
          showDestination: true,
          viewState,
          showHeader
        };
      }
      if (destinationFocused) {
        return {
          ...state,
          showHeader: true,
          viewState: PICKER_STATE.INITIAL,
          destinationText: place.name,
          destinationData: placeDetails,
          destinationPlaceData: place,
          sourceFocused: false,
          destinationFocused: false,
        };
      }
      break;
    }
    case CityPickerAction.ACTION_SWAP_SEARCH: {
      const {
        sourceText, destinationText, sourceData, destinationData,
        sourcePlaceData, destinationPlaceData, selectedAirportTypeIndex, tripType
      } = state;
      let airportIndex = selectedAirportTypeIndex;
      if (tripType === TripType.AT.value) {
        airportIndex = airportIndex === AirportPickupType.FROM.value ?
          AirportPickupType.TO.value : AirportPickupType.FROM.value;
      }
      return {
        ...state,
        sourceText: destinationText,
        destinationText: sourceText,
        sourceData: destinationData,
        destinationData: sourceData,
        destinationPlaceData: sourcePlaceData,
        sourcePlaceData: destinationPlaceData,
        selectedAirportTypeIndex: airportIndex
      };
    }
    case CityPickerAction.ACTION_SET_RECENT_PLACES: {
      return {
        ...state,
        recentPlaces: action.data
      };
    }
    case CityPickerAction.ACTION_SET_POPULAR_SEARCHES: {
      return {
        ...state,
        popularPlaces: action.data
      };
    }
    case CityPickerAction.ACTION_LOCATION_LOADING: {
      return {
        ...state,
        locationLoading: true
      };
    }
    case CityPickerAction.ACTION_LOCATION_ERROR: {
      return {
        ...state,
        locationLoading: false
      };
    }
    case CityPickerAction.ACTION_ASK_LOCATION_PERM: {
      return {
        ...state,
        showLocationPerm: true
      };
    }
    case CityPickerAction.ACTION_ASK_LOCATION_SETTINGS: {
      return {
        ...state,
        showLocationSettings: true
      };
    }
    case CityPickerAction.ACTION_LOCATION_HIDE_LOCATION_PERM: {
      return {
        ...state,
        showLocationPerm: false,
        locationLoading: false
      };
    }
    case CityPickerAction.ACTION_LOCATION_HIDE_LOCATION_SETTINGS: {
      return {
        ...state,
        showLocationSettings: false,
        locationLoading: false
      };
    }
    case CityPickerAction.ACTION_SET_CITY_PICKER_LOCATION: {
      const { sourceData, destinationData, sourceText, destinationText, selectedAirportTypeIndex } = action.data;
      return {
        ...state,
        sourceData,
        destinationData,
        sourceText,
        destinationText,
        selectedAirportTypeIndex,
        showLoading: false
      };
    }
    case CityPickerAction.ACTION_UPDATE_SELECTED_AIRPORT_INDEX: {
      return {
        ...state,
        selectedAirportTypeIndex: action.data
      };
    }
    case CityPickerAction.ACTION_DISMISS_CITY_PICKER: {
      return {
        ...state,
        isDismissed: true
      };
    }
    case CityPickerAction.ACTION_SET_CITY_PICKET_SCREEN: {
      return {
        ...state,
        fromCityPicker: action.data
      }
    }
    default:
      break;
  }
  return state;
};

export default CityPickerReducer;
