import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View, Platform } from 'react-native';
import { colors, fonts } from 'src/Styles/globalStyles';
import TouchableRipple from '../../../Common/Components/TouchableRipple';
import InputView from '../Components/InputView';
import { CabsTestIDs } from '../CabsTestIDs';

export const HyperlocationStates = {
  HYPERLOCATION_AVAILABLE: 'hyperlocation_available',
  HYPERLOCATION_REQUIRED: 'hyperlocation_required',
  HYPERLOCATION_UPDATED: 'hyperlocation_updated',
  HYPERLOCATION_ERROR: 'hyperlocation_error',
};

const FormFieldsStyleMap = {
  BLUR: {
    backgroundColor: colors.grey14,
    borderColor: colors.lightGrey,
    labelColor: colors.lightTextColor,
  },
  ERROR: {
    backgroundColor: colors.white,
    borderColor: colors.red,
    labelColor: colors.red,
  },
};

class PickUpDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  _setPickUpDetailsLayout = () => {
    if (this._pickUpView) {
      this._pickUpView.measure((fx, fy, width, height, px, py) => {
        this.props.onLayout(py, this._pickUpView);
      });
    }
  };

  _onPickUpPress = () => {
    if (Platform.OS === 'web') {
      this.props.onPress();
      return;
    }
    if (this.props.hyperlocationState === HyperlocationStates.HYPERLOCATION_AVAILABLE) {
      return;
    }
    this.props.onPress();
  };

  _getInputStyles = () => {
    let style = FormFieldsStyleMap.BLUR;
    if (this.props.hyperlocationState === HyperlocationStates.HYPERLOCATION_ERROR) {
      style = FormFieldsStyleMap.ERROR;
    }
    return style;
  };

  render() {
    const {
      sourceData, hyperlocationState, isSource = true,
    } = this.props;
    let label = isSource ? "PICK-UP FROM" : "DROP-OFF";
    let pickUpInputMessage = isSource ? 'Enter pick-up address / nearby area' : 'Enter drop address'
    let fromStyle = styles.fromStyle;
    let underlineColor = colors.white;
    let pickUpInputMessageStyle = styles.pickUpInputMessage;
    switch (hyperlocationState) {
      case HyperlocationStates.HYPERLOCATION_AVAILABLE:
        pickUpInputMessage = sourceData.address;
        break;

      case HyperlocationStates.HYPERLOCATION_REQUIRED:
        underlineColor = colors.disabledButton;
        break;

      case HyperlocationStates.HYPERLOCATION_UPDATED:
        underlineColor = colors.disabledButton;
        pickUpInputMessage = sourceData.address;
        pickUpInputMessageStyle = [pickUpInputMessageStyle, { color: colors.defaultTextColor }];
        break;

      case HyperlocationStates.HYPERLOCATION_ERROR:
        fromStyle = [fromStyle, { color: colors.red }];
        underlineColor = colors.red;
        break;
      default:
      //
    }

    return (
      <View
        ref={(e) => {
          this._pickUpView = e;
        }}
        onLayout={this._setPickUpDetailsLayout}
      >
        <TouchableRipple onPress={this._onPickUpPress} testID={CabsTestIDs.Review_PickUpAddress}>
          <View style={styles.container}>
            <View style={styles.tripDetailsView}>
              {isSource && hyperlocationState !== HyperlocationStates.HYPERLOCATION_AVAILABLE && (
                <Text style={styles.pickUpDetailsMessage}>
                  This will help us update your exact price.
                </Text>
              )}
              <View style={{ marginRight: 16 }}>
                <InputView
                  label={label}
                  value={pickUpInputMessage}
                  {...this._getInputStyles()}
                  defaultValueColor={
                    this.props.hyperlocationState !== HyperlocationStates.HYPERLOCATION_UPDATED &&
                      Platform.OS !== 'web'
                      ? colors.lightTextColor
                      : colors.black
                  }
                />
              </View>
              {hyperlocationState === HyperlocationStates.HYPERLOCATION_ERROR && (
                <Text style={[fromStyle, { fontSize: 12, marginTop: 6 }]}>
                  We need your exact location to proceed
                </Text>
              )}
            </View>
          </View>
        </TouchableRipple>
      </View>
    );
  }
}

PickUpDetails.propTypes = {
  onPress: PropTypes.func.isRequired,
  sourceData: PropTypes.object.isRequired,
  hyperlocationState: PropTypes.string.isRequired,
  onLayout: PropTypes.func.isRequired,
};

PickUpDetails.defaultProps = {
  isSource: true,
};
const styles = StyleSheet.create({
  container: {
    marginBottom: 13,
  },
  tripDetailsView: {
    marginLeft: 15,
  },
  pickUpDetails: {
    fontSize: 16,
    color: colors.black,
    fontFamily: fonts.bold,
    marginBottom: 10,
  },
  pickUpDetailsMessage: {
    fontSize: 14,
    color: colors.lightGrey4,
    fontFamily: fonts.regular,
    marginBottom: 12,
  },
  fromStyle: {
    fontSize: 10,
    color: colors.lightGrey4,
    fontFamily: fonts.regular,
  },
  pickUpInputMessage: {
    fontSize: 14,
    color: Platform.select({
      android: 'rgba(151,151,151, 0.9)',
      ios: 'rgba(151,151,151, 0.6)',
      web: 'rgba(151,151,151, 0.4)',
    }),
    fontFamily: fonts.bold,
    paddingLeft: 0,
    marginRight: 15,
  },
});

export default PickUpDetails;
