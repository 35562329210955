import React from "react";
import { AppRegistry, View } from "react-native";
import AuthenticateUser from "MMT-UI/authenticateUser/AuthenticateUser";
import WebRouter from "./WebRouter";
import "./WebModules/OmnitureModule";
import "./WebModules/UserSessionModules";
import "./WebModules/AbConfigModule";
import Toast from "../Mobile-mmt-react-native/src/Common/Components/Toast2";
import { initAbConfig } from "../Mobile-mmt-react-native/src/Native/AbConfig/AbConfigModule";
import MMTToast from "../Mobile-mmt-react-native/packages/ui/components/toast";
import { CLARITY_PROJECT_ID, ENABLE_CLARITY } from "./webConfig";
import { init as initApm } from '@elastic/apm-rum';

const customStyle = `
  #persuasionScrollList::-webkit-scrollbar {
    display: none;
    background: transparent; /* make scrollbar transparent */
  }
  #persuasionScrollList {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  }
  #skuLevelPersuasionTicker ::-webkit-scrollbar {
    display: none;
    background: transparent; /* make scrollbar transparent */
  }
  #skuLevelPersuasionTicker {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  }
  .appDnld { /* Using for AppDownloadWidget on Landing*/
    box-sizing: border-box;
  }
  #promoImageNonKiosk {
    position: relative;
  }
`;

const App = () => (
  <View style={{ flex: 1 }}>
    <AuthenticateUser />
    <WebRouter />
    <Toast />
    <MMTToast />
  </View>
);

initApm({
  serviceName: 'CABS-RNW',
  serverUrl: process.env.NODE_ENV === 'production' ? 'https://cabs-apm.makemytrip.com' : 'http://172.16.217.90:8200',
  environment: process.env.NODE_ENV === 'production' ? 'production' : 'staging'
});

const insertCustomStyle = () => {
  let style = document.createElement("style");
  document.head.appendChild(style);
  style.type = "text/css";
  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = customStyle;
  } else {
    style.appendChild(document.createTextNode(customStyle));
  }
};

initAbConfig().finally(() => {
  const rootElement = window.document.getElementById("react-root");
  rootElement.innerHTML = "";
  insertCustomStyle();
  AppRegistry.registerComponent("MmtReactNative", () => App);
  AppRegistry.runApplication("MmtReactNative", {
    rootTag: rootElement,
  });
});

if (window) {
  if ("serviceWorker" in window.navigator) {
    window.addEventListener("load", () => {
      window.navigator.serviceWorker
        .register("/rn/service-worker.js")
        .then((registration) => {
          console.log("SW registered: ", registration);
        })
        .catch((registrationError) => {
          console.log("SW registration failed: ", registrationError);
        });
    });
    /*
    window.addEventListener('touchmove', function(event) {
      console.log(event.source);
      //if (event.source == document.body)
      event.preventDefault();
    }, {
      passive: false,
      useCapture: false
    });
*/
  }

  window.addEventListener("pageshow", (event) => {
    const historyTraversal =
      event.persisted ||
      (typeof window.performance !== "undefined" &&
        window.performance.navigation.type === 2);
    if (historyTraversal) {
      // Handle page restore.
      window.location.reload();
    }
  });
}

/*try {
  Raven.config('https://3fde8daf8d044272abb0a4c79733e951@bugs.goibibo.com/143').install({
    shouldSendCallback: () => {
      return !__DEV__;
    },
  });
} catch (e) {
  console.error('error while loading raven', e);
}*/

function landscapeLock() {
  const winW = window.innerWidth;
  const landscapeOnlyEl = window.document.getElementById("landscape_screen");
  const rootElement = window.document.getElementById("react-root");
  if (winW > 700) {
    rootElement.style.display = "none";
    landscapeOnlyEl.style.display = "flex";
  } else {
    rootElement.style.display = "flex";
    landscapeOnlyEl.style.display = "none";
  }
}
window.onresize = landscapeLock;
landscapeLock();

if (ENABLE_CLARITY) {
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", CLARITY_PROJECT_ID);
}
