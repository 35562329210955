import React, { Component, useEffect } from 'react';
import { Image, StyleSheet, Text, View, ImageBackground } from 'react-native';
import RupeeText from 'src/Common/Components/RupeeText';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import BottomSheetModal from 'src/Common/Components/Modals/BottomSheetModal';
import { colors, fonts } from 'src/Styles/globalStyles';
import { rupeeFormatter } from 'src/Helpers/currencyUtils';
import TouchableRipple from '../../../Common/Components/TouchableRipple';
import { trackReviewEvent } from '../cabsAnalytics';
import { seggregateContent } from '../utils/cabsCommonUtils';
import { CabsTestIDs } from '../CabsTestIDs';

const paymentTag = require('../../../Assets/payment_tag.webp');
const radioChecked = require('../../../Assets/radio_blue_active.webp');
const radioUnchecked = require('../../../Assets/radio_blue_inactive.webp');

export const PaymentMode = {
  part: 'part',
  full: 'full',
  zero: 'zero',
};

export const AvailablePaymentOptions = ({
  couponCode = '',
  isFullPaymentOnly = false,
  selected,
  partPercent,
  partPaymentText: partPaymentTitle,
  partAmount,
  fullAmount,
  fullPaymentText,
  partPaymentSubLabel: partPaymentSubTitle,
  onOptionChange,
  tripGuaranteeInfo = null,
}) => {
  let zeroPayment = false;
  let partPaymentMode = PaymentMode.part;
  if (partAmount === 0) {
    zeroPayment = true;
    partPaymentMode = PaymentMode.zero;
    partPaymentTitle = 'Book Now at \u20B9 0';
    partPaymentSubTitle = 'Pay in cash to driver';
  }
  useEffect(() => {
    if (isFullPaymentOnly) {
      onOptionChange(PaymentMode.full);
    }
  }, [isFullPaymentOnly]);
  const amountFullPayDisplay = isFullPaymentOnly
    ? [styles.amount, { color: colors.greyish }]
    : styles.amount;
  const amountDisplayStyle =
    selected === partPaymentMode ? [styles.amount, { color: colors.black }] : amountFullPayDisplay;
  return (
    <View style={{ marginVertical: 10 }}>
      <Text style={styles.title}>Payment options</Text>
      {Boolean(tripGuaranteeInfo) && (
        <View
          style={[styles.tripGuaranteeInfoView, { backgroundColor: tripGuaranteeInfo.bg_color }]}
        >
          <Text style={styles.tripGuaranteeInfoText}>
            {seggregateContent(
              tripGuaranteeInfo.text,
              { fontFamily: fonts.black },
              { fontFamily: fonts.regular },
            )}
          </Text>
        </View>
      )}
      <TouchableRipple
        disabled={isFullPaymentOnly}
        onPress={() => onOptionChange(partPaymentMode)}
        feedbackColor={colors.transparent}
        testID={CabsTestIDs.Review_PartialPaymentButton}
      >
        <View style={styles.optionContainer}>
          <View style={{ flexDirection: 'row' }}>
            <RadioButton checked={selected === partPaymentMode && !isFullPaymentOnly} />
            <View style={styles.marginTop8}>
              <RupeeText
                style={
                  isFullPaymentOnly
                    ? [styles.option, { fontFamily: fonts.regular, color: colors.greyish }]
                    : styles.option
                }
              >
                {partPaymentTitle}
              </RupeeText>
              {partPaymentSubTitle ? (
                <Text style={styles.optionSub}>{partPaymentSubTitle} </Text>
              ) : null}
              <View style={{ marginLeft: 8, marginBottom: 8 }}>
                <ImageBackground
                  style={styles.paymentTagBackground}
                  imageStyle={{ resizeMode: 'stretch' }}
                  source={paymentTag}
                >
                  {!isFullPaymentOnly && selected === partPaymentMode && (
                    <Text style={styles.paymentTagText}>
                      {zeroPayment ? '100% CONFIRMED BOOKING' : 'CONFIRMED BOOKING'}
                    </Text>
                  )}
                </ImageBackground>
              </View>
            </View>
          </View>
          {!zeroPayment && (
            <RupeeText style={amountDisplayStyle}>{`\u20B9 ${rupeeFormatter(
              partAmount,
            )}`}</RupeeText>
          )}
        </View>
      </TouchableRipple>
      <TouchableRipple
        onPress={() => {
          if (Boolean(tripGuaranteeInfo)) {
            trackReviewEvent('Mob_Cab_Review_TG_Full_Pay_Selected');
          }
          onOptionChange(PaymentMode.full);
        }}
        feedbackColor={colors.transparent}
        testID={CabsTestIDs.Review_FullPaymentButton}
      >
        <View>
          <View style={styles.optionContainer}>
            <View style={{ flexDirection: 'row' }}>
              <RadioButton checked={selected === PaymentMode.full || isFullPaymentOnly} />
              <View style={styles.marginTop8}>
                <Text style={styles.option}>{fullPaymentText}</Text>
                <View style={{ marginLeft: 8, marginBottom: 8 }}>
                  <ImageBackground
                    style={styles.paymentTagBackground}
                    imageStyle={{ resizeMode: 'stretch' }}
                    source={paymentTag}
                  >
                    {!isFullPaymentOnly && selected === PaymentMode.full && (
                      <Text style={styles.paymentTagText}>CONFIRMED BOOKING</Text>
                    )}
                  </ImageBackground>
                </View>
              </View>
            </View>
            <RupeeText
              style={
                selected === PaymentMode.full
                  ? [styles.amount, { color: colors.black }]
                  : styles.amount
              }
            >{`\u20B9 ${rupeeFormatter(fullAmount)}`}</RupeeText>
          </View>
          {isFullPaymentOnly && (
            <View style={styles.fullPaymentOnly}>
              <Text style={styles.boldFullPayment}>{couponCode} coupon applied!</Text>
              <Text style={styles.regularFullPayment}>
                This coupon is applicable only for full payment.
              </Text>
            </View>
          )}
        </View>
      </TouchableRipple>
    </View>
  );
};

AvailablePaymentOptions.propTypes = {
  selected: PropTypes.string.isRequired,
  partPercent: PropTypes.number,
  partAmount: PropTypes.number,
  fullAmount: PropTypes.number.isRequired,
  onOptionChange: PropTypes.func.isRequired,
};

AvailablePaymentOptions.defaultProps = {
  partPercent: 0,
  partAmount: 0,
};

class PaymentOptions extends Component {
  _onPaymentOptionChange = (option) => {
    switch (option) {
      case PaymentMode.full:
        trackReviewEvent('Mob_Cabs_Review_Bottom_Bar_Section_Pay_Full_Click');
        break;
      case PaymentMode.part:
        trackReviewEvent('Mob_Cabs_Review_Bottom_Bar_Section_Pay_Part_Click');
        break;
      case PaymentMode.zero:
        trackReviewEvent('Mob_Cabs_Review_Bottom_Bar_Section_Pay_To_Driver_Click');
        break;
      default:
        break;
    }
    this.props.onPaymentOptionChange(option);
  };

  render() {
    const selectedAmount = rupeeFormatter(
      this.props.selectedOption === PaymentMode.full
        ? this.props.fullAmount
        : this.props.partAmount,
    );
    return (
      <BottomSheetModal
        onTouchOutside={this.props.onTouchOutside}
        additionalContainerStyle={{ zIndex: 10, elevation: 5, marginBottom: 61 }}
      >
        <View style={styles.container}>
          <AvailablePaymentOptions
            couponCode={this.props.couponCode}
            selected={this.props.selectedOption}
            partPercent={this.props.partPercent}
            partAmount={this.props.partAmount}
            fullAmount={this.props.fullAmount}
            onOptionChange={(option) => this._onPaymentOptionChange(option)}
            partPaymentText={this.props.partPaymentText}
            fullPaymentText={this.props.fullPaymentText}
            partPaymentSubLabel={this.props.partPaymentSubLabel}
            tripGuaranteeInfo={this.props.tripGuaranteeInfo}
            isFullPaymentOnly={this.props.isFullPaymentOnly}
          />
        </View>
      </BottomSheetModal>
    );
  }
}

const RadioButton = ({ checked }) => (
  <Image
    style={{
      width: 28,
      height: 28,
      margin: 4,
    }}
    source={checked ? radioChecked : radioUnchecked}
  />
);

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
};

PaymentOptions.propTypes = {
  selectedOption: PropTypes.string,
  fullAmount: PropTypes.number.isRequired,
  partAmount: PropTypes.number.isRequired,
  partPercent: PropTypes.number.isRequired,
  onTouchOutside: PropTypes.func.isRequired,
  onPaymentOptionChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

PaymentOptions.defaultProps = {
  selectedOption: PaymentMode.full,
};

export default PaymentOptions;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    padding: 8,
  },
  title: {
    color: colors.black,
    fontSize: 20,
    fontFamily: fonts.black,
    marginLeft: 15,
    marginBottom: 10,
  },
  optionContainer: {
    flexDirection: 'row',
    paddingLeft: 8,
    paddingRight: 12,
    justifyContent: 'space-between',
  },
  option: {
    color: colors.black,
    fontSize: 16,
    marginLeft: 8,
    fontFamily: fonts.bold,
  },
  optionSub: {
    color: '#7b7b7b',
    marginLeft: 8,
    fontSize: 14,
    fontFamily: fonts.regular,
  },
  amount: {
    color: colors.darkGreyish,
    fontSize: 18,
    fontFamily: fonts.bold,
    alignSelf: 'center',
  },
  paymentTagBackground: {
    marginTop: 5,
    width: null,
    height: null,
  },
  paymentTagText: {
    color: colors.white,
    fontSize: 12,
    fontFamily: fonts.bold,
    paddingTop: 2,
    paddingBottom: 4,
    paddingLeft: 6,
    paddingRight: 14,
  },
  fullPaymentOnly: {
    backgroundColor: colors.cruise,
    marginLeft: 50,
    marginRight: 16,
    paddingVertical: 6,
    paddingLeft: 10,
    paddingRight: 4,
    borderRadius: 5,
  },
  boldFullPayment: {
    fontSize: 10,
    fontFamily: fonts.black,
    color: colors.green,
  },
  regularFullPayment: {
    fontSize: 10,
    fontFamily: fonts.regular,
    color: colors.green,
  },
  tripGuaranteeInfoView: {
    padding: 10,
    marginBottom: 15,
    borderRadius: 4,
    marginHorizontal: 10,
  },
  tripGuaranteeInfoText: {
    fontSize: 12,
    lineHeight: 14.4,
    color: colors.black,
  },
  marginTop8: {
    marginTop: 8,
  },
});

PaymentOptions.navigationOptions = {
  header: null,
};
