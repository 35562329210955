import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import { getAppConfiguration, setAppConfiguration } from './utils/cabsLocalStore';
import cabsConfig from './cabsConfig';
import TripType from './types/TripType';
import { updateInstantCabOffer, setCommonPokusFlags, setAssistedFlowData } from './cabsCommonActions';
import {
  setCabConfigDefaultDateTime,
  setHRPackages,
  setRideNotAvailableError,
  setBnplTripTypes,
  setTripPersuationData,
} from './Landing/cabsLandingActions';
import { getSessionId } from './utils/cabsCommonUtils';
import { isCorporateUser, ProfileType } from 'src/Native/UserSession/UserSessionModule';

let _cabConfig = null;
let _cabConfigCalled = false;
const MAX_MULTICITY_STOPS = 5;

const _fetchAppConfiguration = async (dispatch) => {
  try {
    const response = await fetch(cabsConfig.configUrl, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        session_id: getSessionId(),
        profile_type: (await isCorporateUser()) ? ProfileType.BUSINESS : ProfileType.PERSONAL,
      },
      timeout: 5000,
    });
    const configResponse = await response.json();
    if (configResponse.status !== 'FAILURE' && configResponse.errors === null) {
      setAppConfiguration(configResponse.response);
      _cabConfig = configResponse.response;
      if (Boolean(_cabConfig?.search_default_date) && Boolean(_cabConfig?.search_default_time)) {
        dispatch(
          setCabConfigDefaultDateTime(
            _cabConfig?.search_default_date,
            _cabConfig?.search_default_time,
          ),
        );
      }
      const tripPersuationData = getCabsTripTypePersuasion();
      if (Boolean(tripPersuationData)) {
        dispatch(setTripPersuationData(tripPersuationData));
      }
      if (Boolean(_cabConfig?.package_duration_filter)) {
        dispatch(setHRPackages(_cabConfig?.package_duration_filter));
      }
      if (Boolean(_cabConfig?.instant_cabs_unavailability_text)) {
        dispatch(setRideNotAvailableError(_cabConfig?.instant_cabs_unavailability_text));
      }
      if (_cabConfig.instant_cabs_promo) {
        dispatch(updateInstantCabOffer([_cabConfig.instant_cabs_promo] || []));
      }
      if (Boolean(_cabConfig?.bnpl_triptypes)) {
        dispatch(setBnplTripTypes(_cabConfig?.bnpl_triptypes));
      }
      if (Boolean(_cabConfig?.pokus_flags)) {
        dispatch(setCommonPokusFlags(_cabConfig?.pokus_flags));
      }
      if (_cabConfig.review_page_assisted_flow) {
        dispatch(setAssistedFlowData(_cabConfig.review_page_assisted_flow));
      }
    }
  } catch (e) {
    // Ignore exception
  }
};

export const getMaxStopsLimit = () => {
  if (_cabConfig && _cabConfig.max_intermediate_stops) {
    return _cabConfig.max_intermediate_stops;
  }
  return MAX_MULTICITY_STOPS;
};

export const getInstantOffer = () => {
  if (_cabConfig && _cabConfig.instant_cabs_promo) {
    return _cabConfig.instant_cabs_promo;
  }
  return [];
};

export const isInstantAvailable = (airportCode) => {
  try {
    if (_cabConfig.instant_cabs_cities.includes(airportCode)) {
      return true;
    }
  } catch (e) { }
  return false;
};

export const initCabConfig = async (dispatch) => {
  _cabConfig = await getAppConfiguration();
  try {
    if (_cabConfig.instant_cabs_promo) {
      dispatch(updateInstantCabOffer([_cabConfig.instant_cabs_promo] || []));
    }
  } catch (e) {
    //  Ignore exception
  }
  if (_isEmpty(_cabConfig) || !_cabConfigCalled) {
    _cabConfigCalled = true;
    await _fetchAppConfiguration(dispatch);
  }
};

export const getDefaultAP = (trip_type) => {
  try {
    return _cabConfig.default_ap[trip_type];
  } catch (e) {
    return TripType[trip_type].defaultAP;
  }
};

export const getMinimumAP = (trip_type) => {
  try {
    return _cabConfig.minimum_ap[trip_type];
  } catch (e) {
    return cabsConfig.minTatHrs;
  }
};

export const getMinimumAPInMinutes = (trip_type) => {
  try {
    return _cabConfig.minimum_ap_in_minutes[trip_type];
  } catch (e) {
    return cabsConfig.minTatHrs;
  }
};

export const getMaxCalenderDays = () => {
  if (!_isNull(_cabConfig) && _cabConfig.max_calender_days > 0) {
    return _cabConfig.max_calender_days;
  }
  return cabsConfig.cabsCalendarMaxDays;
};

export const getCabsTripTypePersuasion = () => {
  if (!_isNull(_cabConfig) && Boolean(_cabConfig?.info_toast)) {
    const infoData = _cabConfig?.info_toast;
    return {
      OW: infoData?.OS_OW,
      RT: infoData?.OS_RT,
      FROM_AIRPORT: infoData?.FROM_AT,
      TO_AIRPORT: infoData?.TO_AT,
      HR: infoData?.HR,
      OW_M: infoData?.OS_M_OW,
      RT_M: infoData?.OS_M_RT,
    };
  }
  return {
    OW: 'Includes one pick up & drop',
    RT: 'Retain cab till return & include multiple stops',
    FROM_AIRPORT: 'Pre-book airport pick ups',
    TO_AIRPORT: 'Pre-book airport drops',
    HR: 'Multi stops, one cab for local travel',
    OW_M: 'Include multiple stops',
    RT_M: 'Return trip with multiple city halts',
  };
};

export const getLocationSearchRadius = () => {
  if (!_isEmpty(_cabConfig) && _cabConfig.location_search_radius > 0) {
    return _cabConfig.location_search_radius;
  }
  return cabsConfig.locationSearchRadius;
};

export const getDefaultRoundTripHrs = () => {
  if (!_isNull(_cabConfig) && _cabConfig.default_round_trip_hrs > 0) {
    return _cabConfig.default_round_trip_hrs;
  }
  return cabsConfig.defaultRoundTripDuration;
};

export const getMinTravelTimeHrs = () => {
  if (!_isNull(_cabConfig) && _cabConfig.min_travel_time_hrs >= 0) {
    return _cabConfig.min_travel_time_hrs;
  }
  return cabsConfig.minTravelTimeHrs;
};

export const getMulticityCount = () => {
  if (!_isEmpty(_cabConfig) && _cabConfig.additional_cities_count > 0) {
    return _cabConfig.additional_cities_count;
  }
  return cabsConfig.additionalCitiesCount;
};

export const getShouldCallNearbyApi = () => {
  if (!_isEmpty(_cabConfig) && 'enable_airport_radial_search' in _cabConfig) {
    return _cabConfig.enable_airport_radial_search;
  }
  return cabsConfig.is_location_based_search;
};

export const getRadiusForLocationBasedSearch = () => {
  if (!_isEmpty(_cabConfig) && _cabConfig.search_radius_kms > 0) {
    return _cabConfig.search_radius_kms;
  }
  return cabsConfig.locationSearchRadius;
};

export const getCommonPokusFlags = () => {
  return _cabConfig?.pokus_flags ?? {};
};

export const getRTRevampUserMessage = () => {
  if (!_isEmpty(_cabConfig) && _cabConfig.rt_trip_end_info_text) {
    return _cabConfig?.rt_trip_end_info_text;
  }
  return cabsConfig.rtRevampText;
};
