// @flow
import fecha from 'fecha'


const oneHour = 60 * 60 * 1000;
const oneMinute = 60 * 1000;
const oneDay = 24 * oneHour;

export const DATE_MONTH = "DD MMM"
export const DAY_DATE_MONTH = "ddd DD MMM"
export const DATE_MONTH_HOUR_MIN_A = "DD MMM hh:mm a"
export const DATE_MONTH_HOUR_MIN_CAP_A = "DD MMM hh:mm A"
export const MONTH_DATE_COMMA_HOUR_MIN_CAP_A = "MMM DD, hh:mm A"
export const DAY_COMMA_DATE_MONTH = "ddd, DD MMM"
export const DAY_COMMA_DATE_MONTH_YEAR = "ddd, DD MMM 'YY"
export const COMMA_DATE_MONTH_YEAR = "DD MMM 'YY"
export const HOUR_MIN_24 = "HH:mm"
export const HOUR_MIN_12 = "hh:mm a"
export const HOUR_MIN_a = "hh:mm a"
export const HOUR_MIN_A = "hh:mm A"
export const YEAR_MONTH_DATE_HOUR_MIN_SEC = "YYYY-MM-DD hh:mm:ss"
export const POSTSALES_DATE_TIME = "YYYY-MM-DDTHH:mm:ss"
export const POSTSALES_DATE_TIME_ZONE = "YYYY-MM-DDTHH:mm:ss.SSZZ"
export const POSTSALES_DATE_TIME_ZONE_NO_MS = "YYYY-MM-DDTHH:mm:ss.Z"
export const MONTH_QUOTE_HALF_YEAR = "MMM 'YY"
export const CAB_ETA = "Do MMM YYYY [at] hh:mm a"
export const TIME_DATE = "HH:mm, DD MMM"

export type DateTimeFormats =
  | DATE_MONTH
  | DAY_DATE_MONTH
  | DATE_MONTH_HOUR_MIN_A
  | DATE_MONTH_HOUR_MIN_CAP_A
  | MONTH_DATE_COMMA_HOUR_MIN_CAP_A
  | DAY_COMMA_DATE_MONTH
  | MONTH_QUOTE_HALF_YEAR
  | HOUR_MIN_24
  | HOUR_MIN_12
  | HOUR_MIN_A
  | POSTSALES_DATE_TIME
  | POSTSALES_DATE_TIME_ZONE
  | POSTSALES_DATE_TIME_ZONE_NO_MS
  | CAB_ETA
  | DAY_COMMA_DATE_MONTH_YEAR
  | COMMA_DATE_MONTH_YEAR
  | TIME_DATE
  | YEAR_MONTH_DATE_HOUR_MIN_SEC
  | HOUR_MIN_a

type DateLike = Date | number

export function parseDate(rawDateInput: string|number, format: DateTimeFormats): Date | null {
  if (typeof rawDateInput === "number") {
    return new Date(rawDateInput)
  }
  const date = fecha.parse(rawDateInput, format);
  if (!date) {
    return null
  }
  return date
}

export function reformatDateString(inputRawDateStr: string,
  inputFormat: DateTimeFormats,
  outFormat: DateTimeFormats): string | null {
  if (typeof inputRawDateStr !== "string") {
    return null
  }
  const dateObj = parseDate(inputRawDateStr, inputFormat);
  if (!dateObj) {
    return null
  }
  return formatDate(dateObj, outFormat)
}

export function formatDate(date: DateLike, format: DateTimeFormats): string {
  const dateObj = new Date(date);
  return fecha.format(dateObj, format)
}

export function getToday(
  {
    stripHours = false,
    stripMinutes = false,
    stripSeconds = false,
    stripMilliSeconds = false,
  }={}
) {
  const date = new Date();
  if (stripHours) {
    date.setHours(0, 0, 0, 0)
  } else if (stripMinutes) {
    date.setMinutes(0, 0, 0)
  } else if (stripSeconds) {
    date.setSeconds(0, 0)
  } else if (stripMilliSeconds) {
    date.setMilliseconds(0)
  }
  return date
}

export function truncateHours(date) {
  date.setHours(0, 0, 0, 0)
  return date
}

export function addDays(date, days) {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + days)
  return newDate
}


export function getDiffInDays(startDate, endDate, floor=true) {
  const diff = (endDate.getTime() - startDate.getTime()) / oneDay;
  if (floor) {
    return Math.floor(diff);
  }
  return Math.ceil(diff);
}


export function getDiffInHours(startDate, endDate, floor=true) {
  let diff = (endDate.getTime() - startDate.getTime()) / oneHour;
  if(floor) {
    diff = Math.floor(diff)
  }
  return diff;
}
