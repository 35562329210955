import _isNil from 'lodash/isNil';
import _intersection from 'lodash/intersection';
import _includes from 'lodash/includes';
import _head from 'lodash/head';
import _isFunction from 'lodash/isFunction';
import { Platform } from 'react-native';
import cabsConfig from '../cabsConfig';
import {
  getGeocodes,
  getPredictions,
  getPredictionsNearByLocation,
  getReverseGeocodes,
} from '../utils/MapUtils/mapsApi';
import {
  requestLocationPerm,
  ensureLocationPermGranted,
  getCurrentLatLng,
  getLastKnownLatLng,
} from '../../../Helpers/locationHelper';

const globalSupportedTypes = [
  'locality', 'administrative_area_level_2', 'administrative_area_level_4',
  'administrative_area_level_5', 'street_address', 'route',
  'intersection', 'sublocality_level_1', 'sublocality_level_2', 'sublocality_level_3',
  'sublocality_level_4', 'sublocality_level_5', 'neighborhood', 'premise', 'subpremise',
  'natural_feature', 'airport', 'park', 'point_of_interest', 'floor', 'parking', 'post_box',
  'room', 'bus_station', 'train_station', 'transit_station', 'establishment'
];

const localSupportedTypes = [
  'administrative_area_level_4', 'administrative_area_level_5', 'street_address', 'route',
  'intersection', 'sublocality_level_1', 'sublocality_level_2', 'sublocality_level_3',
  'sublocality_level_4', 'sublocality_level_5', 'neighborhood', 'premise', 'subpremise',
  'natural_feature', 'airport', 'park', 'point_of_interest', 'floor', 'parking', 'post_box',
  'room', 'bus_station', 'train_station', 'transit_station', 'establishment'
];

const matchMainTextWithQuery = (mainText, query) => {
  const matchStart = mainText.toLowerCase().indexOf(query.toLowerCase());
  const matches = [];
  if (matchStart < 0) {
    matches.push({
      text: mainText,
      matched: false
    });
  } else {
    if (matchStart > 0) {
      matches.push({
        text: mainText.substring(0, matchStart),
        matched: false
      });
    }
    matches.push({
      text: mainText.substring(matchStart, matchStart + query.length),
      matched: true
    });
    if (matchStart + query.length < mainText.length) {
      matches.push({
        text: mainText.substring(matchStart + query.length, mainText.length + 1),
        matched: false
      });
    }
  }
  return matches;
};

const predictionsCache = new WeakMap();

const _filterPredictions = (predictions, query, isNearBy) => {
  const supportedTypes = isNearBy ? localSupportedTypes : globalSupportedTypes;
  return predictions
    .filter((prediction) => {
      for (const predictionType in prediction.types) {
        if (supportedTypes.indexOf(prediction.types[predictionType]) > -1) {
          return true;
        }
      }
      return false;
    })
    .map((prediction) => {
      const mainText = prediction.structured_formatting.main_text;
      const secondaryText = prediction.structured_formatting.secondary_text ?
        prediction.structured_formatting.secondary_text : mainText;
      const name = `${mainText}, ${secondaryText}`;
      const matches = matchMainTextWithQuery(mainText, query);
      return {
        name,
        matches,
        mainText,
        secondaryText,
        placeId: prediction.place_id
      };
    });
};

export const fetchAutoCompleteResultsForNearByLocation = async (query, location, canReturnCache = true) => {
  const key = `${query}_nearby`;
  if (predictionsCache[key] && canReturnCache) {
    return predictionsCache[key];
  }
  const predictions = await getPredictionsNearByLocation(query, location);
  const results = _filterPredictions(predictions, query, true);
  predictionsCache[key] = results;
  return results;
};

export const fetchAutoCompleteResults = async (query) => {
  if (predictionsCache[query]) {
    return predictionsCache[query];
  }
  const predictions = await getPredictions(query);
  const results = _filterPredictions(predictions, query, false);
  predictionsCache[query] = results;
  return results;
};  

export const fetchAutoCompleteResultsFromServer = async (query) => {
  let autoCompleteUrl = '';
  autoCompleteUrl = `${cabsConfig.autoCompleteUrl}/${query}`;
  if (predictionsCache[autoCompleteUrl]) {
    return predictionsCache[autoCompleteUrl];
  }
  let result;
  try {
    const response = await fetch(autoCompleteUrl);
    result = await response.json();
  } catch (e) {
    return [];
  }
  if (result.status !== 'SUCCESS') {
    return [];
  }
  const predictions = result.response;
  let queryMatch = '';
  queryMatch = query;
  const results = predictions
    .map((prediction) => {
      const mainText = prediction.main_text;
      const secondaryText = prediction.secondary_text;
      const name = `${mainText}, ${secondaryText}`;
      const matches = matchMainTextWithQuery(mainText, queryMatch);
      return {
        name,
        matches,
        mainText,
        secondaryText,
        placeId: prediction.place_id,
        airportId: prediction.airport_id
      };
    });
  predictionsCache[autoCompleteUrl] = results;
  return results;
};

export const getPlaceDetails = async (placeId) => {
  const result = await getGeocodes(placeId);
  return getAddress(result);
};

export const getServerPlaceDetails = async (placeId) => {
  const url = `${cabsConfig.addressDetail}/${placeId}`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('API Error');
  }
  const responseBody = await response.json();
  return responseBody.response;
};

export const getServerPlaceDetailsV2 = async (placeDetail) => {
  const url = `${cabsConfig.addressDetail}/`;
  const response = await fetch(url, {
    method: 'post',
    body: JSON.stringify(placeDetail),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    timeout: 1000
  });
  if (!response.ok) {
    throw new Error('API Error');
  }
  const responseBody = await response.json();
  return responseBody.response;
};

export const getLocationFromCityCode = async (cityCode, terminal = '') => {
  let locationDetail = null;
  try {
    const request = {
      type: 'locationRequest',
      city_code: cityCode,
      terminal
    };
    const response = await fetch(cabsConfig.locationRequestUrl, {
      method: 'post',
      body: JSON.stringify(request),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      timeout: 1000
    });
    const locationDataResponse = await response.json();
    if (locationDataResponse.status === 'SUCCESS') {
      locationDetail = locationDataResponse.location;
      if (locationDetail) {
        locationDetail.is_airport =
          locationDetail.is_airport ||
          _includes(locationDetail.address.toLowerCase(), 'airport');
        if (cityCode && !(cityCode.indexOf('POI') > -1) && !locationDetail.is_airport) {
          locationDetail.is_city = true;
        }
      }
    }
  } catch (e) {
    // Ignore exception
  }
  return locationDetail;
};

export const reverseGeocode = async (lat, lng) => {
  const result = await getReverseGeocodes(lat, lng);
  return getAddress(result);
};

const getAddress = (result) => {
  const cityTypes = ['administrative_area_level_2', 'locality', 'administrative_area_level_1'];
  const airportTypes = ['airport'];
  const addressComponents = result.address_components;
  const cityName = _filterComponentByType(addressComponents, cityTypes);
  const city = cityName && cityName.long_name;
  const isCity = _intersection(cityTypes, result.types).length > 0;
  const isAirport = _intersection(airportTypes, result.types).length > 0 || _includes(result.formatted_address.toLowerCase(), 'airport');
  const postalCode = _filterComponentByType(addressComponents, ['postal_code']);
  const pincode = postalCode && postalCode.short_name;
  let {lat, lng} = result.geometry.location;
  if (_isFunction(lat)) {
    lat = lat();
    lng = lng();
  }
  return {
    address: result.formatted_address,
    place_id: result.place_id,
    latitude: lat,
    longitude: lng,
    pincode,
    city,
    google_city: city,
    is_city: isCity,
    is_airport: isAirport
  };
};

const _filterComponentByType = (addressComponents, types) => {
  // TODO Replace with lodash
  for (const type of types) {
    for (const addressComponent of addressComponents) {
      if (addressComponent.types.includes(type)) {
        return addressComponent;
      }
    }
  }
  return null;
};

export const getCityByName = async (cityName) => {
  // if (Platform.OS === 'web') {
  //   await waitForMapsLoad();
  // }
  //
  //Needs to be used if Google api is used in future.
  const results = await fetchAutoCompleteResults(cityName);
  const cityObject = _head(results);
  if (_isNil(cityObject)) {
    return null;
  }
  return getPlaceDetails(cityObject.placeId);
};

export const getCurrentLocation = async () => {
  await ensureLocationPermGranted();
  return getCurrentPosition();
};

export const getLocationWithPerm = async () => {
  await requestLocationPerm();
  return getCurrentPosition();
};

export const getCurrentPosition = async () => {
  let latLng = await getCurrentLatLng();
  return reverseGeocode(latLng.lat, latLng.lng);
};

export const getLastKnownPosition = async () => {
  const latLng = await getLastKnownLatLng();
  return reverseGeocode(latLng.lat, latLng.lng);
};
