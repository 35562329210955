"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GSTIN_USER_CONSENT = exports.getParameterValueFromURL = exports.editParamsToUrl = void 0;
/**
  * this function edits the url passed to it ,be it append or delete and returns a new url
 * @param {string} url current page url
 * @param {string} editType type of edit (append/delete)
 * @param {string} paramKey parameter key to be deleted or added
 * @param {QueryParamsObject} queryParamsObj  query param object
 * @returns
 */
exports.editParamsToUrl = function (url, editType, paramKey, queryParamsObj) {
    if (editType === void 0) { editType = 'append'; }
    if (paramKey === void 0) { paramKey = null; }
    if (queryParamsObj === void 0) { queryParamsObj = null; }
    var urlQ = new URL(url);
    var urlParams = new URLSearchParams(urlQ.search);
    if (editType === 'append' && queryParamsObj) {
        for (var key in queryParamsObj) {
            urlParams.append(key, String(queryParamsObj[key]));
        }
    }
    else if (editType === 'delete') {
        paramKey === null || paramKey === void 0 ? void 0 : paramKey.forEach(function (elm) {
            urlParams.delete(String(elm));
        });
    }
    // Update the search property of the URL object
    urlQ.search = urlParams.toString();
    return urlQ.toString();
};
/**
 *  this function takes a url and a key param that is present in the URL object extracts it and returns
 * @returns {string} return the searched property of the URL object
 */
exports.getParameterValueFromURL = function (url, parameterName) {
    var parsedURL = new URL(url);
    var searchParams = new URLSearchParams(parsedURL.search);
    return searchParams.get(parameterName);
};
exports.GSTIN_USER_CONSENT = 'user_consent';
