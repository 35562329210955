import * as constants from './HotelMarketingActions';

const initialState = {
  destinationName: '',
  destinationLocation: {},
  sourceName: '',
  sourceLocation: {},
  hotel_Id: '',
  apiResponse: {},
  offersList: [],
  recentSearch: []
};

const HotelMarketingReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.ACTION_UPDATE_DESTINTION_NAME: {
      return {
        ...state,
        destinationName: action.data,
      };
    }
    case constants.ACTION_SET_DESTINATION_LOCATION: {
      return {
        ...state,
        destinationLocation: action.data,
      };
    }
    case constants.ACTION_UPDATE_RECENT_SEARCH: {
      return {
        ...state,
        recentSearch: action.data,
      }
    }
    case constants.ACTION_SET_HOTEL_OFFERS: {
      return {
        ...state,
        offersList: action.data,
      };
    }
    case constants.ACTION_UPDATE_SOURCE_NAME: {
      return {
        ...state,
        sourceName: action.data,
      };
    }
    case constants.ACTION_SET_SOURCE_LOCATION: {
      return {
        ...state,
        sourceLocation: action.data,
      };
    }
    case constants.ACTION_SET_HOTEL_ID: {
      return {
        ...state,
        hotel_Id: action.data,
      };
    }
    case constants.ACTION_UPDATE_API_RESPONSE: {
      return {
        ...state,
        apiResponse: action.data,
      };
    }
    case constants.ACTION_SWAP_SRC_DEST: {
      return {
        ...state,
        sourceName: action.data.sourceName,
        sourceLocation: action.data.sourceLocation,
        destinationName: action.data.destinationName,
        destinationLocation: action.data.destinationLocation
      }
    }
    default: {
      return state;
    }
  }
};

export default HotelMarketingReducer;
