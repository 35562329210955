import {
  ACTION_ADD_ON_PARENT_DETAILS,
  ACTION_ADD_ON_UPDATE_BOOKING_DETAILS,
  ACTION_UPDATE_ADD_ON_TRAVEL_DETAILS
} from './cabsAddOnActions';

const initialState = {
  travelDetails: null,
  tripLegs: null,
  addOnDetailsNextEnabled: false,
  flightBookingId: null,
  parentScreen: null,
  parentCabId: null,
  bookingResp: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_ADD_ON_PARENT_DETAILS: {
      return {
        ...state,
        flightBookingId: action.data.flightBookingId,
        parentScreen: action.data.parentScreen,
        parentCabId: action.data.parentCabId,
        tripLegs: action.data.tripLegs,
        travelDetails: action.data.travelDetails
      };
    }
    case ACTION_ADD_ON_UPDATE_BOOKING_DETAILS: {
      return {
        ...state,
        flightBookingId: action.data.flightBookingId,
        bookingResp: action.data.bookingResp,
      };
    }
    case ACTION_UPDATE_ADD_ON_TRAVEL_DETAILS: {
      const {data} = action;
      return {
        ...state,
        travelDetails: data.travelDetails,
        tripLegs: data.tripLegs,
        addOnDetailsNextEnabled: data.addOnDetailsNextEnabled
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
