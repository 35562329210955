import {Platform} from 'react-native';
import NetworkModule from 'src/Native/NetworkModule';
import {getUserDetails, isUserLoggedIn} from 'src/Native/UserSession/UserSessionModule';
import {getScreenDensityName} from 'src/Helpers/displayHelper';
import {StringBuilder} from './StringUtils';


export const appendHttpsToUrl = (url) => {
  if (url.startsWith('http')) {
    return url;
  }
  return `https:${url}`;
};

export const replaceSpaceFromUrl = url => url.replace(/ /g, '%20');

export const replaceSpaceAndAppendHttps = url => appendHttpsToUrl(replaceSpaceFromUrl(url));

export const getHeaders = async () => NetworkModule.getHeaders();

export function addGetParamsToUrl(url, paramMap) {
  const urlBuilder = new StringBuilder(url);
  urlBuilder.append('?');
  Object.keys(paramMap).forEach((paramName) => {
    const param = paramMap[paramName];
    urlBuilder.append(`${paramName}=${param}&`);
  });

  const toReturn = urlBuilder.toString();
  urlBuilder.clear();
  return toReturn;
}
export const getPromoImageUrl = (imageURL) => {
  if (!imageURL) {
    return '';
  }
  return imageURL.replace('%s', getScreenDensityName());
};
/** *
 *
 * @param otherHeaders: give your other custom fixed headers or if you want to override any headers
 * @returns {Promise<*>}
 */
export const getHeaderDetails = async (otherHeaders) => {
  try {
    const headers = await NetworkModule.getHeaders();
    const hasUserLoggedIn = await isUserLoggedIn();
    if (hasUserLoggedIn) {
      const userDetails = await getUserDetails();
      headers['mmt-auth'] = userDetails.mmtAuth;
    } else {
      headers['mmt-auth'] = '';
    }
    if (Platform.OS === 'ios' && headers.os) {
      const osVersion = headers.os.split('_');
      headers.os = `ios ${osVersion[1]}`;
    }
    // Maybe more checks are required
    if (otherHeaders && typeof otherHeaders === 'object') {
      return {...headers, ...otherHeaders};
    }
    return headers;
  } catch (e) {
    console.log(e);
    return otherHeaders;
  }
};

export const fetchWithTimeout = async (resource, options = {}) => {
  const { timeout = 5000 } = options;
  // eslint-disable-next-line no-undef
  const abortController = new AbortController();
  const id = setTimeout(() => abortController.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: abortController.signal,
  });
  clearTimeout(id);
  return response;
};
