import isNil from 'lodash/isNil';
import fecha from 'fecha';

export const oneHour = 60 * 60 * 1000;
export const oneMinute = 60 * 1000;
const oneDay = 24 * oneHour;
export const dateFormat = 'DD MMM YY';
export const timeFormat = 'hh:mm A';
export const dateTimeFormat = 'DD MMM hh:mm A';
export const fullYearDateFormat = 'DD:MM:YYYY';
export const twentyFourHourTimeFormat = 'HH:mm';
export const twentyFourHourDateTimeFormat = 'DD-MM-YYYY HH:MM:ss';
export const dayFormat = 'ddd, Do MMM';
export const dateMonth = 'DD MMM'

export const truncateHours = (date) => {
  if (isNil(date)) {
    return null;
  }
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const now = () => new Date();

export const today = () => truncateHours(new Date());

export const getTomorrow = (currDate = now()) => addDays(currDate, 1);

export const getYesterday = (currDate = now()) => addDays(currDate, -1);

export const addDays = (date, days) => new Date(date.getTime() + (oneDay * days));

export const addHours = (date, hours) => new Date(date.getTime() + (oneHour * hours));

export const addMinutes = (date, minutes) => new Date(date.getTime() + (oneMinute * minutes));

export const addMonths = (date, months) => {
  const d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
};

export const diffDays = (date1, date2, floor = true) => {
  const diff = (date1.getTime() - date2.getTime()) / oneDay;
  if (floor) {
    return Math.floor(diff);
  }
  return Math.ceil(diff);
};

export const diffHours = (date1, date2) => (date1.getTime() - date2.getTime()) / oneHour;

export const diffMinutes = (date1, date2) => (date1.getTime() - date2.getTime()) / (1000 * 60);


export const isSameDate = (date1, date2) => date1.getDate() === date2.getDate()
  && date1.getMonth() === date2.getMonth()
  && date1.getFullYear() === date2.getFullYear();

export const isToday = (date, todayDate = new Date()) => {
  if (!date || !todayDate) {
    return false;
  }
  return isSameDate(date, todayDate);
};

export const isTomorrow = (date) => {
  if (!date) {
    return false;
  }
  return isSameDate(date, getTomorrow());
};

/* If the date is today returns Today, else if date is tomorrow returns Tomorrow else return Day
 like Friday.. */
export const formatDay = (date) => {
  if (isToday(date)) {
    return 'Today';
  }
  if (isTomorrow(date)) {
    return 'Tomorrow';
  }
  return fecha.format(date, 'dddd');
};

export const getFormattedDate = (date, oldFormat, newFormat) => {
  if (!date || date.length === 0) {
    return '';
  }
  const formattedDate = fecha.parse(date, oldFormat);
  return fecha.format(formattedDate, newFormat);
};

// function to convert millisecond to hh:mm:ss format
export const msToHMS = (ms) => {
  let seconds = parseInt(ms / 1000);
  const hours = parseInt(seconds / 3600);
  seconds %= 3600;
  const minutes = parseInt(seconds / 60);
  seconds %= 60;
  return hours ? (`${hours}h:${minutes}m:${seconds}s`) : (`${minutes}m:${seconds}s`);
};

// function to convert in minutes
export const toMinutes = ms => parseInt(ms / (60 * 1000));

export const getDateObjectFromDate = (date, format) => fecha.parse(date, format);

export const getDateObjectFromDateAndTime = (date, datePattern, time, timePattern) => {
  const timeObject = fecha.parse(time, timePattern);
  const dateObject = fecha.parse(date, datePattern);
  dateObject.setHours(timeObject.getHours());
  dateObject.setMinutes(timeObject.getMinutes());
  return dateObject;
};

export const getFormattedDateFromMillis = (dateInMillis, dateFormatStr) => {
  if (!dateInMillis || !dateFormatStr) {
    return '';
  }
  return fecha.format(new Date(dateInMillis), dateFormatStr);
};

export const getFormattedDateFromDate = (date1, dateFormatStr) => {
    if (date1 == null || !dateFormatStr) {
        return '';
    }
    return fecha.format(date1, dateFormatStr);
};


export const getDurationComponents = (date1, date2, includeDays) => {
  if (date1 == null || date2 == null) {
    return null;
  }

  let delta = Math.abs(date1 - date2) / 1000;

  let days = null;
  if (includeDays === true) {
    days = Math.floor(delta / 86400);
    delta -= days * 86400;
  }

  const hours = includeDays ? Math.floor(delta / 3600) % 24 : Math.floor(delta / 3600);
  delta -= hours * 3600;

  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  return {days, hours, minutes};
};

export const getDuration = (date1, date2, includeDays, separator) => {
  const durationComponents = getDurationComponents(date1, date2, includeDays);
  const sepSign = separator || ' ';
  if (durationComponents) {
    let result = '';
    const {days, hours, minutes} = durationComponents;

    if (includeDays === true && days > 0) {
      result += `${days}d`;
    }

    if (hours > 0) {
      result += result.length > 0 ? sepSign : '';
      result += `${hours}h`;
    }

    if (minutes > 0) {
      result += result.length > 0 ? sepSign : '';
      result += `${minutes}m`;
    }

    return result;
  }

  return null;
};

export const transformDate = (dateStr, from, to) => {
  try {
    const dateObj = fecha.parse(dateStr, from);
    return fecha.format(dateObj, to);
  } catch (err) {
    return null;
  }
};

export const convertDateObject = (dateStr) => {
  try {
    let str = dateStr.split('-');
    if(str.length !== 3) {
      return Date()
    }
    return fecha.parse(dateStr, 'YYYY-MM-DD');
  } catch (e) {
    return Date();
  }
};

export const compareDates = (date1, date2) => {
  if(date1.getFullYear() === date2.getFullYear()) {
    if(date1.getMonth() === date2.getMonth()) {
      if(date1.getDate() === date2.getDate()) {
        return 0
      } else {
        return (date1.getDate() > date2.getDate()) ? 1 : -1
      }
    } else {
      return (date1.getMonth() > date2.getMonth()) ? 1 : -1
    }
  } else {
    return (date1.getFullYear() > date2.getFullYear()) ? 1 : -1
  }
}

//Converting time to indian time zone
export function convertToISTDate(currentTime) {
  try {
    if (currentTime?.getTimezoneOffset()) {
      const currentOffset = currentTime.getTimezoneOffset();
      const ISTOffset = 330; // IST offset UTC +5:30
      const ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);
      return ISTTime;
    } else {
      const currentOffset = new Date().getTimezoneOffset();
      const ISTOffset = 330;
      const ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);
      return ISTTime;
    }
  } catch (error) {
    console.error('Error while converting time to indian time zone',error);
    return currentTime;
  }
}
