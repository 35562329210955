import React from "react";
import loadable from "@loadable/component";
import Loader from "./components/Loader";

const CabsLanding = loadable(
  () =>
    import(
      /* webpackChunkName: "cabsLanding" */
      "@rn/Routes/Cabs/Landing/CabsLandingWebWrapper"
    ),
  {
    fallback: <Loader />,
  }
);

const CityPicker = loadable(
  () =>
    import(
      /* webpackChunkName: "cityPicker" */
      "@rn/Routes/Cabs/Landing/Components/CityPickerContainer"
    ),
  {
    fallback: <Loader />,
  }
);

const DestinationPicker = loadable(
  () =>
    import(
      /* webpackChunkName: "destinationPicker" */
      "@rn/Routes/Cabs/ProvisionalBooking/ProvisionalDestPicker/ProvisionalDestPickerContainer"
    ),
  {
    fallback: <Loader />,
  }
);

const DestinationPickerNonKiosk = loadable(
  () =>
    import(
      /* webpackChunkName: "destinationPicker" */
      "@rn/Routes/Cabs/ProvisionalBookingNonKiosk/Components/DestPicker/container"
    ),
  {
    fallback: <Loader />,
  }
);

const DestinationPickerHOB = loadable(
  () =>
    import(
      /* webpackChunkName: "destinationPicker" */
      "@rn/Routes/Cabs/ProvisionalBookingHOB/HouseOfBrands/Components/DestPicker/container"
    ),
  {
    fallback: <Loader />,
  }
);


const ProvisionalBookingHOB = loadable(
  () =>
    import(
      /* webpackChunkName: "ProvisionalBookingHOB" */
      "@rn/Routes/Cabs/ProvisionalBookingHOB/webWrapper"
    ),
  {
    fallback: <Loader />,
  }
);

const ThankYouPageHOB = loadable(
  () =>
    import(
      /* webpackChunkName: "ThankYouPageHOB" */
      "@rn/Routes/Cabs/ProvisionalBookingHOB/HouseOfBrands/Postsale"
    ),
  {
    fallback: <Loader />,
  }
);

const ListingPageHOB = loadable(
  () =>
    import(
      /* webpackChunkName: "ListingPageHOB" */
      "@rn/Routes/Cabs/ProvisionalBookingHOB/HouseOfBrands/ListingScreen/webWrapper"
    ),
  {
    fallback: <Loader />,
  }
);


const HotelCityPicker = loadable(
  () =>
    import(
      /* webpackChunkName: "hotelCityPicker" */
      "@rn/Routes/Cabs/HotelMarketing/CityPicker/CityPickerWebWrapper"
    ),
  {
    fallback: <Loader />,
  }
);

const CabsListing = loadable(
  () =>
    import(
      /* webpackChunkName: "cabsListing" */
      "@rn/Routes/Cabs/Listing/CabListingWebWrapper"
    ),
  {
    fallback: <Loader />,
  }
);

const ProvisionalBooking = loadable(
  () =>
    import(
      /* webpackChunkName: "provisionalBooking" */
      "@rn/Routes/Cabs/ProvisionalBooking/ProvisionalBookingWebWrapper"
    ),
  {
    fallback: <Loader />,
  }
);

const ProvisionalBookingNonKiosk = loadable(
  () =>
    import(
      /* webpackChunkName: "ProvisionalBookingNonKiosk" */
      "@rn/Routes/Cabs/ProvisionalBookingNonKiosk/webWrapper"
    ),
  {
    fallback: <Loader />,
  }
);

const KioskImageBurstMode = loadable(
  () =>
    import(
      /* webpackChunkName: "kioskImageBurst" */
      "@rn/Routes/Cabs/ProvisionalBooking/Components/KioskImageBurstMode"
    ),
  {
    fallback: <Loader />,
  }
);
const HotelMarketing = loadable(
  () =>
    import(
      /* webpackChunkName: "hotelMarketing" */
      "@rn/Routes/Cabs/HotelMarketing/HotelMarketingWebWrapper"
    ),
  {
    fallback: <Loader />,
  }
);
const CabsRating = loadable(
  () =>
    import(
      /* webpackChunkName: "cabsRating" */
      "@rn/Routes/Cabs/Ratings"
    ),
  {
    fallback: <Loader />,
  }
);

const CabsThankYouWeb = loadable(
  () =>
    import(
      /* webpackChunkName: "cabsThankyou" */
      "@rn/Routes/Cabs/PostPayment/Web/ThankYouCabWeb"
    ),
  {
    fallback: <Loader />,
  }
);

const CabAddOnThankYouWeb = loadable(
  () =>
    import(
      /* webpackChunkName: "addOnThankYou" */
      "@rn/Routes/Cabs/PostPayment/Web/CabAddOnThankYouWeb"
    ),
  {
    fallback: <Loader />,
  }
);

const CabAddOnDetailsWeb = loadable(
  () =>
    import(
      /* webpackChunkName: "addOnDetails" */
      "@mmt/cabs/src/addon/Flight/Web/CabAddOnDetailsWebWrapper"
    ),
  {
    fallback: <Loader />,
  }
);

const CabAddOnReviewWeb = loadable(
  () =>
    import(
      /* webpackChunkName: "addOnReview" */
      "@mmt/cabs/src/addon/Flight/Web/CabAddOnReviewPageWebWrapper"
    ),
  {
    fallback: <Loader />,
  }
);
const CabReview = loadable(
  () =>
    import(
      /* webpackChunkName: "cabsReview" */
      "@rn/Routes/Cabs/Review/CabReviewWebWrapper"
    ),
  {
    fallback: <Loader />,
  }
);

const CabRepresentativeImages = loadable(
  () =>
    import(
      /* webpackChunkName: "cabRepresentativeImages" */
      "@rn/Routes/Cabs/Review/Components/CabRepresentativeImages"
    ),
  {
    fallback: <Loader />,
  }
);

const Marketing = loadable(
  () =>
    import(
      /* webpackChunkName: "marketing" */
      "./components/Marketing/MarketingWebWrapper"
    ),
  {
    fallback: <Loader />,
  }
);

const FareBreakupModal = loadable(
  () =>
    import(
      /* webpackChunkName: "fareBreakup" */
      "@rn/Routes/Cabs/Review/FareBreakupModal"
    ),
  {
    fallback: <Loader />,
  }
);

const CancellationRules = loadable(
  () =>
    import(
      /* webpackChunkName: "cancellationRule" */
      "@rn/Routes/Cabs/Review/CancellationRules"
    ),
  {
    fallback: <Loader />,
  }
);

const WebViewWrapper = loadable(
  () =>
    import(
      /* webpackChunkName: "webViewWrapper" */
      "@rn/Common/Components/WebViewWrapper"
    ),
  {
    fallback: <Loader />,
  }
);
const CabsCalendar = loadable(
  () =>
    import(
      /* webpackChunkName: "cabsCalendar" */
      "../Mobile-mmt-react-native/src/Routes/Cabs/Calendar"
    ),
  {
    fallback: <Loader />,
  }
);

const InstaStory = loadable(
  () =>
    import(
      /* webpackChunkName: "instaStory" */
      "@rn/Routes/Cabs/Components/InstaStory"
    ),
  {
    fallback: <Loader />,
  }
);

const CabsCorporate = loadable(
  () =>
    import(
      /* webpackChunkName: "cabsCorporate" */
      "@rn/Routes/Cabs/MyBizApproval/Container"
    ),
  {
    fallback: <Loader />,
  }
);

const SelfDrive = loadable(
  () =>
    import(
      /* webpackChunkName: "selfDrive" */
      "./selfDriveWebNavigation"
    ),
  {
    fallback: <Loader />,
  }
);

export {
  CabAddOnDetailsWeb,
  CabAddOnReviewWeb,
  CabAddOnThankYouWeb,
  CabRepresentativeImages,
  CabReview,
  CabsCalendar,
  CabsCorporate,
  CabsLanding,
  CabsListing,
  CabsRating,
  CabsThankYouWeb,
  CancellationRules,
  CityPicker,
  DestinationPicker,
  DestinationPickerNonKiosk,
  DestinationPickerHOB,
  ThankYouPageHOB,
  ListingPageHOB,
  ProvisionalBookingHOB,
  FareBreakupModal,
  HotelCityPicker,
  HotelMarketing,
  InstaStory,
  KioskImageBurstMode,
  Marketing,
  ProvisionalBooking,
  ProvisionalBookingNonKiosk,
  SelfDrive,
  WebViewWrapper,
};
