export const ACTION_UPDATE_DESTINTION_NAME = 'ACTION_UPDATE_DESTINTION_NAME';
export const ACTION_SET_DESTINATION_LOCATION = 'ACTION_SET_DESTINATION_LOCATION';
export const ACTION_SET_FLIGHT_ID = 'ACTION_SET_FLIGHT_ID';
export const ACTION_SET_TIME = 'ACTION_SET_TIME';
export const ACTION_UPDATE_PROV_RESPONSE = 'ACTION_UPDATE_PROV_RESPONSE'

export const updateDestinationName = destinationName => (dispatch, getState) => {
    dispatch({
      type: ACTION_UPDATE_DESTINTION_NAME,
      data: destinationName
    })
}

export const updateDestinationLocation = destinationLocation => (dispatch, getState) => {
    dispatch({
      type: ACTION_SET_DESTINATION_LOCATION,
      data: destinationLocation
    })
}

export const setFlightId = flight_Id => (dispatch, getState) => {
    dispatch({
      type: ACTION_SET_FLIGHT_ID,
      data: flight_Id
    })
}

export const setTime = time => (dispatch, getState) => {
  dispatch({
    type: ACTION_SET_TIME,
    data: time
  })
}


export const updateProvisionalResponse = resp => (dispatch, getState) => {
  dispatch({
    type: ACTION_UPDATE_PROV_RESPONSE,
    data: resp
  })  
}
