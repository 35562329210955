import * as constants from './cabsReviewActions';
import * as listingConstants from '../Listing/cabsListingActions';
import { ViewStates } from '../Listing/cabsListingReducer';
import { PaymentMode } from './PaymentOptions';
import { Status } from '../utils/cabReviewActionsUtils';
import { TRAVELLER_DETAILS } from '../cabsConstants';

const paxType = {
  MYSELF: 'myself',
  SOMEONE_ELSE: 'someoneElse'
}
const initialState = {
  viewState: ViewStates.VIEW_LOADING,
  pax: {
    [paxType.MYSELF]: null,
    [paxType.SOMEONE_ELSE]: null,
    bookingForTabSelected: ''
  },
  tag: null,
  selectedCabType: null,
  fuelType: null,
  isMySafetyKitEnabled: false,
  otp: '',
  availableCabList: [],
  holdStatus: Status.INITIAL,
  couponValidationStatus: Status.INITIAL,
  couponResponse: null,
  allowZeroPayment: false,
  safetyParams: null,
  allowPartPayment: false,
  isAlternateCab: false,
  alternateCabCount: 0,
  showCouponLoading: true,
  mmtBlackPersuasion: null,
  shouldDismissReview: false,
  partPaymentPercent: null,
  ePassChecklist: null,
  selectedPaymentOption: PaymentMode.part,
  coupons: [],
  target_screen: null,
  cabsVisitedInterventionPopup: [],
  travelRequest: {
    languagePreferences: [],
    routeFamilarity: false,
  },
  typedCoupons: [],
  bpgInfo: null,
  freeCancellationText: '',
  tripGuarantee: null,
  isAlterCouponModalOpen: false,
  userConsentData: null,
  tripTagData: null,
  defaultGSTDetails: null,
  gstData: null,
  locusInfo: null,
  cancellationPolicies: null,
  myBizApprovalFlowData: null,
  myBizApprovalRequestReasons: null,
  initiateApprovalSuccessData: null,
  tripGuaranteePaymentPersuasion: null,
  reviewErrorMessage: null,
  fuelTypePokusKey: null,
  responsePokusValues: null,
  trip_distance_and_duration: null,
  rydeUpsellData: null,
  isCabCardExpanded: false,
  payButtonCTAData: null,
  brandInfo: null,
};

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.ACTION_SET_CAB_FOR_REVIEW: {
      const {
        data: { cab, isAlternateCab },
      } = action;
      let {
        cab_fare_detail: {
          special_route_discount_percent,
          cash_to_driver = false,
          total_amount,
          partial_payable_amount: partPaymentAmount,
          payment_message,
          part_payment_rest_amount_message: partPaymentSubLabel,
          full_payment_message,
          coupon_amount,
          info = null,
          full_payable_amount,
        },
        alternate_cabs_count,
      } = cab;

      const alternateCabCount = isAlternateCab ? state.alternateCabCount : alternate_cabs_count;

      const allowZeroPayment = cash_to_driver;
      const allowPartPayment =
        special_route_discount_percent !== 100 && !allowZeroPayment && !!partPaymentAmount;
      const total = full_payable_amount;
      let { selectedPaymentOption } = state;
      if (state.couponValidationStatus !== Status.IN_PROGRESS) {
        if (allowZeroPayment) {
          selectedPaymentOption = PaymentMode.zero;
        }
        if (!allowPartPayment) {
          selectedPaymentOption = PaymentMode.full;
        }
      }

      return {
        ...state,
        cab,
        isAlternateCab,
        viewState: ViewStates.VIEW_CABS,
        selectedCabType: cab.type,
        fuelType: cab.fuel_type,
        isMySafetyKitEnabled: cab.is_mysafety_kit_available || false,
        fullAmount: total,
        partPaymentLabel: payment_message || '',
        partPaymentSubLabel: partPaymentSubLabel || '',
        fullPaymentLabel: full_payment_message || '',
        allowPartPayment,
        allowZeroPayment,
        alternateCabCount,
        partpaymentAmount: Math.round(partPaymentAmount),
        selectedPaymentOption,
        tripGuaranteePaymentPersuasion: info,
      };
    }

    case constants.ACTION_SET_CAB_TYPE: {
      return { ...state, selectedCabType: action.data };
    }

    case constants.ACTION_UPDATE_TRAVEL_REQUEST: {
      return { ...state, travelRequest: action.data };
    }

    case constants.ACTION_ADD_INTERVENTION_CAB_VISITED: {
      return {
        ...state,
        cabsVisitedInterventionPopup: [...state.cabsVisitedInterventionPopup, action.data],
      };
    }

    case constants.ACTION_SET_MY_VALUES_PARAMS: {
      return { ...state, safetyParams: action.data };
    }

    case constants.ACTION_SET_E_PASS_CHECKLIST: {
      return { ...state, ePassChecklist: action.data };
    }
    case constants.ACTION_SET_MMT_BLACK_PERSUASION: {
      return { ...state, mmtBlackPersuasion: action.data };
    }

    case constants.ACTION_UPDATE_TRIP_TAG_VALUE: {
      return {
        ...state,
        tripTagData: action.data,
      };
    }

    case constants.ACTION_UPDATE_GST_DETAILS: {
      return {
        ...state,
        gstData: {
          ...state.gstData,
          ...action.data,
        },
      };
    }

    case constants.ACTION_UPDATE_DEFAULT_GST_DETAILS: {
      return {
        ...state,
        defaultGSTDetails: action.data,
      };
    }

    case constants.ACTION_SET_REVIEW_DATA: {
      return {
        ...state,
        tripGuarantee: action.data.tripGuarantee,
        userConsentData: action.data.userConsentData,
        locusInfo: action.data.locusInfo,
        cancellationPolicies: action.data.cancellationPolicies,
        myBizApprovalFlowData: action.data.myBizApprovalFlowData,
        fuelTypePokusKey: action.data.fuelTypePokusKey,
        responsePokusValues: action.data.responsePokusValues,
        rapBanner: action.data.rapBanner,
        trip_distance_and_duration: action.data?.trip_distance_and_duration,
        rydeUpsellData: action.data?.rydeUpsellData,
        isCabCardExpanded: action.data?.isCabCardExpanded,
        payButtonCTAData: action.data?.payButtonCTAData,
        brandInfo: action.data?.brand_info,
        whatsapp_chat_details: action.data?.whatsapp_chat_details,
        show_trip_assistant: action.data?.show_trip_assistant,
      };
    }

    case constants.ACTION_UPDATE_VIEW_STATE: {
      return { ...state, viewState: action.data };
    }

    case constants.ACTION_SET_ROOT_TAG: {
      return { ...state, tag: action.data };
    }

    case listingConstants.ACTION_LOADING_CABS: {
      return {
        ...initialState,
        selectedPaymentOption: state.selectedPaymentOption,
        selectedCabType: state.selectedCabType,
        isMySafetyKitEnabled: state.isMySafetyKitEnabled,
        fuelType: state.fuelType,
        tag: state.tag,
        pax: state.pax,
        viewState: ViewStates.VIEW_LOADING,
        cab: { travel_addons: state?.cab?.travel_addons },
      };
    }

    case constants.ACTION_SET_BPG_INFO_REVIEW: {
      return {
        ...state,
        bpgInfo: action.data,
      };
    }

    case constants.ACTION_SET_CANCELLATION_INFO: {
      return {
        ...state,
        freeCancellationText: action.data,
      };
    }

    case listingConstants.ACTION_ERROR: {
      return {
        ...state,
        shouldDismissReview: true,
      };
    }

    case constants.ACTION_RESET_REVIEW: {
      return {
        ...initialState,
        cabsVisitedInterventionPopup: state.cabsVisitedInterventionPopup,
        reviewErrorMessage: null,
        pax: { ...state.pax }
      };
    }

    case constants.ACTION_DISMISS_REVIEW: {
      return { ...state, shouldDismissReview: true };
    }

    case constants.ACTION_UPDATE_PAX_DETAIL: {
      const { paxType, pax } = action.data
      if (paxType === TRAVELLER_DETAILS.ALL_BOOKING) {
        return {
          ...state,
          pax: {
            ...state.pax,
            [TRAVELLER_DETAILS.MYSELF]: { ...pax },
            [TRAVELLER_DETAILS.SOMEONEELSE]: {
              ...pax,
              bookingFor: '',
              name: '',
              gender: ''
            }
          }
        }
      }
      return {
        ...state,
        pax: {
          ...state.pax,
          [paxType]: pax
        }
      };
    }

    case constants.ACTION_SET_MYBIZ_REQUEST_REASONS: {
      return { ...state, myBizApprovalRequestReasons: action.data };
    }

    case constants.ACTION_SET_REVIEW_ERROR: {
      return {
        ...state,
        reviewErrorMessage: action.data.message,
        target_screen: action.data.target_screen,
      };
    }

    case constants.ACTION_RESET_REVIEW_ERROR: {
      return { ...state, reviewErrorMessage: null };
    }

    case constants.ACTION_HOLD_IN_PROGRESS: {
      return { ...state, holdStatus: Status.IN_PROGRESS };
    }

    case constants.ACTION_HOLD_SUCCESS: {
      return {
        ...state,
        holdStatus: Status.SUCCESS,
      };
    }

    case constants.ACTION_SET_INITIATE_APPROVAL_DATA: {
      return {
        ...state,
        initiateApprovalSuccessData: action.data,
      };
    }

    case constants.ACTION_SET_ALTER_COUPON_MODAL_OPEN: {
      return { ...state, isAlterCouponModalOpen: action.data };
    }

    case constants.ACTION_HOLD_FAILURE: {
      return {
        ...state,
        holdStatus: Status.FAILED,
      };
    }

    case constants.ACTION_OTP_CHANGE: {
      return { ...state, otp: action.data };
    }

    case constants.ACTION_PAYMENT_OPTION_CHANGE: {
      return { ...state, selectedPaymentOption: action.data };
    }

    case constants.ACTION_UPDATE_COUPONS: {
      return { ...state, coupons: action.data, showCouponLoading: false };
    }

    case constants.ACTION_ADD_TYPED_COUPON: {
      return {
        ...state,
        typedCoupons: [
          ...state.typedCoupons,
          { coupon_code: action.data, user_message: '', message: '' },
        ],
      };
    }

    case constants.ACTION_UPDATE_COUPON_VALIDATION_STATUS: {
      return {
        ...state,
        couponValidationStatus: action.data.status,
        couponResponse: action.data.response,
      };
    }

    case constants.ACTION_SET_HYPERLOCATION_ACTIVITY_INDICATOR: {
      return {
        ...state,
        showHyperlocationActivityIndicator: action.data,
      };
    }

    case constants.ACTION_UPDATE_AVAILABLE_CABS: {
      return {
        ...state,
        availableCabList: action.data,
      };
    }
    case constants.ACTION_SET_BOOKING_FOR_TAB: {
      return {
        ...state,
        pax: {
          ...state.pax,
          bookingForTabSelected: action.data.paxType
        }
      }
    }
    case constants.ACTION_UPDATE_SELECTED_ADDONS: {
      const addOns = state.cab?.travel_addons || [];
      const addOnIndex = addOns.findIndex(addOn => addOn?.id === action.data.addon?.addonId);
      if (addOnIndex === -1) return state;
      const isSelected = action.data.addon?.selected;
      const prevChildPrice = addOns?.[addOnIndex]?.options?.find(option => option?.selected)?.price || 0;;
      const prevChildMinPayment = addOns?.[addOnIndex]?.options?.find(option => option?.selected)?.min_payment || 0;
      const updateAddonOptions = (addon, selectedChildId) =>
        addon?.options?.map(option => ({
          ...option,
          selected: option?.id === selectedChildId,
        }));
      const updatedAddOns = addOns.map((addon, index) => {
        if (index !== addOnIndex) return addon;
        if (action.data.addon?.selectedChildId) {
          return {
            ...addon,
            selected: !!isSelected,
            options: updateAddonOptions(addon, action.data.addon?.selectedChildId),
          };
        }
        return { ...(addon || {}), selected: !!isSelected };
      });
      const updatedAddon = updatedAddOns[addOnIndex];
      const addonValue = updatedAddon?.options?.find(option => option?.selected)?.price || updatedAddon?.price || 0;
      const addonMinValue = updatedAddon?.options?.find(option => option?.selected)?.min_payment || updatedAddon?.min_payment || 0;
      let updatedFareBreakupList = [...(state.cab?.fare_breakup_list || [])];
      const selectedChild = updatedAddon?.options?.find((child) => child?.selected);
      const addOnName = updatedAddon?.name + `${selectedChild?.name ? ` (${selectedChild?.name})` : ''}`;

      // Handle fare breakup removal or update
      const fareIndex = updatedFareBreakupList.findIndex(fare => fare?.parentName === updatedAddon?.name);
      if (!isSelected) {
        if (fareIndex > -1) updatedFareBreakupList?.splice(fareIndex, 1);
      } else {
        const newFareBreakup = {
          fareType: 'ADDON',
          displayName: addOnName,
          value: addonValue,
          parentName: updatedAddon?.name,
        };
        if (action.data.addon?.isToggle && fareIndex > -1) {
          updatedFareBreakupList[fareIndex] = newFareBreakup;
        } else {
          const discountIndex = updatedFareBreakupList.findIndex(fare => fare?.fareType === 'DISCOUNT');
          const insertionIndex = discountIndex > -1 ? discountIndex : updatedFareBreakupList?.length - 1;
          updatedFareBreakupList?.splice(insertionIndex, 0, newFareBreakup);
        }
      }

      // Update total fare
      const totalIndex = updatedFareBreakupList?.findIndex(fare => fare?.fareType === 'TOTAL');
      if (totalIndex > -1) {
        updatedFareBreakupList[totalIndex] = {
          ...updatedFareBreakupList[totalIndex],
          value: updatedFareBreakupList[totalIndex]?.value + (isSelected ? action.data.addon?.isToggle ? (addonValue - prevChildPrice) : addonValue : -addonValue),
        };
      }
      const newPartPaymentAmount = state?.partpaymentAmount + (isSelected ? action.data.addon?.isToggle ? (addonMinValue - prevChildMinPayment) : addonMinValue : -addonMinValue);
      const newFullPayPaymentAmount = state?.fullAmount + (isSelected ? action.data.addon?.isToggle ? (addonValue - prevChildPrice) : addonValue : -addonValue);
      return {
        ...state,
        cab: {
          ...(state?.cab || {}),
          travel_addons: updatedAddOns,
          fare_breakup_list: updatedFareBreakupList,
        },
        partpaymentAmount: newPartPaymentAmount,
        fullAmount: newFullPayPaymentAmount,
      };
    }

    default:
      return state;
  }
};

export default reviewReducer;
