import {NativeModules, Platform} from 'react-native';
import _ from 'lodash';
import {isUserLoggedIn} from './UserSession/UserSessionModule';

const {UpcomingTripsModule} = NativeModules;

export const getUserBookingDetails = async () => {
  // return _getDummyDetail();
  try {
    const userLoggedIn = await isUserLoggedIn();
    if (Platform.OS === 'android' && userLoggedIn) {
      const userDetails = await UpcomingTripsModule.getUpcomingTrips();
      return _parseUserUpcomingTrips(userDetails);
    }
  } catch(e) {
    //
  }
  return [];
};

const _parseUserUpcomingTrips = (userDetails) => {
  try {
    const userDetailsObj = JSON.parse(userDetails);
    const flightData = [];
    const {
      userBookingDetailList = []
    } = userDetailsObj;

    userBookingDetailList.forEach((booking) => {
      const {
        flightSegment = []
      } = booking;
      flightSegment.forEach((flight) => {
        const flightDict = {};
        flightDict.source_airport = {
          airport_code: flight.origin,
          terminal: flight.departureTerminal
        };
        flightDict.destination_airport = {
          airport_code: flight.destination,
          terminal: flight.arrivalTerminal
        };
        flightDict.departure_date_time = flight.startDateTime;
        flightDict.arrival_date_time = flight.endDateTime;
        flightDict.flight_number = flight.airlineCode + flight.airlineNumber;
        flightDict.carrier = flight.airLineName;
        flightData.push(flightDict);
      });
    });

    return flightData;
  } catch (e) {
    // Ignore exception
  }
  return [];
};
