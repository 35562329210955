import React from 'react';
import { Animated, Platform, Text } from 'react-native';
import { colors, fonts } from '../../Styles/globalStyles';

export const THEME_DARK = 'dark';
export const THEME_LIGHT = 'light';

let _ref;
export default class Toast extends React.Component {
  static show(
    message,
    duration = 2000,
    bottomMargin = 64,
    theme = THEME_DARK,
    style = { position: Platform.OS === 'web' ? 'fixed' : 'static' },
    textStyle = {},
  ) {
    _ref._show(message, duration, bottomMargin, theme, style, textStyle);
  }

  constructor(props) {
    super(props);
    _ref = this;
    this.state = {
      show: false,
      message: '',
      opacityAnim: new Animated.Value(0),
      bottomMargin: 64,
    };
  }

  _show = (message, duration, bottomMargin, theme, style, textStyle) => {
    this.setState(
      {
        show: true,
        message,
        bottomMargin,
        theme,
        style,
        textStyle,
      },
      () => {
        Animated.timing(this.state.opacityAnim, {
          toValue: 1,
          duration: 300,
        }).start(() => {
          Animated.timing(this.state.opacityAnim, {
            toValue: 0,
            duration: 300,
            delay: duration,
          }).start(() => {
            this.setState({
              message: '',
              show: false,
            });
          });
        });
      },
    );
  };

  render() {
    const { message, show, opacityAnim, bottomMargin, theme, style, textStyle } = this.state;
    if (!show) {
      return null;
    }
    let backGroundColor = '#4a4a4a';
    let color = colors.white;
    if (theme === THEME_LIGHT) {
      backGroundColor = '#ffffff';
      color = '#000000';
    }
    return (
      <Animated.View
        style={[
          {
            position: 'absolute',
            bottom: bottomMargin, // 56px(bottom-bar) + 8px padding
            left: 8,
            right: 8,
            justifyContent: 'center',
            opacity: opacityAnim,
          },
          style,
        ]}
      >
        <Text
          style={[
            {
              borderRadius: 6,
              backgroundColor: backGroundColor,
              color,
              fontFamily: fonts.regular,
              fontSize: 12,
              padding: 16,
            },
            textStyle,
          ]}
          testID={'toastMsg'}
        >
          {message}
        </Text>
      </Animated.View>
    );
  }
}
