import {NativeModules, Platform} from 'react-native';
import { getRootTag } from '../RootTagHolder';

if (NativeModules.GenericModule) {
  const openMyra = NativeModules.GenericModule.openMyraPage;
  NativeModules.GenericModule.openMyraPage = (additionalInfo) => {
    if (Platform.OS === 'ios') {
      const reactTag = getRootTag();
      openMyra(reactTag, additionalInfo);
    } else {
      openMyra();
    }
  };

  const openDeepLink = NativeModules.GenericModule.openDeepLink;
  NativeModules.GenericModule.openDeepLink = (deeplink) => {
    if (Platform.OS === 'ios') {
      const reactTag = getRootTag();
      openDeepLink(deeplink, reactTag);
    } else {
      openDeepLink(deeplink);
    }
  };
}

export const updateRecentSearchHistory = (lob, searchData) => {
  const {GenericModule} = NativeModules;
  if (!GenericModule || !GenericModule.updateRecentHistory) {
    return;
  }
  GenericModule.updateRecentHistory(lob, searchData);
};


export const openViewFileIntentAndroid = ({ uri, mimeType, targetApp = null, newTaskFlag = true, noHistoryFlag = false }) => {
  const {GenericModule} = NativeModules;
  if (!GenericModule || !GenericModule.openViewFileIntentAndroid) {
    throw 'Action not supported! GenericModule.openFileIntentAndroid() doesn\'t exists on native side';
  }
  return GenericModule.openViewFileIntentAndroid({
    uri,
    'mime-type': mimeType,
    'target-app': targetApp,
    'new-task': newTaskFlag,
    'no-history': noHistoryFlag,
  });
};

export default NativeModules.GenericModule;
