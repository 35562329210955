export const ERROR_MSG_COLOR = '#960D11';
export const CONSENT_MSG_DEFAULT_COLOR = '#757575';
export const SELECT_STATE = 'Please select a State!';
export const USER_CONSENT = 'Please provide User Consent';
export const USER_CONSENT_ERROR = 'User Consent Not Provided';
export const USER_DETAILS_SUBMISSION_ERROR = 'Error in Submitting User Details to User Service API';
export const MAPI_URL = 'https://mapi.makemytrip.com/';
export const PREFILL_LOCUS_PATH = '/locus/web/v2/graphQl?region=in&language=eng&currency=inr';
export const PREFILL_LOCUS_API = MAPI_URL + PREFILL_LOCUS_PATH;

export const GSTIN_OMNITURE = {
    BAC_filled_render: 'BAC_filled_render',
    BAC_blank_render: 'BAC_blank_render',
    BAC_confirm_click: 'BAC_confirm_click',
    BAC_info_click: 'BAC_info_click',
    BAC_confirm_error: 'BAC_confirm_error',
    BAC_state_edit: 'BAC_state_edit',
    Re_GSTWidget_State_Match: 'Re_GSTWidget_State_Match',
    Re_GSTWidget_State_Mismatch: 'Re_GSTWidget_State_Mismatch',
    Re_GSTWidget_State_edit_match: 'Re_GSTWidget_State_edit_match',
    Re_GSTWidget_State_edit_mismatch: 'Re_GSTWidget_State_edit_mismatch'
};

export const CABS_OMNITURE_PAGE_NAME = 'funnel:domestic cabs:review';


export const LOB_PAGENAME_MAPPING_APPS = {
    'rails': 'Traveller Details',
    'bus': 'mob:funnel:domestic bus:review',
    'cabs': 'mob:funnel:domestic cabs:review',
};

export const LOB_PAGENAME_MAPPING_MWEB = {
    'rails': 'Traveller Details',
    'bus': 'funnel:domestic bus:review',
    'cabs': 'funnel:domestic cabs:review',
};

export const StatesData = [
    {
        id: '0001',
        label: 'Andaman and Nicobar Islands',
    },
    {
        id: '0002',
        label: 'Andhra Pradesh',
    },
    {
        id: '0003',
        label: 'Arunachal Pradesh',
    },
    {
        id: '0004',
        label: 'Assam',
    },
    {
        id: '0005',
        label: 'Bihar',
    },
    {
        id: '0006',
        label: 'Chandigarh',
    },
    {
        id: '0007',
        label: 'Chhattisgarh',
    },
    {
        id: '0008',
        label: 'Dadra and Nagar Haveli and Daman and Diu',
    },
    {
        id: '0009',
        label: 'Delhi',
    },
    {
        id: '0010',
        label: 'Goa',
    },
    {
        id: '0011',
        label: 'Gujarat',
    },
    {
        id: '0012',
        label: 'Haryana',
    },
    {
        id: '0013',
        label: 'Himachal Pradesh',
    },
    {
        id: '0014',
        label: 'Jammu and Kashmir',
    },
    {
        id: '0015',
        label: 'Jharkhand',
    },
    {
        id: '0016',
        label: 'Karnataka',
    },
    {
        id: '0017',
        label: 'Kerala',
    },
    {
        id: '0018',
        label: 'Ladakh',
    },
    {
        id: '0019',
        label: 'Lakshadweep',
    },
    {
        id: '0020',
        label: 'Madhya Pradesh',
    },
    {
        id: '0021',
        label: 'Maharashtra',
    },
    {
        id: '0022',
        label: 'Manipur',
    },
    {
        id: '0023',
        label: 'Meghalaya',
    },
    {
        id: '0024',
        label: 'Mizoram',
    },
    {
        id: '0025',
        label: 'Nagaland',
    },
    {
        id: '0026',
        label: 'Odisha',
    },
    {
        id: '0027',
        label: 'Puducherry',
    },
    {
        id: '0028',
        label: 'Punjab',
    },
    {
        id: '0029',
        label: 'Rajasthan',
    },
    {
        id: '0030',
        label: 'Sikkim',
    },
    {
        id: '0031',
        label: 'Tamil Nadu',
    },
    {
        id: '0032',
        label: 'Telangana',
    },
    {
        id: '0033',
        label: 'Tripura',
    },
    {
        id: '0034',
        label: 'Uttar Pradesh',
    },
    {
        id: '0035',
        label: 'Uttarakhand',
    },
    {
        id: '0036',
        label: 'West Bengal',
    },
    {
        id: '0037',
        label: 'Outside India',
    },
];
