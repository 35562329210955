import {createBrowserHistory} from 'history';
import {combineReducers} from 'redux';
import {configureStore, registerReducerTransformer} from '@rn/asyncStore';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

export const history = createBrowserHistory();
window.mmtCabHistory = history;
window.mmtHistory = history;

const persistConfig = {
  key: 'root',
  storage,
};

registerReducerTransformer(reducers => reducers);

const _dummyReducer = (state = {}) => state;
const reducer = combineReducers({__DUMMY__: _dummyReducer});
const persistedReducer = persistReducer(persistConfig, reducer);

export const store2 = () => {
  let store = createStore(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor }
};

const store = configureStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
export default store;
