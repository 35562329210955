const CabOmnitureEvent = Object.freeze({
  Landing_AT_Calendar_Widget_Click: 'Mob_Cabs_AT_Landing_Calendar_Widget_Click',
  Landing_HR_Calendar_Widget_Click: 'Mob_Cabs_HR_Landing_Calendar_Widget_Click',
  Landing_Zero_Offers: 'Mob_Cabs_Landing_Zero_Offers',
  Landing_Back_Press: 'Mob_Cabs_Landing_Back',
  MultiCity_Present: 'Cab_Multicity_Yes',
  MultiCity_Absent: 'Cab_Multicity_No',
  LPG_Yes: 'LPG_Yes',
  LPG_No: 'LPG_No',
  MyDeal_Strike_Off_Yes: 'MyDeal_Strike_Off_Yes',
  Manthan_Yes: 'Manthan_Enabled_Yes',
  Manthan_No: 'Manthan_Enabled_No',
  MyDeal_Strike_Off_No: 'MyDeal_Strike_Off_No',
  TY_Pay_To_Driver: 'Mob_Cabs_TY_Pay_Now_Click_for_Pay_to_Driver',
  AT_Short_Funnel: 'AT_Short_Funnel',
  AT_Instant_Funnel: 'AT_Instant_Funnel',
  TY_My_Trips_Click: 'Mob_Cabs_TY_My_Trips_Click',
  Listing_Fare_Breakup_Click: 'Mob_Cabs_Listing_Fare_Breakup_Click',
  Review_Pickup_Location_Not_Available: 'Mob_Cabs_Review_Pickup_Location_Not_Available',
  Review_Pickup_Location_Available: 'Mob_Cabs_Review_Pickup_Location_Available',
  Review_Drop_Location_Available: 'Mob_Cabs_Review_Drop_Location_Available',
  Review_Drop_Location_Not_Available: 'Mob_Cabs_Review_Drop_Location_Not_Available',
  Listing_Page_Section_Pay_Full_Click: 'Mob_Cabs_Listing_Page_Section_Pay_Full_Click',
  Listing_Page_Section_Pay_Part_Click: 'Mob_Cabs_Listing_Page_Section_Pay_Part_Click',
  Listing_Page_Section_Pay_To_Driver_Click: 'Mob_Cabs_Listing_Page_Section_Pay_To_Driver_Click',
  Listing_Edit_Click: 'Mob_Cabs_Listing_Edit_Click',
  Listing_TnC_Click: 'Mob_Cabs_Listing_TnC_Click',
  Listing_Privacy_Policy_Click: 'Mob_Cabs_Listing_Privacy_Policy_Click',
  Listing_User_Agreement_Click: 'Mob_Cabs_Listing_User_Agreement_Click',
  Listing_Cancellation_Rules_Click: 'Mob_Cabs_Listing_Cancellation_Rules_Click',
  Listing_Traveler_Enter_Click: 'Mob_Cabs_Listing_Traveler_Enter_Click',
  Listing_Calendar_Widget_Click: 'Mob_Cabs_Listing_Calendar_Widget_Click',
  Listing_Hold_Failed: 'Mob_Cab_Listing_Hold_Failed',
  Listing_Nearest_Slot_Search: 'Mob_Cabs_Listing_Nearest_Slot_Search',
  Listing_Complete_Payment_Click: 'Mob_Cab_Listing_Complete_Payment_Click',
  Listing_Partial_Payment_Click: 'Mob_Cab_Listing_Partial_Payment_Click',
  Listing_Zero_Payment_Click: 'Mob_Cab_Listing_Zero_Payment_Click',
  Listing_Default_Payment_Click: 'Mob_Cab_Listing_Default_Payment_Click',
  Listing_Hold_Fail_Time_Reset: 'Mob_Cabs_Listing_Hold_Fail_Time_Reset',
  Listing_Coupon_Enter: 'Mob_Cabs_Listing_Coupon_Enter',
  ADD_ON_DETAIL_NEXT_CLICK: 'Mob_Cab_Landing_Next_Click',
  ADD_ON_DETAIL_INCOMPLETE: 'Mob_Cab_Landing_Incomplete_Details_Error',
  ADD_ON_DETAIL_BACK_CLICK: 'Mob_Cab_Landing_Back_Click',
  ADD_ON_REVIEW_BACK_CLICK: 'Mob_Cab_Review_Back_Click',
  ADD_ON_REVIEW_CONFIRM_CLICK: 'Mob_Cab_Review_Confirm_Click',
  ADD_ON_TY_FARE_DETAIL_CLICK: 'Mob_Cab_Fare_Details_Click',
  ADD_ON_TY_MY_TRIP_CLICK: 'Mob_Cab_TY_Go_To_My_Trips_Click',
  ADD_ON_DETAIL_LOAD_ERROR: 'Mob_Cab_Landing_Error',
  ADD_ON_REVIEW_LOAD_ERROR: 'Mob_Cab_Review_Error',
  ADD_ON_TY_LOAD_ERROR: 'Mob_Cab_TY_Error',
  INSTANT_TO_PREBOOKING: 'Instant_to_Prebooking',
  NO_RYDE_HATCH: 'No_Ryde_Hatch',
  NO_RYDE_SEDAN: 'No_Ryde_Sedan',
  NO_RYDE_SUV: 'No_Ryde_SUV',
  SCHEDULE_CAB_BUTTON_INSTANT_CLICKED: 'Mob_Cab_Listing_NA_Schedule_Cab_Click',
  Landing_Offer_Cards_Click: 'Cabs_Landing_Offer_Cards_Click',
  Instant_Offer_Card_Click: 'Mob_Cabs_Landing_Instant_Cab_Card_Click',
  Landing_Ride_Now_Present: 'Mob_Cabs_Landing_Ride_Now_Present',
  Landing_Ride_Now_Absent: 'Mob_Cabs_Landing_Ride_Now_Absent',
  SCHEDULE_LATER_CLICKED: 'Mob_Cabs_Listing_Schedule_Later_Click',
  RIDE_NOW_CLICKED: 'Mob_Cabs_Listing_Ride_Now_Click',
  RIDE_NOW_TO_SCHEDULE: 'Mob_Cabs_Listing_Instant_to_Schedule',
  SCHEDULE_TO_RIDE_NOW: 'Mob_Cabs_Listing_Schedule_to_Instant',
  Mob_Cabs_Listing_Ratings_Click: 'Mob_Cabs_Listing_Ratings_Click',
  Mob_Cabs_Review_Ratings_Click: 'Mob_Cabs_Review_Ratings_Click',
  Cabs_Review_RatingsReviews_Section_Click: 'Cabs_Review_RatingsReviews_Section_Click',
  PHONE_PE: 'PhonePe'
});

export default CabOmnitureEvent;
