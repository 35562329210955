import { Platform } from 'react-native';

const isAndroid = Platform.OS === 'android';
const isIos = Platform.OS === 'ios';
const isRnw = Platform.OS === 'web';

const platformPokuskey = (androidKey, iosKey, mWebKey) => {
  if (isAndroid) {
    return androidKey;
  } else if (isIos) {
    return iosKey;
  } else if (isRnw && mWebKey) {
    return mWebKey;
  }
  // TODO RNW: Key for AbConfig
  return androidKey;
  // throw new Error('Unsupported platform!');
};

const abConfigKeyMappings = Object.freeze({
  flightInfoOptionSequence: 'flightInfoOptionSequenceKey',
  cabsGoogleApiKey: 'cabsGoogleApiKey',
  addGuestEnabled: 'addGuestEnabled',
  dateChangeEnabled: 'dateChangeEnabled',
  htlShowBlackPromo: 'htlShowBlackPromo',
  autoPlayVideo: 'autoPlayVideo',
  showVideos: 'showVideos',
  autoEnableAudio: 'autoEnableAudio',
  isHerculean: platformPokuskey('flightHerculean', 'ios_isNewFlightOn'),
  myraChatEnabled: platformPokuskey('myraChatEnabled', 'MyraEnabledIOS'),
  // risEnabled: 'risEnabled', // permanently true
  mmtp: 'mmtp',
  visaMyraChatEnabled: 'visaMyraChatEnabled',
  mmtp: 'mmtp',
  flightGrpBookingText: 'flightGrpBookingText',
  flightGrpBookingNumber: 'flightGrpBookingNumber',
  flightGrpBookingThreshold: 'flightGrpBookingThreshold',
  essenceSortingEXP: 'essenceSortingEXP',
  googleApiKeyEXP: 'googleApiKeyEXP',
  detailFooterBtnTextEXP: 'detailFooterBtnTextEXP',
  promoOverlayBanner: 'promoOverlayBanner',
  landingAppUpdateOverlayEXP: 'landingAppUpdateOverlayEXP',
  detailsSortingEXP: 'detailsSortingEXP',
  quickSearchEXP: 'quickSearchEXP',
  isSupportButtonEnabledEXP: 'isSupportButtonEnabledEXP',
  isMyraSupportEnabledEXP: 'isMyraSupportEnabledEXP',
  isAcmeQuerySupportEnabledEXP: 'isAcmeQuerySupportEnabledEXP',
  newUGCFormEXP: 'newUGCFormEXP',
  acmeLandingSort: 'acmeLandingSort',
  newUGCDetailsExp: 'newUGCDetailsExp',
  isUGCListingEnabled: 'isUGCListingEnabled',
  minReviewsToShow: 'minReviewsToShow',
  isMySafetyEnabled: 'isMySafetyEnabled',
  GCCManthanConfig: 'GCCManthanConfig',
  acmeMySafetyData: 'acmeMySafetyData',
  isAcmeMmtBlackEnabled: 'isAcmeMmtBlackEnabled',
  acmeListingCardOrders: 'acmeListingCardOrders',
  enableOldTagUI: 'enableOldTagUI',
  showWGBannerLandingHol: 'showWGBannerLandingHol',
  landingSearchFilter: 'landingSearchFilterNew',
  tpPostSalesQueryNumHol: 'tpPostSalesQueryNumHol',
  giCustomerCareNum: 'giCustomerCareNum',
  disableUserGroupHol: 'disableUserGroupHol',
  showBudgetGraphHol: 'showBudgetGraphHol',
  listingStoryCountHol: 'listingStoryCountHol',
  detailStoryCountHol: 'detailStoryCountHol',
  coachmarkDaysDelayHol: 'coachmarkDaysDelayHol',
  tcsBannerHOL: 'tcsBannerHOL_Mobile',
  railsCDFEnabled: platformPokuskey('railsCDFEnabledAndroid', 'railsCDFEnabledIOS'),
  railsPostPaymentFailureEnabled: 'railsPostPaymentFailureEnabled',
  // railsNearbyStations: 'railsNearbyStations',  permanently false
  railsUserFlowV2Enabled: 'railsUserFlowV2Enabled',
  // railsShowMmtTrainFeatures: 'railsShowMmtTrainFeatures', permanently false
  railsShowCNFProbabilityOnSRP: 'railsShowCNFProbabilityOnSRP',
  // irctcCreateNewAccountAb: 'irctcCreateNewAccountAb', permanently true
  railsFunnelVariant: 'railsFunnelVariant',
  enableIrctcJuspay: 'enableIrctcJuspay',
  showSmiliesOnHotelFeedback: 'showSmiliesOnHotelFeedback',
  railsHotelsCrossSell: 'railsHotelsCrossSell',
  railsVoucherCode: 'railsVoucherCode',
  railsHotelCouponCombined: 'railsHotelCouponCombined',
  cabsGenderInputEnabled: 'CabRNGenderField',
  railsShowConfirmationChance: 'railsShowConfirmationChance',
  // railsShowConfirmationChanceV2: 'railsShowConfirmationChanceV2', permanent false
  // railListingV3: 'railListingV3', // permanent true
  railsShowTnCStatement: 'railsShowTnCStatement',
  ptCardRefreshThresholdOnExpiry: 'ptCardRefreshThresholdOnExpiry',
  railsShowCustomIrctcWebpage: 'railsShowCustomIrctcWebpage',
  railsNewUserFlow: 'railsNewUserFlow',
  railsCouponDiscount: 'railsCouponDiscount',
  // railsWalletPersuasion: 'railsWalletPersuasion', permanently true
  railsRefreshIrctcButton: 'railsRefreshIrctcButton',
  // railsCouponCard: 'railsCouponCard', permanently false
  // railsHotelCard: 'railsHotelCard', permanently true
  railsEnableVernacular: 'railsEnableVernacular',
  railsTravelerAdditionalPreference: 'railsTravelerAdditionalPreference',
  skipReviewPage: 'railsSkipReviewPage',
  RailsInsuranceDefaultSelected: 'RailsInsuranceDefaultSelected',
  // RailsShowCancelButtonOnIRCTCPage: 'railsShowCancelButtonOnIRCTCPage', permanently true
  RailsEnableHelpSectionOnIRCTCWebpage: 'railsEnableHelpSectionOnIRCTCWebpage',
  railsRisLtsCardsPokus: 'Rails_LTS_Cards',
  ptShowWhatsappSubscription: 'ptShowWhatsappSubscription',
  ptWhatsappCheckedState: 'ptWhatsappCheckedState',
  APSRTC_ACTIVE: 'APSRTC_ACTIVE',
  isAcmeRecomEnabled: 'isAcmeRecomEnabled',
  lobsToShowNps: 'lobsToShowNps',
  // railsTrvAddPrefExpanded: 'railsTrvAddPrefExpanded', permanently true
  oneLineCalendarVisibility: 'oneLineCalendarVisibility',
  MCA: platformPokuskey('MCA', 'MCI'),
  showChangeInPlanCard: 'showChangeInPlanCard', // this is a workaround till we integrate pokus on web & iOS
  // dummyKey: platformPokuskey('dummyKey_Android', 'dummyKey_iOS')
  // metroEnabled: 'metroEnabled', permanently true
  railsBusShowCalendarWithHolidays: 'railsBusShowCalendarWithHolidays',
  // railsShowCalendarWithHolidays: 'railsShowCalendarWithHolidays', permanently false
  // showTrainRecommendedCoupons: 'showTrainRecommendedCoupons', // permanent true
  showFCUpgradeOption: 'FC_upgrade',
  // showMetroReferral: 'showMetroReferral', permanently false
  // showMetroRoute: 'showMetroRoute', permanently true,
  busSortAndFilter: 'Li_Quick_Filters_New',
  railsRISOptions: 'railsRISOptions',
  railsBusAdsOptions: 'rails_ads_common_landing_screen',
  adsOnRailFunnelApps: 'Rails_Ad_ONOFF_Apps',
  adsOnRailFunnelWebs: 'Rails_Ad_ONOFF_Mweb',
  showFreebie: 'showFreebiesPersuasions',
  railsShowNewLandingSearchForm: 'railsShowNewLandingSearchForm',
  railsShowAdditionalAddressLines: 'railsShowAdditionalAddressLines',
  railsShowDuplicateBookingAlert: 'railsShowDuplicateBookingAlert',
  holidayCovidContent: 'holidayCovidContent',
  showSimilarPkgDestMob: 'showSimilarPkgDestMob',
  railsShowIRCTCUsernameOnTraveller: 'railsShowIRCTCUsernameOnTraveller',
  railsShowIRCTCUsernameInfoMessagesAndPassword: 'railsShowIRCTCUsernameInfoMessagesAndPassword',
  // railsShowNewIRCTCUsernameBottomSheet: 'railsShowNewIRCTCUsernameBottomSheet',  permanently true
  cabLandingRadioButton: 'cab_landing_radio_button',
  backReviewPopupShow: 'Cab_BPG_Back_Popup',
  holDocumentUpload: 'holDocumentUpload',
  showFromCityFPH: 'showFromCityFPH',
  showPriceChangeFPH: 'showPriceChangeFPH',
  holStoryExpiryInDaysMob: 'holStoryExpiryInDaysMob',
  mapStaticMarkers: 'mapStaticMarkers',
  holMapPageFilterImages: 'mapImgs',
  showFPHEditOverlay: 'showFPHEditOverlay',
  openIndigoPackageoutApp: 'openIndigoPackageoutApp',
  showReviews: 'cab_view_all_reviews',
  freeCancellationRails: 'free_cancellation_rails',
  acmeXsell_config: 'acmeXsell_config',
  busEnableNitroDeals: 'enableNitroDeals',
  busRatingV2: 'Li_Rating_Color_Changes_New',
  holQueryFormDefaultTravelDaysCount: 'queryFormDefaultTravelDaysCount',
  holRecentSearchExpireDays: 'holRecentSearchExpireDays',
  rails_RIS_options_NEW: 'rails_RIS_options_NEW_V2',
  showMoreOptionsCabs: 'more_cab_options',
  busHideReviewTnCs: platformPokuskey(
    'busAppsHideReviewTnCs',
    'busAppsHideReviewTnCs',
    'busMwebHideReviewTnCs',
  ),
  busShowBPDPrecommendation: 'busBPDPrecommendation',
  showHECardonDetail: 'showHECardonDetail',
  maxUndoAllowed: 'maxUndoAllowed',
  railsConfirmationGuaranteeOption: 'railsConfirmationGuaranteeOption',
  railsShowFCStampOnListing: 'railsShowFCStampOnListing',
  busListingAds: 'busListingAds',
  busListingOffersCarousel: 'busListingOffersCarousel',
  loadNewBusOfferBanner: 'busOffersV2',
  busShowSnackbarAds: 'busSnackbarAB',
  busNoGST: 'Re_NoGST',
  rNWebGoogleApiKey: 'rNWebGoogleApiKey',
  showOvernightFlightsFilter: 'showOvernightFlightsFilterNew',
  showNewOverlays_FPH_MOB: 'showNewOverlays_FPH_MOB',
  showNeedHelpCard: 'showNeedHelpCard',
  cuesConfig: 'cuesConfig',
  busFreeCancellationPopup: 'manthanIntegration',
  showQueryFormV2: 'showQueryFormV2',
  railsLanguageSelector: 'Rails_Lang_selector',
  busBookViaChatCallout: 'BusBookViaChatCallout',
  busShowNewLoginPrompt: 'busReviewLoginPrompt',
  enableCarouselViewDetail: 'enableCarouselViewDetail',
  railsPNRBlocker: 'rails_PNR_login_blocker',
  enableLoginPersuasion: 'rail_PNR_login_persuasion_ONOFF',
  tlsV3Enabled: 'tlsV3Enabled',
  isRisLtsPlotCT: 'Rails_LTS_Offline_Modes',
  ltsRNFlow: 'LTS_Native_RN_migrations',
  ctInfoEnabled: 'Rails_LTSOffline_CTHeader_ONOFF',
  busListingFlatList: 'busListingFlatList',
  railsBusTrainCrossSell: 'Rail_bustrain_tab_listing',
  railsLtsFeedback: 'Rails_LTS_Feedback_V2',
  railsShowRouteonListing: 'railsShowRouteonListing',
  busCrossSell: 'busTrainsTabListing',
  enableHolidaysSuitableForHeaderFilters: 'enableHolidaysSuitableForHeaderFilters',
  verticalScrollNudgeConfig: 'verticalScrollNudgeConfig',
  enableSummaryTabRemoveAccordion: 'enableSummaryTabRemoveAccordion',
  show6EGroupPackages: 'show6EGroupPackages',
  holShowSummaryTabFirst: 'holShowSummaryTabFirst',
  Offertimer_Mobile: 'Offertimer_Mobile',
  showTICardonMytrips: 'showTICardonMytrips',
  Planningpresales: 'Planningpresales',
  busMultiBannerMWeb: 'busMultibannerMWeb',
  apiPokusListing: 'apiPokusListing',
  risLtsLandingV2: 'Rails_LTS_Newlanding_ONOFF',
  HolidaysCtaData: 'HolidaysCtaData',
  railsShowBnplWidget: 'Rails_listing_BNPL_ONOFF',
  desRedirection: 'Rails_Bus_DES_redirection',
  multicitySwitch: 'Multicity_switch',
  phoenixReviewSectionsExpanded: 'phoenixReviewSectionsExpanded',
  phoenixReviewSectionsOrder: 'phoenixreviewsectionsorder',
  showPriceChangeDetails: 'showPriceChangeDetails',
  enablePhoenixSearchPage: 'enablePhoenixSearchPage',
  cabAppDownloadWidget: 'App_download_persuasion',
  enableRailsLtsAccuracyLogging: 'Rails_LTS_Offline_CTandAccuracy_logging',
  enableRailsLTSIntermediateAPIV2: 'Rails_LTS_Intermediate_API_V2',
  mbmc: 'mbmc',
  filterBottomSheet: 'filterBottomSheet',
  enableHolidaysGroupingSection: 'enableHolidaysGroupingSection',
  hotelMarketingCheckFare: 'Hotel_Marketing_CheckFare',
  TalkToUsMyTrips: 'TalkToUsMyTrips',
  EscalateMyTrips: 'EscalateMyTrips',
  enableGroupingGalleryWidget: 'enableGroupingGalleryWidget',
  mytripsCSATApp: 'mytripsCSATApp',
  showHolBaggageInfo: 'showHolBaggageInfo',
  addBooking: 'addbooking_postsales',
  railsTGSLoginCompulsory: 'rails_tgs_mweb_login_compulsor',
  tgsEmailInput: 'rails_tgs_email_input',
  tgsBookingSource: 'rails_tgs_booking_source',
  voipPostSales: 'voip_postsales',
  seatMapRedesignV2: 'Se_Seatmap_Redesign',
  reviewAssistedWidget: 'review_assisted_widget_new',
  isomorphic: 'Isomorphic_Web',
  listingAssistedWidget: 'Listing_Assisted_Widget_web',
});

export const PokusLobs = {
  DATAPLATFORM: 'DATAPLATFORM',
  PAYMENT: 'PAYMENT',
  BUS: 'BUS',
  DOUBLE_BLACK: 'DOUBLE_BLACK',
  ACME: 'ACME',
  HOTEL_INT: 'HOTEL_INT',
  CAB: 'CAB',
  VISA: 'VISA',
  GIFT_CARD: 'GIFT_CARD',
  HOTEL: 'HOTEL',
  OTHERS: 'OTHERS',
  FLIGHT: 'FLIGHT',
  RAIL: 'RAIL',
  HOLIDAY: 'HOLIDAY',
  COMMON: 'COMMON',
  POST_SALES: 'POST_SALES',
  FLIGHT_INT: 'FLIGHT_INT',
};

export default abConfigKeyMappings;
