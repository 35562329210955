const bottleIcon = require('../../Assets/AmenitiesIcons/water_bottle.webp');
const placard = require('../../Assets/AmenitiesIcons/placard.webp');
const newspaper = require('../../Assets/AmenitiesIcons/newspaper.webp');
const driver = require('../../Assets/AmenitiesIcons/driver.webp');
const car_model = require('../../Assets/AmenitiesIcons/car_model.webp');

export const CABS_DATE_FMT = 'cabsServerDate';
export const CABS_TIME_FMT = 'cabsServerTime';
export const ALLOWED_DATE_FORMAT = '$date_';
export const UNIVERSAL_SEARCH = 'Universal_Search';
export const LOCAL_NOTIFICATION_TIMESTAMP = 'cabs_local_notification_timestamp';
export const LISTING_EXISTING_USER = 'cabs_listing_existing_user';
export const CAB_YEAR_MAKER = 'CAB YEAR MAKE';
export const INCLUSION_EXCLUSION = 'FARE INCLUSIONS & EXCLUSIONS';

export const CABS_OMNI_LOB = 'domestic cabs';
export const CABS_OMNI_PAGE_NAME = 'landing:domestic cabs:landing-New';
export const IDENTIFIER = '_source_cab_thankyou';

export const CABS_LISTING_PAGE = 'list';
export const CABS_REVIEW_PAGE = 'review';

export const CABS_HOLD_FAILURE_MSG =
  'Selected cab is no longer available, please' + ' try booking another cab';
export const CABS_COUPON_FAILURE_MSG = 'Failed to apply the coupon.';
export const CABS_COUPON_REMOVAL_FAILURE_MSG =
  'Sorry! There was some problem in removing the coupon. Kindly move to the previous page to remove it.';

export const MyBizTripChangeErrCode = 'MY_BIZ_TRIP_TYPE_CHANGED_ERROR_CODE'; //Err Code to do search again for myBiz triptype change.

export const COUPON_BACK_PRESS_KEY = 'cabs_rnw_backPress_coupon';
export const REVIEW_PAGE_POS_ON_SCROLL = 'cabs_review_page_pos_on_scroll'

export const noRideNowAvailableErrMsg =
  'Ride now is not available for this address. Would you like to schedule a pickup instead?';

export const FUNNEL_SOURCE = {
  PROVISIONAL: 'Provisional_WA',
};

export const CabsMultiVendorTags = {
  RECOMMENDED: 'RECOMMENDED',
  MOST_BOOKED: 'MOST_BOOKED',
  CHEAPEST: 'CHEAPEST',
  HIGHEST_RATED: 'HIGHEST_RATED',
};

export const CabsBoardingType = {
  ON_SPOT: 'ON_SPOT',
  REGULAR: 'REGULAR',
};

export const CabsATScheduleType = {
  SCHEDULE: 'Schedule',
  RIDE_NOW: 'Ride Now',
};

export const LANDING_CARD_TYPES = {
  OFFERS: 'OFFERS',
  COVID_INFO_CARD: 'COVID_INFO_CARD',
  ADS: 'ADS',
  BUS_HIRE: 'BUS_HIRE',
  MYSAFETY: 'MYSAFETY',
  WHATS_NEW: 'WHATS_NEW',
  CATEGORIES: 'CATEGORIES',
};

export const CabsVendorId = {
  RYDE: 4,
};

export const CabsPartners = {
  RYDE: 'RYDE',
  DEFAULT: 'DEFAULT',
};

export const DeeplinkParentScreen = Object.freeze({
  FIS_LANDING_PAGE: 'flightStatusLanding',
  UNIVERSAL_SEARCH: 'universalSearch',
  FLIGHT_TRIP_DETAILS: 'flightTripDetails',
});

export const CabTypes = {
  HATCHBACK: 'HATCHBACK',
  SEDAN: 'SEDAN',
  SUV: 'SUV',
};

export const CabsHeaderSizes = {
  SIZE_SMALL: 56,
  SIZE_LARGE: 108,
  SIZE_NORMAL: 72,
};

export const RouterPathName = {
  CABS_LISTING: '/cabsListing',
  CABS_REVIEW: '/cabsTripReview',
};

export const CAB_PAGE_STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  DEFAULT: 'default',
  NO_RESULT: 'noResult',
  NO_INTERNET: 'noInternet',
  ERROR: 'error',
  PENDING: 'pending',
  FAILED: 'failed',
};

export const RatingBreakupLabels = {
  on_time: 'On Time Pick Up',
  cab_condition: 'Car Rating',
  driver_behavior: 'Driver Rating',
  cab_cleanliness: 'Car Cleanliness',
};

export const RatingBucket = {
  EXCELLENT: 'EXCELLENT',
  GOOD: 'GOOD',
  POOR: 'POOR',
};

export const InfoTypes = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  DANFER: 'DANGER',
};

export const ErrorCodes = Object.freeze({
  BOOKING_NOT_FOUND_ERROR_CODE: 'BOOKING_NOT_FOUND',
  BOOKING_ALREADY_UPDATED_ERROR_CODE: 'BOOKING_ALREADY_UPDATED',
  LOCKDOWN_ERROR_CODE: 'LOCK_DOWN_ERROR_CODE',
  LOB_BLOCKED_ERROR_CODE: 'LOB_BLOCKED_ERROR_CODE',
  INTRACITY_UNAVAILABILITY_ERROR_CODE: 'INTRACITY_UNAVAILABILITY_ERROR_CODE',
  MYBIZ_NO_CABS_FOUND: 'MYBIZ_NO_CABS_FOUND', //Err Code to open form for offline flow
  RIDE_NOW_DEAD_END_CODE: 'RIDE_NOW_DEAD_END_CODE',
  BOOKING_CANCELLED_ERROR_CODE: 'BOOKING_CANCELLED_ERROR_CODE'
});

export const FLIGHT_DETAILS_RELOAD_SOURCE = {
  FROM_FLIGHT_DATE_CHANGE: '111',
  FLIGHT_CANCELLATION_DONE: '113',
  FROM_CAB_CROSS_SELL_PAGE: '114',
};

export const FareType = {
  DISCOUNT: 'DISCOUNT',
  NON_DISCOUNT: 'PAYABLE',
  TOTAL: 'TOTAL',
};

export const AirportPrimeFeaturesIcon = {
  bottle: bottleIcon,
  placard: placard,
  newspaper: newspaper,
  driverUniform: driver,
  carModel: car_model,
};

//  Below enums are defined for fetchOffers Section
// ALL implies Independent of the enum
export const offerType = {
  DISCOUNT: 'DISCOUNT',
  SUBSCRIPTION: 'SUBSCRIPTION',
};

export const offerBookingType = {
  INSTANT: 'INSTANT',
  NORMAL: 'NORMAL',
  ALL: 'ALL',
};

export const offerSource = {
  HOTELS_X_SELL: 'HOTELS_X_SELL',
  PROVISIONAL: 'PROVISIONAL',
  FUNNEL: 'FUNNEL',
  SEO: 'SEO',
  ALL: 'ALL',
};

export const offerPage = {
  LANDING: 'LANDING',
  LISTING: 'LISTING',
  REVIEW: 'REVIEW',
  X_SELL: 'X_SELL',
  SEO: 'SEO',
  ALL: 'ALL',
};

// Above enums are define for fetchOffersSection

export const ClickToActionTypes = {
  NO_ACTION: 'NO_ACTION',
};

export const myBizTravellerTypes = {
  SELF: 'SELF',
  GUEST: 'GUEST',
  COLLEAGUE: 'COLLEAGUE',
};

export const LandingCardsProfieTypes = {
  PERSONAL: 'PERSONAL',
  BUSINESS: 'BUSINESS',
  ALL: 'ALL',
};

export const npsRating = {
  header: 'Rate your Booking Experience?',
  subheader: 'Tell us how was your booking experience!',
  subHeading: {
    oneStar: "Oh! Too bad! Why don't you give us some feedback?",
    twoStar: "Oh! Too bad! Why don't you give us some feedback?",
    threeStar: "Umm! Looks like we are doing just okay! Why don't you give us your feedback?",
    fourStar: 'Yay! We are always trying to do our best!',
    fiveStar: 'Awesome! We will keep improving!',
  },
  successMsg: 'Thanks for your rating!',
  actionOn4: 'TY',
  abVarient: 'IARP',
  canEdit: true,
  lowRatingCta: [
    {
      type: 'LEFT',
      title: 'SKIP',
    },
    {
      type: 'RIGHT',
      title: 'FEEDBACK',
    },
  ],
  fourRatingCTA: [
    {
      type: 'LEFT',
      title: 'SKIP',
    },
    {
      type: 'RIGHT',
      title: 'SUBMIT',
    },
  ],
  fiveRatingCTA: [
    {
      type: 'LEFT',
      title: 'SKIP',
    },
    {
      type: 'RIGHT',
      title: 'SUBMIT',
    },
  ],
};

export const cabBlockLobs = {
  OS_HIDE: 'OUTSTATION_CABS|0',
  OS_BLOCK: 'OUTSTATION_CABS|1',
  AT_HIDE: 'AIRPORT_CABS|0',
  AT_BLOCK: 'AIRPORT_CABS|1',
  HR_HIDE: 'RENTAL_CABS|0',
  HR_BLOCK: 'RENTAL_CABS|1',
};

// CabABKeys are pokus key constants
export const CabABKeys = {
  Review_Page_Luggage_Info: 'Review_Page_Luggage_Info',
  App_download_persuasion: 'App_download_persuasion',
  Blusmart_Listing: 'Blusmart_Listing',
  OS_Listing_Packages: 'OS_Listing_Packages',
  App_download_persuasion: 'App_download_persuasion',
  Review_Assisted_Widget: 'review_assisted_widget_new',
  Listing_Assisted_Widget: 'Listing_Assisted_Widget_web',
};
export const cabsAbOmnitureKeys = {
  Listing_Coupon_Persuasion: 'Listing_Coupon_Persuasion',
  Listing_Part_Payment: 'Listing_Part_Payment',
  OS_Listing_Packages: 'OS_Listing_Packages',
  Listing_Assisted_Widget_web: 'Listing_Assisted_Widget_web',
  review_assisted_widget_new: 'review_assisted_widget_new',
};

// The Omniture Values are constants used for tracking
export const OmnitureValues = {
  Cab_CNG_Number: 'Cab_CNG_Number',
  Cab_CNG_String: 'Cab_CNG_String',
  Ryde_AT_True: 'Ryde_AT_True',
  Ryde_AT_False: 'Ryde_AT_False',
  Luggage_Info_True: 'Luggage_Info_True',
  Luggage_Info_False: 'Luggage_Info_False',
  LandingPage_SnackBar_True: 'Snack_Bar_True',
  LandingPage_SnackBar_False: 'Snack_Bar_False',
  Review_Full_Price_True: 'Review_Full_Price_True',
  Review_Full_Price_False: 'Review_Full_Price_False',
  Ryde_listing_swap_True: 'Ryde_listing_swap_True',
  Ryde_listing_swap_False: 'Ryde_listing_swap_False',
  App_download_persuasion_True: 'App_download_persuasion_True',
  App_download_persuasion_False: 'App_download_persuasion_False',
  Mysafety_Switch_True: 'Mysafety_Switch_True',
  Mysafety_Switch_False: 'Mysafety_Switch_False',
  Listing_Coupon_Persuasion_True: 'Listing_Coupon_Persuasion_True',
  Listing_Coupon_Persuasion_False: 'Listing_Coupon_Persuasion_False',
  Blusmart_Listing_0: 'Blusmart_Listing_0',
  Blusmart_Listing_1: 'Blusmart_Listing_1',
  Blusmart_Listing_2: 'Blusmart_Listing_2',
  Quick_Checkout_Mweb_OS_True: 'Quick_Checkout_Mweb_OS_True',
  Quick_Checkout_Mweb_OS_False: 'Quick_Checkout_Mweb_OS_False',
  Quick_Checkout_Mweb_AT_True: 'Quick_Checkout_Mweb_AT_True',
  Quick_Checkout_Mweb_AT_False: 'Quick_Checkout_Mweb_AT_False'
};

// export const cabsAbOmnitureKeys = {
//   Mysafety_Switch: 'Mysafety_Switch',
//   Listing_Coupon_Persuasion: 'Listing_Coupon_Persuasion',
//   Listing_Part_Payment: 'Listing_Part_Payment',
//   Review_UX_RBYB: 'Review_UX_RBYB',
//   Quick_Checkout_Mweb_OS: 'Quick_Checkout_Mweb_OS',
//   Quick_Checkout_Mweb_AT: 'Quick_Checkout_Mweb_AT'
// }

export const CONFIG_KEYS = {
  LANDING_SNACKBAR_CONTEXT: 'Q0FCI0xBTkRJTkcjU05BQ0tCQVI=',
};

export const TRAVEL_TYPE_OFFERS_KEYS = {
  OUTSTATION: 'ocabs',
  AIRPORT: 'acabs',
  HOURLY_RENTALS: 'rcabs',
};

const PROP_54 = 'm_c54';
const EVAR_46 = 'm_v46';
export const CabsBnplOmnitureData = {
  CHECK_ELIGIBILITY_CLICK: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility_Clicked',
  },
  BNPL_SUCCESS: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility_Success',
  },
  BNPL_FAILURE: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility_Failed',
  },
  BNPL_TIMEOUT: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility_Timeout',
  },
  BNPL_SHOWN: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility',
  },
  REPAYMENT_DETAILS_CLICKED: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility_Success_Repayment Details_Clicked',
  },
  BNPL_FAILED_CLOSE: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility_Close',
  },
  BNPL_TIMEOUT_CLOSE: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Check_Eligibility_Timeout_Close',
  },
  REPAYMENT_DETAILS_CLOSED: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Repayment_Page_Close',
  },
  ADD_MOBILE_NUMBER_CLICK: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Add_Mobile_Clicked',
  },
  LOGIN_CLICK: {
    key: PROP_54,
    value: 'Mob_Cab_Listing_BNPL_Login_Clicked',
  },
  BNPL_SUCCESS_CONVERSION: {
    key: EVAR_46,
    value: 'BNPL_Success',
  },
  BNPL_FAILURE_CONVERSION: {
    key: EVAR_46,
    value: 'BNPL_Failure',
  },
  BNPL_TIMEOUT_CONVERSION: {
    key: EVAR_46,
    value: 'BNPL_Timeout',
  },
};

export const CAB_LIST_CATEGORIES = {
  RAP_CABS: 'RAP_CABS',
  NON_RAP_CABS: 'NON_RAP_CABS',
};

export const CAB_TYPE_PREFERENCE = {
  RAP: 'RAP',
  NON_RAP: 'Non-Rap',
};

export const cabPages = {
  LANDING: 'Landing',
  LISTING: 'Listing',
  REVIEW: 'Review',
  THANKYOU: 'ThankYou',
};

export const TRAVELLER_DETAILS = {
  MYSELF: 'myself',
  SOMEONEELSE: 'someoneElse',
  GENREAL: 'general',
  BOOKING_FOR_HANDLER: 'bookingForChange',
  ALL_BOOKING: 'all',
  TRAVELLER_TITLE: `Traveller's information`,
}
