import _isEmpty from 'lodash/isEmpty';
import _flatMap from 'lodash/flatMap';
import _uniqBy from 'lodash/uniqBy';
import _isNil from 'lodash/isNil';
import _trim from 'lodash/trim';
import { Keyboard } from 'react-native';
import { Actions } from 'src/Routes/Cabs/Navigation';
import debounce from 'lodash/debounce';
import {
  CabsPageTrackerFunc,
  eventHeaderForTripType,
  trackLandingEvent,
} from '../../cabsAnalytics';
import { getRecentSearches } from '../../utils/cabsLocalStore';
import TripType, { AirportPickupType } from '../../types/TripType';
import {
  fetchAutoCompleteResults,
  fetchAutoCompleteResultsForNearByLocation,
  fetchAutoCompleteResultsFromServer,
  getCurrentLocation,
  getLocationWithPerm,
  getPlaceDetails,
  getServerPlaceDetails,
} from '../cabsPlaces';
import { fetchLiteAutoCompleteResults, getLatLongDetails } from '../cabsPlacesV2';
import { GPS_OFF, NO_LOCATION_PERM } from '../../../../Helpers/locationHelper';
import LocationModule from '../../../../Native/LocationHelperModule';
import { getAirportTypeFromCityCode, isLocationNearBy } from '../../utils/cabsSearchUtil';
import { CabsScreen } from '../../config/cabsScreen';
import { getTripDataFromState } from '../../utils/cabsCommonUtils';

export const CityPickerAction = {
  SET_INITIAL_PICKER_DATA: 'SET_INITIAL_PICKER_DATA',
  ACTION_SOURCE_FOCUS: 'ACTION_SOURCE_FOCUS',
  ACTION_DEST_FOCUS: 'ACTION_DEST_FOCUS',
  ACTION_SOURCE_CLEAR: 'ACTION_SOURCE_CLEAR',
  ACTION_DEST_CLEAR: 'ACTION_DEST_CLEAR',
  ACTION_SOURCE_TEXT_CHANGE: 'ACTION_SOURCE_TEXT_CHANGE',
  ACTION_DEST_TEXT_CHANGE: 'ACTION_DEST_TEXT_CHANGE',
  ACTION_LOCATION_SELECT: 'ACTION_LOCATION_SELECT',
  ACTION_RECENT_PLACE_CLICKED: 'ACTION_RECENT_PLACE_CLICKED',
  ACTION_SWAP_SEARCH: 'ACTION_SWAP_SEARCH',
  ACTION_AUTO_COMPLETE_RESULTS_SELECT: 'ACTION_AUTO_COMPLETE_RESULTS_SELECT',
  ACTION_AUTO_COMPLETE_RESULTS: 'ACTION_AUTO_COMPLETE_RESULTS',
  ACTION_SET_RECENT_PLACES: 'ACTION_SET_RECENT_PLACES',
  ACTION_SET_POPULAR_SEARCHES: 'ACTION_SET_POPULAR_SEARCHES',
  ACTION_LOCATION_LOADING: 'ACTION_LOCATION_LOADING',
  ACTION_LOCATION_ERROR: 'ACTION_LOCATION_ERROR',
  ACTION_LOCATION_HIDE_LOCATION_PERM: 'ACTION_LOCATION_HIDE_LOCATION_PERM',
  ACTION_ASK_LOCATION_PERM: 'ACTION_ASK_LOCATION_PERM',
  ACTION_ASK_LOCATION_SETTINGS: 'ACTION_ASK_LOCATION_SETTINGS',
  ACTION_SET_LAST_KNOWN_LOCATION: 'ACTION_SET_LAST_KNOWN_LOCATION',
  ACTION_LOCATION_HIDE_LOCATION_SETTINGS: 'ACTION_LOCATION_HIDE_LOCATION_SETTINGS',
  ACTION_SET_CITY_PICKER_LOCATION: 'ACTION_SET_CITY_PICKER_LOCATION',
  ACTION_UPDATE_SELECTED_AIRPORT_INDEX: 'ACTION_UPDATE_SELECTED_AIRPORT_INDEX',
  ACTION_DISMISS_CITY_PICKER: 'ACTION_DISMISS_CITY_PICKER',
  ACTION_SET_CITY_PICKET_SCREEN: 'ACTION_SET_CITY_PICKET_SCREEN'
};

export const PICKER_STATE = {
  INITIAL: 'INITIAL',
  NO_NETWORK: 'NO_NETWORK',
  LOADING: 'LOADING',
  SUGGESTIONS: 'SUGGESTIONS',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
};

let popularSearchesCache = {};

export const sourceFocusAction = () => (dispatch) => {
  dispatch({
    type: CityPickerAction.ACTION_SOURCE_FOCUS,
  });
};

export const updateSelectedAirportTypeIndex = (airportIndex) => (dispatch) => {
  dispatch({
    type: CityPickerAction.ACTION_UPDATE_SELECTED_AIRPORT_INDEX,
    data: airportIndex,
  });
};

export const sourceClearAction = () => (dispatch) => {
  dispatch({
    type: CityPickerAction.ACTION_SOURCE_CLEAR,
  });
  dispatch(onAddressChange(false));
};

export const sourceTextChangeAction = (data) => ({
  type: CityPickerAction.ACTION_SOURCE_TEXT_CHANGE,
  data,
});

export const destinationFocusAction = () => (dispatch) => {
  dispatch({
    type: CityPickerAction.ACTION_DEST_FOCUS,
  });
};

export const destClearAction = () => (dispatch) => {
  dispatch({
    type: CityPickerAction.ACTION_DEST_CLEAR,
  });
  dispatch(onAddressChange(false));
};

export const destTextChangeAction = (data) => ({
  type: CityPickerAction.ACTION_DEST_TEXT_CHANGE,
  data,
});

export const autoCompleteResults = (data) => ({
  type: CityPickerAction.ACTION_AUTO_COMPLETE_RESULTS,
  data: data || [],
});

export const autoCompleteResultsSelectAction = (data) => ({
  type: CityPickerAction.ACTION_AUTO_COMPLETE_RESULTS_SELECT,
  data,
});

export const setRecentPlacesAction = (data) => ({
  type: CityPickerAction.ACTION_SET_RECENT_PLACES,
  data,
});

export const locationLoadingAction = {
  type: CityPickerAction.ACTION_LOCATION_LOADING,
};

export const locationErrorAction = {
  type: CityPickerAction.ACTION_LOCATION_ERROR,
};

export const locationSelectAction = (data) => (dispatch) => {
  dispatch({
    type: CityPickerAction.ACTION_LOCATION_SELECT,
    data,
  });
  dispatch(onAddressChange());
};

export const askLocationPermAction = {
  type: CityPickerAction.ACTION_ASK_LOCATION_PERM,
};

export const askLocationSettingsAction = {
  type: CityPickerAction.ACTION_ASK_LOCATION_SETTINGS,
};

export const swapAction = () => (dispatch) => {
  dispatch({
    type: CityPickerAction.ACTION_SWAP_SEARCH,
  });
  dispatch(onAddressChange(false));
};

export const onBackClick = () => (dispatch, getState) => {
  const { sourceFocused, tripType, screenName, handleBackPress } = getState().cabsCityPicker;
  const { showListingWidget = false } = getState().cabsListing;
  if (!showListingWidget) {
    if (sourceFocused) {
      cityPickerEvent(tripType, CityPickerEvent.PickupAddressBack, screenName);
    } else {
      cityPickerEvent(tripType, CityPickerEvent.DestinationAddressBack, screenName);
    }
  }
  if (handleBackPress) {
    dispatch(handleBackPress());
  }
  dispatch(dismissCityPicker());
};

export const dismissCityPicker = () => (dispatch) => {
  dispatch({
    type: CityPickerAction.ACTION_DISMISS_CITY_PICKER,
  });
  Actions.pop();
};

export const hideLocationPermAction = (permGranted = false) => {
  if (!permGranted) {
    trackLandingEvent('Mob_Cab_Landing_Location_Perm_No');
  }
  return {
    type: CityPickerAction.ACTION_LOCATION_HIDE_LOCATION_PERM,
  };
};

export const hideLocationSettingsAction = () => ({
  type: CityPickerAction.ACTION_LOCATION_HIDE_LOCATION_SETTINGS,
});

export const initCityPicker = (request) => {
  const {
    disableSource = false,
    tripType,
    sourceData,
    destinationData,
    additionalCityData,
    additionalCityIndex,
    destFocused = false,
    selectedAirportTypeIndex = AirportPickupType.TO.value,
    handleAddressChange,
    screenName = CabsScreen.LANDING,
    cityPickerHeader = '',
    sourceTitle = '',
    sourcePlaceholder = '',
    exactPickUpRequired = false,
    nearByLocation = null,
    showLoading = false,
    handleBackPress = null,
    showToolTip = false,
    showSingleInputView = false,
    addNewCity = false,
    showRecentPlaces = true,
    isAddon = false,
    referenceLocation,
  } = request;

  return {
    type: CityPickerAction.SET_INITIAL_PICKER_DATA,
    data: {
      sourceData,
      destinationData,
      additionalCityData,
      additionalCityIndex,
      destFocused,
      tripType,
      selectedAirportTypeIndex,
      handleAddressChange,
      showLoading,
      screenName,
      handleBackPress,
      showToolTip,
      cityPickerHeader,
      sourceTitle,
      sourcePlaceholder,
      exactPickUpRequired,
      nearByLocation,
      showSingleInputView,
      addNewCity,
      showRecentPlaces,
      isAddon,
      referenceLocation,
    },
  };
};

export const getRecentPlaces = (req_trip_type, isCityCheck = false) => async (dispatch) => {
  const recentSearches = await getRecentSearches();
  if (!_isEmpty(recentSearches)) {
    let recentPlaces = _flatMap(recentSearches, (recentSearch) => {
      // TODO Reformat this structure for representing recent search address and it placeData
      const { source_location, destination_location, trip_type } = recentSearch;
      const addressList = [];
      if (trip_type === req_trip_type) {
        if (trip_type === TripType.HR.value) {
          addressList.push({ address: source_location });
        } else {
          addressList.push({ address: source_location });
          addressList.push({ address: destination_location });
        }
      }
      return addressList;
    });
    recentPlaces = _uniqBy(recentPlaces, 'address.place_id');
    dispatch(setRecentPlacesAction(recentPlaces));
  }
};

export const getRecentPlacesForExactPickUp = (req_trip_type, nearByLocation) => async (
  dispatch,
) => {
  const recentSearches = await getRecentSearches();
  if (!_isEmpty(recentSearches)) {
    let recentPlaces = _flatMap(recentSearches, (recentSearch) => {
      // TODO Reformat this structure for representing recent search address and it placeData
      const { source_location, destination_location, trip_type } = recentSearch;
      const addressList = [];
      if (trip_type === req_trip_type) {
        if (isLocationNearBy(source_location, nearByLocation) && !source_location.is_city) {
          addressList.push({ address: source_location });
        }
        if (trip_type !== TripType.HR.value) {
          if (
            isLocationNearBy(destination_location, nearByLocation) &&
            !destination_location.is_city
          ) {
            addressList.push({ address: destination_location });
          }
        }
      }
      return addressList;
    });
    recentPlaces = _uniqBy(recentPlaces, 'address.place_id');
    dispatch(setRecentPlacesAction(recentPlaces));
  }
};

const _getPredictions = async (dispatch, query, sourceData) => {
  let results = null;
  if (!_isEmpty(sourceData)) {
    results = await fetchAutoCompleteResultsForNearByLocation(query, sourceData);
  } else {
    results = await fetchAutoCompleteResults(query);
  }
  dispatch(autoCompleteResults(results));
};

const _getServerPredictions = async (dispatch, query) => {
  const results = await fetchAutoCompleteResultsFromServer(query);
  if (_isEmpty(results)) {
    trackLandingEvent('Mob_Cab_Landing_Airport_Name_MisMatch_Error');
  }
  dispatch(autoCompleteResults(results));
};


const _getSuggestionList = async ({
  dispatch,
  query,
  tripType,
  requestFor,
  refLat,
  refLng,
  countryCode,
  isB2B,
}) => {
  const res = await fetchLiteAutoCompleteResults({
    query,
    tripType,
    requestFor,
    refLat,
    refLng,
    countryCode,
    isB2B
  });

  const result = res?.map((suggestion)=>({ 
    name: suggestion.address,
    matches: [{ text: suggestion.main_text, matched: true }],
    mainText: suggestion.main_text,
    secondaryText: suggestion.secondary_text,
    placeId: suggestion.place_id,
    is_airport: suggestion.is_airport,
    is_city: suggestion.is_city,
    isAutocompleteV3: true,
  }));

  dispatch(autoCompleteResults(result));
};

const getSuggestionListDebounced = debounce(_getSuggestionList, 300);
const _getPredictionsDebounced = debounce(_getPredictions, 300);
const _getServerPredictionsDebounced = debounce(_getServerPredictions, 300);

export const fetchAutoCompletePredictions = (query, isSource, sourceData = null) => (
  dispatch,
  getState,
) => {
  const { tripType, isAddon, referenceLocation } = getState().cabsCityPicker;
  const { selectedAirportTypeIndex } = getTripDataFromState(getState());
  let callAirportAutoComplete = false;
  if (tripType === TripType.AT.value) {
    callAirportAutoComplete = isSource
      ? selectedAirportTypeIndex === AirportPickupType.FROM.value
      : selectedAirportTypeIndex === AirportPickupType.TO.value;
  }
  const isEmptyQuery = _isEmpty(query);
  if (isSource) {
    if (isEmptyQuery) {
      dispatch(sourceClearAction());
    } else {
      dispatch(sourceTextChangeAction(query));
    }
  } else if (isEmptyQuery) {
    dispatch(destClearAction());
  } else {
    dispatch(destTextChangeAction(query));
  }
  if (isEmptyQuery) {
    dispatch(autoCompleteResults([]));
    return null;
  }
  if (_trim(query).length > 1) {
    if(isAddon){
      return getSuggestionListDebounced({
        dispatch,
        query,
        tripType,
        requestFor: 'addon',
        refLat: referenceLocation?.latitude || '',
        refLng: referenceLocation?.longitude || '',
        countryCode: referenceLocation?.country_code || '',
        isB2B: false,
      });
    }
    if (callAirportAutoComplete) {
      return _getServerPredictionsDebounced(dispatch, query);
    }
    return _getPredictionsDebounced(dispatch, query);
  }
  return null;
};

export const onPredictionSelected = (data, isPredectionClicked = true) => async (dispatch, getState) => {
  const {
    sourceFocused, destinationFocused, tripType, screenName
  } = getState().cabsCityPicker;
  let placeDetails = {};
  if(data?.isAutocompleteV3){
    placeDetails = await getLatLongDetails(data.placeId);
    if (!_isEmpty(placeDetails)) {
      placeDetails.address = data.address || null;
      placeDetails.is_city = data.is_city || false;
      placeDetails.is_airport = data.is_airport || false;
    }
  } else{
    if (tripType === TripType.AT.value && data.airportId > 0) {
      placeDetails = await getServerPlaceDetails(data.airportId);
    } else {
      placeDetails = await getPlaceDetails(data.placeId);
  
      if (!_isEmpty(placeDetails)) {
        placeDetails.address = data.name;
      }
    }
    if (!_isEmpty(placeDetails)) {
      placeDetails.mainText = data.mainText || null;
    }
  }

  dispatch(autoCompleteResultsSelectAction({ place: data, placeDetails }));
  dispatch({
    type: CityPickerAction.ACTION_SET_CITY_PICKET_SCREEN,
    data: isPredectionClicked
  })
  dispatch(onAddressChange());
  const { showListingWidget = false } = getState().cabsListing;
  if (!showListingWidget) {
    if (sourceFocused) {
      cityPickerEvent(tripType, CityPickerEvent.PickupSelect, screenName);
    } else if (destinationFocused) {
      cityPickerEvent(tripType, CityPickerEvent.DestinationSelect, screenName);
    }
  }
};

export const useCurrentLocation = () => async (dispatch, getState) => {
  const { cabsCityPicker } = getState();
  try {
    const { locationLoading, currentLocation, tripType, screenName } = cabsCityPicker;
    if (locationLoading) {
      return;
    }
    cityPickerEvent(tripType, CityPickerEvent.CurrentLocationClick, screenName);
    if (_isNil(currentLocation)) {
      dispatch(locationLoadingAction);
      const currentPlaceAddress = await getCurrentLocation();
      dispatch(locationSelectAction(currentPlaceAddress));
    } else {
      dispatch(locationSelectAction(currentLocation));
    }
  } catch (e) {
    Keyboard.dismiss();
    dispatch(locationErrorAction);
    if (e.message === NO_LOCATION_PERM) {
      dispatch(askLocationPermAction);
    } else if (e.message === GPS_OFF) {
      dispatch(askLocationSettingsAction);
    }
  }
};

export const onLocationPermGranted = () => async (dispatch) => {
  trackLandingEvent('Mob_Cab_Landing_Location_Perm_Yes');
  dispatch(hideLocationPermAction(true));
  try {
    dispatch(locationLoadingAction);
    const currentPlaceAddress = await getLocationWithPerm();
    dispatch(locationSelectAction(currentPlaceAddress));
  } catch (e) {
    dispatch(locationErrorAction);
    if (e.message === GPS_OFF) {
      dispatch(askLocationSettingsAction);
    }
  }
};

export const onLocationSettingsOff = () => (dispatch) => {
  trackLandingEvent('Mob_Cab_Landing_GPS_No');
  dispatch(hideLocationSettingsAction());
};

export const onLocationSettingsOn = () => async (dispatch) => {
  trackLandingEvent('Mob_Cab_Landing_GPS_Yes');
  dispatch(hideLocationSettingsAction());
  try {
    const enabled = await LocationModule.enableLocationServices();
    if (!enabled) {
      return;
    }
    dispatch(locationLoadingAction);
    const currentPlaceAddress = await getLocationWithPerm();
    dispatch(locationSelectAction(currentPlaceAddress));
  } catch (e) {
    dispatch(locationErrorAction);
  }
};

export const onRecentPlaceClicked = (place, recentPlaceClicked = true) => (dispatch, getState) => {
  dispatch({
    type: CityPickerAction.ACTION_RECENT_PLACE_CLICKED,
    data: place,
  });
  dispatch({
    type: CityPickerAction.ACTION_SET_CITY_PICKET_SCREEN,
    data: recentPlaceClicked
  })
  const { tripType, screenName } = getState().cabsCityPicker;
  const {
    showListingWidget = false
  } = getState().cabsListing;
  if (!showListingWidget) {
    cityPickerEvent(tripType, CityPickerEvent.RecentSearchClick, screenName);
  }
  dispatch(onAddressChange());
};

export const setLastKnownLocation = (lastKnownLocation) => (dispatch) => {
  dispatch({
    type: CityPickerAction.ACTION_SET_LAST_KNOWN_LOCATION,
    data: lastKnownLocation,
  });
};

export const onAddressChange = (shouldPop = true) => (dispatch, getState) => {
  let {
    sourceData,
    sourceText,
    destinationData,
    destinationText,
    sourceFocused,
    destinationFoused,
    handleAddressChange,
    selectedAirportTypeIndex,
    additionalCityIndex,
    addNewCity = false,
  } = getState().cabsCityPicker;
  const { tripType } = getState().cabsTripInfo;
  try {
    if (sourceData) {
      sourceData.address = sourceText;
    }
    if (destinationData) {
      destinationData.address = destinationText;
    }
  } catch (e) {
    //  Ignore exception
  }
  const request = {
    sourceData,
    destinationData,
    shouldPop,
    tripType,
    selectedAirportTypeIndex,
    additionalCityIndex,
    addNewCity,
  };
  dispatch(handleAddressChange(request));
};

export const cityPickerEvent = (tripType, event, screen) => {
  try {
    const header = eventHeaderForTripType(tripType);
    if (header !== '') {
      const trackEvent = `${header + screen}_${event}`;
      CabsPageTrackerFunc[screen](trackEvent);
    }
  } catch (e) {
    // Ignore
  }
};

export const setCityPickerLocation = (data) => (dispatch) => {
  const {
    sourceData,
    destinationData,
    sourceText,
    destinationText,
    selectedAirportTypeIndex,
  } = data;
  dispatch({
    type: CityPickerAction.ACTION_SET_CITY_PICKER_LOCATION,
    data: {
      sourceData,
      destinationData,
      sourceText,
      destinationText,
      selectedAirportTypeIndex,
    },
  });
  if (_isEmpty(sourceData)) {
    dispatch(sourceFocusAction());
  } else {
    dispatch(destinationFocusAction());
  }
};

export const CityPickerEvent = {
  CurrentLocationClick: 'Current_Location_Click',
  PickupSelect: 'Pickup_Select',
  DestinationSelect: 'Destination_Select',
  PickupAddressBack: 'Pickup_Address_Back',
  DestinationAddressBack: 'Destination_Address_Back',
  RecentSearchClick: 'Recent_Place_Click',
};
