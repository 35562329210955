import {history} from './webStore';

module.exports = {
  Actions: {
    pop() {
      history.goBack();
    },
    cabs() {
      history.push('/');
    },
    cabsCityPicker(params) {
      history.push('/rnw/cabsCityPicker', params);
    },
    cabsCalendar(params) {
      history.push('/rnw/cabsCalendar', params);
    },
    cabsListing(params) {
      history.push('/cabsListing', params);
    },
    cabsReview(params) {
      history.push('/cabsTripReview', params);
    },
    cabsFareBreakup(params) {
      history.push('/rnw/cabsFareBreakup', params);
    },
    changeCabCoupon(params) {
      history.push('/rnw/changeCabCoupon', params);
    },
    termsAndConditions(params) {
      history.push('/rnw/termsAndConditions', params);
    },
    privacyPolicies(params) {
      history.push('/rnw/privacyPolicies', params);
    },
    cancellationRules(params) {
      history.push('/rnw/cancellationRules', params);
    },
    mmtAssuredCabsHowto(params) {
      history.push('/rnw/mmtAssuredCabsHowto', params);
    },
    cabsThankYou(params) {
      history.push('/cabsThankYou', params);
    },
    cabAddOnDetails(params) {
      history.push('/cabAddOnDetails', params);
    },
    cabAddOnReview(params) {
      history.push('/cabAddOnReview', params);
    },
    cabsAddOnThankYou(params) {
      history.push('/cabsAddOnThankYou', params);
    },
    openWebView(params) {
      history.push('/rnw/openWebView', params)
    },
    cabsRating(params) {
      history.push('/rnw/cabsRating', params)
    },
    airportPrimeInfo(params) {
      history.push('/airportPrimeInfo', params)
    },
    cabsFeedback(params) {
      history.push('/overallRating', params)
    },
    cabsExtraFeedback(params) {
      history.push('/cabsExtraFeedback', params)
    },
    cabsFeedbackThankyou(params) {
      history.push('/cabsFeedbackThankyou', params)
    },
    cabsFeedbackError(params) {
      history.push('/cabsFeedbackError', params)
    },
    cabInstaStory(params) {
      history.push('/cabInstaStory', params)
    },
    cabsProvisionalDestPicker(params) {
      history.push('/provisionalDestPicker', params)
    },
    cabsProvisional(params) {
      history.push('/provisionalBooking', params)
    },
    cabsHotelMarketing(params) {
      history.push('/cabsHotelMarketing', params)
    },
    cabsHotelMarketingCityPicker(params) {
      history.push('/hotelMarketingCityPicker', params)
    }
  },
  ActionConst: {}
};
