import _isEmpty from 'lodash/isEmpty';
import {Platform} from 'react-native';
import * as tune from '../../Common/utils/TuneTrackerUtils';
import TripType from './types/TripType';

const delimiter = ' | ';

export const TuneEvent = {
  CAB_LISTING: 'Cab Shopper',
  CAB_REVIEW: 'Cab Review',
  CAB_THANKS: 'Cab Transaction'
};

const _getTripType = (tripType = TripType.AT.value) => {
  if (tripType === TripType.AT.value) return TripType.AT.value;
  if (tripType === TripType.HR.value) return TripType.HR.value;
  return 'OS';
}

export const tuneListingTracker = (request) => {
  if (Platform.OS !== 'web') {
    const {
      source_location,
      destination_location,
      departure_date,
      pickup_time
    } = request;

    let addressAttribute = source_location.address;
    if (!_isEmpty(destination_location)) {
      addressAttribute = addressAttribute + delimiter + destination_location.address;
    }

    const params = {};
    params[tune.EVENT_NAME] = `${TuneEvent.CAB_LISTING}_${_getTripType(request.trip_type)}`;
    params[tune.ATTRIBUTE_1] = addressAttribute;
    params[tune.ATTRIBUTE_2] = departure_date;
    params[tune.ATTRIBUTE_3] = pickup_time;
    tune.trackTune(params);
  }
};

export const tuneReviewTracker = (
  request,
  cab
) => {
  if (Platform.OS !== 'web') {
    const {
      source_location,
      destination_location,
      departure_date,
      pickup_time
    } = request;
    let addressAttribute = source_location.address;
    if (!_isEmpty(destination_location)) {
      addressAttribute = addressAttribute + delimiter + destination_location.address;
    }
    const params = {};
    const payableAmount = cab.cab_fare_detail.total_amount - cab.cab_fare_detail.coupon_amount;

    params[tune.EVENT_NAME] = `${TuneEvent.CAB_REVIEW}_${_getTripType(request.trip_type)}`;
    params[tune.ATTRIBUTE_1] = addressAttribute;
    params[tune.ATTRIBUTE_2] = departure_date;
    params[tune.ATTRIBUTE_3] = pickup_time;
    params[tune.ATTRIBUTE_4] = cab.vehicle_model;
    if (!_isEmpty(payableAmount)) {
      params[tune.ATTRIBUTE_5] = payableAmount.toString();
    }
    tune.trackTune(params);
  }
};

export const tuneThankyouTracker = (data, tripType = null) => {
  if (Platform.OS !== 'web') {
    const {
      pickupAddress,
      dropAddress,
      departureDate,
      pickupTime,
      cabModel,
      bookingId,
      totalPayableAmount,
      tripDetails: {
        tripType = TripType.OW.value
      }
    } = data;
    let addressAttribute = pickupAddress;
    if (!_isEmpty(dropAddress)) {
      addressAttribute = addressAttribute + delimiter + dropAddress;
    }
    const bkgId = `bkgId: <${bookingId || ''}>`
    const params = {};
    if(tripType){
      params[tune.EVENT_NAME] = `${TuneEvent.CAB_THANKS}_${_getTripType(tripType)}`;
    }
    params[tune.ATTRIBUTE_1] = addressAttribute;
    params[tune.ATTRIBUTE_2] = departureDate;
    params[tune.ATTRIBUTE_3] = pickupTime;
    params[tune.ATTRIBUTE_4] = cabModel;
    params[tune.ATTRIBUTE_5] = bkgId;
    tune.trackTune(params);
  }
};
