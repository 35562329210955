import {CabsPdtConstants} from './CabsPdtConstants';
import CabsPdtLandingEvent from '../PdtModel/PdtEvents/CabsPdtLandingEvent';
import {pushPdtData} from '../../api/cabsApis';


export default class CabsPdtLandingHelper {
  static trackPageLoad(data) {
    try {
      const pdtData = data;
      pdtData.activityName = CabsPdtConstants.BaseEventAction.LOAD_PAGE;
      const pdtObject = new CabsPdtLandingEvent(pdtData).trackLandingEvent();
      pushPdtData(pdtObject);
    } catch (e) {
      // ignore
    }
  }

  static trackPageExit(data) {
    try {
      const pdtData = data;
      pdtData.activityName = CabsPdtConstants.BaseEventAction.EXIT_PAGE;
      const pdtObject = new CabsPdtLandingEvent(pdtData).trackLandingEvent();
      pushPdtData(pdtObject);
    } catch(e) {
      // ignore
    }
  }

  static trackBackPressed(data) {
    try {
      const pdtData = data;
      pdtData.activityName = CabsPdtConstants.BaseEventAction.CLICK_BACK;
      const pdtObject = new CabsPdtLandingEvent(pdtData).trackLandingEvent();
      pushPdtData(pdtObject);
    } catch (e) {
      // ignore
    }
  }

  static trackSearchClicked(data) {
    try {
      const pdtData = data;
      pdtData.activityName = CabsPdtConstants.LandingEventAction.CLICK_SEARCH;
      const pdtObject = new CabsPdtLandingEvent(pdtData).trackLandingEvent();
      pushPdtData(pdtObject);
    } catch (e) {
      // ignore
    }
  }
}
