import { Platform } from 'react-native';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import _isObject from 'lodash/isObject';
import fecha from 'fecha';
import * as omniture from 'src/Common/utils/OmnitureTrackerUtils';
import { diffDays, today } from 'src/Helpers/dateHelpers';
import {
  CABS_DATE_FMT,
  UNIVERSAL_SEARCH,
  CabABKeys,
  OmnitureValues,
  cabsAbOmnitureKeys,
} from './cabsConstants';
import { getOmnitureEVars, saveOmnitureEVars } from './utils/cabsLocalStore';
import TripType from './types/TripType';
import { CabsScreen } from './config/cabsScreen';
import * as UserSessionModule from '../../Native/UserSession/UserSessionModule';
import CabsABConfigHelper from './utils/CabsABConfigHelper';
import { CabTypes } from './cabsConstants';
import { calcAvgSedanPrice, isIsomorphicFunnelUser } from './utils/cabsCommonUtils';
const PAGE_NAME_PREFIX_LANDING = 'landing:domestic cabs';
export const PAGE_NAME_PREFIX_FUNNEL = 'funnel:domestic cabs';
const PAGE_NAME_PREFIX_INT_FUNNEL = 'funnel:intl cabs';
const PAGE_NAME_PREFIX_INT_LANDING = 'landing:intl cabs';

const LOB_NAME = 'domestic cabs';
const LOB_NAME_INTL = 'intl cabs';

export const PAGE_NAMES = {
  LANDING_PAGE: 'landing-New',
  LISTING_PAGE: 'listing',
  REVIEW_PAGE: 'review',
  CHECKOUT_PAGE: 'checkout',
  THANKYOU_PAGE: 'thankyou',
  PRIMARY_PAX_PAGE: 'primary_pax',
  UNIVERSAL_SEARCH_OVERLAY_PAGE: 'universal search overlay page',
  RATINGS_REVIEWS_PAGE: 'ratings & review',
  ADDON_LANDING_PAGE: 'landing airport add on',
  ADDON_REVIEW_PAGE: 'review airport add on',
  ADDON_THANKYOU_PAGE: 'thankyou airport add on',
  APPROVER_REQUESTOR: 'myBiz',
  LOCAL_NOTIFICATION_OVERLAY_PAGE: 'local notification provisional overlay page',
  PROVISIONAL_OVERLAY_PAGE: 'provisional overlay page',
  PROVISIONAL_NONKIOSK_OVERLAY_PAGE: 'non kiosk provisonal overlaypage',
  PROVISIONAL_HOB_OVERLAY_PAGE_LANDING: 'hob kiosk provisonal landing overlaypage',
  PROVISIONAL_HOB_OVERLAY_PAGE_LISTING: 'hob kiosk provisonal listing overlaypage',
  PROVISIONAL_HOB_OVERLAY_PAGE_THANKYOU: 'hob kiosk provisonal thankyou overlaypage',
  HOTEL_MARKETING_PAGE: 'hotel marketing page',
};

export const cabsSource = 'm_v100';

const cabsAbTrackingKey = 'm_v47';
const EVAR_28 = 'm_v28';
const cabsEPassKey = 'm_v82';
const cabsSrcDest = 'm_v13';
const cabsHydraSegment = 'm_v94';
const EVAR_82 = 'm_v82';
const cabsHotelId = 'm_v27';
const EVAR_97 = 'm_v97';
const EVAR_27 = 'm_v27';
const EVAR_50 = 'm_v50';
const EVAR_40 = 'm_v40';
const PROP_1 = 'm_c1';
const EVAR_46 = 'm_v46';
const EVAR_75 = 'm_v75';
const anyFuelPokusTracking = 'm_v106';
let _userDetails = {};
export const CabsFeedbackPages = Object.freeze({
  OVERALL_RATING_PAGE: 'funnel:domestic cabs:rating and review pg1',
  EXTRA_FEEDBACK_PAGE: 'funnel:domestic cabs:rating and review pg2',
  THANKYOU_PAGE: 'funnel:domestic cabs:rating and review pg3',
});
// If there are multiple props/var needs to be tracked then pass a dictionary or if only prop54
// needs to be tracked pass prop54 value as string
const _prepareTrackingData = (data) => {
  let trackingData = data;
  if (_isString(trackingData)) {
    trackingData = {
      [omniture.PROP_54]: data,
    };
  }
  if (Platform.OS === 'web' && Boolean(window?.navigator?.userAgent?.includes('GPay-Microapps'))) {
    trackingData[EVAR_75] = 'affiliates|gpay';
    trackingData[omniture.VAR_CAMPAIGN] = 'affiliates|gpay';
  }

  const isomorphicKey = `Isomorphic_${isIsomorphicFunnelUser()?'True':'False'}`;

  return {
    ...(trackingData || {}),
    [cabsAbTrackingKey]: trackingData?.[cabsAbTrackingKey] ? trackingData[cabsAbTrackingKey] +`| ${isomorphicKey}` : isomorphicKey
  };
};

export const setUserDetailsInCabsAnalytics = (userDetails) => {
  _userDetails = userDetails;
};
const _trackEvent = async (pageName, data, isLoadEvent = false) => {
  let channelName = pageName.startsWith('landing:') ? 'landing' : 'domestic cabs funnel';
  if(_isInternational){
    channelName = 'intl cabs funnel';
  }
  let lobName = _isInternational ? LOB_NAME_INTL : LOB_NAME;
  const isCorpUser = await UserSessionModule.isCorporateUser();
  if (isCorpUser || pageName.includes(PAGE_NAMES.APPROVER_REQUESTOR)) {
    if (channelName !== 'landing') {
      channelName += '|corporate';
    }
    lobName += '|corporate';
  }
  // Wrapping inside a setTimeout to prevent any omniture latency/erros to effect the main logic
  setTimeout(() => {
    omniture.trackOmniture(
      pageName,
      {
        ..._prepareTrackingData(data),
        [omniture.PROP_24]: lobName,
        [omniture.EVAR_15]: pageName,
        [omniture.VAR_CHANNEL]: channelName,
        [omniture.VAR_LOB_KEY]: lobName,
      },
      isLoadEvent,
    );
  }, 0);
};

export const getPagePrefix = (isLanding = false) => {
  if(_isInternational){
    return isLanding ? PAGE_NAME_PREFIX_INT_LANDING : PAGE_NAME_PREFIX_INT_FUNNEL;
  }

  return isLanding ? PAGE_NAME_PREFIX_LANDING : PAGE_NAME_PREFIX_FUNNEL;
};

export const trackLandingEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix(true);
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.LANDING_PAGE}`, data, isLoadEvent);
};

export const trackLandingLoad = () => {
  trackLandingEvent({}, true);
};

export const trackPrimaryPaxEvent = (data) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.PRIMARY_PAX_PAGE}`, data);
};

let _isInternational = false;
let _cabsEVars = {};
let _cabsAddonEVars = {};
let _cabsFeedbackEVars = {};
let _onHoldTrackingFunc = null;
let personalizedTripTracker = '';

export const trackLandingAbEvent = (abExperimentName) => {
  trackLandingEvent({
    [cabsAbTrackingKey]: abExperimentName,
  });
};

export const getCabsOmnitureEVars = () => _cabsEVars;
export const setCabsOmnitureEVars = (value) => {
  _cabsEVars = value;
};

export const personalizedTripTracking = (data) => {
  personalizedTripTracker = data;
};

export const setIsLoggedInTracker = (tracker) => {
  _cabsEVars = {
    ..._cabsEVars,
    [omniture.VAR_USER_LOGIN_STATUS]: tracker,
  };
};

export const myBizPrimaryPaxTracker = (bookerType, primaryPaxType) => {
  _cabsEVars = {
    ..._cabsEVars,
    [EVAR_27]: `Mob_Cab_MyBiz_${bookerType}_${primaryPaxType}`,
  };
};

export const setReceiverSourceAndCmp = (source = null, cmp = null) => {
  if (cmp) {
    _cabsEVars = {
      ..._cabsEVars,
      [omniture.VAR_CAMPAIGN]: cmp,
    };
  }
};

export const setHydraSegment = (hydraSegment = null) => {
  if (hydraSegment) {
    _cabsEVars = {
      ..._cabsEVars,
      [cabsHydraSegment]: hydraSegment,
    };
  }
};

// FunnelVar are variables persisted by omniture throughout the funnel, this is not const
// because it'll be updated in subsequent pages
export const createFunnelVars = (listingRequest, source = null, isMMTBlackUser = false) => {
  const {
    source_location,
    destination_location,
    departure_date,
    pickup_time,
    trip_type,
    profile_type,
    is_instant_search = false,
    source_city_name,
    destination_city_name
  } = listingRequest;
  let srcDest = `${source_city_name}`;
  let srcDestAddress = `${source_location.address}`;
  if (destination_location !== null && trip_type !== TripType.HR.value) {
    srcDest = `${source_city_name} | ${destination_city_name}`;
    srcDestAddress = `${source_location.address} | ${destination_location.address}`;
  }
  const deptDate = fecha.parse(departure_date, CABS_DATE_FMT);
  const deptDateFormatted = fecha.format(deptDate, 'D:M:YYYY');
  const advancePurchaseDays = parseInt(diffDays(deptDate, today()), 10);
  _cabsEVars = {
    ..._cabsEVars,
    [omniture.VAR_SRC_DEST]: srcDest,
    [cabsSrcDest]: srcDestAddress,
    [omniture.VAR_AP]: advancePurchaseDays,
    [omniture.VAR_START_DATE]: `${deptDateFormatted} ${pickup_time}`,
    [omniture.VAR_TRIP_TYPE]:
      trip_type === TripType.AT.value
        ? is_instant_search
          ? `AT_Instant`
          : `AT_Schedule`
        : trip_type,
  };
  if (isMMTBlackUser) {
    _cabsEVars = {
      ..._cabsEVars,
      [omniture.PROP_42]: 'MMT_Black_User',
    };
  }
  if (profile_type === UserSessionModule.ProfileType.BUSINESS) {
    _cabsEVars = { ..._cabsEVars, [omniture.VAR_MYBIZ_CABS]: 'MyBiz_Cabs' };
  }
  if (source === UNIVERSAL_SEARCH) {
    _cabsEVars = { ..._cabsEVars, [cabsSource]: UNIVERSAL_SEARCH };
  }
  return _cabsEVars;
};

export const trackListingLoadErrorEvent = () => {
  trackListingErrorEvent(_cabsEVars, false, true);
};

export const trackUniversalSearchLoad = (srcDest, srcDestAddress, tripType) => {
  if (srcDest) {
    const trackingData = {
      [omniture.VAR_SRC_DEST]: srcDest,
      [cabsSrcDest]: srcDestAddress,
      [omniture.VAR_TRIP_TYPE]: tripType,
      [cabsSource]: UNIVERSAL_SEARCH,
    };
    trackUniversalSearchEvent(trackingData, true);
  }
};

export const pushOnHoldTrackingListing = () => {
  if (_onHoldTrackingFunc) _onHoldTrackingFunc();
};

export const setOnHoldTrackingFunc = (trackerFunc) => {
  _onHoldTrackingFunc = trackerFunc;
};
const _capitalize = (s) => (s ? s[0].toUpperCase() + s.slice(1) : '');

export const setPokusFlag = (pokusFlags) => {
  return pokusFlags.reduce((acc, nxt) => {
    if (cabsAbOmnitureKeys[nxt.key]) {
      if (nxt.value === null) {
        return (acc = acc
          ? `${acc} | ${nxt.key}_${_capitalize(nxt.flag.toString())}`
          : `${nxt.key}_${_capitalize(nxt.flag.toString())}`);
      }
      return (acc = acc ? `${acc} | ${nxt.key}_${nxt.value}` : `${nxt.key}_${nxt.value}`);
    }
    return acc;
  }, '');
};

export const ABPokusKeyTracking = (pokusFlags) => {
  let pokusOmniture = '';
  if (Boolean(pokusFlags)) {
    pokusOmniture = setPokusFlag(pokusFlags);
  }
  return pokusOmniture;
};

export const resetEvar28 = () => {
  _cabsEVars = {
    ..._cabsEVars,
    [EVAR_28]: '',
  };
};

export const resetEvar40 = () => {
  _cabsEVars = {
    ..._cabsEVars,
    [EVAR_40]: '',
  };
};

export const trackHRPackageId = (packageId) => {
  _cabsEVars = {
    ..._cabsEVars,
    [cabsAbTrackingKey]: `${_cabsEVars[cabsAbTrackingKey]}${Boolean(packageId) ? ` | Mob_Funnel_HR_Package_${packageId}` : ''
      }`,
  };
};

const makeOmnitureValues = (isOldListing, response) => {
  let cabList = [];
  let noOfCabs = 0;
  let noOfSedans = 0;
  let sedanSellingPrice = 0;
  let fuelAvailable = new Set();
  let SKUs = new Set();
  let vendors = new Set();
  if (isOldListing) {
    cabList = [...response?.response?.cab_list || [], ...response?.response?.rap_cab_list || []]

    noOfCabs = cabList?.length;
    cabList.map((cabItem) => {
      if (cabItem?.type === CabTypes.SEDAN) {
        sedanSellingPrice += cabItem?.cab_fare_detail?.total_amount;
        noOfSedans += 1;
      }
      SKUs.add(cabItem?.type);
      vendors.add(cabItem?.vendor_name);
      fuelAvailable.add(cabItem?.fuel_type);
    });
  }
  return {
    noOfCabs,
    sedanSellingPrice,
    noOfSedans,
    fuelAvailable,
    SKUs,
    vendors,
  }
}

export const trackListingLoad = (
  distance,
  durationFilter,
  isEPassRequired = false,
  showListingWidget = false,
  searchRequest = null,
  specificModelsList = [],
  packagesList = [],
  fuelTypePokusKey = null,
  responsePokusValues,
  cabPartnerTracker = null,
  selectedHRCabPackage = null,
  response,
  isOldListing
) => {
  let duration =
    response?.overall_trip_duration_in_minutes > 0
      ? (response?.overall_trip_duration_in_minutes / 60).toFixed(2)
      : 0;
  if (searchRequest?.return_date) {
    let days = searchRequest?.return_date.split('-')[0] - searchRequest?.departure_date.split('-')[0]
    duration = `${days} | ${duration}`;
  }
  _cabsEVars = {
    ..._cabsEVars,
    [anyFuelPokusTracking]: `Any_Fuel_${fuelTypePokusKey}`,
  };

  const pokusOmniture = ABPokusKeyTracking(responsePokusValues);
  if (Boolean(pokusOmniture)) {
    _cabsEVars = {
      ..._cabsEVars,
      [cabsAbTrackingKey]: `${pokusOmniture}${Boolean(personalizedTripTracker) ? ` | ${personalizedTripTracker}` : ''
        }`,
    };
  }

  // Tracking Seleceted Package for HR in evar97
  if (searchRequest.trip_type === TripType.HR.value) {
    trackHRPackageId(selectedHRCabPackage);
  }

  if (showListingWidget) {
    _cabsEVars = { ..._cabsEVars, [cabsSource]: UNIVERSAL_SEARCH };
  }

  const getValuesForOmniture = makeOmnitureValues(isOldListing, response)

  let vendorsString = [...getValuesForOmniture.vendors].join('|');
  let SKUsString = [...getValuesForOmniture.SKUs].join('|');
  let fuelString = [...getValuesForOmniture.fuelAvailable].join('|');
  const avgSedanPrice = calcAvgSedanPrice(getValuesForOmniture.sedanSellingPrice, getValuesForOmniture.noOfSedans)

  _cabsEVars = {
    ..._cabsEVars,
    [omniture.VAR_DURATION]: duration,
    [EVAR_28]: `${cabPartnerTracker ? cabPartnerTracker + ` | ` : ''
      } ${getValuesForOmniture.noOfCabs}`,
    ['m_v59']: `${SKUsString}||${fuelString}||${vendorsString}`,
    [omniture.VAR_PRICE]: `${avgSedanPrice ? avgSedanPrice : ''}`
  };

  if (searchRequest) {
    if (searchRequest.trip_type === TripType.AT.value) {
      if (searchRequest.source_location.is_airport) {
        _cabsEVars = {
          ..._cabsEVars,
          [EVAR_82]: 'A2C',
        };
      } else {
        _cabsEVars = {
          ..._cabsEVars,
          [EVAR_82]: 'C2A',
        };
      }
    } else {
      let SKUs = [...new Set(packagesList)];
      if (specificModelsList.length > 0) {
        SKUs.push('Specific');
      }
      _cabsEVars = {
        ..._cabsEVars,
        [EVAR_82]: SKUs.length ? SKUs.join('_') : 'Not_Intercity',
      };
    }
  }

  trackListingEvent(_cabsEVars, true);
};

export const trackListingEvent = (data, isLoadEvent = false) => {
  if (!window.location.href.includes('cabsTripReview')) {
    const PagePrefix = getPagePrefix();
    _trackEvent(`${PagePrefix}:${PAGE_NAMES.LISTING_PAGE}`, data, isLoadEvent);
  }
};

export const trackCabsCorporateEvent = (page, data, isLoadEvent = false) => {
  _trackEvent(page, data, isLoadEvent);
};

export const trackUniversalSearchEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.UNIVERSAL_SEARCH_OVERLAY_PAGE}`, data, isLoadEvent);
};

export const trackPokusEvent = (data) => {
  const trackingData = {
    [omniture.BRC_ABTrackingKey]: data,
  };
  return trackingData;
};

export const trackListingErrorEvent = (
  data,
  isUniversalWidgetVisible = false,
  isLoadEvent = false,
  errorCode = null,
  isIntracity = false,
) => {
  const PagePrefix = getPagePrefix();
  let omnitureCabListingErrorPageName = `${PagePrefix}:${PAGE_NAMES.LISTING_PAGE}`;
  if (!_isEmpty(_userDetails) && !UserSessionModule.isPersonalUser(_userDetails)) {
    omnitureCabListingErrorPageName = `${omnitureCabListingErrorPageName}`;
  }
  if (_isObject(data)) {
    if (isUniversalWidgetVisible && isLoadEvent) {
      data = {
        ...data,
        [cabsSource]: UNIVERSAL_SEARCH,
      };
    }
    if (isLoadEvent && isIntracity) {
      data = {
        ...data,
        [omniture.VAR_TRIP_TYPE]: 'Local',
      };
    }
    let errCode = errorCode;
    if (!_isEmpty(_userDetails) && !UserSessionModule.isPersonalUser(_userDetails)) {
      errCode = `Mob_Cab_MyBiz_Listing_Load_Error_${errCode}`;
    }
    _trackEvent(
      omnitureCabListingErrorPageName,
      {
        ...data,
        [omniture.VAR_ERROR_KEY]: errCode || 'Mob_Cab_Listing_Load_Error',
        [PROP_1]: 'Not Available',
      },
      isLoadEvent,
    );
  } else {
    _trackEvent(
      omnitureCabListingErrorPageName,
      { [PROP_1]: 'Not Available', [omniture.PROP_54]: data },
      isLoadEvent,
    );
  }
};

export const trackBnplEventsFromListing = (data) => {
  const PagePrefix = getPagePrefix();
  if (Boolean(data[EVAR_46])) {
    _cabsEVars = {
      ..._cabsEVars,
      [EVAR_46]: data[EVAR_46],
    };
  }
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.LISTING_PAGE}`, data);
};

export const trackRTdurationDaysHrs = (data) => {
  const departDateTime = fecha.parse(
    `${data?.departureDate} ${data?.pickup_time}`,
    'DD:MM:YYYY hh:mm',
  );
  const returnDateTime = fecha.parse(`${data?.returnDate} ${data?.drop_time}`, 'DD:MM:YYYY hh:mm');
  const departDate = fecha.parse(`${data?.departureDate}`, 'DD:MM:YYYY');
  const returnDate = fecha.parse(`${data?.returnDate}`, 'DD:MM:YYYY');
  const diffHrs = (returnDateTime - departDateTime) / 3600000;
  const numOfDays = (returnDate - departDate) / 86400000;
  trackListingEvent(`Mob_Cabs_RT_Listing_${diffHrs}_${numOfDays}`);
};

const _getSafetyShieldAndKitAvailability = (mySafetyAccessories) => {
  if (!mySafetyAccessories) return '';
  let kitAccessoriesString = '';
  mySafetyAccessories.forEach((accessory) => {
    if (accessory.icon === 'PROTECTIVE_SHIELD_BLACK') {
      kitAccessoriesString += ' | SafetyShield';
    }
    if (accessory.icon === 'PERSONALISED_KIT') {
      kitAccessoriesString += ' | SafetyKit';
    }
  });
  return kitAccessoriesString;
};

const _createCabSpecificEvar = (cab, distance, durationFilter) => {
  const {
    vendor_display_name,
    cab_fare_detail: { is_assured, is_all_inclusive, is_airport_prime },
    type,
    my_safety = false,
    my_safety_accessories = null,
    is_other_cab = false,
  } = cab;
  const subCategory = cab.package_data?.packages[0].package || null;
  let priceType;
  if (is_assured) {
    priceType = 'MMT Assured';
  } else if (is_all_inclusive) {
    priceType = 'All Inclusive';
  } else {
    priceType = 'Regular';
  }
  if (is_airport_prime) priceType = 'Airport Prime';
  const isSpecificModel = cab.vehicle_model_type !== 'GENERIC';
  _cabsEVars = {
    ..._cabsEVars,
    [omniture.VAR_PRODUCT_ID]: `${type}${subCategory ? ` | ${subCategory}` : ''}${isSpecificModel ? ` | ${cab.vehicle_model}` : ''
      }${is_other_cab ? ` | MoreCabs` : ''} | MySafety${my_safety ? 'Yes' : 'No'
      }${_getSafetyShieldAndKitAvailability(my_safety_accessories)}`,
    [EVAR_82]: cab?.type,
  };
};

export const trackRatingAndReviewLoad = () => {
  const trackingData = {
    ..._cabsEVars,
  };
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.RATINGS_REVIEWS_PAGE}`, trackingData, true);
};

export const trackFlightAddOnEvent = (data, isLoadEvent = false) => {
  _trackEvent('funnel:domestic cabs:listing', data, isLoadEvent);
};

export const trackFlightAddOnErrorEvent = (data, isLoadEvent = false) => {
  _trackEvent('funnel:domestic cabs: error : No cabs available : Short Funnel', data, isLoadEvent);
};

export const trackRatingAndReviewEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.RATINGS_REVIEWS_PAGE}`, data, isLoadEvent);
};

export const trackFlightAddOnInstantToRegular = (data) => {
  const trackingData = {
    [omniture.VAR_REFERRER]: data,
  };
  trackFlightAddOnEvent(trackingData);
};

export const trackFlightAddOnAbEvent = (abExperimentName) => {
  trackFlightAddOnEvent({
    [cabsAbTrackingKey]: abExperimentName,
  });
};

export const trackFlightAddOnLoad = (cab, distance, durationFilter) => {
  _createCabSpecificEvar(cab, distance, durationFilter);
  trackFlightAddOnEvent(_cabsEVars, true);
};

export const trackErrorFlightAddOnLoad = (data, isInstantCab, isLoadEvent = true) => {
  const trackingData = {
    ...data,
    [omniture.BRC_ABTrackingKey]: isInstantCab ? 'AT_Instant_Cabs' : 'AT_Short_Funnel',
  };
  _trackEvent(
    'funnel:domestic cabs: error : No cabs available : Short Funnel',
    trackingData,
    isLoadEvent,
  );
};

export const trackReviewLoad = (
  cab,
  distance,
  durationFilter,
  isMyBizApprovalRequired = false,
  responsePokusValues = null,
  selectedHRCabPackage = null,
  tripType = TripType.OW.value,
  rydeUpsellData = null,
  nonRapCabData = null,
) => {
  _createCabSpecificEvar(cab, distance, durationFilter);
  let isMyBizUser = !_isEmpty(_userDetails) && !UserSessionModule.isPersonalUser(_userDetails);

  const pokusOmniture = ABPokusKeyTracking(responsePokusValues);
  if (Boolean(pokusOmniture)) {
    _cabsEVars = {
      ..._cabsEVars,
      [cabsAbTrackingKey]: `${pokusOmniture}${Boolean(personalizedTripTracker) ? ` | ${personalizedTripTracker}` : ''
        }`,
    };
  }

  const isSpecificModel = cab.vehicle_model_type !== 'GENERIC';
  let evar97Value = _cabsEVars[cabsAbTrackingKey];
  let evar40Value = Boolean(_cabsEVars[EVAR_40])
    ? `${_cabsEVars[EVAR_40]} | ${cab.cab_partner === 'RAP' ? 'RAP' : 'Non RAP'}_${isSpecificModel ? cab.vehicle_model : cab.type_label
    }`
    : `${cab.cab_partner === 'RAP' ? 'RAP' : 'Non RAP'}_${isSpecificModel ? cab.vehicle_model : cab.type_label
    }`;
  if (cab.cab_partner !== 'RAP') {
    evar40Value = `${evar40Value} | ${Boolean(rydeUpsellData) ? 'Ryde + Non Ryde' : 'Non Ryde'}`;
  } else {
    evar97Value = `${evar97Value}${Boolean(nonRapCabData) ? ' | RAP_Category_Upsell' : ''}`;
  }
  _cabsEVars = {
    ..._cabsEVars,
    [EVAR_40]: evar40Value,
    [cabsAbTrackingKey]: evar97Value,
  };

  // Tracking Seleceted Package for HR in evar97
  if (tripType === TripType.HR.value) {
    trackHRPackageId(selectedHRCabPackage);
  }

  if (isMyBizUser) {
    let evar50;
    if (isMyBizApprovalRequired) {
      evar50 = 'Mob_Cab_MyBiz_Approval_Needed';
    } else {
      evar50 = 'Mob_Cab_MyBiz_Approval_Not Needed';
    }
    _cabsEVars = {
      ..._cabsEVars,
      [EVAR_50]: evar50,
    };
  }
  trackReviewEvent(_cabsEVars, true);
};

export const trackReviewEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.REVIEW_PAGE}`, data, isLoadEvent);
};

export const trackReviewAbEvent = (abExperimentName) => {
  trackReviewEvent({
    [cabsAbTrackingKey]: abExperimentName,
  });
};

export const trackListingAbEvent = (trackEvent, abExperimentName) => {
  trackEvent({
    [cabsAbTrackingKey]: abExperimentName,
  });
};

export const trackPaymentCheckout = async () => {
  await saveOmnitureEVars(_cabsEVars);
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.CHECKOUT_PAGE}`, _cabsEVars);
};

export const trackThankYouLoad = async (
  bookingId = null,
  isRNWFunnel = false,
  responsePokusValues = null,
  selectedHRCabPackage = null,
  tripType = TripType.OW.value,
  payMode = null,
) => {
  const eVars = await getOmnitureEVars();
  let data = { ...eVars, isRNWFunnel };

  const pokusOmniture = ABPokusKeyTracking(responsePokusValues);
  if (Boolean(pokusOmniture)) {
    data = {
      ...data,
      [cabsAbTrackingKey]: `${pokusOmniture}${Boolean(personalizedTripTracker) ? ` | ${personalizedTripTracker}` : ''
        }`,
    };
  }
  if (Boolean(payMode)) {
    if (payMode === 'Pay Later') {
      data = {
        ...data,
        [EVAR_46]: `${Boolean(_cabsEVars[EVAR_46]) ? `${_cabsEVars[EVAR_46]} | BNPL_PayMode` : 'BNPL_PayMode'
          }`,
      };
    }
  }
  if (eVars[cabsAbTrackingKey]?.includes('RAP_Category_Upsell')) {
    data = {
      ...data,
      [cabsAbTrackingKey]: `${Boolean(data[cabsAbTrackingKey])
        ? `${data[cabsAbTrackingKey]} | RAP_Category_Upsell`
        : 'RAP_Category_Upsell'
        }`,
    };
  }
  // Tracking Seleceted Package for HR in evar97
  if (tripType === TripType.HR.value) {
    data = {
      ...data,
      [cabsAbTrackingKey]: `${data[cabsAbTrackingKey]}${Boolean(selectedHRCabPackage) ? ` | Mob_Funnel_HR_Package_${selectedHRCabPackage}` : ''
        }`,
    };
  }

  if (bookingId) {
    data = {
      ...data,
      purchaseID: bookingId,
      [omniture.VAR_PURCHASE_ID]: bookingId,
    };
  }
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.THANKYOU_PAGE}`, data, true);
};

export const trackThankYouEvent = (data) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.THANKYOU_PAGE}`, data);
};

export const trackThankYouABEvent = (abExperimentName) => {
  trackThankYouEvent({
    [cabsAbTrackingKey]: abExperimentName,
  });
};

export const trackThankYouFailed = async (isRNWFunnel = true) => {
  const eVars = await getOmnitureEVars();
  const data = { ...eVars, [omniture.VAR_ERROR_KEY]: 'Mob_Cab_TY_Booking_Failed', isRNWFunnel };
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.THANKYOU_PAGE}`, data);
};

export const trackReviewBackPressCoupon = () => {
  _cabsEVars = {
    ..._cabsEVars,
    [EVAR_97]: Boolean(_cabsEVars.m_v97)
      ? `${_cabsEVars.m_v97} | Review_Back_Coupon`
      : 'Review_Back_Coupon',
  };
};

// ADD-ON
export const trackAddOnDetailLoad = () => {
  trackAddOnDetailEvent(_cabsAddonEVars, true);
};

export const createFeedbackFunnelEvars = (data) => {
  try {
    const { originDestination, originDestinationAddress, departDate, tripType, cabType } = data;
    _cabsFeedbackEVars = {
      [omniture.VAR_SRC_DEST]: originDestination,
      [cabsSrcDest]: originDestinationAddress,
      [omniture.VAR_START_DATE]: departDate,
      [omniture.VAR_TRIP_TYPE]: tripType,
      [omniture.VAR_PRODUCT_ID]: cabType,
    };
  } catch (e) {
    // ignore
  }
};

export function setInternationalFlow(value){
  _isInternational = value;
}

export const createAddonFunnelEvars = (data) => {
  try {
    const {
      originDestination,
      originDestinationAddress,
      advancePurchaseDays,
      cabDetails,
      numberOfCabs,
    } = data;
    _cabsAddonEVars = {
      [omniture.VAR_SRC_DEST]: originDestination,
      [cabsSrcDest]: originDestinationAddress,
      [omniture.VAR_TRIP_DATE]: advancePurchaseDays,
      [omniture.VAR_PRICE]: cabDetails,
      [omniture.VAR_ADDON_NUMBER_OF_CABS]: numberOfCabs,
    };
  } catch (e) {
    // ignore
  }
};

export const trackFeedbackLoad = (pageName) => {
  trackFeedbackEvent(_cabsFeedbackEVars, pageName, true);
};

export const trackFeedbackErrorLoad = (pageName, errorCode) => {
  const error = {
    [omniture.VAR_ERROR_KEY]: errorCode,
  };
  _trackEvent(pageName, error);
};

export const trackFeedbackEvent = (data, pageName, isLoadEvent = false) => {
  _trackEvent(pageName, data, isLoadEvent);
};

export const trackAddOnDetailEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.ADDON_LANDING_PAGE}`, data, isLoadEvent);
};

export const trackAddOnReviewLoad = () => {
  trackAddOnReviewEvent(_cabsAddonEVars, true);
};

export const trackAddOnReviewEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.ADDON_REVIEW_PAGE}`, _cabsAddonEVars, isLoadEvent);
};

export const trackAddOnConfirmErrorEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.ADDON_REVIEW_PAGE}`, data, isLoadEvent);
};

export const trackAddOnThankYouLoad = (bookingId = null) => {
  const trackingData = {
    ..._cabsAddonEVars,
    purchaseID: bookingId,
    [omniture.VAR_PURCHASE_ID]: bookingId,
  };
  trackAddOnThankYouEvent(trackingData, true);
};

export const trackAddOnThankYouEvent = (data, isLoadEvent = false) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.ADDON_THANKYOU_PAGE}`, data, isLoadEvent);
};

export const trackError = (data) => {
  _trackEvent('funnel:domestic cabs:error', data);
};

export const eventHeaderForTripType = (tripType) => {
  let header = '';
  switch (tripType) {
    case TripType.OW.value:
      header = 'Mob_Cabs_OW_';
      break;
    case TripType.RT.value:
      header = 'Mob_Cabs_RT_';
      break;
    case TripType.AT.value:
      header = 'Mob_Cabs_AT_';
      break;
    case TripType.HR.value:
      header = 'Mob_Cabs_HR_';
      break;
    default:
      header = '';
  }
  return header;
};

export const CabsPageTrackerFunc = Object.freeze({
  [CabsScreen.LANDING]: trackLandingEvent,
  [CabsScreen.LISTING]: trackListingEvent,
  [CabsScreen.FLIGHTADDON]: trackListingEvent,
  [CabsScreen.REVIEW]: trackReviewEvent,
  [CabsScreen.ADD_ON_BOOKING_DETAIL]: trackAddOnDetailEvent,
});

export const trackProvisionalBookingLoad = (
  data,
  isLocalNotification,
  source = null,
  isBlusmart = false,
  supplier_name = null,
) => {
  try {
    const PagePrefix = getPagePrefix();
    if (isLocalNotification) {
      const {
        flightSegment,
        config: { airport },
      } = JSON.parse(data);
      const { destinationCityName } = flightSegment[flightSegment.length - 1];
      _trackEvent(
        `${PagePrefix}:${PAGE_NAMES.LOCAL_NOTIFICATION_OVERLAY_PAGE}`,
        {
          [omniture.VAR_SRC_DEST]: `${airport} | ${destinationCityName}`,
          [cabsSrcDest]: `${airport} | ${destinationCityName}`,
          [omniture.VAR_REFERRER]: 'Provisional_Local_Notification',
        },
        true,
      );
    } else {
      _trackEvent(
        `${PagePrefix}:${PAGE_NAMES.PROVISIONAL_OVERLAY_PAGE}`,
        {
          [omniture.VAR_SRC_DEST]: `${data.airport_name}`,
          [cabsSrcDest]: `${data.airport_name}`,
          [omniture.VAR_REFERRER]: source,
          [omniture.VAR_PRODUCT_ID]: supplier_name,
        },
        true,
      );
    }
  } catch (e) { }
};

export const trackProvisionalBookingErrorLoad = (errorData) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(
    `${PagePrefix}:${PAGE_NAMES.PROVISIONAL_OVERLAY_PAGE}`,
    {
      [omniture.VAR_ERROR_KEY]: errorData?.code,
    },
    true,
  );
};

export const trackNonKioskBookingLoad = (
  data,
  isLocalNotification,
  source = null,
  isBlusmart = false,
  supplier_name = null,
) => {
  try {
    const PagePrefix = getPagePrefix();
    if (isLocalNotification) {
      const {
        flightSegment,
        config: { airport },
      } = JSON.parse(data);
      const { destinationCityName } = flightSegment[flightSegment.length - 1];
      _trackEvent(
        `${PagePrefix}:${PAGE_NAMES.LOCAL_NOTIFICATION_OVERLAY_PAGE}`,
        {
          [omniture.VAR_SRC_DEST]: `${airport} | ${destinationCityName}`,
          [cabsSrcDest]: `${airport} | ${destinationCityName}`,
          [omniture.VAR_REFERRER]: 'Provisional_Local_Notification',
        },
        true,
      );
    } else {
      _trackEvent(
        `${PagePrefix}:${PAGE_NAMES.PROVISIONAL_NONKIOSK_OVERLAY_PAGE}`,
        {
          [omniture.VAR_SRC_DEST]: `${data.airport_name}`,
          [cabsSrcDest]: `${data.airport_name}`,
          [omniture.VAR_REFERRER]: source,
          [omniture.VAR_PRODUCT_ID]: supplier_name,
        },
        true,
      );
    }
  } catch (e) { }
};

export const trackHOBBookingLoad = (
  data,
  source = null,
) => {
  try {
    const PagePrefix = getPagePrefix();
      _trackEvent(
        `${PagePrefix}:${PAGE_NAMES.PROVISIONAL_HOB_OVERLAY_PAGE_LANDING}`,
        {
          [omniture.VAR_SRC_DEST]: `${data?.airport_name}`,
          [cabsSrcDest]: `${data?.airport_name}`,
          [omniture.VAR_REFERRER]: source,
        },
        true,
      );
  } catch (e) {}
};

export const trackHOBListingLoad = (response) => {
    try {
      const PagePrefix = getPagePrefix();
        _trackEvent(
          `${PagePrefix}:${PAGE_NAMES.PROVISIONAL_HOB_OVERLAY_PAGE_LISTING}`,
          {
            [omniture.PROP_54]: `Listing Load Success`,
            [omniture.VAR_PRICE]: response?.avg_price
          },
          true,
        );
    } catch (e) {}
  };

export const trackHOBThankYouLoad = (supplier_name = '') => {
  try {
    const PagePrefix = getPagePrefix();
      _trackEvent(
        `${PagePrefix}:${PAGE_NAMES.PROVISIONAL_HOB_OVERLAY_PAGE_THANKYOU}`,
        {
          [omniture.VAR_PRODUCT_ID]: supplier_name,
        },
        true,
      );
  } catch (e) {}
};

export const trackNonKioskBookingErrorLoad = (errorData) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(
    `${PagePrefix}:${PAGE_NAMES.PROVISIONAL_NONKIOSK_OVERLAY_PAGE}`,
    {
      [omniture.VAR_ERROR_KEY]: errorData?.code,
    },
    true,
  );
};

export const trackHOBBookingErrorLoad = (errorData) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(
    `${PagePrefix}:${PAGE_NAMES.PROVISIONAL_HOB_OVERLAY_PAGE_LANDING}`,
    {
      [omniture.VAR_ERROR_KEY]: errorData?.code,
    },
    true,
  );
};

export const trackHOBListingErrorLoad = (errorData = '') => {
  const PagePrefix = getPagePrefix();
  _trackEvent(
    `${PagePrefix}:${PAGE_NAMES.PROVISIONAL_HOB_OVERLAY_PAGE_LISTING}`,
    {
      [omniture.VAR_ERROR_KEY]: errorData,
    },
    true,
  );
};

export const trackHOBThankYouErrorLoad = (errorData = '') => {
  const PagePrefix = getPagePrefix();
  _trackEvent(
    `${PagePrefix}:${PAGE_NAMES.PROVISIONAL_HOB_OVERLAY_PAGE_THANKYOU}`,
    {
      [omniture.VAR_ERROR_KEY]: errorData,
    },
    true,
  );
};

export const trackProvisionalBookingExit = (data) => {
  const PagePrefix = getPagePrefix();
  if ('fromWhatsapp' in data) {
    _trackEvent(
      `${PagePrefix}:${PAGE_NAMES.PROVISIONAL_OVERLAY_PAGE}`,
      'Mob_Cab_Provisional_Back_Clicked',
    );
  } else {
    _trackEvent(
      `${PagePrefix}:${PAGE_NAMES.LOCAL_NOTIFICATION_OVERLAY_PAGE}`,
      'Mob_Cab_Local_Notification_Back_Clicked',
    );
  }
};

export const trackProvisionalEvent = (pageName, data) => {
  _trackEvent(pageName, data);
};

export const trackCabsHotelMarketingLoad = (data) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(
    `${PagePrefix}:${PAGE_NAMES.HOTEL_MARKETING_PAGE}`,
    {
      [omniture.VAR_PAGE_NAME]: 'Hotels_Whatsapp_Marketing_Page',
      [cabsHotelId]: data.hotelId,
      [EVAR_97]: data.hotelName,
      [EVAR_82]: data.cabOffered,
    },
    true,
  );
};

export const trackCabsHotelMarketingClickEvent = (data) => {
  const PagePrefix = getPagePrefix();
  _trackEvent(`${PagePrefix}:${PAGE_NAMES.HOTEL_MARKETING_PAGE}`, data);
};
