import {NativeModules} from 'react-native';
import cookie from 'react-cookies';
import _ from 'lodash';
 import { userServiceHeaders, userServiceBody } from '../../Mobile-mmt-react-native/packages/legacy-commons/Common/Components/GSTIN/Helpers/utils';
import cabsConfig from '../../Mobile-mmt-react-native/src/Routes/Cabs/cabsConfig';

const _isUserLoggedIn = () => {
  const mmtAuth = cookie.load('mmt-auth');
  return Promise.resolve(!_.isEmpty(mmtAuth));
};

const _getUserDetails = ()=>{
  throw new Error("Method not implemented in Web")
};

const _getMmtAuth = async () => {
  const mmtAuth = cookie.load('mmt-auth');
  if (_.isEmpty(mmtAuth)) {
    return Promise.reject(new Error('User not logged in'));
  }
  return Promise.resolve(mmtAuth);
};

const _isCorporateUser = () => {
  return false;
}

const _showGSTNWidget = async () => {
  const isLoggedIn = await _isUserLoggedIn();
  

  if(!isLoggedIn) return true;

  const options = {
    headers: await userServiceHeaders(),
    body: JSON.stringify(userServiceBody()),
    method: 'POST',
  }

  try {
    let res = await fetch(`${cabsConfig.fetchUserDetailsUrl}`, options);
    res = await res.json();
    const statePresent = getAddressinformation(res);
  
    if(statePresent.length > 0) return false;
  
    return true;
  } catch (error) {
    console.log(error);
  }
  
}

const getAddressinformation = (userDetails) => {
  const {
    result: {
      extendedUser: {
        addressDetails,
        profileType,
      }
    }
  } = userDetails;
  
  const statePresent = addressDetails?.filter(address => {
    return address.state !== null;
  });

  return statePresent;
}

NativeModules.UserSessionModule = {
  isUserLoggedIn: _isUserLoggedIn,
  getUserDetails: _getUserDetails,
  getMmtAuth: _getMmtAuth,
  isCorporateUser: _isCorporateUser,
  showGSTNWidget: _showGSTNWidget,
};
