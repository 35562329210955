import fecha from 'fecha';
import _trim from 'lodash/trim';
import _isEmpty from 'lodash/isEmpty';
import {
  addHours,
  addMinutes,
  formatDay,
  now,
  oneHour,
  oneMinute
} from '../../Helpers/dateHelpers';
import cabsConfig from './cabsConfig';
import TripType from './types/TripType';
import { getDefaultAP } from './cabsDynamicConfig';
import { ALLOWED_DATE_FORMAT, CABS_DATE_FMT, CABS_TIME_FMT } from './cabsConstants';
import { addDays } from 'src/Helpers/dateTimehelpers';

export const decimalToString = (dec) => {
  const hour = Math.round(dec);
  const min = (dec - hour) * 60;
  const date = new Date();
  date.setHours(hour);
  date.setMinutes(min);
  return fecha.format(date, 'HH:mm');
};

export const stringToDecimal = (str) => {
  const date = fecha.parse(str, 'HH:mm');
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return hours + minutes / 60;
};

export const hoursToDecimal = (date) => {
  const minutes = date.getMinutes();
  const hours = date.getHours();
  return hours + minutes / 60;
};

export const adjustHourToSlot = (date) => {
  const currentMinutes = date.getMinutes();
  switch (true) {
    case currentMinutes > 0 && currentMinutes < 15:
      date.setMinutes(15, 0, 0);
      break;
    case currentMinutes > 15 && currentMinutes < 30:
      date.setMinutes(30, 0, 0);
      break;
    case currentMinutes > 30 && currentMinutes < 45:
      date.setMinutes(45, 0, 0);
      break;
    case currentMinutes > 45 && currentMinutes < 60:
      date.setHours(date.getHours() + 1, 0, 0, 0);
      break;
    default:
    // nothing
  }
}
export const diffHours = (date1, date2, floor = true) => {
  const diff = (date1.getTime() - date2.getTime()) / oneHour;
  if (floor) {
    return Math.floor(diff);
  }
  return Math.ceil(diff);
};

export const diffMinutes = (date1, date2, floor = true) => {
  const diff = (date1.getTime() - date2.getTime()) / oneMinute;
  if (floor) {
    return Math.floor(diff);
  }
  return Math.ceil(diff);
};

export const getNearestNextSlot = (
  hrs = 0,
  currDate = now()
) => {
  adjustHourToSlot(currDate);
  return addHours(currDate, hrs >= 0 ? hrs : cabsConfig.minTatHrs);
};

export const getNearestNextSlotInMinutes = (
  hrs = 0,
  currDate = now()
) => {
  adjustHourToSlot(currDate);
  return addMinutes(currDate, hrs > 0 ? hrs : cabsConfig.minTatHrs);
};

export const getDefaultDepartureForShortFunnel = () => {
  const currentDate = now();
  adjustHourToSlot(currentDate);
  return addHours(currentDate, 8);
};

export const getNearestNextSlotForTripType = (tripType = TripType.OW.value, currDate = now()) => {
  if (tripType === TripType.HR.value) {
    let departDate = addDays(now(), 1);
    departDate.setHours(10);
    departDate.setMinutes(0);
    return departDate;
  }
  const hrs = getDefaultAP(tripType);
  adjustHourToSlot(currDate);
  return addHours(currDate, hrs > 0 ? hrs : TripType[tripType].defaultAP);
}

export const getNextDay10Am = () => {
  let departDate = addDays(now(), 1);
  departDate.setHours(10);
  departDate.setMinutes(0);
  return departDate;
};


export const getNearestReturnDate = departDate => {
  const dynamicConf = require('./cabsDynamicConfig');
  return addHours(departDate, dynamicConf.getDefaultRoundTripHrs());
};

export const getMinimumReturnDate = departDate => {
  const dynamicConf = require('./cabsDynamicConfig');
  return addHours(departDate, dynamicConf.getMinTravelTimeHrs());
};

export const getDurationFromMinute = (minute) => {
  let duration = '';
  const hrs = Math.floor(minute / 60);
  const mins = minute % 60;
  if (hrs > 0) {
    duration = `${hrs} hr`;
  }
  if (mins > 0) {
    duration += ` ${mins} m`;
  }
  return _trim(duration);
};

export const parseDateTimeString = (date, time) => {
  const dateObj = fecha.parse(date, CABS_DATE_FMT);
  const timeObj = fecha.parse(time, CABS_TIME_FMT);
  dateObj.setHours(timeObj.getHours(), timeObj.getMinutes(), 0, 0);
  return dateObj;
};

/*
  Converts the Date and Time for cabs to DateTime object.
  In case of exception return current datetime.
 */
export const getDateFromDateTime = (date, time) => {
  try {
    return parseDateTimeString(date, time);
  } catch (e) {
    // Ignore exception
  }
  return now();
};

export const getDateForSEM = (date) => {
  let allowedDate = date;
  if (!_isEmpty(allowedDate) && allowedDate.includes(ALLOWED_DATE_FORMAT)) {
    allowedDate = getIncrementedDate(allowedDate);
  }
  return allowedDate;
};

export const getIncrementedDate = (date) => {
  try {
    const splitStrings = String(date).split('_');
    const days = splitStrings.length > 1 ? splitStrings[1] : 0;
    return fecha.format(addDays(now(), days), 'DD-MM-YYYY');
  } catch (e) {
    //ignore exception
  }
  return date;
};

export const parseDateComponents = (dateObj) => {
  const day = formatDay(dateObj);
  const [date, month, year] = fecha.format(dateObj, 'DD-MMM-YYYY').toUpperCase().split('-');
  const time = fecha.format(dateObj, 'h:mm A').toUpperCase().split('-');
  return {
    date,
    day,
    month,
    year,
    time
  };
};

