import fecha from 'fecha';
import { AbConfigKeyMappings, getPokusConfig } from 'src/Native/AbConfig/AbConfigModule';
import { CABS_DATE_FMT, CABS_TIME_FMT } from './cabsConstants';
import cabsConfig from './cabsConfig';
import {
  getNativeUserDetails,
  getRNWUserDetails,
  getDeviceDetails,
  ACTION_SET_ASSURANCE_DATA
} from './cabsCommonActions';
import { initCabConfig } from './cabsDynamicConfig';
import { PokusLobs } from "../../Native/AbConfig/AbConfigKeyMappings";
import { getAssuranceData } from './api/cabsApis';

fecha.masks = {
  [CABS_DATE_FMT]: 'DD-MM-YYYY',
  [CABS_TIME_FMT]: 'HH:mm'
};


// eslint-disable-next-line import/prefer-default-export
export const getCabsGoogleApiKey = () =>
  getPokusConfig(PokusLobs.CAB, AbConfigKeyMappings.cabsGoogleApiKey, cabsConfig.googleApiKey);

export const initCabs = () => async (dispatch) => {
  await dispatch(getNativeUserDetails);
  dispatch(getDeviceDetails);
  const userDetails = await dispatch(getRNWUserDetails());
  await dispatch(initCabConfig);
  try {
    if (userDetails?.profileType !== 'BUSINESS') {
      const assuranceData = await getAssuranceData();

      dispatch({ type: ACTION_SET_ASSURANCE_DATA, data: assuranceData?.response ?? null });
    }
  } catch (err) {
    console.error('Failed to fetch assurance data');
  }
};
