import { Actions } from 'src/Routes/Cabs/Navigation';
import fecha from 'fecha';
import _ from 'lodash';
import { Platform } from 'react-native';
import { addToRecentSearches } from '../utils/cabsLocalStore';
import {
  CABS_DATE_FMT,
  CABS_TIME_FMT,
  offerBookingType,
  offerSource,
  offerPage,
  CabABKeys,
  CabsPartners,
  CAB_LIST_CATEGORIES,
  CAB_TYPE_PREFERENCE,
} from '../cabsConstants';
import { addHours, now, truncateHours } from '../../../Helpers/dateHelpers';
import { CabsScreen } from '../config/cabsScreen';
import {
  adjustHourToSlot,
  getDateFromDateTime,
  getNearestNextSlotForTripType,
  getNearestReturnDate,
} from '../cabsDateTimeHelpers';
import {
  createFunnelVars,
  eventHeaderForTripType,
  trackListingErrorEvent,
  trackListingEvent,
  setOnHoldTrackingFunc,
  trackListingAbEvent,
  trackRTdurationDaysHrs,
} from '../cabsAnalytics';
import {
  ACTION_LANDING_DATE_CHANGED,
  ACTION_UPDATE_DEFAULT_PICKUP,
  ACTION_UPDATE_TRAVEL_TYPE,
  landingAddressChanged,
  updateRecentSearchData,
  updateSelectedAirportIndex,
} from '../Landing/cabsLandingActions';
import { fetchCabDetails, onCabsLoadedAction, setCabType } from '../Review/cabsReviewActions';
import logAction from '../../../Helpers/actionsLogger';
import { DateSelectMode, initWithDates } from '../Calendar/cabsCalendarActions';
import validateSearchServerRequest from '../types/validators/searchRequestValidator';
import TripType, { AirportPickupType, TravelType } from '../types/TripType';
import { tuneListingTracker } from '../cabsTuneTracker';
import { getSearchCabsRequest, getWalletBalanceRequest } from '../api/cabsRequestBuilder';
import { getSearchCabsResponse, getWalletBalanceResponse, getOffers } from '../api/cabsApis';
import { handleTripTypeChange } from '../utils/cabsSearchUtil';
import * as UserSessionModule from '../../../Native/UserSession/UserSessionModule';
import CabsPdtListingHelper from '../PdtAnalytics/PdtHelper/CabsPdtListingHelper';
import { initCabs } from '../cabsInit';
import { processDeepLinkData } from '../DeepLink/DeepLinkManager';
import CabOmnitureEvent from '../cabsOmnitureEvents';
import { ViewStates } from './cabsListingReducer';
import CabsABConfigHelper from '../utils/CabsABConfigHelper';
import { commonsSearchConnector, skywalkerSearchConnector, skywalkerSectorConnector } from '../Skywalker/connector';
import { getNativeUserDetails } from '../cabsCommonActions';
import { pushGTMData, getParamsForGTM } from '../utils/GTM/util';
import {
  showMoreOptions,
  fetchAppVersion,
  getPokusFlagsForBE,
  getTripDataFromState,
  getTravelTypeFromTripType
} from '../utils/cabsCommonUtils';
import {
  createFiltersFromCabList,
  getSelectedFilters,
  isCabEligibleByCarType,
  isCabEligibleByFuelType,
  isCabEligibleByModelType,
} from '../Filters/utils';
import { groupBy } from 'lodash';
import { firebaseListingTracker } from '../Analytics/Firebase/util';
import { updateHRPackage, updateLocation, updateStopOvers, updateTripDate, updateTripType, updateAirportTripType } from '../cabsTripInfoAction';

export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';
export const ACTION_DATE_TIME_CHANGED = 'ACTION_DATE_TIME_CHANGED';
export const ACTION_DURATION_FILTER_CHANGED = 'ACTION_DURATION_FILTER_CHANGED';
export const ACTION_INITIAL_DATA = 'ACTION_INITIAL_DATA';
export const ACTION_LOADING_CABS = 'ACTION_LOADING_CABS';
export const ACTION_ERROR = 'ACTION_ERROR';
export const ACTION_LISTING_SHOW_ERROR = 'ACTION_LISTING_SHOW_ERROR';
export const ACTION_LISTING_SET_OFFERS = 'ACTION_LISTING_SET_OFFERS';
export const ACTION_LISTING_HIDE_ERROR = 'ACTION_LISTING_HIDE_ERROR';
export const ACTION_UPDATE_WALLET_BALANCE = 'ACTION_UPDATE_WALLET_BALANCE';
export const ACTION_UPDATE_FLIGHT_NUMBER = 'ACTION_UPDATE_FLIGHT_NUMBER';
export const ACTION_ADD_ON_UPDATE_FLIGHT_BOOKING_ID = 'ACTION_ADD_ON_UPDATE_FLIGHT_BOOKING_ID';
export const ACTION_ADD_ON_UPDATE_IS_INSTANT_CAB = 'ACTION_ADD_ON_UPDATE_IS_INSTANT_CAB';
export const ACTION_UPDATE_SEARCH_REQUEST = 'ACTION_UPDATE_SEARCH_REQUEST';
export const ACTION_UPDATE_IS_FUTURE_CAB = 'ACTION_UPDATE_IS_FUTURE_CAB';
export const ACTION_TOGGLE_LISTING_WIDGET = 'ACTION_TOGGLE_LISTING_WIDGET';
export const ACTION_TOGGLE_E_PASS_INTERMEDIATE_SCREEN = 'ACTION_TOGGLE_E_PASS_INTERMEDIATE_SCREEN';
export const ACTION_SET_SELECTED_CAB_PACKAGE = 'ACTION_SET_SELECTED_CAB_PACKAGE';
export const ACTION_SET_BPG_INFO = 'ACTION_SET_BPG_INFO';
export const ACTION_SET_DISTANCE = 'ACTION_SET_DISTANCE';
export const ACTION_SET_SUPPLIER_ID = 'ACTION_SET_SUPPLIER_ID';
export const ACTION_SET_HOTEL_ID = 'ACTION_SET_HOTEL_ID';
export const ACTION_TOGGLE_IS_DEFAULT_DATE_UPDATED = 'ACTION_TOGGLE_IS_DEFAULT_DATE_UPDATED';
export const ACTION_UPDATE_SELECTED_PACKAGE = 'ACTION_UPDATE_SELECTED_PACKAGE';
export const ACTION_SET_PREFFERED_CAB_PARTNER = 'ACTION_SET_PREFFERED_CAB_PARTNER';
export const ACTION_SET_CAB_LIST_BY_PARTNER = 'ACTION_SET_CAB_LIST_BY_PARTNER';
export const ACTION_SET_SOURCE_AND_CAMPAIGN = 'ACTION_SET_SOURCE_AND_CAMPAIGN';
export const ACTION_SET_CAB_CONFIG_HR_PACKAGE = 'ACTION_SET_CAB_CONFIG_HR_PACKAGE';
export const ACTION_SET_BNPL_TRIP_TYPES = 'ACTION_SET_BNPL_TRIP_TYPES';
export const ACTION_SET_CAB_BNPL_LIMIT_DATA = 'ACTION_SET_CAB_BNPL_LIMIT_DATA';
export const ACTION_RESET_LISTING_ALERT_DATA = 'ACTION_RESET_LISTING_ALERT_DATA';
export const ACTION_SET_NON_RAP_DATA = 'ACTION_SET_NON_RAP_DATA';
export const ACTION_SET_VIEW_STATE_LOADING = 'ACTION_SET_VIEW_STATE_LOADING';
export const ACTION_UPDATE_HEADER_INFO_LABEL = 'ACTION_UPDATE_HEADER_INFO_LABEL';
export const ACTION_SET_CAB_BRAND_INFO = 'ACTION_SET_CAB_BRAND_INFO';
export const ACTION_SET_TRIP_PERSUASION_DATA = 'ACTION_SET_TRIP_PERSUASION_DATA';

// Filters Actions Constants
export const ACTION_UPDATE_MULTI_SELECT_FILTER = 'ACTION_UPDATE_MULTI_SELECT_FILTER';
export const ACTION_UPDATE_SINGLE_SELECT_FILTER = 'ACTION_UPDATE_SINGLE_SELECT_FILTER';
export const ACTION_UPDATE_SINGLE_GROUP_FILTERS = 'ACTION_UPDATE_SINGLE_GROUP_FILTERS';
export const ACTION_UPDATE_FILTERS = 'ACTION_UPDATE_FILTERS';
export const ACTION_UPDATE_SORTING_TYPE = 'ACTION_UPDATE_SORTING_TYPE';
export const ACTION_APPLY_AND_UPDATE_FILTERS = 'ACTION_APPLY_AND_UPDATE_FILTERS';
export const ACTION_UPDATE_PREVIEW_CABS = 'ACTION_UPDATE_PREVIEW_CABS';
export const ACTION_CLEAR_ALL_FILTERS = 'ACTION_CLEAR_ALL_FILTERS';
export const ACTION_UPDATE_SORTING_KEY = 'ACTION_UPDATE_SORTING_KEY';
export const ACTION_SET_FILTERS = 'ACTION_SET_FILTERS';
/* ------------------- Filters Actions ----------------- */

export const clearAllFilters = (group) => (dispatch, getState) => {
  let { filters, cabs } = getState().cabsListing;
  if (!group) {
    Object.keys(filters).forEach((filterLabel) => {
      Object.keys(filters[filterLabel]).forEach((option) => {
        filters[filterLabel][option].isSelected = false;
      });
    });
  } else {
    Object.keys(filters[group]).forEach((option) => {
      filters[group][option].isSelected = false;
    });
  }
  dispatch(updateFilterObjAndPreviewCabs(filters));
};

export const applyFiltersAndUpdateCab = () => (dispatch, getState) => {
  const {
    cabs: { userDetails = {} },
  } = getState();
  const isCorporateUser = userDetails?.profileType === UserSessionModule.ProfileType.BUSINESS;
  dispatch({
    type: ACTION_APPLY_AND_UPDATE_FILTERS,
    data: isCorporateUser,
  });
};

export const setHeaderInforLabel = (value) => ({
  type: ACTION_UPDATE_HEADER_INFO_LABEL,
  data: value,
});

export const setNonRapData = (data) => ({
  type: ACTION_SET_NON_RAP_DATA,
  data: data,
});

export const setViewStateAsLoading = () => ({
  type: ACTION_SET_VIEW_STATE_LOADING,
});

export const updatePreviewCabs = () => (dispatch, getState) => {
  const { cabs, selectedFilters, isFilterApplied } = getState().cabsListing;
  let previewCabs = [];

  cabs.forEach((cab) => {
    if (
      isCabEligibleByFuelType(cab, selectedFilters.fuelType) &&
      isCabEligibleByCarType(cab, selectedFilters.carType) &&
      isCabEligibleByModelType(cab, selectedFilters.modelType)
    ) {
      previewCabs.push(cab);
    } else if (!isFilterApplied) {
      previewCabs.push(cab);
    }
  });
  dispatch({
    type: ACTION_UPDATE_PREVIEW_CABS,
    data: previewCabs,
  });
};

const updateFilterGroup = (filters, group, groupData) => {
  try {
    Object.keys(filters[group]).forEach((filterGroup) => {
      if (Object.keys(groupData).includes(filterGroup)) {
        filters[group][filterGroup].count = groupData[filterGroup].length;
      } else {
        filters[group][filterGroup].count = 0;
      }
    });
    return filters;
  } catch (e) {
    return filters;
  }
};

const getFiltersByCarType = (selectedFilters, filters, cabs) => {
  const getCarTypeFilters = [];
  cabs.forEach((cab) => {
    if (
      isCabEligibleByFuelType(cab, selectedFilters.fuelType) &&
      isCabEligibleByModelType(cab, selectedFilters.modelType)
    ) {
      getCarTypeFilters.push(cab);
    }
  });
  let cabTypes = groupBy(getCarTypeFilters, 'type_label');
  updateFilterGroup(filters, 'carType', cabTypes);
  return filters;
};

const getFiltersByFuelType = (selectedFilters, filters, cabs) => {
  const getCarTypeFilters = [];
  cabs.forEach((cab) => {
    if (
      isCabEligibleByCarType(cab, selectedFilters.carType) &&
      isCabEligibleByModelType(cab, selectedFilters.modelType)
    ) {
      getCarTypeFilters.push(cab);
    }
  });
  let fuelTypes = groupBy(getCarTypeFilters, 'fuel_type');
  updateFilterGroup(filters, 'fuelType', fuelTypes);
  return filters;
};

const getFiltersByModelType = (selectedFilters, filters, cabs) => {
  const getCarTypeFilters = [];
  cabs.forEach((cab) => {
    if (
      isCabEligibleByCarType(cab, selectedFilters.carType) &&
      isCabEligibleByFuelType(cab, selectedFilters.fuelType)
    ) {
      getCarTypeFilters.push(cab);
    }
  });
  let modelTypes = groupBy(
    getCarTypeFilters.filter((cab) => {
      return cab.vehicle_model_type !== 'GENERIC';
    }),
    'vehicle_model',
  );
  updateFilterGroup(filters, 'modelType', modelTypes);
  return filters;
};

export const updateMultiSelectFilter = (group, option) => (dispatch, getState) => {
  let { filters, cabs } = getState().cabsListing;
  let filterGroupData = filters[group];
  filterGroupData[option].isSelected = !filterGroupData[option].isSelected;
  dispatch(updateFilterObjAndPreviewCabs(filters));
};

const updateFilterObjAndPreviewCabs = (filters) => (dispatch, getState) => {
  let { cabs } = getState().cabsListing;
  const { selectedFilters } = getSelectedFilters(filters);
  filters = getFiltersByCarType(selectedFilters, filters, cabs);
  filters = getFiltersByFuelType(selectedFilters, filters, cabs);
  filters = getFiltersByModelType(selectedFilters, filters, cabs);
  dispatch(updateFilters(filters));
  dispatch(updatePreviewCabs());
};

export const updateFilters = (filters) => (dispatch) => {
  const { isFilterApplied, selectedFilters } = getSelectedFilters(filters);
  dispatch({
    type: ACTION_UPDATE_FILTERS,
    data: { selectedFilters, filters, isFilterApplied },
  });
};

export const updateSortingKey = (key) => (dispatch) => {
  dispatch({
    type: ACTION_UPDATE_SORTING_KEY,
    data: key,
  });
};
/* ----------------- ENDS HERE -------------*/

export const setPrefferedCabPartner = (partner) => (dispatch) => {
  dispatch({
    type: ACTION_SET_PREFFERED_CAB_PARTNER,
    data: partner,
  });
  if (partner) {
    dispatch(setCabListByPartner());
    // Update filters everytime when cab partner changes
    dispatch(createFilters());
  }
};

export const dateChangedAction = (departDate, returnDate) => ({
  type: ACTION_DATE_TIME_CHANGED,
  data: [departDate, returnDate],
});

export const updateSelectedHRPackage = (packageId, isLanding = false) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: ACTION_UPDATE_SELECTED_PACKAGE,
    data: packageId,
  });
  if (!isLanding) {
    let request = getState().cabsListing.searchRequest;
    request = {
      ...request,
      package_key: packageId,
      fetch_package_list: false,
    };
    dispatch(setLoadingCabsAction);
    await searchCabs(request, dispatch);
  }
};

export const resetListingAlertData = () => ({
  type: ACTION_RESET_LISTING_ALERT_DATA,
})

export const toggleEPassIntermediateScreen = (value = false) => ({
  type: ACTION_TOGGLE_E_PASS_INTERMEDIATE_SCREEN,
  data: value,
});

export const setCabPackage = (data) => ({
  type: ACTION_SET_SELECTED_CAB_PACKAGE,
  data: data,
});

export const setInitialListingData = (request) => ({
  type: ACTION_INITIAL_DATA,
  data: request,
});

export const setHotelID = (hotelId) => ({
  type: ACTION_SET_HOTEL_ID,
  data: hotelId,
});

export const setLoadingCabsAction = {
  type: ACTION_LOADING_CABS,
};

export const setHRPackages = (data) => (dispatch) => {
  dispatch({
    type: ACTION_SET_CAB_CONFIG_HR_PACKAGE,
    data: data,
  });
};

export const setTripPersuationData = (data) => (dispatch) => {
  dispatch({
    type: ACTION_SET_TRIP_PERSUASION_DATA,
    data: data,
  });
};

export const setBnplTripTypes = (data) => (dispatch) => {
  dispatch({
    type: ACTION_SET_BNPL_TRIP_TYPES,
    data: data,
  });
};

export const setBrandInfo = (data) => (dispatch) => {
  dispatch({
    type: ACTION_SET_CAB_BRAND_INFO,
    data: data,
  });
};

export const searchDeepLink = (deepLinkData) => async (dispatch, getState) => {
  await dispatch(initCabs());
  const { allTravelTypes, userDetails } = getState().cabs;
  const {
    searchRequest: { is_instant_search: isInstantCab = false },
    showListingWidget = false,
  } = getState().cabsListing;
  const requestDetail = await processDeepLinkData(deepLinkData, allTravelTypes, userDetails, false);
  if (requestDetail.isValid) {
    const {
      tripType,
      departDate,
      returnDate,
      flightNumber = null,
      fromAddress: from,
      toAddress: to,
      openWidget: openListingWidget = false,
      packageKey = null,
      airportPickupType = AirportPickupType.TO.name,
      marketingHotelId = null,
      source = null,
      cmp = null,
    } = requestDetail;
    const sourceAndCmp = {
      source: source,
      cmp: cmp,
    };
    dispatch({
      type: ACTION_SET_SOURCE_AND_CAMPAIGN,
      data: sourceAndCmp,
    });
    dispatch(updateStopOvers(requestDetail?.stopOverObjects));
    if (marketingHotelId !== null) {
      await dispatch(setHotelID(marketingHotelId));
    }
    dispatch(setLoadingCabsAction);
    dispatch(setFlightNumberDetail(flightNumber));
    await dispatch(
      setCabRequest(
        { from, to, tripType, isInstantCab, isDefaultSearch: false },
        departDate,
        returnDate,
        packageKey,
        marketingHotelId,
      ),
    );
    if (source === 'UNIVERSAL_SEARCH') {
      dispatch(toggleListingWidget(true));
    } else {
      if (showListingWidget) {
        dispatch(toggleListingWidget(false));
      }
    }
  } else {
    Actions.cabs({ initial: false });
  }
};

export const toggleListingWidget = (shouldOpen = false) => (dispatch) => {
  dispatch({
    type: ACTION_TOGGLE_LISTING_WIDGET,
    data: shouldOpen,
  });
};
export const tripTypeChangeAction = (tripType) => (dispatch, getState) => {
  const { selectedHRCabPackage, hrPackages } = getState().cabsListing;
  if (tripType === TripType.HR.value && !selectedHRCabPackage) {
    dispatch(updateSelectedHRPackage(hrPackages[0]?.package_id, true));
  }
  dispatch(updateTripType(tripType));

};
export const setCabRequest = (
  {
    from,
    to,
    intermediateCities,
    tripType,
    isInstantCab = false,
    isDefaultSearch = false,
    requestSource = null,
    connectorSearch = false,
  },
  departDate = null,
  returnDate = null,
  packageKey = null,
  marketingHotelId = null,
) => async (dispatch, getState) => {
  const {
    cabs: { userDetails = {}, deviceDetails = {} },
    cabsMyBizPrimaryPax: { is_guest_user, primary_pax_details },
    cabsListing: { sourceAndCmp = null },
  } = getState();
  const {
    tripType: landingTripType,
    sourceData,
    destinationData,
    stops: stopovers = [],
    departDate: landingDepartDate,
    returnDate: landingReturnDate,
  } = getTripDataFromState(getState());

  if (Boolean(stopovers?.length)) {
    if (!Boolean(returnDate)) {
      returnDate = landingReturnDate;
    }
  }
  const data = {
    from,
    to,
    tripType,
    atCrossSellRequest: false,
    departDate,
    returnDate,
    userDetails,
    requestSource,
    deviceDetails,
    isDefaultSearch,
    intermediateCities,
    packageKey,
    isInstantCab,
    connectorSearch,
    marketingHotelId,
    is_guest_user,
    primary_pax_details,
    funnel_source: sourceAndCmp?.source || null,
    stopovers,
  };
  const { request, reqDepartDate, reqReturnDate, initiator = null } = await getSearchCabsRequest(
    data,
  );
  dispatch(setInitialListingData(request));

  // If the date of trip changes then update landing
  if (tripType !== landingTripType) {
    dispatch(_updateTripType(tripType));
  }

  if (landingReturnDate !== reqReturnDate || landingDepartDate !== reqDepartDate) {
    if (!isInstantCab) {
      if (Boolean(reqReturnDate)) {
        dispatch(_updateTravelDate(reqDepartDate, reqReturnDate));
      } else {
        dispatch(_updateTravelDate(reqDepartDate, landingReturnDate));
      }
    }
  }

  if (!sourceData || !_.isEqual(from, sourceData) || !_.isEqual(to, destinationData)) {
    dispatch(landingAddressChanged(from, to));
  }
  searchCabs(request, dispatch, initiator);
};

/* Prepare and send the cab request, takes request object from recent search
 * This also checks if the saved recent search time might be invalid and extends the request
 * with new valid time and fires the request */
export const searchRecent = (recentSearchRequest, currDate = now()) => async (
  dispatch,
  getState,
) => {
  const {
    source_location,
    destination_location,
    pickup_time,
    departure_date,
    return_date,
    drop_time,
    trip_type,
  } = recentSearchRequest;
  const { userDetails = {} } = getState().cabs;
  const isRoundTrip = trip_type === TripType.RT.value;
  let newDepDate = truncateHours(fecha.parse(departure_date, CABS_DATE_FMT));
  let newRetDate =
    (isRoundTrip && return_date && truncateHours(fecha.parse(return_date, CABS_DATE_FMT))) || null;
  let newDropTime = (isRoundTrip && drop_time && fecha.parse(drop_time, CABS_TIME_FMT)) || null;
  let newPickupTime = fecha.parse(pickup_time, CABS_TIME_FMT);
  // Merge depart date and time for comparision
  newDepDate.setHours(newPickupTime.getHours(), newPickupTime.getMinutes(), 0, 0);
  // Create next nearest pickup time
  const minPickupTime = addHours(currDate, TripType[trip_type].defaultAP);
  adjustHourToSlot(minPickupTime);
  // Check if pickup is still valid
  if (newDepDate < minPickupTime) {
    // Set depart and pickup time to next nearest date time
    newDepDate = truncateHours(minPickupTime);
    newPickupTime = minPickupTime;
    newRetDate = (newRetDate && getNearestReturnDate(newDepDate)) || null;
    newDropTime = (newRetDate && newPickupTime) || null;
  }
  adjustHourToSlot(newPickupTime);
  const newPickupTimeStr = fecha.format(newPickupTime, CABS_TIME_FMT);
  const newDropTimeStr = (newDropTime && fecha.format(newDropTime, CABS_TIME_FMT)) || null;
  const retDate = newRetDate && fecha.format(newRetDate, CABS_DATE_FMT);
  const request = {
    source_location,
    destination_location,
    trip_type,
    return_date: retDate,
    departure_date: fecha.format(newDepDate, CABS_DATE_FMT),
    pickup_time: newPickupTimeStr,
    drop_time: newDropTimeStr,
    booking_device: Platform.select({
      android: 'Android',
      ios: 'iOS',
    }),
    // TODO: Fetch these params from native for current user
    app_version: Platform.select({
      android: '7.2.5',
      ios: '6.0.2',
    }),
    email: userDetails.email,
    session_token: userDetails.mmtAuth,
    sector: null,
    visitor_id: null,
    device_id: null,
  };
  dispatch(setInitialListingData(request));
  await searchCabs(request, dispatch);
};

/* Changes the date/time of current search request and fires it */
export const performSearch = () => async (dispatch, getState) => {
  let request = getState().cabsListing.searchRequest;
  let isListingAvailable = getState().cabsListing.viewState === ViewStates.VIEW_CABS;
  const { stops } = getTripDataFromState(getState());
  if (request) {
    const currentTripType = request.trip_type;
    const {
      cabsCalendar: { departDate, returnDate },
      cabsListing: { activeDurationFilter },
    } = getState();
    request = {
      ...request,
      pickup_time: fecha.format(departDate, CABS_TIME_FMT),
      departure_date: fecha.format(departDate, CABS_DATE_FMT),
      return_date: null,
    };
    // Defensive approach: @TODO Later verify and put logic of insert return_date everytime when its available
    if (returnDate && currentTripType === TripType.OW.value && stops.length > 0) {
      request = {
        ...request,
        drop_time: fecha.format(returnDate, CABS_TIME_FMT),
        return_date: fecha.format(returnDate, CABS_DATE_FMT),
      };
    } else if (returnDate && currentTripType === TripType.RT.value) {
      request = {
        ...request,
        drop_time: fecha.format(returnDate, CABS_TIME_FMT),
        return_date: fecha.format(returnDate, CABS_DATE_FMT),
      };
    } else if (currentTripType === TripType.RT.value) {
      request = {
        ...request,
        trip_type: TripType.OW.value,
      };
    }

    if (request.trip_type === TripType.HR.value) {
      request = {
        ...request,
        destination_location: null,
      };
    }

    if (currentTripType !== request.trip_type) {
      dispatch(_updateTripType(request.trip_type));
    }

    // Track AP < 3hrs
    const pickupTimeTs = departDate.getTime();
    const currTime = now();
    const diff = pickupTimeTs - currTime.getTime();
    if (diff < 3 * 60 * 60 * 1000) {
      if (isListingAvailable) {
        trackListingEvent(CabOmnitureEvent.Listing_Nearest_Slot_Search);
      } else {
        trackListingErrorEvent(CabOmnitureEvent.Listing_Nearest_Slot_Search);
      }
    }

    dispatch(setInitialListingData(request));

    await searchCabs(request, dispatch);
  }
};

export const checkAndPrepareDataForSafetySheet = (cabs) => {
  let data = null;
  try {
    cabs.forEach((cab) => {
      if (cab?.my_safety_accessories_details) {
        cab.my_safety_accessories_details.forEach((details) => {
          if (details.show_learn_more && details.learn_more_details) {
            data = {
              ...details.learn_more_details,
              images_list: details.images_list,
            };
          }
        });
      }
    });
  } catch (e) {
    // Ignore
  }
  return data;
};

export const getWalletBalance = () => async (dispatch, getState) => {
  const { userDetails = {}, deviceDetails = {} } = getState().cabs;
  let walletBalance = 0;
  let walletBurnRules = [];

  if (
    !_.isEmpty(userDetails) &&
    !(_.isEmpty(userDetails.email) && _.isEmpty(userDetails.mmtAuth)) &&
    UserSessionModule.isPersonalUser(userDetails)
  ) {
    const request = getWalletBalanceRequest(userDetails, deviceDetails);
    const response = await getWalletBalanceResponse(request);
    try {
      const walletResponse = await response.json();
      walletBalance = walletResponse.wallet_balance;
      walletBurnRules = walletResponse.payment_bonus_details;
    } catch (e) {
      walletBalance = 0;
      walletBurnRules = [];
    }
  }

  dispatch({
    type: ACTION_UPDATE_WALLET_BALANCE,
    data: {
      walletBalance,
      walletBurnRules,
    },
  });
};

export const showingFutureCabs = (value) => (dispatch) => {
  dispatch({
    type: ACTION_UPDATE_IS_FUTURE_CAB,
    data: value,
  });
};

const _handleGTMData = (data) => {
  try {
    // @ts-ignore
    if (!window?.dataLayer) return;
    const request = data.request || {};
    const response = data.response || {};
    // TODO : change getting isFromAirport value
    const isFromAirport =
      (request?.trip_type === TripType.AT.value && request?.source_city?.indexOf('_AIR') > -1) ||
      false;
    let gtmTagCode = TripType[request?.trip_type]?.gtmTagCode || '';
    if (request?.trip_type === TripType.AT.value) {
      if (isFromAirport) {
        gtmTagCode = 'AT-A2C';
      } else {
        gtmTagCode = 'AT-C2A';
      }
    }

    const gtmData = getParamsForGTM({
      sourceLocation: request?.source_location || {},
      destinationLocation: request?.destination_location || {},
      departureDate: request?.departure_date || '',
      pickupTime: request?.pickup_time || '',
      returnDate: request?.return_date || '',
      gtmTagCode,
      sourceCityName: request?.source_city_name || '',
      destinationCityName: request?.destination_city_name || '',
      pageType: 'listing',
    });

    setTimeout(() => pushGTMData(gtmData), 1000);
    // getParamsForGTM(params);
  } catch (e) {
    // Ignore exception
  }
};

export const setBPGInfo = (info) => (dispatch) => {
  dispatch({
    type: ACTION_SET_BPG_INFO,
    data: info,
  });
};

export const setDistance = (distance) => (dispatch) => {
  dispatch({
    type: ACTION_SET_DISTANCE,
    data: distance,
  });
};

export const setBnplLimitData = (data) => (dispatch) => {
  dispatch({
    type: ACTION_SET_CAB_BNPL_LIMIT_DATA,
    data: data,
  });
};

export const setSupplierId = (supplierId) => (dispatch) => {
  dispatch({
    type: ACTION_SET_SUPPLIER_ID,
    data: supplierId,
  });
};

export const getOffersList = (
  bookMode,
  tripType,
  isInstantCabSearch,
  myBizTravellerEmail = null,
) => async (dispatch, getState) => {
  const { userDetails = {} } = getState().cabs;
  try {
    const offersRequest = {
      type: 'fetchOffersRequest',
      book_mode: bookMode,
      source: offerSource.FUNNEL,
      page: offerPage.LISTING,
      trip_type: tripType,
      booking_type: isInstantCabSearch ? offerBookingType.INSTANT : offerBookingType.NORMAL,
      profile_type:
        userDetails?.profileType === UserSessionModule.ProfileType.BUSINESS
          ? UserSessionModule.ProfileType.BUSINESS
          : UserSessionModule.ProfileType.PERSONAL,
      mybiz_traveller_email: myBizTravellerEmail,
      email: userDetails?.email,
      session_token: userDetails?.mmtAuth,
      app_version: await fetchAppVersion(),
    };
    const offersResponse = await getOffers(offersRequest);
    dispatch(setOffers(offersResponse?.offers || []));
  } catch (e) {
    dispatch(setOffers([]));
  }
};

/* Fires the request, dispatches loading before firing request and response or error events */
export const searchCabs = async (request, dispatch, requestSource = null) => {
  validateSearchServerRequest(request, 'searchCabs()');
  let {
    source_location: { city: fromCity },
    pickup_time,
    trip_type,
    departure_date,
    return_date,
    is_instant_search: isInstantCab = false,
    stopovers = [],
  } = request;
  const toCity = request.destination_location !== null ? request.destination_location.city : '';
  const searchLogData = _.compact([
    fromCity,
    toCity,
    pickup_time,
    trip_type,
    departure_date,
    return_date,
  ]).join('_');
  //  Only put return date in request based on below condition
  if (stopovers?.length === 0 && trip_type !== TripType.RT.value) {
    return_date = null;
  }
  logAction('cabs search', searchLogData);
  tuneListingTracker(request);
  firebaseListingTracker(request);
  try {
    dispatch(setLoadingCabsAction);
    request = {
      ...request,
      rating_show: true,
      is_custom_payment_allowed: true,
      show_kit_enabled_cabs: true,
      is_intercity_2: true,
      is_other_cabs_enabled: false, // showMoreOptions()
      pokus_flags: getPokusFlagsForBE(),
      fetch_ryd_cabs: true,
      return_date,
    };
    const response = await getSearchCabsResponse(request);
    const listingResponse = await response.json();
    if (listingResponse?.request?.trip_type === TripType.AT.value) {
      if (listingResponse?.airport_trip_type === 'PICKUP') {
        listingResponse.request.source_location.is_airport = true;
      } else {
        listingResponse.request.destination_location.is_airport = true;
      }
      dispatch(
        updateLocation({
          sourceData: listingResponse?.request?.source_location,
          destinationData: listingResponse?.request?.destination_location,
          stops: [],
          airportIndex: listingResponse?.request?.source_location?.is_airport ? AirportPickupType.FROM.value : AirportPickupType.TO.value,
          travelType: getTravelTypeFromTripType(listingResponse?.request?.trip_type),
        }),
      );
    }
    if (Platform.OS === 'web') {
      _handleGTMData(listingResponse);
    }


    // Tracking RT duration in hrs and days
    if (
      listingResponse?.status === 'SUCCESS' &&
      listingResponse?.request?.trip_type === TripType.RT.value
    ) {
      trackRTdurationDaysHrs(listingResponse?.request);
    }
    const bnplLimitData = {
      bnpl_limit: 4500,
      bnpl_rap_limit: 4500,
    };
    if (Boolean(listingResponse.bnpl_limit)) {
      bnplLimitData.bnpl_limit = listingResponse.bnpl_limit;
    }
    if (Boolean(listingResponse.bnpl_rap_limit)) {
      bnplLimitData.bnpl_rap_limit = listingResponse.bnpl_rap_limit;
    }
    dispatch(setBrandInfo(listingResponse?.response?.brand_info || null));
    dispatch(setBnplLimitData(bnplLimitData));
    dispatch(setDistance(listingResponse?.overall_trip_distance_in_kms || null));
    dispatch(setBPGInfo(listingResponse?.response?.best_price_guaranteed_data || null));
    const responseRequest = listingResponse?.request;
    if (responseRequest?.trip_type !== TripType.HR.value && (!stopovers || stopovers?.length === 0)) {
      try {
        skywalkerSearchConnector(listingResponse);
        skywalkerSectorConnector(listingResponse.request, CabsScreen.LISTING);
        commonsSearchConnector(listingResponse.request);
      } catch (e) { }
    }
    dispatch(
      getOffersList(
        responseRequest.book_mode,
        responseRequest.trip_type,
        responseRequest.is_instant_search,
        request?.mybiz_traveller_email,
      ),
    );
    const { changeTripType, message, listingAvailable } = handleTripTypeChange(
      listingResponse,
      request,
    );

    let previousTripType = null;
    const listingRequest = listingResponse.request;
    if (listingRequest !== null && request.trip_type !== listingRequest.trip_type) {
      previousTripType = request.trip_type;
      request.trip_type = listingRequest.trip_type;
      const source = listingRequest.source_city;
      if (listingRequest.trip_type === TripType.AT.value) {
        let isFromAirport = false;
        if (listingResponse?.airport_trip_type === 'PICKUP') {
          isFromAirport = true;
          dispatch(updateAirportTripType(1));
        }
        dispatch(updateSelectedAirportIndex(source, isFromAirport));
      }
      if (changeTripType) {
        dispatch(_updateTripType(request.trip_type));
      }
    }
    let isMMTBlackUser = false;
    if (listingAvailable) {
      try {
        isMMTBlackUser = !!listingResponse?.response?.mmt_black_persuasion;
      } catch (e) {
        //ignore
      }
      createFunnelVars(listingRequest, requestSource, isMMTBlackUser);
      if (
        request.destination_location !== null &&
        (request.source_location.city === request.destination_location.city ||
          (request.trip_type !== previousTripType && request.trip_type === TripType.HR.value))
      ) {
        const tripType = previousTripType || request.trip_type;
        const event = `${eventHeaderForTripType(tripType)}Landing_Intra_City_Search`;
        trackListingEvent(event);
      }
      let isMyBizOOP = false;
      listingResponse?.response?.cab_list.map((data, index) => {
        if (!isMyBizOOP) {
          isMyBizOOP = data?.cab_fare_detail?.is_out_of_corp_policy;
        }
      });
      if (isMyBizOOP) {
        trackListingEvent('Mob_Cab_MyBiz_Listing_OOP_shown');
      }
    } else {
      if (isInstantCab) {
        trackListingEvent(`Mob_Cabs_Instant_No_Alternate`);
      }
    }

    if (previousTripType) {
      _trackTripChange(previousTripType, request.trip_type, listingAvailable);
    }
    if (Boolean(previousTripType) && previousTripType !== listingResponse?.request?.trip_type) {
      dispatch(tripTypeChangeAction(listingResponse?.request?.trip_type));
    }
    dispatch(setSearchData(request, listingResponse));
    if (!_.isEmpty(message)) {
      const errorCode = listingResponse?.errors?.error_list[0]?.code || null;
      dispatch(
        showTripTypeChangeMessage({ tripChangeMessage: message, searchErrorCode: errorCode }),
      );
    }
    if (!listingAvailable) {
      dispatch(captureListingError(listingRequest, requestSource, listingResponse));
    } else {
    }
    // dispatch(landingSearchComplete(listingRequest, listingAvailable));
  } catch (e) {
    dispatch(captureListingError(request, requestSource));
    dispatch(setCabsErrorAction(request, e));
    // dispatch(landingSearchComplete(null, false));
  }
};

const captureListingError = (request, requestSource = null, listingResponse = null) => (
  dispatch,
  getState,
) => {
  const isUniversalWidget = getState().cabsListing.showListingWidget;
  let funnelEVars = createFunnelVars(request, requestSource);
  const errorCode = listingResponse?.errors?.error_list?.[0]?.code;
  const isIntracity = listingResponse?.request?.is_intracity_search;
  if (!isUniversalWidget)
    trackListingErrorEvent(funnelEVars, isUniversalWidget, true, errorCode, isIntracity);
  else {
    setOnHoldTrackingFunc(() =>
      trackListingErrorEvent(funnelEVars, isUniversalWidget, true, errorCode, isIntracity),
    );
  }
};

const _trackTripChange = (existingTripType, newTripType, listingAvailable) => {
  let event = '';
  if (TripType.OW.value === existingTripType) {
    switch (newTripType) {
      case TripType.AT.value:
        event = 'Mob_Cabs_Listing_Outstation_to_Local_AT_Redirect';
        break;
      case TripType.HR.value:
        event = 'Mob_Cabs_Listing_Outstation_to_Local_HR_Redirect';
        break;
      default:
        event = '';
    }
  } else if (TripType.AT.value === existingTripType && TripType.OW.value === newTripType) {
    event = 'Mob_Cabs_Listing_Local_to_Outstation_AT_Redirect';
  }

  if (!_.isEmpty(event)) {
    listingAvailable ? trackListingEvent(event) : trackListingErrorEvent(event);
  }
};

const setCabsErrorAction = (request, err) => ({
  type: ACTION_ERROR,
  data: { request, err },
});

export const setCabListByPartner = () => (dispatch, getState) => {
  const { prefferedCabPartner, rapCabs, defaultCabs } = getState().cabsListing;
  let cabs = [];
  if (prefferedCabPartner === CAB_TYPE_PREFERENCE.RAP) {
    cabs = rapCabs;
  } else if (prefferedCabPartner === CAB_TYPE_PREFERENCE.NON_RAP) {
    cabs = defaultCabs;
  }
  dispatch({
    type: ACTION_SET_CAB_LIST_BY_PARTNER,
    data: cabs,
  });
};

export const createFilters = () => (dispatch, getState) => {
  const { prefferedCabPartner, rapCabs, defaultCabs } = getState().cabsListing;
  let filters = null;
  if (prefferedCabPartner === CAB_TYPE_PREFERENCE.RAP) {
    filters = createFiltersFromCabList(rapCabs || []);
  } else if (prefferedCabPartner === CAB_TYPE_PREFERENCE.NON_RAP) {
    filters = createFiltersFromCabList(defaultCabs || []);
  }
  if (filters) {
    dispatch(updateFilters(filters));
  }
};

/* Dispatch set search data and also saves it to recent searches */
export const setSearchData = (request, response) => (dispatch, getState) => {
  const currState = getState();
  let {
    cabs: { userDetails, deviceDetails },
    cabsTripInfo: { tripType, travelType },
  } = currState;
  let { sourceData, destinationData, departDate, returnDate } = currState.cabsTripInfo[travelType];
  if (!_.isNil(sourceData) && sourceData.place_id !== request.source_location.place_id) {
    sourceData = null;
  }
  if (
    _.isNil(request.destination_location) ||
    (!_.isNil(destinationData) &&
      destinationData.place_id !== request.destination_location.place_id)
  ) {
    destinationData = null;
  }
  // Handle the case where pickup time is changed from server or HR package change from server
  try {
    let shouldShowTimeChangeModal = false;
    let shouldShowPackageChangeModal = false;
    let timeChangeErrorMessage = null;
    let packageChangeErrorMessage = null;
    if (
      Boolean(response?.selected_package) &&
      Boolean(response?.request?.trip_type === TripType.HR.value) &&
      Boolean(request?.package_key) &&
      request.package_key !== response.selected_package
    ) {
      packageChangeErrorMessage = {
        text:
          response?.package_change_popup_msg ||
          'Selected package isnt available at the moment. Showing you the next available package',
      };
      shouldShowPackageChangeModal = true;
    }
    if (
      request.pickup_time !== response.request.pickup_time ||
      request.departure_date !== response.request.departure_date ||
      request.return_date !== response.request.return_date ||
      request.drop_time !== response.request.drop_time
    ) {
      shouldShowTimeChangeModal =
        (request.pickup_time !== response.request.pickup_time ||
          request.departure_date !== response.request.departure_date) &&
        !request.is_default_search;

      const oldDateStr = `${request.departure_date} ${request.pickup_time}`;
      const oldDepartDate = fecha.parse(oldDateStr, 'DD:MM:YYYY hh:mm');

      const pickupTime = response.request.pickup_time;
      const newDateStr = `${response.request.departure_date} ${pickupTime}`;
      const newDepartDate = fecha.parse(newDateStr, 'DD:MM:YYYY hh:mm');
      let newReturnDate = returnDate;

      if (response.request.return_date && response.request.drop_time) {
        let newReturnDateStr = `${response.request.return_date} ${response.request.drop_time}`;
        newReturnDate = fecha.parse(newReturnDateStr, 'DD:MM:YYYY hh:mm');
      }
      const oldPickupTime = fecha.format(oldDepartDate, 'h:mm A');
      const newPickupTime = fecha.format(newDepartDate, 'hh:mm A');

      timeChangeErrorMessage = {
        header: `Cabs available for ${newPickupTime}`,
      };
      const is_ride_now_dead_end_retry = request?.is_ride_now_dead_end_retry || false;
      if (!is_ride_now_dead_end_retry) {
        // Since it's not RideNow Dead End, old pickUp time is user Selected
        timeChangeErrorMessage.text = `Couldn’t find cabs for ${oldPickupTime} departure.`;
      } else {
        timeChangeErrorMessage.header = `Showing next available cabs at ${newPickupTime}`;
        // Now removing RideNow Dead End Retry, so that it dont go to redux
        request.is_ride_now_dead_end_retry = false;
      }
      request.pickup_time = pickupTime;
      request.departure_date = fecha.format(newDepartDate, CABS_DATE_FMT);
      if (!request.is_instant_search) {
        dispatch(_updateTravelDate(newDepartDate, newReturnDate));
      }
    }
    if (shouldShowTimeChangeModal) {
      dispatch({
        type: ACTION_LISTING_SHOW_ERROR,
        data: { errorMessage: timeChangeErrorMessage },
      });
    } else {
      if (shouldShowPackageChangeModal) {
        dispatch({
          type: ACTION_LISTING_SHOW_ERROR,
          data: { errorMessage: packageChangeErrorMessage },
        });
      }
    }
  } catch (e) {
    console.log(e);
  }

  const searchRequest = {
    ...request,
    sourceData,
    is_default_search: false,
    destinationData,
    departDate,
    returnDate,
    userDetails,
    deviceDetails,
  };


  dispatch({
    type: SET_SEARCH_DATA,
    data: { searchRequest, response },
  });
  dispatch(createFilters());
  dispatch(setCabListByPartner());
  dispatch(updateRecentSearch(searchRequest));
  dispatch(onCabsLoadedAction());
};

const updateRecentSearch = (searchRequest) => async (dispatch) => {
  await addToRecentSearches(searchRequest);
  dispatch(updateRecentSearchData());
};

/* Dispatches the current search without modifing anything in the request */
export const retryCabSearch = () => (dispatch, getState) => {
  const { searchRequest } = getState().cabsListing;
  searchCabs(searchRequest, dispatch);
};

export const retrySecheduleCabSearch = (is_ride_now_dead_end_retry = false) => (
  dispatch,
  getState,
) => {
  let { searchRequest } = getState().cabsListing;
  searchRequest = { ...searchRequest, is_instant_search: false, is_ride_now_dead_end_retry };
  searchCabs(searchRequest, dispatch);
};

export const changeTripScheduleType = () => async (dispatch, getState) => {
  let { searchRequest } = getState().cabsListing;
  let { cabsListing } = getState();
  // searchRequest.is_instant_search = !searchRequest.is_instant_search;
  searchRequest.is_instant_search = !searchRequest.is_instant_search;
  const isInstantCab = searchRequest.is_instant_search;
  let isDefaultSearch = searchRequest.is_default_search;
  let departDate,
    returnDate = null;
  if (isInstantCab) {
    (isDefaultSearch = true), (departDate = now());
  } else {
    departDate = getNearestNextSlotForTripType(TripType.AT.value);
  }

  const request = {
    ...searchRequest,
    is_default_search: isDefaultSearch,
    pickup_time: fecha.format(departDate, CABS_TIME_FMT),
    departure_date: fecha.format(departDate, CABS_DATE_FMT),
    return_date: null,
  };
  dispatch(setInitialListingData(request));
  await searchCabs(request, dispatch);
};

export const setFlightNumberDetail = (flightNumber) => ({
  type: ACTION_UPDATE_FLIGHT_NUMBER,
  data: flightNumber,
});

export const setFlightBookingId = (flightBookingId) => (dispatch) => {
  dispatch({
    type: ACTION_ADD_ON_UPDATE_FLIGHT_BOOKING_ID,
    data: flightBookingId,
  });
};

export const openReviewPage = (selectedCab) => async (dispatch, getState) => {
  let {
    cabData: cab,
    nextAvailabilityDate: availabilityDate = null,
    nextAvailabilityTime: availabilityTime = null,
    nextAvailabilityTag: availabilityTag = null,
  } = selectedCab;
  const { userDetails, deviceDetails } = getState().cabs;
  const { departDate, returnDate, sourceData, destinationData } = getState().cabsLanding;
  const { cabs, searchServerRequest } = getState().cabsListing;
  const {
    source_city: sourceCityCode,
    destination_city: destinationCityCode,
    trip_type: tripType,
    locus_info: locusInfo,
  } = searchServerRequest;
  const pdtData = {
    userDetails,
    deviceDetails,
    cab,
    cabs,
    searchData: {
      departDate,
      returnDate,
      sourceData,
      destinationData,
      sourceCityCode,
      destinationCityCode,
      tripType,
      locusInfo,
    },
  };
  let is_airport_prime = false;
  try {
    is_airport_prime = cab.cab_fare_detail.is_airport_prime || false;
  } catch (e) {
    is_airport_prime = false;
  }
  if (is_airport_prime) {
    trackListingEvent('Mob_Cab_Listing_Airport_Prime_Click');
  }
  CabsPdtListingHelper.trackCabClicked(pdtData);
  dispatch(setCabType(cab.type));
  if (availabilityDate && availabilityTime) {
    try {
      const newDepartDate = getDateFromDateTime(availabilityDate, availabilityTime);
      trackListingEvent(`Mob_Cabs_Instant_NA_${availabilityTag}_click`);
      dispatch(handleFutureCab(newDepartDate, null, false));
    } catch (e) { }
  } else {
    dispatch(
      fetchCabDetails(cab.fuel_type || null, cab.type, cab.is_mysafety_kit_available || false),
    );
  }
  Actions.cabsReview({ parentScreen: CabsScreen.LISTING });
};

export const shouldShowCoachMark = () => { };

export const showTripTypeChangeMessage = (data) => (dispatch) => {
  dispatch({
    type: ACTION_LISTING_SHOW_ERROR,
    data: data,
  });
};

export const setOffers = (offers) => (dispatch) => {
  dispatch({
    type: ACTION_LISTING_SET_OFFERS,
    data: offers,
  });
};

export const _updateTripType = (tripType) => (dispatch, getState) => {
  dispatch(updateTripType(tripType));
};

export const hideError = {
  type: ACTION_LISTING_HIDE_ERROR,
};

const _updateTravelDate = (departDate, returnDate) => async (dispatch) => {
  dispatch(updateTripDate({ departDate, returnDate }));
};

export const updateSearchRequest = (searchRequest) => ({
  type: ACTION_UPDATE_SEARCH_REQUEST,
  data: searchRequest,
});

export const handleFutureCab = (departDate, returnDate, is_instant_search = false) => async (
  dispatch,
  getState,
) => {
  let searchRequest = getState().cabsListing.searchRequest || {};
  await dispatch(_updateTravelDate(departDate, returnDate));
  searchRequest = { ...searchRequest, is_instant_search, departDate, returnDate };
  dispatch(updateSearchRequest({ ...searchRequest }));
  dispatch(showingFutureCabs(false));
  await dispatch(handleReviewDateChange(departDate));
};

export const toggleIsDefaultDateTimeUpdated = (value) => ({
  type: ACTION_TOGGLE_IS_DEFAULT_DATE_UPDATED,
  data: value,
});

const _getExistingCabListCategories = (rapCabs, nonRapCabs) => {
  const cabsAvailableCategories = [];
  rapCabs?.length > 0 && cabsAvailableCategories.push(CAB_LIST_CATEGORIES.RAP_CABS);
  nonRapCabs?.length > 0 && cabsAvailableCategories.push(CAB_LIST_CATEGORIES.NON_RAP_CABS);
  return cabsAvailableCategories;
};

const _handleCalenderDateChanged = (departDate, returnDate, is_instant_search = false) => async (
  dispatch,
  getState,
) => {
  const { prefferedCabPartner, rapCabs, defaultCabs } = getState().cabsListing;
  dispatch(toggleIsDefaultDateTimeUpdated(true));
  const { stops, tripType } = getTripDataFromState(getState());
  const isMulticityTrip = stops?.length > 0;
  let newTripType = tripType;
  if ((tripType === TripType.OW.value || tripType === TripType.RT.value) && !isMulticityTrip) {
    if (returnDate) {
      newTripType = TripType.RT.value;
    } else if (tripType === TripType.RT.value) {
      newTripType = TripType.OW.value;
    }
    dispatch(updateTripType(newTripType));
  }
  await dispatch(_updateTravelDate(departDate, returnDate));
  dispatch(setFlightNumberDetail(null));
  let searchRequest = getState().cabsListing.searchRequest || {};
  dispatch(
    updateSearchRequest({
      ...searchRequest,
      trip_type: newTripType,
      is_instant_search,
      cab_partner_pref: prefferedCabPartner,
      cabs_available_categories: _getExistingCabListCategories(rapCabs, defaultCabs),
    }),
  );
  await dispatch(performSearch());
};

export const handleReviewDateChange = (departDate) => async (dispatch, getState) => {
  let { request, prefferedCabPartner, rapCabs, defaultCabs } = getState().cabsListing;
  request = {
    ...request,
    pickup_time: fecha.format(departDate, CABS_TIME_FMT),
    departure_date: fecha.format(departDate, CABS_DATE_FMT),
    cab_partner_pref: prefferedCabPartner,
    cabs_available_categories: _getExistingCabListCategories(rapCabs, defaultCabs),
  };

  dispatch(setInitialListingData(request));
  await searchCabs(request, dispatch);
};


export const handleReviewCityChange = (locData, isSource = true) => async (dispatch, getState) => {
  let request = getState().cabsListing.searchRequest;
  if (isSource) {
    request = {
      ...request,
      source_location: locData,
    };
  } else {
    request = {
      ...request,
      destination_location: locData,
    };
  }
  dispatch(setInitialListingData(request));
  if (isSource) {
    dispatch(landingAddressChanged(locData, request.destination_location));
  } else {
    dispatch(landingAddressChanged(request.source_location, locData));
  }
  dispatch(
    updateLocation({
      sourceData: isSource ? locData : request.source_location,
      destinationData: isSource ? request.destination_location : locData,
      airportIndex: 0,
    }),
  );
  await searchCabs(request, dispatch);
};

export const updateDefaultPickup = () => (dispatch) => {
  dispatch({
    type: ACTION_UPDATE_DEFAULT_PICKUP,
  });
};

export const openCalendar = (initialDateSelectMode, screen = CabsScreen.LISTING) => (
  dispatch,
  getState,
) => {
  const { tripType, departDate, returnDate, stops } = getTripDataFromState(getState());
  const disableReturn = tripType === TripType.AT.value || tripType === TripType.HR.value;
  dispatch(
    initWithDates(
      initialDateSelectMode,
      departDate,
      returnDate,
      tripType,
      _handleCalenderDateChanged,
      disableReturn,
      screen,
      stops?.length > 0,
    ),
  );
  // @To Do, need to remove setTimeout
  setTimeout(() => {
    Actions.cabsCalendar();
  }, 10);
};

export const onDurationFilterChange = (durationFilter) => async (dispatch, getState) => {
  const {
    cabsListing: { departDate, returnDate, tripType },
  } = getState();
  dispatch(initWithDates(DateSelectMode.PICKUP, departDate, returnDate, tripType, null, null));
  dispatch({
    type: ACTION_DURATION_FILTER_CHANGED,
    data: durationFilter,
  });
  await dispatch(performSearch());
};

export const trackPdtBackPressed = () => (dispatch, getState) => {
  const { sourceData, destinationData, departDate, returnDate } = getState().cabsLanding;
  const { userDetails, deviceDetails } = getState().cabs;
  const { cabs, searchServerRequest = {} } = getState().cabsListing;
  if (searchServerRequest) {
    const {
      source_city: sourceCityCode,
      destination_city: destinationCityCode,
      trip_type: tripType,
      locus_info: locusInfo,
    } = searchServerRequest;

    const pdtData = {
      userDetails,
      deviceDetails,
      searchData: {
        sourceData,
        destinationData,
        departDate,
        returnDate,
        sourceCityCode,
        destinationCityCode,
        tripType,
        locusInfo,
      },
      cabs,
    };
    CabsPdtListingHelper.trackBackPressed(pdtData);
  }
};
export const updateTravelLoc = (source_location, destination_location) => (dispatch, getState) => {
  const { selectedAirportTypeIndex, tripType } = getTripDataFromState(getState());
  if (tripType === TripType.AT.value) {
    dispatch(
      updateLocation({
        sourceData: source_location,
        destinationData: destination_location,
        airportIndex: selectedAirportTypeIndex,
      }),
    );
  } else {
    dispatch(
      updateLocation({
        sourceData: source_location,
        destinationData: destination_location,
      }),
    );
  }
};