import _findIndex from 'lodash/findIndex';
import _indexOf from 'lodash/indexOf';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _pick from 'lodash/pick';
import fecha from 'fecha';
import { Platform } from 'react-native';
import TripType, { AirportPickupType } from '../types/TripType';
import cabsConfig from '../cabsConfig';
import { CabsScreen } from '../config/cabsScreen';
import { CABS_TIME_FMT } from '../cabsConstants';
import {
  adjustHourToSlot,
  getDateForSEM,
  getDateFromDateTime,
  getNearestNextSlotForTripType,
  getNextDay10Am,
  hoursToDecimal,
} from '../cabsDateTimeHelpers';
import { addDays, getTomorrow, now } from '../../../Helpers/dateHelpers';
import { getReturnDate } from '../utils/cabsSearchUtil';
import { getCityByName, getLocationFromCityCode, getPlaceDetails } from '../Landing/cabsPlaces';
import validateDeepLinkData from './DeepLinkValidator';
import {
  CabsPageTrackerFunc,
  setReceiverSourceAndCmp,
  setHydraSegment,
  trackLandingEvent,
  setIsLoggedInTracker,
  cabsSource,
} from '../cabsAnalytics';
import {
  VAR_CAMPAIGN,
  VAR_REFERRER,
  VAR_USER_LOGIN_STATUS,
} from '../../../Common/utils/OmnitureTrackerUtils';
import { getHydraSegment } from '../api/cabsApis';
import * as omniture from "../../../Common/utils/OmnitureTrackerUtils";
import { isPhonePePlatform, isProvisionalBooking } from '../utils/cabsCommonUtils';
import CabOmnitureEvent from '../cabsOmnitureEvents';
import { getDiffInHours } from '../../../Helpers/dateTimehelpers';
import { updatedStopOvers } from '../cabsTripInfoAction';

const _processDateTime = (deepLinkData, allTravelTypes, isFromLanding = false) => {
  let {
    startDate,
    returnDate,
    pickupTime = cabsConfig.defaultNextDayTimeFormatted,
    dropTime = cabsConfig.defaultNextDayTimeFormatted,
  } = deepLinkData;
  const {
    tripType = TripType.OW.value,
    airportPickupType = AirportPickupType.TO.name,
    stopsPlaceIds,
    stopOvers = null
  } = deepLinkData;

  const travelType = allTravelTypes.filter((travelTypeObj) => {
    return _indexOf(travelTypeObj.allTripTypes, tripType) >= 0;
  })[0].name;

  // let nextSlotForTripType = getNearestNextSlotForTripType(tripType);
  // const isProvisionalFlow = isProvisionalBooking();
   let  nextSlotForTripType = now();
  
  let departDate;
  const departureDateObj = getDateFromDateTime(startDate, pickupTime);
  if (getDiffInHours(now(), departureDateObj) >= 0 || isProvisionalFlow) {
    departDate = departureDateObj;
  } else {
    departDate = getNextDay10Am();
  }
  if (departDate < nextSlotForTripType) {
    departDate = nextSlotForTripType;
  }
  adjustHourToSlot(departDate);

  const hours = hoursToDecimal(departDate);
  if (hours > 23.5) {
    departDate = getTomorrow();
    pickupTime = cabsConfig.defaultNextDayTimeFormatted;
  } else if (hours > cabsConfig.defaultNextDayTime) {
    pickupTime = fecha.format(departDate, CABS_TIME_FMT);
  }

  if (tripType === TripType.RT.value || (tripType === TripType.OW.value && (stopsPlaceIds || stopOvers))) {
    returnDate = getReturnDate(departDate, returnDate, dropTime, true);
    dropTime = fecha.format(returnDate, CABS_TIME_FMT);
  } else {
    returnDate = null;
    dropTime = null;
  }

  const selectedAirportTypeIndex =
    airportPickupType === AirportPickupType.FROM.name
      ? AirportPickupType.FROM.value
      : AirportPickupType.TO.value;

  const deepLinkScreen = deepLinkData?.screen;
  // This is being passed to make depart Date null when from Landing (not doing here because of validateDeepLinkData issue)

  return {
    travelType,
    tripType,
    selectedAirportTypeIndex,
    departDate,
    pickupTime,
    returnDate,
    dropTime,
    deepLinkScreen,
  };
};

const _getAddress = async (address, cityName, placeId) => {
  let addressDetail = null;
  try {
    if (!_isEmpty(address)) {
      try {
        addressDetail = typeof address === 'object' && address.place_id ? address: JSON.parse(address);
      } catch (e) {
        addressDetail = await getLocationFromCityCode(address);
      }
    } else if (!_isEmpty(cityName)) {
      addressDetail = await getCityByName(cityName);
    } else if (!_isEmpty(placeId)) {
      addressDetail = await getPlaceDetails(placeId);
    }
  } catch (e) {
    //
  }
  return addressDetail;
};
const _processAddressDetail = async (deepLinkData) => {
  const {
    fromCityName = null,
    toCityName = null,
    fromPlaceId = null,
    toPlaceId = null,
    isLocus = false,
    fromLocus = null,
    toLocus = null,
    cityLocus = null,
    airportPickupType = AirportPickupType.TO.name,
    tripType = TripType.OW.value,
    stopsPlaceIds = null,
    stopOvers = null,
  } = deepLinkData;
  let { from = null, to = null } = deepLinkData;
  // This part is for supporting locus deeplink. Replacing from and to with locus codes for further processing
  if (isLocus === true || isLocus === 'true') {
    from = fromLocus;
    to = toLocus;
    if (tripType === TripType.AT.value) {
      if (airportPickupType === AirportPickupType.FROM.name) {
        from = `${cityLocus}_AIR_LOCUS`;
        to = cityLocus;
      } else {
        from = cityLocus;
        to = `${cityLocus}_AIR_LOCUS`;
      }
    }
  }
  const fromAddress = await _getAddress(from, fromCityName, fromPlaceId);
  const toAddress = await _getAddress(to, toCityName, toPlaceId);
  let stopOverObjects = [];
  if (stopsPlaceIds) {
    const stopOverPlaceIds = JSON.parse(stopsPlaceIds);
    for (let index in stopOverPlaceIds) {
      const locFromPlaceId = await _getAddress('', '', stopOverPlaceIds[index]);
      const locationObject = {
        id: new Date().getTime(),
        location: locFromPlaceId,
      };
      stopOverObjects = [...stopOverObjects, locationObject];
    }
  }
  if (stopOvers) {
    const stopOverPlaces = JSON.parse(stopOvers);
    for (let elm of stopOverPlaces) {
      const locFromPlace = await _getAddress(elm, '', '');
      const locationObject = {
        id: new Date().getTime(),
        location: locFromPlace,
      };
      stopOverObjects = [...stopOverObjects, locationObject];
    }
  }
  return {
    fromAddress,
    toAddress,
    tripType,
    stopOverObjects,
  };
};

const _trackDeepLinkRequest = async (data, userDetails = null) => {
  const { cmp = '', referrer = '', screen } = data;
  let { source = '' } = data;
  let trackingData = {};
  if (!_isEmpty(cmp) && Platform.OS !== 'web') {
    trackingData = {
      [VAR_CAMPAIGN]: cmp,
    };
  }
  if (!_isEmpty(source)) {
    trackingData = {
      ...data,
      [VAR_REFERRER]: source,
    };
  } else if (!_isEmpty(referrer)) {
    trackingData = {
      ...data,
      [VAR_REFERRER]: source,
    };
  }
  const isDirect = screen === 'Landing';
  source = source || (isDirect ? 'Direct' : 'unknown');
  setReceiverSourceAndCmp((isPhonePePlatform() ? CabOmnitureEvent.PHONE_PE : source), cmp);
  if (userDetails?.mmtAuth) {
    await captureHydraSegments(userDetails);
  }
  if (data?.screen === CabsScreen.LANDING) {
    const tracker = {
      [cabsSource]: source,
    };
    CabsPageTrackerFunc[CabsScreen.LANDING](tracker, true);
  }
};

export const captureHydraSegments = async (userDetails, landingPage = false) => {
  try {
    const hydraSegmentData = await getHydraSegment(userDetails.mmtAuth, userDetails.profileType);
    if (hydraSegmentData?.success) {
      const allHydraSegments = [];
      Object.keys(hydraSegmentData.data.segments).map((segment) => {
        allHydraSegments.push(segment);
      });
      if (landingPage) {
        if (allHydraSegments.length > 0) {
          const myHydraSegment = allHydraSegments.join('|');
          trackLandingEvent({ m_v94: myHydraSegment });
        }
      } else {
        setHydraSegment(allHydraSegments.join('|'));
      }
    }
  } catch (e) { }
};

export const processDeepLinkData = async (
  deepLinkData,
  allTravelTypes,
  userDetails = null,
  isFromLanding = false,
) => {
  const { tripType = TripType.OW.value, marketingHotelId = null, cmp = '', source = '' } = deepLinkData;
  let processedDetail = {};
  let isValid = false;

  const validTripType = _find(
    allTravelTypes,
    ({ allTripTypes }) => _indexOf(allTripTypes, tripType) >= 0,
  );

  if (TripType[tripType] && validTripType) {
    const dateTimeDetail = _processDateTime(deepLinkData, allTravelTypes, isFromLanding);
    const addressDetail = await _processAddressDetail(deepLinkData);
    processedDetail = {
      ...deepLinkData,
      ...dateTimeDetail,
      ...addressDetail,
    };
    isValid = validateDeepLinkData(processedDetail, deepLinkData.screen);
    if (isValid) {
      userDetails
        ? _trackDeepLinkRequest(deepLinkData, userDetails)
        : _trackDeepLinkRequest(deepLinkData);
    }
  }

  return {
    isValid,
    ...processedDetail,
    marketingHotelId,
    cmp,
    source
  };
};

export const getDeepLinkData = (data, screen = CabsScreen.LANDING) => {
  let deepLinkData = null;
  if (data) {
    const { deeplink = 'false' } = data;
    // For common URL handling in Desktop/RNW/SEO
    const KeyMap = {
      fromCity: 'from',
      toCity: 'to',
      departDate: 'startDate',
      category: 'cabType',
      returnDate: 'returnDate'
    };
    if (deeplink === 'true' || deeplink === true) {
      deepLinkData = _pick(data, [
        'pickupTime',
        'startDate',
        'returnDate',
        'dropTime',
        'tripType',
        'deeplink_url',
        'referrer',
        'airportPickupType',
        'cmp',
        'source',
        'cityCode',
        'terminal',
        'flightNumber',
        'from',
        'to',
        'fromCityName',
        'toCityName',
        'fromPlaceId',
        'toPlaceId',
        'packageKey',
        'cabType',
        'isCorporate',
        'openWidget',
        'flightBookingId',
        'parentScreen',
        'parentCabId',
        'bookingId',
        'is_provisional_instant',
        'category_id',
        'category',
        'cabPackage',
        'vendor_name',
        'safety_variant',
        'vehicle_model_type',
        'fuel_type',
        'fromLocus',
        'toLocus',
        'isLocus',
        'cityLocus',
        'stopsPlaceIds',
        'marketingHotelId',
        'fromCity',
        'toCity',
        'departDate',
        'stopOvers'
      ]);
      const updateDeepLinkData = (key, value) => {
        deepLinkData = { ...deepLinkData, [KeyMap[key]]: value, [key]: value };
      };

      for (const property in KeyMap) {
        if (property === 'departDate' || property === 'returnDate') {
          if (deepLinkData[property]?.includes('date')) {
            const [_, daysToAdd] = deepLinkData[property].split('_');
            const newDate = fecha.format(addDays(now(), parseInt(daysToAdd)), 'DD-MM-YYYY');
            updateDeepLinkData(property, newDate);
          } else {
            updateDeepLinkData(property, deepLinkData[property] || deepLinkData[KeyMap[property]]);
          }
        } else {
          updateDeepLinkData(property, deepLinkData[property] || deepLinkData[KeyMap[property]]);
        }
      }
      deepLinkData = { ...deepLinkData, screen };
    }
    //for Mweb
    if (deepLinkData?.startDate?.includes('date') && deepLinkData.startDate) {
      const rolledDate = deepLinkData?.startDate.split('_');
      let rolledDepartDate = addDays(now(), rolledDate[1]);
      rolledDepartDate = fecha.format(rolledDepartDate, 'DD-MM-YYYY');
      deepLinkData = {
        ...deepLinkData,
        startDate: rolledDepartDate,
      };
    }
    return deepLinkData;
  }
};
