import AsyncStorage from '@react-native-async-storage/async-storage';

// class is in Dev Process
export const KEY_FLIGHT_RECENT_SEARCH_HISTORY = 'recent_search:history';
export const KEY_FLIGHT_RECENT_SEARCH_HISTORY_CORP = 'recent_search:history_corp';
export const TRAVEL_PURPOSE_TIME = 'travel_purpose:time';
export const TRAVEL_PURPOSE_DATA = 'travel_purpose:data';
export const FAVOURITE_CITIES = 'recent_search:favourite';
export const MULTICITY_LAST_SEARCH = 'multicity_lastsearch';
export const KEY_HOLIDAY_LANDING_RESPONSE = 'landing_response';
export const KEY_HOLIDAY_WG_LANDING_RESPONSE = 'wg_landing_response';
export const KEY_USER_DEP_CITY = 'hol_user_dep_city';
export const KEY_USER_DEST_CITY = 'hol_user_dest_city_data';
export const KEY_USER_CITY_LOCUS_ID = 'hol_user_city_locus_id';
export const KEY_USER_CITY_SEARCH_TYPE = 'hol_user_city_search_type';
export const KEY_USER_CITY_SELECTION_TYPE = 'hol_user_city_selection_type';
export const KEY_APP_LAUNCH_COUNT = 'app_launch_count';
export const KEY_USER_DETAILS = 'hol_user_detail';
export const KEY_STORY_PACKAGE_MAP = 'hol_story_package_map';
export const KEY_TRAIN_RECENT_SEARCH_HISTORY = 'train_recent_search:history';
export const KEY_PNR_DETAIL_RECENT_SEARCH_HISTORY = 'pnr_detail_recent_search:history';
export const KEY_SEAT_AVAIL_RECENT_SEARCH_HISTORY = 'seat_availability_search:history';
export const KEY_TRAIN_SCHD_RECENT_SEARCH_HISTORY = 'train_schedule_search:history';
export const KEY_LIVE_STATION_RECENT_SEARCH_HISTORY = 'live_station_recent_search:history';
export const POPULAR_TRAIN_KEY = 'popular_trains'; // stores a list of popular trains
export const KEY_HOL_DETAIL_TOOLTIP_SHOWN = 'KEY_HOL_DETAIL_TOOLTIP_SHOWN';
export const KEY_HOL_META = 'KEY_HOL_META';
export const KEY_AFF_REF_ID = 'KEY_AFF_REF_ID';
export const KEY_CMP_META = 'KEY_CMP_META';
export const KEY_HOL_DETAIL_VISIT = 'KEY_HOL_DETAIL_VISIT';
export const RAILS_LANDING_VISIT_NUMBER = 'RAILS_LANDING_VISIT_NUMBER';
export const KEY_HOL_CUES_STEPS_SHOWN = 'KEY_HOL_CUES_STEPS_SHOWN'; // stores a list of cues steps already shown so that we can avoid to show duplicate steps during onboarding
export const KEY_HOL_ONBOARDING_PAGE_VISIT = 'KEY_HOL_ONBOARDING_PAGE_VISIT'; // stores last date when we shown onboarding coach marks/cues to the user (page wise)
export const KEY_HOL_VERTICAL_NUDGE_VISIT = 'KEY_HOL_VERTICAL_NUDGE_VISIT'; // stores last date when we shown onboarding coach marks/cues to the user (page wise)

export const setDataInStorage = async (key, data) => {
  try {
    AsyncStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.log('err', e);
  }
};

export const getDataFromStorage = async (key) => {
  try {
    const data = await AsyncStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  } catch (e) {
    console.log('err', e);
    return null;
  }
};

export const getDataFromWebLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }
  return null;
}

export const removeDataFromStorage = async (key) => {
  try {
    AsyncStorage.removeItem(key);
  } catch (e) {
    console.log('err', e);
  }
};
