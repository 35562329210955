import {NativeModules, Platform} from 'react-native';
import startsWith from 'lodash/startsWith';
import isEmpty from 'lodash/isEmpty';

const LOGIN_GENERIC_HEADING = 'Login for the Best Travel Offers';

export const isUserLoggedIn = async () => {
  const {UserSessionModule} = NativeModules;
  return UserSessionModule && UserSessionModule.isUserLoggedIn();
};

export const performLogin = async (showAsBottomSheet = false, heading = LOGIN_GENERIC_HEADING, verifyMobile = false, verifyHeader = null, verifySubText = null) => {
  const {UserSessionModule} = NativeModules;
  return UserSessionModule && UserSessionModule.performLogin(showAsBottomSheet, heading, verifyMobile, verifyHeader, verifySubText);
};

export const performEditProfile = () => {
  const {UserSessionModule} = NativeModules;
  return UserSessionModule && UserSessionModule.performEditProfile();
}

export const ProfileType = {
  BUSINESS: 'BUSINESS',
  PERSONAL: 'PERSONAL'
};

export const isCorporateUser = async () => {
  const {UserSessionModule} = NativeModules;
  return UserSessionModule.isCorporateUser();
};

export const getMmtAuth = async () => {
  if (Platform.OS === 'web') {
    // eslint-disable-next-line global-require
    const {default: cookies} = require('react-cookies');
    return Promise.resolve(cookies.load('mmt-auth'));
  }
  const {mmtAuth} = await getUserDetails();
  return mmtAuth;
};


export const isPersonalUser = (userDetails) => {
  const {profileType} = userDetails;
  return profileType !== ProfileType.BUSINESS;
};

export const getUserDetails = async () => {
  const {UserSessionModule} = NativeModules;
  try {
    const userDetails = await UserSessionModule.getUserDetails();
    return _parseUserDetails(userDetails)();
  } catch (error) {
    return {};
  }
};

export const shouldShowGSTNWidget = async () => {
  if(Platform.OS === 'web') {
    const {UserSessionModule} = NativeModules;
    return UserSessionModule.showGSTNWidget();
  };
  let userLoggedIn = await isUserLoggedIn();
  if (!userLoggedIn) {
    return true;
  }

  let userDetails = await getUserDetails();
  if (userDetails?.state) {
    return false;
  }

  return true;
};


const _parseUserDetails = userDetails => Platform.select({
  android: () => _parseUserDetailsAndroid(userDetails),
  ios: () => _parseUserDetailsIos(userDetails),
  web: () => userDetails
});

const _parseUserDetailsAndroid = (userDetails) => {
  const userDetailsObj = JSON.parse(userDetails);
  const {
    cupAge,
    cupCrdt,
    cupEmailid,
    cupFname,
    cupGender,
    cupLandline,
    cupLname,
    cupPrCty,
    cupProfileType,
    cupUpdt,
    emailVerified,
    uuid,
    imageUrl,
    loggedIn,
    loginType,
    mmtAuth,
    corpData = null,
    mmtAuthInHeaderFormat,
    mobileContactNumberList,
    verifiedMobileNumber
  } = userDetailsObj;
  let mobile;
  let mobileVerified = false
  if (verifiedMobileNumber && verifiedMobileNumber.length > 0) {
    mobile = verifiedMobileNumber[0];
    mobileVerified = true
  } else if (mobileContactNumberList && mobileContactNumberList.length > 0) {
    mobile = mobileContactNumberList[0];
  }

  let organizationId = null;
  if (!isEmpty(corpData)) {
    try {
      organizationId = corpData.employee.organizationId;
    } catch (e) {
      // Ignore exception
    }
  }

  return {
    firstName: cupFname,
    lastName: cupLname,
    email: cupEmailid,
    profileType: cupProfileType,
    uuid,
    imageUrl,
    gender: cupGender,
    mmtAuth,
    mmtAuthInHeaderFormat,
    loggedIn,
    loginType,
    mobile,
    verifiedMobileNumber,
    emailVerified,
    mobileVerified,
    organizationId,
    corpData,
    createdAt: new Date(cupCrdt)
  };
};

const _parseUserDetailsIos = (userDetails) => {
  const {
    FirstName,
    LastName,
    Email,
    dob,
    uuid,
    profileType,
    gender,
    imageUrl,
    loggedIn = true,
    loginChannel,
    mmtAuth,
    phoneContacts,
    verifiedNumbers,
    countryCode,
    Phone,
    isEmailVerified,
    isMobileVerified,
    organizationId = null
  } = userDetails;

  let {corpData = null} = userDetails;

  //Following Change in CorpData to make consistent for Both Android & iOS
  const roles = Boolean(corpData?.roles) ? corpData?.roles.split(',') : null;
  const blockLobs = Boolean(corpData?.blockLobs) ? corpData?.blockLobs.split('~') : null;
  corpData = {
    employee: {...corpData, roles, blockLobs}
  }

  let mobile = null;
  let verifiedMobileNumber;
  try {
    const mobileContactNumberList = JSON.parse(phoneContacts);
    verifiedMobileNumber = JSON.parse(verifiedNumbers);
    if (verifiedMobileNumber && verifiedMobileNumber.length > 0) {
      mobile = verifiedMobileNumber[0];
    } else if (mobileContactNumberList && mobileContactNumberList.length > 0) {
      mobile = mobileContactNumberList[0];
    }
  } catch (e) {
    // Ignore
  }

  if (mobile) {
    let {loginId: mobileNumber} = mobile;
    const code = mobile.countryCode;
    if (startsWith(mobileNumber, code)) {
      mobileNumber = mobileNumber.substr(code.length, mobileNumber.length - 1);
    }
    mobile = {countryCode: code, mobileNumber};
  } else {
    mobile = {countryCode, mobileNumber: Phone};
  }
  const mobileVerified = isMobileVerified === 'true';
  const emailVerified = isEmailVerified === 'true';
  return {
    firstName: FirstName,
    lastName: LastName,
    email: Email,
    imageUrl,
    gender,
    mmtAuth,
    loggedIn,
    loginType: loginChannel,
    mobile,
    verifiedMobileNumber,
    profileType,
    uuid,
    corpData,
    emailVerified,
    mobileVerified,
    organizationId
  };
};
