"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Actions = void 0;
exports.Actions = {
    exitApp: function () {
        window.mmtCabHistory.goBack();
    },
    canGoBack: function () {
        return true;
    },
    goBack: function () {
        window.mmtCabHistory.goBack();
    },
    pop: function () {
        window.mmtCabHistory.goBack();
    },
    cabs: function () {
        window.mmtCabHistory.push('/');
    },
    cabsCityPicker: function (params) {
        window.mmtCabHistory.push('/rnw/cabsCityPicker', params);
    },
    cabsCalendar: function (params) {
        window.mmtCabHistory.push('/rnw/cabsCalendar', params);
    },
    cabsListing: function (params) {
        window.mmtCabHistory.push('/cabsListing', params);
    },
    cabsReview: function (params) {
        window.mmtCabHistory.push('/cabsTripReview', params);
    },
    cabsFareBreakup: function (params) {
        window.mmtCabHistory.push('/rnw/cabsFareBreakup', params);
    },
    changeCabCoupon: function (params) {
        window.mmtCabHistory.push('/rnw/changeCabCoupon', params);
    },
    termsAndConditions: function (params) {
        window.mmtCabHistory.push('/rnw/termsAndConditions', params);
    },
    privacyPolicies: function (params) {
        window.mmtCabHistory.push('/rnw/privacyPolicies', params);
    },
    cancellationRules: function (params) {
        window.mmtCabHistory.push('/rnw/cancellationRules', params);
    },
    mmtAssuredCabsHowto: function (params) {
        window.mmtCabHistory.push('/rnw/mmtAssuredCabsHowto', params);
    },
    cabsThankYou: function (params) {
        window.mmtCabHistory.push('/cabsThankYou', params);
    },
    cabAddOnDetails: function (params) {
        window.mmtCabHistory.push('/cabAddOnDetails', params);
    },
    cabAddOnReview: function (params) {
        window.mmtCabHistory.push('/cabAddOnReview', params);
    },
    cabsAddOnThankYou: function (params) {
        window.mmtCabHistory.push('/cabsAddOnThankYou', params);
    },
    openWebView: function (params) {
        window.mmtCabHistory.push('/rnw/openWebView', params);
    },
    cabsRating: function (params) {
        window.mmtCabHistory.push('/rnw/cabsRating', params);
    },
    airportPrimeInfo: function (params) {
        window.mmtCabHistory.push('/airportPrimeInfo', params);
    },
    cabsFeedback: function (params) {
        window.mmtCabHistory.push('/overallRating', params);
    },
    cabsExtraFeedback: function (params) {
        window.mmtCabHistory.push('/cabsExtraFeedback', params);
    },
    cabsFeedbackThankyou: function (params) {
        window.mmtCabHistory.push('/cabsFeedbackThankyou', params);
    },
    cabsFeedbackError: function (params) {
        window.mmtCabHistory.push('/cabsFeedbackError', params);
    },
    cabInstaStory: function (params) {
        window.mmtCabHistory.push('/cabInstaStory', params);
    },
    cabsProvisionalDestPicker: function (params) {
        window.mmtCabHistory.push('/provisionalDestPicker', params);
    },
    cabsProvisionalDestPickerNonKiosk: function (params) {
        window.mmtCabHistory.push('/provisionalDestPickerNonKiosk', params);
    },
    cabsProvisionalDestPickerHOB: function () {
        window.mmtCabHistory.push('/provisionalDestPickerHOB');
    },
    cabsProvisional: function (params) {
        window.mmtCabHistory.push('/provisionalBooking', params);
    },
    cabsProvisionalHOBListing: function (params) {
        window.mmtCabHistory.push('/listingPageHOB', params);
    },
    kioskImageBurstMode: function (params) {
        window.mmtCabHistory.push('/kioskImageBurstMode', params);
    },
    cabRepresentativeImages: function (params) {
        window.mmtCabHistory.push('/cabRepresentativeImages', params);
    }
};
