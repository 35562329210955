import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';


const loggerMiddleware = createLogger({
  predicate: () => __DEV__
});

let _reducerTransformer = reducer => reducer;
export const registerReducerTransformer = (reducerTransformer) => {
  _reducerTransformer = reducerTransformer;
};

export const injectAsyncReducer = (name, asyncReducer) => {
  if (_store.asyncReducers[name]) {
    return;
  }
  // eslint-disable-next-line no-param-reassign
  _store.asyncReducers[name] = asyncReducer;
  _store.replaceReducer(createReducer(_store.asyncReducers));
};

const createReducer = (asyncReducers = {}) => combineReducers({
  ...asyncReducers
});

let _store;

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;

export const configureStore = (initialReducers, initialState, middlewares = []) => {
  const enhancer = composeEnhancers(applyMiddleware(
    thunkMiddleware,
    loggerMiddleware,
    ...middlewares
  ));
  _store = createStore(initialReducers, initialState, enhancer);
  _store.asyncReducers = {};
  return _store;
};


export const _getStore = () => _store;
