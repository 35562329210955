import fecha from 'fecha';
import {addDays, today} from './dateHelpers';

export const sleep = time => new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, time);
});

export function nth(n) { return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th'; }

export const objectToQueryParams = obj => Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&');

export const getQueryParamsFromUrl = (url) => {
  const urlSplits = decodeURIComponent(url).split('?');
  if (urlSplits.length < 2) return {};
  const pairs = urlSplits[1].split('&');
  const result = {};

  pairs.forEach((pair) => {
    const keyValues = pair.split('=');
    result[keyValues[0]] = decodeURIComponent(keyValues[1] || '');
  });

  return JSON.parse(JSON.stringify(result));
};

// returns date from URL in different formats => YYYYMMDD / d+1,d+2
export const getDateFromURLFormat = (date) => {
  try {
    let dateVal;
    const tempDate = date;
    if (tempDate && tempDate.includes('+')) {
      const dateArr = tempDate.split('+');
      dateVal = addDays(today(), Number(dateArr[1]));
    } else if (tempDate && tempDate.trim() && tempDate.includes(' ')) {
      const dateArr = tempDate.split(' ');
      dateVal = addDays(today(), Number(dateArr[1]));
    } else {
      dateVal = tempDate ? fecha.parse(tempDate, 'YYYYMMDD') : today();
      dateVal = dateVal || today();
    }
    return dateVal;
  } catch (e) {
    return today();
  }
};
