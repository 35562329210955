import { colors } from '../../Styles/globalStyles';

const EDIT_ICON = require('src/Assets/edit_blue.webp');
const TNC_CHECKED_ICON = require('src/Assets/ic-check-box-active.webp');
const TNC_CHECKED_ICON_MYBIZ = require('src/Assets/tncCheckedIconBiz.webp');
const TNC_UNCHECKED_ICON = require('src/Assets/ic-check-box-inactive.webp');

export const THEMES = {
  DEFAULT: 'default',
  CORP: 'corp',
};

export const DEFAULT_THEME = {
  type: THEMES.DEFAULT,
  landingSearchGradient: ['#53b2fe', '#065af3'],
  accentColor: colors.azure,
  accentColorDateCard: colors.darkBlue6,
  lightAccentColor: '#f4faff',
  lightBackground: colors.lightBlue5,
  lightBorder: colors.lightBlue4,
  backgroundGradient: ['#065af3', '#53b2ff'],
  searchBackground: colors.azure,
  editIcon: EDIT_ICON,
  inputFieldBackground: colors.lightBlue5,
  inputFieldLabel: colors.azure,
  checkedIcon: TNC_CHECKED_ICON,
  unCheckedIcon: TNC_UNCHECKED_ICON,
  bookButton: [colors.lightBlue, colors.darkBlue],
  filterAccent: colors.azure,
  filterButtonGradient: [colors.lightBlue, colors.darkBlue],
  swapImage: require('src/Assets/swap_v2.webp'),
  valueModelBgGradient: [colors.lightBlue8, colors.lightBlue9],
};

export const CORP_THEME = {
  ...DEFAULT_THEME,
  type: THEMES.CORP,
  landingSearchGradient: ['#ff7f3f', '#ff3e5e'],
  accentColor: colors.goldenYellow6,
  accentColorDateCard: colors.goldenYellow6,
  lightAccentColor: colors.lightGoldenYellow,
  lightBackground: colors.lightGoldenYellow1,
  lightBorder: colors.lightGoldenYellow2,
  backgroundGradient: ['#ff7f3f', '#ff3e5e'],
  searchBackground: colors.goldenYellow7,
  editIcon: EDIT_ICON,
  inputFieldBackground: colors.lightGoldenYellow3,
  inputFieldLabel: colors.goldenYellow8,
  checkedIcon: TNC_CHECKED_ICON_MYBIZ,
  unCheckedIcon: TNC_UNCHECKED_ICON,
  bookButton: [colors.goldenYellow10, colors.goldenYellow11],
  filterAccent: colors.goldenYellow9,
  filterButtonGradient: [colors.goldenYellow10, colors.goldenYellow11],
  swapImage: require('src/Assets/swap_business.webp'),
  valueModelBgGradient: ['rgba(248, 134, 70, 0.25)', 'rgba(251, 199, 84, 0.25)'],
};
