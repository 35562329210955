/* eslint-disable func-names */
import replace from 'lodash/replace';
import isEmpty from 'lodash/isEmpty';

export default function replaceAllInString(text, toFind, replacement) {
  return replace(text, new RegExp(toFind, 'g'), replacement);
}

export function StringBuilder(value) {
  this.strings = [];
  this.append(value);
}

StringBuilder.prototype.append = function (value) {
  if (value) {
    this.strings.push(value);
  }
};

StringBuilder.prototype.clear = function () {
  this.strings.length = 0;
};

StringBuilder.prototype.toString = function () {
  return this.strings.join('');
};

StringBuilder.prototype.length = function () {
  return this.strings.length;
};
export function convertStringtoTitleCase(str) {
  const strArray = str.toLowerCase().split(' ');
  for (let i = 0; i < strArray.length; i++) {
    strArray[i] = strArray[i].charAt(0).toUpperCase() + strArray[i].slice(1);
  }
  return strArray.join(' ');
}

export const isNonEmpty = string => !isEmpty(string);

/*
const pluralStr = getPluralString({
  none: 'no items',
  singular: 'one item',
  plural: '%d items',
  "2":'Invalid items'
})
pluralStr(0) === 'no items'
pluralStr(1) === 'one item'
pluralStr(2) === 'Invalid items'
pluralStr(3) === '3 items'
pluralStr(100) === '10 items'
* */
export const getPluralString = ({
  singular, plural, none, ...other
}) => (count) => {
  if (count <= 0) {
    return none.replace('%d', count);
  }
  if (count === 1) {
    return singular;
  }
  if (other[`${count}`]) {
    return other[`${count}`];
  }
  return plural.replace('%d', count);
};

export function joinStrings(listOfStrings, delimiter) {
  if (listOfStrings && listOfStrings.length > 0) {
    return listOfStrings.filter(Boolean).join(delimiter);
  }
  return '';
}

export function isAlphabeticalStringWithSpace(str) {
  const alphabetRegex = /^[A-Za-z ]+$/;
  return alphabetRegex.test(str);
}

export function isAlphaNumericStringWithoutSpace(str) {
  const  alphabetRegex = /^[a-z0-9]+$/i
  return alphabetRegex.test(str);
}

export const acronym = (name) => {
  try {
    if (!isNonEmpty(name)) return '';
    const matches = name.match(/\b(\w)/g);
    return matches.join('').substr(0, 2).toUpperCase();
  } catch (e) {
    console.log('ex', e);
  }
  return '';
};
