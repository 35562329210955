import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import {Platform, NativeModules} from 'react-native';
import fecha from 'fecha';
import {
  applicableCouponDetailsList,
  appliedCouponDetails,
  bookingDetails,
  cabDetails,
  cabOtherDetails,
  cabPricingDetailsList,
  cabSearchContext,
  carCategoryVendorSelected,
  itineraryDetailsList,
  travellerDetailsList
} from '../PdtModel/CabsPdtTemplate';
import {diffDays, now, today} from '../../../../Helpers/dateHelpers';

export const isRawClient = () => Platform.OS === 'web';
let commonHeadersObj = null;

export const getSearchEventData = (searchData) => {
  if (_isEmpty(searchData)) {
    return null;
  }

  const {
    sourceData,
    destinationData,
    departDate,
    returnDate,
    sourceCityCode,
    destinationCityCode,
    tripType,
    locusInfo = null
  } = searchData;

  const pickUpDateEpoch = !_isNil(departDate) ? departDate.getTime().toString() : null;
  const returnDateEpoch = !_isNil(returnDate) ? returnDate.getTime().toString() : null;
  const advancePurchase = !_isNil(departDate) ? parseInt(diffDays(departDate, today()), 10) : null;
  const isDepartDateEmpty = _isNil(departDate);
  const isReturnDateEmpty = _isNil(returnDate);
  const isSourceEmpty = _isEmpty(sourceData);
  const isDestEmpty = _isEmpty(destinationData);
  const country = 'India';
  let travelDuration = null;
  if (!isDepartDateEmpty && !isReturnDateEmpty) {
    travelDuration = parseInt(diffDays(returnDate, departDate), 10);
  }

  const itineraryData = {
    from_city_name: isSourceEmpty ? null : sourceData.city,
    departure_date: isDepartDateEmpty ? null : fecha.format(new Date(departDate), 'YYYY-MM-DD'),
    from_country_name: country,
    to_country_name: country,
    from_city_code: sourceCityCode,
    departure_date_epoch: pickUpDateEpoch,
    to_city_name: isDestEmpty ? null : destinationData.city,
    to_city_code: destinationCityCode
  };

  const itineraryDetails = itineraryDetailsList(itineraryData);
  const pdtData = {
    pickup_time_epoch: pickUpDateEpoch,
    return_date_epoch: returnDateEpoch,
    from_city_name: isSourceEmpty ? null : sourceData.city,
    drop_time_epoch: returnDateEpoch,
    from_country_name: country,
    from_city_code: sourceCityCode,
    travel_duration: travelDuration,
    to_city_name: isDestEmpty ? null : destinationData.city,
    to_city_code: destinationCityCode,
    itinerary_details_list: itineraryDetails,
    advance_purchase: advancePurchase,
    drop_time: isReturnDateEmpty ? null : fecha.format(new Date(returnDate), 'HH:mm'),
    to_country_name: country,
    pickup_time: isDepartDateEmpty ? null : fecha.format(new Date(departDate), 'HH:mm').toString(),
    return_date: isReturnDateEmpty ? null : fecha.format(new Date(returnDate), 'YYYY-MM-DD'),
    journey_type: tripType,
    ...locusInfo
  };
  return cabSearchContext(pdtData);
};

export const getCabData = (cabData) => {
  if (_isEmpty(cabData)) {
    return null;
  }
  const {
    vendor_name,
    overall_rating,
    vehicle_model,
    cabType,
    utilities
  } = cabData;
  const utilitiesLength = utilities.length;
  const pdtData = {
    cab_vendor_name: vendor_name,
    cab_rating: overall_rating,
    cab_model: vehicle_model,
    cab_category: cabType,
    sitting_capacity: utilitiesLength === 3 ? Number(utilities[1].split(' ')[0]) : null,
    luggage_capacity: utilitiesLength === 3 ? utilities[2] : null
  };
  return cabDetails(pdtData);
};

export const getCabOtherData = (cabData) => {
  if (_isEmpty(cabData)) {
    return null;
  }
  const {
    multi_vendor_tag,
    approx_distance,
    per_km_extra_charge,
    alternate_cabs_count
  } = cabData;

  const pdtData = {
    is_alternate_cab_booked: multi_vendor_tag === 'RECOMMENDED',
    kilometers_included: approx_distance,
    extra_charges: {
      waiting_charges: null,
      extra_charges_per_hour: null,
      night_charges: null,
      extra_charges_per_kilometer: per_km_extra_charge
    },
    is_multiple_vendor_shown: alternate_cabs_count >= 1
  };
  return cabOtherDetails(pdtData);
};

export const getCarCategoryVendorSelected = (cabData) => {
  if (_isEmpty(cabData)) {
    return null;
  }
  const {
    total_amount,
    overall_rating,
    cabType
  } = cabData;

  const pdtData = {
    vendor_price: total_amount,
    vendor_rating: overall_rating,
    car_category: cabType
  };

  return carCategoryVendorSelected(pdtData);
};

export const getCabPricingDetailList = (cabsData) => {
  if (_isEmpty(cabsData)) {
    return null;
  }
  const pricingDetails = [];
  cabsData.forEach((cabData) => {
    const {
      total_amount
    } = cabData;
    const pdtData = {
      total_fare: total_amount
    };
    pricingDetails.push(cabPricingDetailsList(pdtData));
  });
  return pricingDetails;
};

export const getApplicableCouponDetailsList = (cabData, couponData) => {
  if (_isEmpty(couponData)) {
    return null;
  }
  const couponDetails = [];
  couponData.forEach((coupon) => {
    const {
      coupon_code,
      message,
      user_message,
      discount
    } = coupon;
    const pdtData = {
      coupon_code,
      coupon_message: message,
      coupon_recommended_message: user_message,
      coupon_discount_amount: discount,
      coupon_success_message: message,
      is_preapplied_coupon: !_isEmpty(cabData) && coupon_code === cabData.coupon_code
    };
    couponDetails.push(applicableCouponDetailsList(pdtData));
  });
  return couponDetails;
};

export const getAppliedCouponDetails = (cabData, couponData) => {
  if (_isEmpty(couponData)) {
    return null;
  }
  let couponDetails = null;
  couponData.forEach((coupon) => {
    const {
      coupon_code,
      message,
      user_message,
      discount
    } = coupon;
    const pdtData = {
      coupon_code,
      coupon_message: message,
      coupon_recommended_message: user_message,
      coupon_discount_amount: discount,
      coupon_success_message: message
    };
    if (!_isEmpty(cabData) && coupon_code === cabData.coupon_code) {
      couponDetails = appliedCouponDetails(pdtData);
    }
  });
  return couponDetails;
};

export const getTravellerDetailsList = (travellerData) => {
  if (_isEmpty(travellerData)) {
    return null;
  }
  const {
    email,
    mobile,
    countryCode = '91',
    name
  } = travellerData;
  const pdtData = {
    mobile_communication_id: mobile,
    email_communication_id: email,
    mobile_country_code: countryCode,
    first_name: name
  };
  return travellerDetailsList(pdtData);
};

export const getBookingDetails = (bookingData) => {
  if (_isEmpty(bookingData)) {
    return null;
  }
  const {
    mmt_booking_id,
    checkout_id,
    bookingStatus
  } = bookingData;
  const pdtData = {
    booking_created_timestamp: now().getTime(),
    booking_transaction_id: mmt_booking_id,
    checkout_id,
    booking_confirmation_status: bookingStatus
  };
  return bookingDetails(pdtData);
};

export const getCommonHeaders = async () => {
  if (commonHeadersObj) {
    return commonHeadersObj;
  }

  const {NetworkModule} = NativeModules;
  const commonHeaders = await NetworkModule.getHeaders();
  commonHeadersObj = {
    mcId: Platform.OS === 'ios' ? commonHeaders['usr-mcid'] : commonHeaders.mcid
  };
  return commonHeadersObj;
};
