"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.pushPurchaseEventToGtm = exports.getParamsForGTM = exports.pushGTMData = void 0;
var dateHelpers_1 = require("src/Helpers/dateHelpers");
var client_1 = require("MMT-UI/getPIIData/client");
exports.pushGTMData = function (data) {
    if (window === null || window === void 0 ? void 0 : window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push(data);
    }
};
exports.getParamsForGTM = function (data) {
    var _a = data.sourceLocation, sourceLocation = _a === void 0 ? {} : _a, _b = data.destinationLocation, destinationLocation = _b === void 0 ? {} : _b, _c = data.departureDate, departureDate = _c === void 0 ? '' : _c, _d = data.pickupTime, pickupTime = _d === void 0 ? '' : _d, _e = data.returnDate, returnDate = _e === void 0 ? '' : _e, _f = data.gtmTagCode, gtmTagCode = _f === void 0 ? '' : _f, _g = data.cabType, cabType = _g === void 0 ? '' : _g, _h = data.bookingAmount, bookingAmount = _h === void 0 ? '' : _h, _j = data.sourceCityName, sourceCityName = _j === void 0 ? '' : _j, _k = data.destinationCityName, destinationCityName = _k === void 0 ? '' : _k, _l = data.pageType, pageType = _l === void 0 ? '' : _l, _m = data.transactionId, transactionId = _m === void 0 ? null : _m;
    var source_city_id = (sourceLocation === null || sourceLocation === void 0 ? void 0 : sourceLocation.place_id) || '';
    var destination_city_id = (destinationLocation === null || destinationLocation === void 0 ? void 0 : destinationLocation.place_id) || '';
    var source_city_name = (sourceLocation === null || sourceLocation === void 0 ? void 0 : sourceLocation.city) || '';
    var destination_city_name = (destinationLocation === null || destinationLocation === void 0 ? void 0 : destinationLocation.city) || '';
    var dateObj = dateHelpers_1.getDateObjectFromDateAndTime(departureDate, 'DD-MM-YYYY', pickupTime, 'HH:mm');
    var ap = dateHelpers_1.diffDays(dateObj, dateHelpers_1.now());
    var params = {
        source_city_id: source_city_id,
        destination_city_id: destination_city_id,
        source_city_name: sourceCityName,
        destination_city_name: destinationCityName,
        trip_type: gtmTagCode,
        ap: ap,
        departure_date: departureDate,
        return_date: returnDate,
        event: 'RT_event',
        page_type: pageType
    };
    if (cabType) {
        params['cab_type'] = cabType;
    }
    if (bookingAmount) {
        params['booking_amount'] = bookingAmount;
    }
    if (transactionId) {
        params['transaction_id'] = transactionId;
    }
    return params;
};
/**
 * GTM event is sent on thankyou page for enhanced tracking of conversions
 * from meta where users might have searched on desktop and later made the booking on mweb
 */
function pushPurchaseEventToGtm() {
    return __awaiter(this, void 0, void 0, function () {
        var userDetails, hashedEmail, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, client_1.getUserInfoPIIClient()];
                case 1:
                    userDetails = _a.sent();
                    if (!(userDetails === null || userDetails === void 0 ? void 0 : userDetails.email)) return [3 /*break*/, 3];
                    return [4 /*yield*/, encryptUsingSha256(userDetails.email)];
                case 2:
                    hashedEmail = _a.sent();
                    // Push the hashed email into the Data Layer
                    if (hashedEmail) {
                        exports.pushGTMData({
                            event: 'hashedEmailReady',
                            hashedEmail: hashedEmail
                        });
                    }
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    err_1 = _a.sent();
                    console.log('Failed to push thank you page event to GTM');
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    });
}
exports.pushPurchaseEventToGtm = pushPurchaseEventToGtm;
function encryptUsingSha256(message) {
    return __awaiter(this, void 0, void 0, function () {
        var msgBuffer, hashBuffer, hashArray, hashHex, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    msgBuffer = new TextEncoder().encode(message);
                    return [4 /*yield*/, crypto.subtle.digest('SHA-256', msgBuffer)];
                case 1:
                    hashBuffer = _a.sent();
                    hashArray = Array.from(new Uint8Array(hashBuffer));
                    hashHex = hashArray.map(function (b) { return b.toString(16).padStart(2, '0'); }).join('');
                    return [2 /*return*/, hashHex];
                case 2:
                    e_1 = _a.sent();
                    // tslint:disable-next-line: no-console
                    console.log('exception while encrypting', e_1);
                    return [2 /*return*/, ''];
                case 3: return [2 /*return*/];
            }
        });
    });
}
