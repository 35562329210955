import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import _compact from 'lodash/compact';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import fecha from 'fecha';
import {
  adjustHourToSlot,
  getDateFromDateTime,
  getNearestNextSlotForTripType,
  getNearestReturnDate,
  getMinimumReturnDate,
} from '../cabsDateTimeHelpers';
import TripType, { AirportPickupType } from '../types/TripType';
import { getLocationSearchRadius } from '../cabsDynamicConfig';
import { CABS_DATE_FMT, CABS_TIME_FMT, MyBizTripChangeErrCode } from '../cabsConstants';
import { CabsScreen } from '../config/cabsScreen';
import logAction from '../../../Helpers/actionsLogger';
import CabsABConfigHelper from './CabsABConfigHelper';

export const getReturnDate = (departDate, returnDateStr = null, dropTimeStr = null, returnMinimum = false) => {
  let returnDate = null;
  try {
    const minReturnDate = returnMinimum ? getMinimumReturnDate(departDate) : getNearestReturnDate(departDate);
    if (!_isNull(returnDateStr) && !_isNull(dropTimeStr)) {
      returnDate = getDateFromDateTime(returnDateStr, dropTimeStr);
      if (returnDate < minReturnDate) {
        returnDate = minReturnDate;
      } else {
        adjustHourToSlot(returnDate);
      }
    } else {
      returnDate = minReturnDate;
    }
  } catch (e) {
    returnDate = getNearestReturnDate(getNearestNextSlotForTripType(TripType.RT.value));
  }
  return returnDate;
};

export const getHeaderDate = (fullDate) => {
  if (!fullDate) {
    return null;
  }
  return fecha.format(fullDate, 'DD MMM, hh:mm A');
};

export const getTravelDateTime = (request) => {
  const { departure_date, return_date, pickup_time: pickupTime, drop_time: dropTime } = request;
  const departDate = fecha.parse(departure_date, CABS_DATE_FMT);
  const returnDate = return_date ? fecha.parse(return_date, CABS_DATE_FMT) : null;
  const pickupTimeDateObj = fecha.parse(pickupTime, CABS_TIME_FMT);
  departDate.setHours(pickupTimeDateObj.getHours(), pickupTimeDateObj.getMinutes(), 0, 0);
  if (!_isNull(returnDate) && !_isEmpty(dropTime)) {
    const dropTimeDateObj = fecha.parse(dropTime, CABS_TIME_FMT);
    returnDate.setHours(dropTimeDateObj.getHours(), dropTimeDateObj.getMinutes(), 0, 0);
  }
  return _compact([departDate, returnDate]);
};

export const getSelectedAirportIndex = (source, destination) => {
  let index = null;
  if (source !== null && source.is_airport) {
    index = AirportPickupType.FROM.value;
  } else if (destination !== null && destination.is_airport) {
    index = AirportPickupType.TO.value;
  }
  if (index === null) {
    if (source?.address?.toLowerCase()?.includes('airport')) {
      index = AirportPickupType.FROM.value;
    } else if (destination?.address?.toLowerCase()?.includes('airport')) {
      index = AirportPickupType.TO.value;
    }
  }
  return index;
};

export const getSearchData = (recent_search) => {
  let searchData = {};
  if (!_isEmpty(recent_search)) {
    const {
      source_location,
      destination_location,
      trip_type,
      departure_date,
      pickup_time,
      return_date = null,
      drop_time = null,
      package_key = null,
    } = recent_search;

    // Departure date calculation
    const minDepartureDate = getNearestNextSlotForTripType(trip_type);
    let departDate = getDateFromDateTime(departure_date, pickup_time);
    if (departDate < minDepartureDate) {
      departDate = minDepartureDate;
    }
    searchData = {
      sourceData: source_location,
      sourceText: source_location.address,
      departDate,
      returnDate: null,
      destinationData: destination_location,
      destinationText: destination_location ? destination_location.address : '',
      packageKey: package_key,
      isRecentSearch: true,
    };

    switch (trip_type) {
      case TripType.RT.value:
        searchData.returnDate = getReturnDate(departDate, return_date, drop_time);
        break;
      case TripType.OW.value:
        searchData.returnDate = getReturnDate(departDate, return_date, drop_time);
        break;
      case TripType.AT.value:
        {
          const selectedAirportTypeIndex = getSelectedAirportIndex(
            source_location,
            destination_location,
          );
          if (selectedAirportTypeIndex !== null) {
            searchData.selectedAirportTypeIndex = selectedAirportTypeIndex;
          }
        }
        break;
      default:
        break;
    }
  }
  return searchData;
};

const _checkForDuplicateAddresses = (addresses) => {
  const length = addresses.length;
  for (let i = 0; i < length; i += 1) {
    for (let j = i + 1; j < length; j += 1) {
      if (addresses[i]?.place_id === addresses[j]?.place_id) {
        return true;
      }
    }
  }
  return false;
};

const _checkForEmptyAddresses = (addresses) => {
  let isEmptyAddress = false;
  addresses.forEach(address => {
    if (!address) {
      isEmptyAddress = true;
    }
  });
  return isEmptyAddress;
};

export const validateSearchRequest = (data, isReviewDestination = false) => {
  const {
    sourceData,
    destinationData,
    tripType,
    selectedAirportTypeIndex,
    additionalCityData,
    isNearByCheckRequired = false,
    nearByLocation = null,
    screenName = CabsScreen.LANDING,
    stopOvers = [],
  } = data;
  let error = null;
  let fieldSpecificErr = '';
  let errorEvent = `Mob_Cabs_${screenName}_Search_Error`;
  if (tripType !== TripType.HR.value && sourceData.address === destinationData.address) {
    error = 'From and To location should not be same';
    fieldSpecificErr = `_${tripType}_Source_Destination_loc_same`;
  } else if (tripType === TripType.AT.value) {
    if (selectedAirportTypeIndex === AirportPickupType.FROM.value) {
      if (!sourceData.is_airport) {
        error = 'Pickup address must be airport';
        fieldSpecificErr = `_${tripType}_Source_loc_not_an_airport`;
      }
    } else if (!destinationData.is_airport) {
      error = 'Drop address must be airport';
      fieldSpecificErr = `_${tripType}_Destination_loc_not_an_airport`;
    } else if (sourceData.is_city) {
      fieldSpecificErr = `_${tripType}_Source_loc_not_a_building`;
      error = 'Pickup address should be of a building, street or locality';
    }
  } else if (screenName === CabsScreen.REVIEW) {
    if (sourceData.is_city) {
      fieldSpecificErr = '_Source_loc_not_a_building';
      error = 'Pickup address should be of a building, street or locality';
    }
  }
  if (isNearByCheckRequired) {
    let isLocationWithInCity = true;
    if (isReviewDestination) {
      // Checking if destination is with in city
      isLocationWithInCity = isLocationNearBy(destinationData, nearByLocation);
    } else {
      // checking if source is with in city
      isLocationWithInCity = isLocationNearBy(sourceData, nearByLocation);
    }
    if (!isLocationWithInCity) {
      fieldSpecificErr = '_Nearby_location_incorrect';
      error = 'Please enter location within same city';
    }
  }
  if (sourceData?.place_id === destinationData?.place_id && tripType === TripType.OW.value) {
    error =
      'From and To location should not be the same for One Way trips. Please select a round trip';
    fieldSpecificErr = `_${tripType}_Source_Destination_loc_same`;
  } else if (stopOvers?.length > 0) {
    const stopOverLocations = stopOvers.map((item) => item.location);
    const allAddresses = [...stopOverLocations, sourceData, destinationData];
    if (_checkForEmptyAddresses(allAddresses)) {
      error = 'Please enter all the locations';
      fieldSpecificErr = '_Stop_address_empty';
    } else if (_checkForDuplicateAddresses(allAddresses)) {
      error = 'Looks like 2 or more locations are same. Please check!';
      fieldSpecificErr = '_Duplicate_loc';
    }
  }
  errorEvent = `${errorEvent}${fieldSpecificErr}`;
  return { error, errorEvent };
};

export const getAirportTypeFromCityCode = (source) => {
  let newAirportIndex = AirportPickupType.TO.value;
  if (source !== null && source.includes('AIR')) {
    newAirportIndex = AirportPickupType.FROM.value;
  }
  return newAirportIndex;
};

export const handleTripTypeChange = (listingResponse, request) => {
  let message = '';
  let changeTripType = true;
  let listingAvailable = false;

  if (listingResponse.status === 'SUCCESS') {
    // const cabLength = _get(listingResponse, 'response.cab_list.length', 0);
    const cabLength =
      (listingResponse?.response?.cab_list?.length || 0) +
      (listingResponse?.response?.rap_cab_list?.length || 0);
    if (cabLength > 0) {
      // Update the trip_type of request
      listingAvailable = true;
      if (request.trip_type !== listingResponse.request.trip_type) {
        changeTripType = false;
        switch (listingResponse.request.trip_type) {
          case TripType.OW.value:
            message =
              'You are being re-directed to Outstation Cabs page since your travel is not within the same city.';
            break;
          case TripType.AT.value:
            message =
              'You are being re-directed to Airport Cabs page since your travel is within the same city.';
            break;
          case TripType.HR.value:
            message =
              `Sorry! We don't serve outstation trips within city. Redirecting you to Hourly Rental Cabs for intracity travel. `;
            break;
          default:
            break;
        }
      }
    }
    logAction('cabs search results', cabLength);
  } else {
    const errList = listingResponse?.errors?.error_list;
    if (Boolean(errList.length) && errList[0].code === MyBizTripChangeErrCode) {
      changeTripType = false;
      message = errList[0].message;
    }
    logAction('cabs search failed');
  }
  return {
    message,
    changeTripType,
    listingAvailable,
  };
};

export const getCityNameFromLocation = (location) => {
  let name = '';
  if (location) {
    name = _isEmpty(location.city) ? location.address : location.city;
  }
  return name;
};

export const getTripChangePopUpEvents = (tripType, screenName) => {
  let popupShownEvent = '';
  let continueEvent = '';
  let backEvent = '';
  switch (tripType) {
    case TripType.AT.value:
      popupShownEvent = `Mob_Cabs_${screenName}_Local_AT_Pop_Up`;
      continueEvent = `Mob_Cabs_${screenName}_Local_AT_Pop_Up_Continue`;
      backEvent = `Mob_Cabs_${screenName}_Local_AT_Pop_Up_Back`;
      break;
    case TripType.HR.value:
      popupShownEvent = `Mob_Cabs_${screenName}_Local_HR_Pop_Up`;
      continueEvent = `Mob_Cabs_${screenName}_Local_HR_Pop_Up_Redirection_Continue`;
      backEvent = `Mob_Cabs_${screenName}_Local_HR_Pop_Up_Back`;
      break;
    case TripType.OW.value:
      popupShownEvent = `Mob_Cabs_${screenName}_Outstation_AT_Pop_Up`;
      continueEvent = `Mob_Cabs_${screenName}_Outstation_AT_Pop_Up_Continue`;
      backEvent = `Mob_Cabs_${screenName}_Outstation_AT_Pop_Up_Back`;
      break;
    default:
      break;
  }
  return {
    popupShownEvent,
    continueEvent,
    backEvent,
  };
};

export const isLocationNearBy = (location, nearByLocation) => {
  try {
    const locationSearchRadius = getLocationSearchRadius();
    const latitudeLocation = location.latitude;
    const longitudeLocation = location.longitude;
    const latitudeNearByLocation = nearByLocation.latitude;
    const longitudeNearByLocation = nearByLocation.longitude;
    const radLatitudeLocation = Math.PI * (latitudeLocation / 180);
    const radLatitudeNearByLocation = Math.PI * (latitudeNearByLocation / 180);
    const theta = longitudeLocation - longitudeNearByLocation;
    const radtheta = Math.PI * (theta / 180);
    let dist =
      (Math.sin(radLatitudeLocation) * Math.sin(radLatitudeNearByLocation) +
        Math.cos(radLatitudeLocation) * Math.cos(radLatitudeNearByLocation)) *
      Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist *= 180 / Math.PI;
    dist *= 60 * 1.1515;
    dist *= 1.609344;
    return dist <= locationSearchRadius;
  } catch (e) {
    return false;
  }
};
