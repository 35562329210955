import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import fecha from 'fecha';
import {CABS_DATE_FMT, CABS_TIME_FMT} from '../../cabsConstants';
import TripType from '../TripType';

const validateSearchServerRequest = (searchRequest, componentName) => {
  const {
    source_location,
    destination_location,
    trip_type,
    departure_date,
    return_date,
    pickup_time,
    drop_time
  } = searchRequest;
  if (!_isObject(source_location)) {
    return new Error(`Invalid source_location passed to ${componentName}`);
  }
  if (!_isObject(destination_location)) {
    return new Error(`Invalid destination_location passed to ${componentName}`);
  }
  if (!_isString(departure_date)) {
    return new Error(`Invalid departure_date passed to ${componentName}`);
  }
  try {
    const date = fecha.parse(departure_date, CABS_DATE_FMT);
    if (date === null) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error();
    }
  } catch (e) {
    return new Error(`Invalid departure_date passed to ${componentName}`);
  }

  if (trip_type !== TripType.OW.value || trip_type !== TripType.RT.value) {
    return new Error(`Invalid trip_type passed to ${componentName}`);
  }

  if (!_isString(pickup_time)) {
    return new Error(`Invalid pickup_time passed to ${componentName}`);
  }

  try {
    const date = fecha.parse(pickup_time, CABS_TIME_FMT);
    if (date === null) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error();
    }
  } catch (e) {
    return new Error(`Invalid pickup_time passed to ${componentName}`);
  }


  if (trip_type === TripType.OW.value) {
    return null; // Ignore validations of return trip values
  }
  if (!_isString(return_date)) {
    return new Error(`Invalid return_date passed to ${componentName}`);
  }
  try {
    const date = fecha.parse(return_date, CABS_DATE_FMT);
    if (date === null) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error();
    }
  } catch (e) {
    return new Error(`Invalid return_date passed to ${componentName}`);
  }

  if (!_isString(drop_time)) {
    return new Error(`Invalid drop_time passed to ${componentName}`);
  }

  try {
    const date = fecha.parse(drop_time, CABS_TIME_FMT);
    if (date === null) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error();
    }
  } catch (e) {
    return new Error(`Invalid drop_time passed to ${componentName}`);
  }
  return null;
};


export default validateSearchServerRequest;
