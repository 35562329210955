/* eslint-disable no-undef */
import {NativeModules} from 'react-native';

const _trackState = (pageName, params, isLoadEvent) => {
  // console.log('_trackState: ', pageName, params)
  // eslint-disable-next-line no-unused-vars
  s.linkTrackVars = 'channel,eVar1,eVar15,eVar24,eVar34,prop24,eVar75,eVar47';
  s.eVar75 = 'PWA_RNW_Direct';
  const keys = Object.keys(params);
  keys.forEach((key) => {
    const newKey = key.replace('m_ch','channel').replace('m_c', 'prop').replace('m_v', 'eVar').replace('m_', '');
    s.linkTrackVars += `,${newKey}`;
    s[newKey] = params[key];
  });
  s.eVar1 = 'PWA_RNW';
  s.eVar15 = pageName;
  s.pageName = pageName;
  s.eVar18 = s.getVisitNum();
  const isThankYouPage = pageName.indexOf('thankyou') >= 0;
  
  if (isLoadEvent) {
    s.events="scOpen,event11,event30,prodView,event325";
    if(isThankYouPage) {
      s.events = s.events + ',purchase';
    }
    const sCode = s.t(this, 'o', pageName);
    if (sCode) {
      document.write(sCode);
    }
  } else {
    const sCode = s.tl(this, 'o', pageName);
    if (sCode) {
      document.write(sCode);
    }
  }
};

const _trackStateSafe = (...args) => {
  try {
    _trackState(...args);
  } catch (e) {
    // ignore
  }
};

NativeModules.OmnitureModule = {
  trackState: _trackStateSafe
};
