const CabsLandingTestID = {
  Landing_CoachMarkButton: 'coach_mark_button',
  Landing_AirportTab: 'airport_tab',
  Landing_OutStationTab: 'out_station_tab',
  Landing_SelfDriveTab: 'self_drive_tab',
  Landing_HourlyRentalTab: 'hourly_rental_tab',
  Landing_SwapButton: 'swap_button',
  Landing_OW_Button: 'ow_button',
  Landing_RT_Button: 'rt_button',
  Landing_TO_AIRPORT_Button: 'to_airport_button',
  Landing_FROM_AIRPORT_Button: 'from_airport_button',
  Landing_SearchButtom: 'search_button',
  Landing_PackageSection: 'package_section',
  Landing_PickUpTime: 'pick_up_time',
  Landing_DropTime: 'drop_time',
};

const CabsListingID = {
  Listing_IntermediateRapCard: 'intermediate_rap_cab',
  Listing_IntermediateNonRapCard: 'intermediate_non_rap_cab',
  Listing_IntermediateViewAllRydeButton: 'intermediate_view_all_rap_button',
  Listing_IntermediateViewAllOtherButton: 'intermediate_view_all_other_button',
  Listing_MySafetyGuidelinesButton: 'mysafety_guidelines_button'
};

const CabsReviewID = {
  Review_FareDetails: 'fare_details',
  Review_ViewDetails: 'view_details',
  Review_HideDetails: 'hide_details',
  Review_TnCBox: 'tnc_box',
  Review_SafetyGuidelines_ReadMoreButton: 'safety_gulidelines_read_more_button',
  Review_PickUpAddress: 'pickup_address',
  Review_NameSection: 'name_section',
  Review_EmailSection: 'email_section',
  Review_MobileSection: 'mobile_section',
  Review_CancellationInfo_ReadMoreButton: 'cancellation_info_read_more_button',
  Review_ImportantInfo_ViewDetailsButton: 'important_info_view_details_button',
  Review_PartialPaymentButton: 'partial_payment_button',
  Review_FullPaymentButton: 'full_payment_button',
  Review_PaymentOptionsToggleButton: 'payment_options_toggle_button',
};

const CabsCommonID = {
  Common_BackButton: 'back_button',
  Common_HeaderEditDateTime: 'header_edit_date_time',
};

export const CabsTestIDs = {
  ...CabsLandingTestID,
  ...CabsListingID,
  ...CabsReviewID,
  ...CabsCommonID,
};

export const mapSegmentToId = {
  'Outstation': CabsTestIDs.Landing_OutStationTab,
  'Airport Cabs': CabsTestIDs.Landing_AirportTab,
  'Self Drive': CabsTestIDs.Landing_SelfDriveTab,
  'Rentals': CabsTestIDs.Landing_HourlyRentalTab
}

export const TripTypeID = {
  OW: 'OW',
  RT: 'RT',
  FROM_AIRPORT: 'FROM_AIRPORT',
  TO_AIRPORT: 'TO_AIRPORT'
}

export const getTestIdForTabView = (type) => {
  switch(type) {
    case TripTypeID.OW:
      return CabsTestIDs.Landing_OW_Button
    case TripTypeID.RT:
      return CabsTestIDs.Landing_RT_Button
    case TripTypeID.FROM_AIRPORT:
      return CabsTestIDs.Landing_FROM_AIRPORT_Button
    case TripTypeID.TO_AIRPORT:
      return CabsTestIDs.Landing_TO_AIRPORT_Button
    default:
      return 'unknown';
  }
}

export const getTestIdForCabCard = (categoryId, isParent = false) => {
  switch(isParent) {
    case true:
      return `${categoryId}_cab_card`
    case false:
      return `${categoryId}_child_book_now_button`
  }
}

export const getTestIdToToggleCabCard = (categoryId, toExpand = false) => {
  switch(toExpand) {
    case true:
      return `${categoryId}_view_all_button`
    case false:
      return `${categoryId}_hide_button`
  }
}

export const getTestIDtoToggleReadBeforeYouBook = (type, toCollapse = false) => {
  switch(toCollapse) {
    case true:
      return `${type}_to_collpase`
    case false:
      return `${type}_to_expand`
  }
}

export const ReadBeforeYouBookSectionIDs = {
  IMPORTANT_INFO: 'important_info',
  EXTRA_CHARGES: 'extra_charges',
  PRICE_INCLUDED: 'included',
  CANCELLATION_INFO: 'cancellation_info',
  LUGGAGE_INFO: 'luggage_info',
};