import * as constants from './ProvisionalBookingActions';

const initialState = {
  destinationName: '',
  destinationLocation: {},
  flight_Id: '',
  provisionalResponse: null
};

const ProvisionalBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.ACTION_UPDATE_DESTINTION_NAME: {
      return {
        ...state,
        destinationName: action.data,
      };
    }
    case constants.ACTION_SET_DESTINATION_LOCATION: {
      return {
        ...state,
        destinationLocation: action.data,
      };
    }
    case constants.ACTION_SET_FLIGHT_ID: {
      return {
        ...state,
        flight_Id: action.data,
      };
    }
    case constants.ACTION_UPDATE_PROV_RESPONSE: {
      return {
        ...state,
        provisionalResponse: action.data,
      }
    }
    default: {
      return state;
    }
  }
};

export default ProvisionalBookingReducer;
